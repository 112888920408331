import {DashboardInMemoryRepository, DashboardRepository} from '../../core/repositories/DashboardRepository';
import {DashboardApiRepository} from '../repositories/DashboardApiRepository';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {ServiceFactory} from './ServiceFactory';
import {
	ComplianceHistory,
	ContemporaneousHistory,
	PolicyDashboardComparison,
	Property,
	TimescoreForPeriod,
	UserComplianceResult,
	UserContemporaneousResult,
	UserGoal,
	UserTimescoreForPeriod,
	UserTimescoreHistory,
	VelocityHistory,
} from '../../core/Models';
import {FakeData} from '../../data/FakeData';
import {VelocityInMemoryRepository, VelocityRepository} from '../../core/repositories/VelocityRepository';
import {VelocityApiRepository} from '../repositories/VelocityApiRepository';
import {ComplianceInMemoryRepository, ComplianceRepository} from '../../core/repositories/ComplianceRepository';
import {ComplianceApiRepository} from '../repositories/ComplianceApiRepository';
import {
	ContemporaneousInMemoryRepository,
	ContemporaneousRepository,
} from '../../core/repositories/ContemporaneousRepository';
import {ContemporaneousApiRepository} from '../repositories/ContemporaneousApiRepository';
import {TimescoreInMemoryRepository, TimescoreRepository} from '../../core/repositories/TimescoreRepository';
import {TimescoreApiRepository} from '../repositories/TimescoreApiRepository';
import {
	UsersComplianceInMemoryRepository,
	UserscomplianceRepository,
} from '../../core/repositories/UsersComplianceRepository';
import {UsersComplianceApiRepository} from '../repositories/UsersComplianceApiRepository';
import {UsersGoalInMemoryRepository, UsersGoalRepository} from '../../core/repositories/UsersGoalRepository';
import {UsersGoalApiRepository} from '../repositories/UsersGoalApiRepository';
import {
	UsersContemporaneousInMemoryRepository,
	UsersContemporaneousRepository,
} from '../../core/repositories/UsersContemporaneousRepository';
import {UsersContemporaneousApiRepository} from '../repositories/UsersContemporaneousApiRepository';
import {PolicyInMemoryrepository, PolicyRepository} from '../../core/repositories/PolicyServiceRepository';
import {PolicyApiRepository, PolicyApiRepositoryForHR} from '../repositories/PolicyApiRepository';
import {PeriodsInMemoryRepository, PeriodsRepository} from '../../core/repositories/PeriodsRepository';
import {PeriodsApiRepository} from '../repositories/PeriodsApiRepository';
import * as moment from 'moment';
import {PolicyEditorInMemoryRepository, PolicyEditorRepository} from '../../core/repositories/PolicyEditorRepository';
import {PolicyEditorApiRepository} from '../repositories/PolicyEditorApiRepository';
import {Policy, PolicyReader} from '../../core/models/Policy';
import {PropertiesInMemoryRepository, PropertiesRepository} from '../../core/repositories/propertiesRepository';
import {PropertiesApiRepository} from '../repositories/propertiesApiRepository';
import {Environment, EnvironmentKind} from '../Routes';
import {PolicyEditorUsersRepository} from '../../core/repositories/policyEditorUsersRepository';
import {PolicyEditorUsersApiRepository} from '../repositories/policyEditorUsersApiRepository';

export class RepositoryFactory {
	private static dashboardRepository: DashboardRepository;
	private static complianceRepository: ComplianceRepository;
	private static velocityRepository: VelocityRepository;
	private static contemporaneousRepository: ContemporaneousRepository;
	private static timescoreRepository: TimescoreRepository;
	private static usersComplianceRepository: UserscomplianceRepository;
	private static usersComplianceRepositoryForHr: UserscomplianceRepository;
	private static usersGoalRepository: UsersGoalRepository;
	private static usersContemporaneousRepository: UsersContemporaneousRepository;
	private static policyRepository: PolicyRepository;
	private static periodsRepository: PeriodsRepository;
	private static policyEditorRepository: PolicyEditorRepository;
	private static propertiesRepository: PropertiesRepository;
	private static isMocked: boolean = false;
	private static policyRepositoryForHr: PolicyRepository;
	private static periodsRepositoryForHr: PeriodsRepository;
	static environment: Environment;
	private static policyEditorUsersRepository: PolicyEditorUsersRepository;

	static initializeEnvironment(environmentKind: EnvironmentKind) {
		switch (environmentKind) {
			case EnvironmentKind.mocked:
				RepositoryFactory.isMocked = true;
				break;
			case EnvironmentKind.devNorthAmerica:
				RepositoryFactory.environment = Environment.createForDevNorthAmerica();
				break;
			case EnvironmentKind.prodNorthAmerica:
				RepositoryFactory.environment = Environment.createForProdNorthAmerica();
				break;
			case EnvironmentKind.prodUk:
				RepositoryFactory.environment = Environment.createForProdUk();
				break;
			case EnvironmentKind.prodCanada:
				RepositoryFactory.environment = Environment.createForProdCanada();
				break;
			case EnvironmentKind.prodAustralia:
				RepositoryFactory.environment = Environment.createForProdAustralia();
				break;
			case EnvironmentKind.prodEu:
				RepositoryFactory.environment = Environment.createForProdEu();
		}
	}

	static getDashboardRepository() {
		if (RepositoryFactory.dashboardRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.dashboardRepository = new DashboardInMemoryRepository(
						PolicyDashboardComparison.createfrom(FakeData.policyDashboardComparisonDTO)
				  ))
				: (RepositoryFactory.dashboardRepository = new DashboardApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getPeriodsService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.dashboardRepository;
	}

	static getVelocityRepository() {
		if (RepositoryFactory.velocityRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.velocityRepository = new VelocityInMemoryRepository(
						FakeData.velocityHistoriesDTO.map((vhDTO) => VelocityHistory.createFrom(vhDTO))
				  ))
				: (RepositoryFactory.velocityRepository = new VelocityApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.velocityRepository;
	}

	static getComplianceRepository() {
		if (RepositoryFactory.complianceRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.complianceRepository = new ComplianceInMemoryRepository(
						FakeData.complianceHistoriesDTO.map((chDTO) => ComplianceHistory.createFrom(chDTO))
				  ))
				: (RepositoryFactory.complianceRepository = new ComplianceApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.complianceRepository;
	}

	static getContemporaneousRepository() {
		if (RepositoryFactory.contemporaneousRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.contemporaneousRepository = new ContemporaneousInMemoryRepository(
						FakeData.contemporaneousHistoriesDTO.map((chDTO) => ContemporaneousHistory.createFrom(chDTO))
				  ))
				: (RepositoryFactory.contemporaneousRepository = new ContemporaneousApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.contemporaneousRepository;
	}

	static getTimescoreRepository() {
		if (RepositoryFactory.timescoreRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.timescoreRepository = new TimescoreInMemoryRepository(
						FakeData.timescoreHistoryDTO.map((tfpDTO) => TimescoreForPeriod.createFrom(tfpDTO)),
						FakeData.usersTimescoresDTO.map((utfpDTO) => UserTimescoreForPeriod.createFrom(utfpDTO)),
						FakeData.userTimescoreHistoryDTO.map((uthDTO) => UserTimescoreHistory.createFrom(uthDTO))
				  ))
				: (RepositoryFactory.timescoreRepository = new TimescoreApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getPeriodsService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.timescoreRepository;
	}

	static getUsersComplianceRepository() {
		if (RepositoryFactory.usersComplianceRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.usersComplianceRepository = new UsersComplianceInMemoryRepository(
						FakeData.usersComplianceDTO.map((ucDTO) => UserComplianceResult.createFrom(ucDTO))
				  ))
				: (RepositoryFactory.usersComplianceRepository = new UsersComplianceApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getPeriodsService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.usersComplianceRepository;
	}

	static getUsersComplianceRepositoryForHr() {
		if (RepositoryFactory.usersComplianceRepositoryForHr == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.usersComplianceRepositoryForHr = new UsersComplianceInMemoryRepository(
						FakeData.usersComplianceDTO.map((ucDTO) => UserComplianceResult.createFrom(ucDTO))
				  ))
				: (RepositoryFactory.usersComplianceRepositoryForHr = new UsersComplianceApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyServiceHr(),
						ServiceFactory.getPeriodsServiceHr(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.usersComplianceRepositoryForHr;
	}

	static getUsersGoalRepository() {
		if (RepositoryFactory.usersGoalRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.usersGoalRepository = new UsersGoalInMemoryRepository(
						FakeData.usersGoalsDTO.map((udgDTO) => UserGoal.createFrom(udgDTO))
				  ))
				: (RepositoryFactory.usersGoalRepository = new UsersGoalApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getPeriodsService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.usersGoalRepository;
	}

	static getUsersContemporaneousRepository() {
		if (RepositoryFactory.usersContemporaneousRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.usersContemporaneousRepository = new UsersContemporaneousInMemoryRepository(
						FakeData.usersContemporaneousDTO.map((ucDTO) => UserContemporaneousResult.createFrom(ucDTO))
				  ))
				: (RepositoryFactory.usersContemporaneousRepository = new UsersContemporaneousApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.usersContemporaneousRepository;
	}

	static getPolicyRepository() {
		if (RepositoryFactory.policyRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.policyRepository = new PolicyInMemoryrepository(
						FakeData.policiesForSubscriptionDTO.map((pfsDTO) => PolicyReader.createFrom(pfsDTO))
				  ))
				: (RepositoryFactory.policyRepository = new PolicyApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.policyRepository;
	}

	static getPolicyRepositoryForHr() {
		if (RepositoryFactory.policyRepositoryForHr == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.policyRepositoryForHr = new PolicyInMemoryrepository(
						FakeData.policiesForSubscriptionDTO.map((pfsDTO) => PolicyReader.createFrom(pfsDTO))
				  ))
				: (RepositoryFactory.policyRepositoryForHr = new PolicyApiRepositoryForHR(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.policyRepositoryForHr;
	}

	static getPeriodsRepository() {
		if (RepositoryFactory.periodsRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.periodsRepository = new PeriodsInMemoryRepository(
						FakeData.policyPeriodsDTO.map((period) => moment(period)),
						FakeData.policyPeriodsDTOWithCurrentDate.map((period) => moment(period))
				  ))
				: (RepositoryFactory.periodsRepository = new PeriodsApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyService(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.periodsRepository;
	}

	static getPeriodsRepositoryForHr() {
		if (RepositoryFactory.periodsRepositoryForHr == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.periodsRepositoryForHr = new PeriodsInMemoryRepository(
						FakeData.policyPeriodsDTO.map((period) => moment(period)),
						FakeData.policyPeriodsDTO.map((period) => moment(period))
				  ))
				: (RepositoryFactory.periodsRepositoryForHr = new PeriodsApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getPolicyServiceHr(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.periodsRepositoryForHr;
	}

	static getPolicyEditorRepository() {
		if (RepositoryFactory.policyEditorRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.policyEditorRepository = new PolicyEditorInMemoryRepository(
						Policy.createFrom(FakeData.policyDTO),
						Policy.createDefault('Default policy')
				  ))
				: (RepositoryFactory.policyEditorRepository = new PolicyEditorApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.policyEditorRepository;
	}

	static getPropertiesRepository() {
		if (RepositoryFactory.propertiesRepository == null)
			RepositoryFactory.isMocked
				? (RepositoryFactory.propertiesRepository = new PropertiesInMemoryRepository(
						FakeData.propertiesFakeDto.map((pDTO) => Property.createFrom(pDTO)),
						[]
				  ))
				: (RepositoryFactory.propertiesRepository = new PropertiesApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getAuthenticationService()
				  ));

		return RepositoryFactory.propertiesRepository;
	}

	static getPolicyEditorUsersRepository() {
		if (RepositoryFactory.policyEditorUsersRepository == null)
			RepositoryFactory.isMocked
				? null
				: (RepositoryFactory.policyEditorUsersRepository = new PolicyEditorUsersApiRepository(
						RepositoryFactory.environment,
						RequestWrapper.create(),
						ServiceFactory.getAuthenticationService(),
						ServiceFactory.getPolicyService()
				  ));

		return RepositoryFactory.policyEditorUsersRepository;
	}
}
