import * as React from 'react';
import {SettingsInteractor} from './settingsInteractor';
import {WrapperReactState} from '../WrapperReactState';
import {InteractorFactory} from '../../../factories/InteractorFactory';

export const SettingsPanelWrapper = () =>
	WrapperReactState.create(SettingsPanel, InteractorFactory.getSettingsInteractor());

const SettingsPanel = (props: {interactor: SettingsInteractor}) => {
	const settingsPanel = props.interactor.shouldShowSettings ? (
		<div className="settings-panel">
			<h2>Settings</h2>
			<i className="icon-icon-close" onClick={props.interactor.onShowSettings} />
			<h4>Theme selection:</h4>
			<div className="themes">
				<div
					onClick={() => props.interactor.onChangeTheme('ADERANT')}
					className={props.interactor.theme == 'ADERANT' ? 'theme aderant selected' : 'theme aderant'}
				></div>
				<div
					onClick={() => props.interactor.onChangeTheme('DAWN')}
					className={props.interactor.theme == 'DAWN' ? 'theme dawn selected' : 'theme dawn'}
				></div>
				<div
					onClick={() => props.interactor.onChangeTheme('AQUA')}
					className={props.interactor.theme == 'AQUA' ? 'theme aqua selected' : 'theme aqua'}
				></div>
				<div
					onClick={() => props.interactor.onChangeTheme('BELLEFIELD')}
					className={
						props.interactor.theme == 'BELLEFIELD' ? 'theme bellefield selected' : 'theme bellefield'
					}
				></div>
			</div>
		</div>
	) : null;

	const settingsPanelOverlay = props.interactor.shouldShowSettings ? (
		<div className="settings-overlay" onClick={props.interactor.onShowSettings} />
	) : null;

	return (
		<a className="nav-link">
			<i className="icon-icon-settings" onClick={props.interactor.onShowSettings} />
			{settingsPanel}
			{settingsPanelOverlay}
		</a>
	);
};
