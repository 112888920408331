import {reverse} from 'lodash';
import * as moment from 'moment';
import {tap} from 'rxjs/operators';
import {ComplianceKind, PeriodKind} from '../../../core/ModelContracts';
import {ComplianceHistory, UserComplianceResult} from '../../../core/Models';
import {ComplianceHistoryService} from '../../../core/services/ComplianceHistoryService';
import {PropertiesService} from '../../../core/services/propertiesService';
import {UsersComplianceResultsService} from '../../../core/services/UsersComplianceResultsService';
import {INotificationService} from '../../appServices/INotificationService';
import {LocaleService} from '../../appServices/LocaleService';
import {PolicyService} from '../../appServices/PolicyService';
import {ChartData, ChartThemeColors} from '../shared/chart/ChartComponent';
import {InteractorBase} from '../shared/InteractorBase';
import {INavigationService} from '../../appServices/INavigationService';
import {
	ExcelUsersComplianceAcrossMonthsService,
	ExcelUsersComplianceService,
} from '../../appServices/ExportToExcelService';
import {forkJoin, Observable} from 'rxjs';
import {orderBy} from 'lodash';

type Column = {Header: string; accessor: string};

export class ComplianceHistoryInteractor extends InteractorBase {
	private readonly initialPeriodKind = 'M';
	private readonly _complianceHistoryKinds: PeriodKind[] = ['D', 'W', 'M'];
	private _currentPeriodKind: PeriodKind = this.initialPeriodKind;
	private readonly ROCKSTARS = 0;
	private readonly COMPLIANT = 1;
	private readonly NOT_COMPLIANT = 2;
	private readonly DATE_FORMAT = 'YYYY-MM-DD';
	private readonly MONTH_FORMAT = 'MMM YYYY';
	private _userCompliances: UserComplianceResult[] = [];
	currentChartElement: '' | 'NOT_COMPLIANT' | 'COMPLIANT' | 'ROCKSTAR';
	private _formattedDate: string;
	private _complianceType: 'Compliant' | 'Not Compliant' | 'Rockstars';

	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private complianceHistoryService: ComplianceHistoryService,
		private policyService: PolicyService,
		private userComplianceResultsService: UsersComplianceResultsService,
		private propertiesService: PropertiesService
	) {
		super(navigationService, notificationService);

		this.complianceHistoryService.complianceHistoriesStream
			.pipe(tap(this.notificationService.hideLoader))
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream
			.pipe(tap(this.resetCurrentPeriod), tap(this.notificationService.showLoader))
			.subscribe();

		this.propertiesService.changeThemeStream.subscribe(this.updateView);
	}

	private resetCurrentPeriod = () => (this._currentPeriodKind = this.initialPeriodKind);

	onPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this.notificationService.showLoader();
		this._currentPeriodKind = event.target.value as PeriodKind;
		this.onRequestComplianceHistory();
	};

	onRequestComplianceHistory = () => {
		this.complianceHistoryService.requestComplianceHistory(this._currentPeriodKind).subscribe();
	};

	prepareChartDataset = (): ChartData => {
		const totalCompliantSorted = reverse(
			this.complianceHistories.map((ch: ComplianceHistory) => ch.compliant_Count)
		);
		const totalNotCompliantSorted = reverse(
			this.complianceHistories.map((ch: ComplianceHistory) => ch.notCompliant_Count)
		);
		const totalRockStarsSorted = reverse(
			this.complianceHistories.map((ch: ComplianceHistory) => ch.rockstars_Count)
		);

		const getThemeColors = () => {
			if (this.propertiesService.theme == 'ADERANT') {
				return ChartThemeColors.aderant;
			}
			if (this.propertiesService.theme == 'BELLEFIELD') {
				return ChartThemeColors.bellefield;
			}
			if (this.propertiesService.theme == 'AQUA') {
				return ChartThemeColors.aqua;
			}
			if (this.propertiesService.theme == 'DAWN') {
				return ChartThemeColors.dawn;
			}
		};

		const dataset = [
			{
				label: 'Rockstars',
				data: totalRockStarsSorted,
				backgroundColor: getThemeColors()[0],
			},
			{
				label: 'Compliant',
				data: totalCompliantSorted,
				backgroundColor: getThemeColors()[1],
			},
			{
				label: 'Not Compliant',
				data: totalNotCompliantSorted,
				backgroundColor: getThemeColors()[2],
			},
		];

		return {
			labels: this.dateLabels(),
			dataset: dataset,
		};
	};

	private fromComplianceHistoryToDatesFormatted = (ch: ComplianceHistory) =>
		LocaleService.fromDateToLocaleFormattedPeriod(ch.date, this._currentPeriodKind);

	dateLabels = () => reverse(this.complianceHistories.map(this.fromComplianceHistoryToDatesFormatted));

	//bar
	onChartClick = (index: number, datasetIndex: number) => {
		const label = this.dateLabels()[index];
		this._formattedDate =
			this._currentPeriodKind == 'M'
				? ChartMonthLabelToDateConverter.getMonthlyFormattedDate(label)
				: moment(label, 'MM-DD-YYYY').format('YYYY-MM-DD');

		if (datasetIndex === this.NOT_COMPLIANT)
			return this.userComplianceResultsService
				.requestUserComplianceWithoutNotify(
					this._formattedDate,
					this._currentPeriodKind,
					ComplianceKind.NotCompliance
				)
				.pipe(
					tap(() => (this.currentChartElement = 'NOT_COMPLIANT')),
					tap((ucs) => (this._userCompliances = ucs)),
					tap(() => (this._complianceType = 'Not Compliant'))
				);

		if (datasetIndex === this.COMPLIANT)
			return this.userComplianceResultsService
				.requestUserComplianceWithoutNotify(
					this._formattedDate,
					this._currentPeriodKind,
					ComplianceKind.Compliance
				)
				.pipe(
					tap(() => (this.currentChartElement = 'COMPLIANT')),
					tap((ucs) => (this._userCompliances = ucs)),
					tap(() => (this._complianceType = 'Compliant'))
				);

		return this.userComplianceResultsService
			.requestUserComplianceWithoutNotify(this._formattedDate, this._currentPeriodKind, ComplianceKind.RockStar)
			.pipe(
				tap(() => (this.currentChartElement = 'ROCKSTAR')),
				tap((ucs) => (this._userCompliances = ucs)),
				tap(() => (this._complianceType = 'Rockstars'))
			);
	};

	onExportAllUsersAcrossMonthsToExcel = () => {
		this.notificationService.showLoader();
		const labels = this.dateLabels();
		let formattedLabels: string[] = [];
		let complianceResultsAcrossMonthsObservables: Observable<UserComplianceResult[]>[] = [];
		labels.forEach((label) => {
			const formattedDate =
				this._currentPeriodKind == 'M'
					? ChartMonthLabelToDateConverter.getMonthlyFormattedDate(label)
					: moment(label, 'MM-DD-YYYY').format('YYYY-MM-DD');
			complianceResultsAcrossMonthsObservables.push(
				this.userComplianceResultsService.requestUserComplianceWithoutNotify(
					formattedDate,
					this._currentPeriodKind
				)
			);
			formattedLabels.push(this.getDateForExcelFileName(formattedDate));
		});

		forkJoin(complianceResultsAcrossMonthsObservables).subscribe((ucResults) => {
			const orderUserComplianceResultByCompliance = (complianceResult: UserComplianceResult[]) =>
				orderBy(complianceResult, ['complianceStatus']);
			let ucResultsOrdered = ucResults.map(orderUserComplianceResultByCompliance);
			ExcelUsersComplianceAcrossMonthsService.generateFile(
				formattedLabels,
				ucResultsOrdered,
				this.getPeriodForExcelFileName()
			);
			this.notificationService.hideLoader();
			this.updateView();
		});
	};

	getDateForExcelFileName(date: string) {
		if (this.currentPeriodKind === 'M') return moment(date).format(this.MONTH_FORMAT);

		if (this.currentPeriodKind === 'W') return `${moment(date).startOf('week').format(this.DATE_FORMAT)}`;

		return moment(date).format(this.DATE_FORMAT);
	}

	getPeriodForExcelFileName = () => {
		if (this._currentPeriodKind == 'D') return 'Days';
		if (this._currentPeriodKind == 'W') return 'Weeks';
		return 'Months';
	};

	get complianceHistories() {
		return this.complianceHistoryService.complianceHistories;
	}

	get complianceHistoryKinds() {
		return this._complianceHistoryKinds;
	}

	get currentPeriodKind() {
		return this._currentPeriodKind;
	}

	get columns(): Column[] {
		return [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: 'Velocity (days)',
				accessor: 'averageVelocity',
			},
			{
				Header: 'Granularity',
				accessor: 'averageGranularity',
			},
			{
				Header: 'Work Hours',
				accessor: 'totalWorkHours',
			},
			{
				Header: 'Timescore',
				accessor: 'timescore',
			},
			{
				Header: 'Delinquent Timecards (hours)',
				accessor: 'totalHoursCreatedAfterGracePeriod',
			},
			{
				Header: this.currentChartElement === 'NOT_COMPLIANT' ? 'Penalty' : 'Reward',
				accessor: this.currentChartElement === 'NOT_COMPLIANT' ? 'totalCashPenalty' : 'totalCashReward',
			},
		];
	}

	exportToExcel = () => {
		ExcelUsersComplianceService.generateFile(
			this.getDateForExcelFileName(this._formattedDate).replace(/\s/g, ''),
			this._userCompliances,
			this._complianceType
		);
	};

	get usersComplianceResults() {
		return this._userCompliances;
	}
}

export class ChartMonthLabelToDateConverter {
	static getMonthFromStringDate(monthName: string) {
		const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		const monthIndex = months.map((m) => m.toLocaleLowerCase()).indexOf(monthName.toLocaleLowerCase());

		return monthIndex < 9 ? `0${monthIndex + 1}` : `${monthIndex + 1}`;
	}

	static getMonthlyFormattedDate = (label: string) => {
		const splittedLabel = label.split(' ');
		const month = splittedLabel[0];
		const year = splittedLabel[1];
		const formattedDate = `${year}-${ChartMonthLabelToDateConverter.getMonthFromStringDate(month)}-01`;
		return formattedDate;
	};
}
