import * as React from 'react';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {Layout} from '../shared/layout/Layout';
import {WrapperReactState} from '../shared/WrapperReactState';
import {WorkHoursOverviewInteractor} from './WorkHoursOverviewInteractor';
import ReactTable from 'react-table-v6';
import {PolicySelectorComponentWrapped} from '../shared/policySelector/PolicySelectorComponent';
import DatePicker from 'react-datepicker';
import {ReactTableHelper} from '../../helpers/ReactTableHelper';
import {Transcript} from '../../../core/Transcript';
import moment = require('moment');

export const WorkHoursOverviewComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getWorkHoursOverviewInteractor()}
		functionComponent={WorkHoursOverviewComponent}
	/>
);

const WorkHoursOverviewComponent = (props: {interactor: WorkHoursOverviewInteractor}) => {
	const isExportToExcelActive = () => props.interactor.usersGoal.length > 0;

	return (
		<Layout title={'Work Hours Overview'} navbarRight={<NavbarRight interactor={props.interactor} />}>
			<div className="container">
				<div className="export-to-excel-wrapper">
					<span
						className={isExportToExcelActive() ? 'export-to-excel' : 'export-to-excel inactive'}
						onClick={isExportToExcelActive() ? props.interactor.onExportUsersGoalToExcel : null}
					>
						Export to <i className="icon-icon-exportxls"></i>
					</span>
				</div>
				<ReactTable
					data={props.interactor.usersGoal}
					columns={props.interactor.columns}
					filterable={true}
					showPageJump={false}
					defaultFilterMethod={ReactTableHelper.filterCaseInsensitive}
				/>
			</div>
		</Layout>
	);
};

const NavbarRight = (props: {interactor: WorkHoursOverviewInteractor}) => {
	const fromKindToOptions = () =>
		props.interactor.usersGoalPeriodKinds.map((kind, i) => (
			<option key={i} value={kind}>
				{Transcript.transcriptKind(kind)}
			</option>
		));

	const fromPeriodsToOptions = () =>
		props.interactor.workHoursOverviewPeriodsForPolicy.map((period, i) => (
			<option key={i} value={period}>
				{props.interactor.formatPeriod(period)}
			</option>
		));

	const getDatePickerOrSelect = () => {
		if (props.interactor.currentPeriodKind == 'D')
			return (
				<DatePicker
					selected={moment(props.interactor.workHoursOverviewCurrentPeriod.format('YYYY-MM-DD')).toDate()}
					onChange={props.interactor.onDatePickerPeriodChange}
					popperPlacement="bottom-end"
					popperModifiers={{
						offset: {
							enabled: true,
							offset: '5px, 10px',
						},
						preventOverflow: {
							enabled: true,
							escapeWithReference: false,
							boundariesElement: 'viewport',
						},
					}}
				/>
			);
		return (
			<div className="select-wrapper">
				<select
					value={props.interactor.currentPeriodForPolicy}
					onChange={props.interactor.onMonthlyOrWeeklyPeriodChange}
				>
					{fromPeriodsToOptions()}
				</select>
			</div>
		);
	};

	return (
		<div className="navbar-right">
			<div className="select-wrapper">
				<PolicySelectorComponentWrapped />
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentPeriodKind} onChange={props.interactor.onPeriodKindChange}>
					{fromKindToOptions()}
				</select>
			</div>
			{getDatePickerOrSelect()}
		</div>
	);
};
