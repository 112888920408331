import {first} from 'lodash';
import * as React from 'react';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {Layout} from '../shared/layout/Layout';
import {WrapperReactState} from '../shared/WrapperReactState';
import {
	GraceDaysCalculator,
	GraceDaysForm,
	GraceDaysQuery,
	PolicyEditorGracePeriodInteractor,
} from './PolicyEditorGracePeriodInteractor';
import {PolicyEditorNameComponent} from './shared/PolicyEditorNameComponent';
import {LoadingInput} from './shared/LoadingInputComponent';
import {HeaderTabs} from './shared/HeaderTabsComponent';
import {NavbarRight} from './shared/NavbarRightComponent';
import moment = require('moment');
import {ServiceFactory} from '../../factories/ServiceFactory';
import {PolicyEditorActionsComponent} from './shared/PolicyEditorActionsComponent';

export const PolicyEditorGracePeriodWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPolicyEditorGracePeriodInteractor()}
		functionComponent={PolicyEditorGracePeriod}
	/>
);

export const PolicyEditorGracePeriod = (props: {interactor: PolicyEditorGracePeriodInteractor}) => {
	const monthlyGraceDaysToInputs = () =>
		props.interactor.deadLinesMonthlyGraceDays.map((v, i) => (
			<GraceDaysInput
				monthIndex={i}
				graceDays={v}
				key={i}
				onChangeGraceDays={props.interactor.onChangeMonthlyGraceDays}
				interactor={props.interactor}
			/>
		));

	return (
		<Layout title={'Policy Editor'} navbarRight={<NavbarRight />}>
			<div className="policy-editor-wrapper">
				<div className="policy-name-component">
					<PolicyEditorNameComponent />
					{ServiceFactory.getAuthenticationService().credentials.hasAdministerRole() ? (
						<PolicyEditorActionsComponent />
					) : null}
				</div>
				<HeaderTabs />
				<div className="grace-days">
					<p>
						Grace Days are the number of days after a month users still have time to enter time for the
						billing period.
					</p>
					<p>
						(Example: It is the end of April and you have 5 grace days setup for your timekeeping policy.
						Users will be able to submit time until May 5<sup>th</sup>)
					</p>
					<p>
						Default Weekly Grace Days:
						<input
							className="policy-editor-input"
							type="number"
							name={GraceDaysForm.WeeklyGraceDays}
							value={first(props.interactor.deadLinesWeekly.graceDays)}
							onChange={props.interactor.onChangeWeeklyGraceDays}
							onFocus={(e) => e.target.select()}
						/>
						<span>
							{props.interactor.dueDate ? `Previous week´s time due by: ${props.interactor.dueDate}` : ''}
						</span>
					</p>
					<div className="days-container">{monthlyGraceDaysToInputs()}</div>
					<p>
						Thrive policy allows you to set penalties and rewards based on compliance or non-compliance for
						each of the policy criteria.
					</p>
					<p>
						Your Firm's Penalty for Delinquent Submission:
						<input
							className="policy-editor-input"
							type="number"
							name={GraceDaysForm.PenaltyForDeliquentSubmission}
							value={props.interactor.deadLines.getPenaltyForDelinquentSubmission()}
							onChange={props.interactor.onChangeNotCompliance}
							onFocus={(e) => e.target.select()}
						/>
					</p>
					<p>
						Your Firm's Reward for Timely Submission:
						<input
							className="policy-editor-input"
							type="number"
							name={GraceDaysForm.RewardsForTimelySubmission}
							value={props.interactor.deadLines.getRewardsForTimelySubmission()}
							onChange={props.interactor.onChangeCompliance}
							onFocus={(e) => e.target.select()}
						/>
					</p>
				</div>
				<div className="button-container">
					<button className="btn btn-primary" onClick={props.interactor.onSave}>
						SAVE POLICY
					</button>
					{/* <button className="btn btn-outline-primary">GENERATE PDF</button> */}
				</div>
			</div>
		</Layout>
	);
};

interface GraceDaysInputProps {
	onChangeGraceDays: (monthIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
	monthIndex: number;
	graceDays: number;
	interactor: PolicyEditorGracePeriodInteractor;
}

const GraceDaysInput = (props: GraceDaysInputProps) => {
	const itemName = props.interactor.formattedMonths[props.monthIndex];
	const currentMonth = moment().get('month');
	const monthIndex = props.interactor.orderedMonthsIndexes[props.monthIndex];
	const graceDaysCalculator = GraceDaysCalculator.create(monthIndex, props.graceDays);
	const generateCurrentOrNextYearFormatted = () => {
		if (itemName.includes(moment().format('YYYY'))) {
			return graceDaysCalculator.generateCurrentYearFormatted(moment());
		}
		return graceDaysCalculator.generateCurrentYearFormatted(moment().add(1, 'year'));
	};

	const generateNextOrNextToNextYearFormatted = () => {
		if (itemName.includes(moment().format('YYYY'))) {
			return graceDaysCalculator.generateNextYearFormatted(moment());
		}
		return graceDaysCalculator.generateNextYearFormatted(moment().add(1, 'year'));
	};

	return (
		<div
			className={
				currentMonth === monthIndex && itemName.includes(moment().format('YYYY'))
					? 'days current-month'
					: 'days'
			}
		>
			<h2>{props.monthIndex + 1}</h2>
			<p>{itemName}</p>
			<input
				className="policy-editor-input"
				name={itemName}
				type="number"
				value={props.graceDays}
				onChange={props.onChangeGraceDays(props.monthIndex)}
				onFocus={(e) => e.target.select()}
			/>

			<p>GRACE DAYS</p>
			<div className="grace-days-date">
				<div className="year">{generateCurrentOrNextYearFormatted()}</div>
				<div className="year">{generateNextOrNextToNextYearFormatted()}</div>
			</div>
		</div>
	);
};
