import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyService} from '../appServices/PolicyService';
import {PeriodsService} from '../appServices/PeriodsService';
import {
	CallContext,
	PeriodKind,
	PolicyDashboardComparisonDTO,
	PolicyDashboardComparisonRequest,
	ServiceRequest,
	ServiceResponse,
} from '../../core/ModelContracts';
import {map} from 'rxjs/operators';
import {Maybe} from 'monet';
import {PolicyDashboardComparison} from '../../core/Models';
import {BackendRoutes, Environment, UrlBuilder} from '../Routes';
import {DashboardRepository} from '../../core/repositories/DashboardRepository';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class DashboardApiRepository implements DashboardRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private periodService: PeriodsService,
		private authenticationService: AuthenticationService
	) {}

	getBy = (periodType: PeriodKind) =>
		this.requestPolicyDashboardComparison(periodType).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((dcDto) => Maybe.fromNull(dcDto)),
			map((mdcDto) => mdcDto.map((dc) => PolicyDashboardComparison.createfrom(dc)))
		);

	requestPolicyDashboardComparison = (periodType: PeriodKind) => {
		const currentPeriodFormated = this.periodService.dashboardCurrentPeriod.format('YYYY-MM-DD');
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const dashboardUrl = urlBuilder.getBackendUrl(BackendRoutes.dashboard);
		const callContext = this.authenticationService.callContext;

		const request = PolicyDashboardComparisonRequest.create(
			this.policyService.currentPolicyId,
			periodType,
			currentPeriodFormated
		);
		const serviceRequest = ServiceRequest.create<PolicyDashboardComparisonRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<PolicyDashboardComparisonDTO>>(
			dashboardUrl,
			'POST',
			serviceRequest
		);
	};
}
