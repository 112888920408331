import {
	VelocityHistoryDTO,
	ContemporaneousHistoryDTO,
	PolicyDashboardComparisonDTO,
	ResultComparisonDTO,
	ComplianceHistoryDTO,
	PeriodKind,
	PolicyForSubscriptionDTO,
	TimescoreForPeriodDTO,
	UserTimescoreForPeriodDTO,
	UserTimescoreHistoryDTO,
	UserComplianceResultDTO,
	ComplianceKind,
	UsersGoalDTO,
	UserContemporaneousResultDTO,
	EngagementPropertiesDTO,
	PropertyDTO,
	PolicyMemberDTO,
	UsersResponseDTO,
	OwnersResponseDTO,
} from './ModelContracts';
import {NumberUtils, StringUtils} from './CoreUtils';
import {Observable} from 'rxjs';
import {first} from 'lodash';
import {Maybe} from 'monet';

export type getAllBy<T> = (periodKind: PeriodKind, complianceKind?: ComplianceKind) => Observable<T[]>;
export type getAllByWithPeriod<T> = (
	period: string,
	periodKind: PeriodKind,
	complianceKind?: ComplianceKind
) => Observable<T[]>;

export type Nullable<T> = T | null;

export type Entity = {id: string};

export class EntityQuery {
	static equals<T extends Entity>(a: T, b: T) {
		return a.id == b.id;
	}
	static getBy<T extends Entity>(entities: T[], entity: T) {
		return first(entities.filter((e) => EntityQuery.equals(e, entity)));
	}

	static getById<T extends Entity>(entities: T[], id: string): T {
		return first(entities.filter((e) => e.id === id));
	}

	static add<T extends Entity>(entities: T[], entity: T) {
		return entities.concat(entity);
	}

	static update<T extends Entity>(entities: T[], entity: T) {
		return entities.map((e) => (EntityQuery.equals(e, entity) ? entity : e));
	}

	static delete<T extends Entity>(entities: T[], entity: T) {
		return entities.filter((e) => !EntityQuery.equals(e, entity));
	}
}

export class VelocityHistory {
	public id: string;
	public date: Date;
	public averageVelocity: number;
	public dateFormatted: string;
	public kind: string;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(vhDTO: VelocityHistoryDTO) {
		let vh = new VelocityHistory();
		vh.date = new Date(vhDTO.Period);
		vh.averageVelocity = vhDTO.AverageVelocity;
		vh.dateFormatted = vhDTO.FormattedPeriod;
		vh.kind = vhDTO.PeriodType;

		return vh;
	}
}
export class ContemporaneousHistory {
	public id: string;
	public date: Date;
	public totalContemporaneousHoursPercent: number;
	public totalContemporaneousCardsPercent: number;
	public dateFormatted: string;
	public kind: string;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(chDTO: ContemporaneousHistoryDTO) {
		let ch = new ContemporaneousHistory();
		ch.date = new Date(chDTO.Period);
		ch.totalContemporaneousHoursPercent = chDTO.ContemporaneousHoursTotalPercent;
		ch.totalContemporaneousCardsPercent = chDTO.ContemporaneousCardsCountPercent;
		ch.dateFormatted = chDTO.FormattedPeriod;
		ch.kind = chDTO.PeriodType;

		return ch;
	}
}

export class PolicyDashboardComparison {
	id: string;
	policyId: number;
	date: Date;
	dateDisplay: string;
	periodGraceDays: number;
	periodAverageVelocity: Maybe<number>;
	periodAverageGranularity: Maybe<number>;
	firmTimeScore: string;
	contemporaneousHoursTotalPercent: number;
	contemporaneousCardsCountPercent: number;
	numberOfUsers: number;
	totalHoursCreatedAfterGracePeriod: number;
	totalTimecardsCreatedAfterGracePeriod: number;
	periodTotalWorkHours: number;
	totalBillableHoursCreatedAfterGracePeriod: number;
	totalBillableTimecardsCreatedAfterGracePeriod: number;
	periodTotalBillableWorkHours: number;
	totalTimekeepersUsingiTK: number;
	totalTimekeepersUsingDelegates: number;
	periodSystemAverageGranularity: Maybe<number>;
	periodSystemTimeScore: Maybe<string>;
	periodSystemAverageVelocity: Maybe<number>;
	periodConfiguredVelocityGoal: number;
	totalTimekeepersWithRewards: number;
	totalCashRewards: number;
	totalTimekeepersWithPenalties: number;
	totalCashPenalties: number;
	resultComparisons: ResultComparison[];

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static create() {
		return new PolicyDashboardComparison();
	}

	static createfrom(pdcDTO: PolicyDashboardComparisonDTO) {
		let pdc = new PolicyDashboardComparison();
		pdc.policyId = pdcDTO.PolicyId;
		pdc.date = new Date(pdcDTO.Period);
		pdc.dateDisplay = pdcDTO.PeriodDisplay;
		pdc.periodGraceDays = pdcDTO.PeriodGraceDays;
		pdc.periodAverageVelocity = Maybe.fromNull(pdcDTO.PeriodAverageVelocity);
		pdc.periodAverageGranularity = Maybe.fromNull(pdcDTO.PeriodAverageGranularity);
		pdc.firmTimeScore = pdcDTO.FirmTimeScore;
		pdc.contemporaneousHoursTotalPercent = pdcDTO.ContemporaneousHoursTotalPercent;
		pdc.contemporaneousCardsCountPercent = pdcDTO.ContemporaneousCardsCountPercent;
		pdc.numberOfUsers = pdcDTO.NumberOfUsers;
		pdc.totalHoursCreatedAfterGracePeriod = pdcDTO.TotalHoursCreatedAfterGracePeriod;
		pdc.totalTimecardsCreatedAfterGracePeriod = pdcDTO.TotalTimecardsCreatedAfterGracePeriod;
		pdc.periodTotalWorkHours = pdcDTO.PeriodTotalWorkHours;
		pdc.totalBillableHoursCreatedAfterGracePeriod = pdcDTO.TotalBillableHoursCreatedAfterGracePeriod;
		pdc.totalBillableTimecardsCreatedAfterGracePeriod = pdcDTO.TotalBillableTimecardsCreatedAfterGracePeriod;
		pdc.periodTotalBillableWorkHours = pdcDTO.PeriodTotalBillableWorkHours;
		pdc.totalTimekeepersUsingiTK = pdcDTO.TotalTimekeepersUsingiTK;
		pdc.totalTimekeepersUsingDelegates = pdcDTO.TotalTimekeepersUsingDelegates;
		pdc.periodSystemAverageGranularity = Maybe.fromNull(pdcDTO.PeriodSystemAverageGranularity);
		pdc.periodSystemTimeScore = Maybe.fromNull(pdcDTO.PeriodSystemTimeScore);
		pdc.periodSystemAverageVelocity = Maybe.fromNull(pdcDTO.PeriodSystemAverageVelocity);
		pdc.periodConfiguredVelocityGoal = pdcDTO.PeriodConfiguredVelocityGoal;
		pdc.totalTimekeepersWithRewards = pdcDTO.TotalTimekeepersWithRewards;
		pdc.totalCashRewards = pdcDTO.TotalCashRewards;
		pdc.totalTimekeepersWithPenalties = pdcDTO.TotalTimekeepersWithPenalties;
		pdc.totalCashPenalties = pdcDTO.TotalCashPenalties;
		pdc.resultComparisons = pdcDTO.ResultComparisons.map(ResultComparison.createFrom);

		return pdc;
	}
}

export class ResultComparison {
	id: string;
	complianceResult: number;
	numberOfUsers: number;
	percentageOfTotalUsers: number;
	numberOfUsersInPreviousPeriod: number;
	percentageOfTotalUsersInPreviousPeriod: number;
	percentageDifference: number;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(rcDTO: ResultComparisonDTO) {
		let rc = new ResultComparison();
		rc.complianceResult = rcDTO.ComplianceResult;
		rc.numberOfUsers = rcDTO.NumberOfUsers;
		rc.percentageOfTotalUsers = rcDTO.PercentageOfTotalUsers;
		rc.numberOfUsersInPreviousPeriod = rcDTO.NumberOfUsersInPreviousPeriod;
		rc.percentageOfTotalUsersInPreviousPeriod = rcDTO.PercentageOfTotalUsersInPreviousPeriod;
		rc.percentageDifference = rcDTO.PercentageDifference;

		return rc;
	}
}

export class ComplianceHistory {
	id: string;
	date: Date;
	policyId: number;
	rockstars_Count: number;
	compliant_Count: number;
	notCompliant_Count: number;
	graceDays: number;
	totalHoursCreatedAfterGracePeriod: number;
	totalTimecardsCreatedAfterGracePeriod: number;
	totalBillableHoursCreatedAfterGracePeriod: number;
	totalBillableTimecardsCreatedAfterGracePeriod: number;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(chDTO: ComplianceHistoryDTO) {
		let ch = new ComplianceHistory();
		ch.date = new Date(chDTO.Period);
		ch.policyId = chDTO.PolicyId;
		ch.rockstars_Count = chDTO.Rockstars_Count;
		ch.compliant_Count = chDTO.Compliant_Count;
		ch.notCompliant_Count = chDTO.NotCompliant_Count;
		ch.graceDays = chDTO.GraceDays;
		ch.totalHoursCreatedAfterGracePeriod = chDTO.TotalHoursCreatedAfterGracePeriod;
		ch.totalTimecardsCreatedAfterGracePeriod = chDTO.TotalTimecardsCreatedAfterGracePeriod;
		ch.totalBillableHoursCreatedAfterGracePeriod = chDTO.TotalBillableHoursCreatedAfterGracePeriod;
		ch.totalBillableTimecardsCreatedAfterGracePeriod = chDTO.TotalBillableTimecardsCreatedAfterGracePeriod;

		return ch;
	}
}

export class TimescoreForPeriod {
	id: string;
	timescore: string;
	numberOfUsers: number;
	percentageOfTotalUsers: number;
	numberOfUsersInPreviousPeriod: number;
	percentageOfTotalUsersInPreviousPeriod: number;
	percentageDifference: number;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(tfpDTO: TimescoreForPeriodDTO) {
		let tfp = new TimescoreForPeriod();
		tfp.timescore = tfpDTO.Timescore;
		tfp.numberOfUsers = tfpDTO.NumberOfUsers;
		tfp.percentageOfTotalUsers = tfpDTO.PercentageOfTotalUsers;
		tfp.numberOfUsersInPreviousPeriod = tfpDTO.NumberOfUsersInPreviousPeriod;
		tfp.percentageOfTotalUsersInPreviousPeriod = tfpDTO.PercentageOfTotalUsersInPreviousPeriod;
		tfp.percentageDifference = tfpDTO.PercentageDifference;

		return tfp;
	}
}

export class UserTimescoreForPeriod {
	id: string;
	currentTimescore: string;
	previousTimescore: string;
	userId: number;
	email: string;
	firstName: string;
	lastName: string;
	title: string;
	practiceArea: string;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(utfpDTO: UserTimescoreForPeriodDTO) {
		let utfp = new UserTimescoreForPeriod();
		utfp.currentTimescore = utfpDTO.Timescore ? utfpDTO.Timescore : '';
		utfp.previousTimescore = utfpDTO.PreviousTimescore ? utfpDTO.PreviousTimescore : '';
		utfp.userId = utfpDTO.UserId;
		utfp.email = utfpDTO.UserEmail;
		utfp.firstName = utfpDTO.UserFirstName;
		utfp.lastName = utfpDTO.UserLastName;
		utfp.title = utfpDTO.Title ? utfpDTO.Title : '';
		utfp.practiceArea = utfpDTO.PracticeArea ? utfpDTO.PracticeArea : '';

		return utfp;
	}
}

export type Score =
	| 'A+'
	| 'A '
	| 'A-'
	| 'B+'
	| 'B '
	| 'B-'
	| 'C+'
	| 'C '
	| 'C-'
	| 'D+'
	| 'D '
	| 'D-'
	| 'F+'
	| 'F '
	| 'F-';

export class UserTimescoreHistory {
	id: string;
	day: string;
	timescore: Score;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(uthDTO: UserTimescoreHistoryDTO) {
		let uth = new UserTimescoreHistory();
		uth.day = uthDTO.Day;
		uth.timescore = uthDTO.Timescore as Score;

		return uth;
	}

	static fromTimescoreToNumber = (timescore: Score) => {
		switch (timescore) {
			case 'A+':
				return 15;
			case 'A ':
				return 14;
			case 'A-':
				return 13;
			case 'B+':
				return 12;
			case 'B ':
				return 11;
			case 'B-':
				return 10;
			case 'C+':
				return 9;
			case 'C ':
				return 8;
			case 'C-':
				return 7;
			case 'D+':
				return 6;
			case 'D ':
				return 5;
			case 'D-':
				return 4;
			case 'F+':
				return 3;
			case 'F ':
				return 2;
			case 'F-':
				return 1;
			default:
				return 1;
		}
	};

	static fromNumberToTimeScore = (number: Number): Score => {
		switch (number) {
			case 15:
				return 'A+';
			case 14:
				return 'A ';
			case 13:
				return 'A-';
			case 12:
				return 'B+';
			case 11:
				return 'B ';
			case 10:
				return 'B-';
			case 9:
				return 'C+';
			case 8:
				return 'C ';
			case 7:
				return 'C-';
			case 6:
				return 'D+';
			case 5:
				return 'D ';
			case 4:
				return 'D-';
			case 3:
				return 'F+';
			case 2:
				return 'F ';
			case 1:
				return 'F-';
		}
	};
}

type ComplianceStatus = 'Not Compliant' | 'Compliant' | 'Rockstar';

export class UserComplianceResult {
	id: string;
	userId: number;
	email: string;
	firstName: string;
	lastName: string;
	complianceStatus: ComplianceStatus;
	totalCashReward: number;
	totalCashPenalty: number;
	averageVelocity: number;
	totalWorkHours: number;
	totalBillableWorkHours: number;
	averageGranularity: number;
	timescore: string;
	hadTimecardCreatedAfterGracePeriod: string;
	totalHoursCreatedAfterGracePeriod: number;
	totalBillableHoursCreatedAfterGracePeriod: number;
	assistantComplianceResult: number;
	velocityComplianceResult: number;
	minMaxHoursComplianceResult: number;
	itkUsageComplianceResult: number;
	itkCreatedByOwnerCount: number;
	itkCreatedByDelegateCount: number;
	problemReason: string;
	location: string;
	title: string;
	practiceArea: string;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(ucrDTO: UserComplianceResultDTO) {
		let ucr = new UserComplianceResult();
		ucr.userId = ucrDTO.UserId;
		ucr.email = ucrDTO.UserEmail;
		ucr.firstName = ucrDTO.UserFirstName;
		ucr.lastName = ucrDTO.UserLastName;
		ucr.complianceStatus = ucrDTO.ComplianceStatus as ComplianceStatus;
		ucr.totalCashReward = ucrDTO.TotalCashReward;
		ucr.totalCashPenalty = ucrDTO.TotalCashPenalty;
		ucr.averageVelocity = ucrDTO.AverageVelocity;
		ucr.totalWorkHours = ucrDTO.TotalWorkHours;
		ucr.totalBillableWorkHours = ucrDTO.TotalBillableWorkHours;
		ucr.averageGranularity = ucrDTO.AverageGranularity;
		ucr.timescore = ucrDTO.Timescore ? ucrDTO.Timescore : '';
		ucr.hadTimecardCreatedAfterGracePeriod = ucrDTO.HadTimecardCreatedAfterGracePeriod;
		ucr.totalHoursCreatedAfterGracePeriod = ucrDTO.TotalHoursCreatedAfterGracePeriod
			? ucrDTO.TotalHoursCreatedAfterGracePeriod
			: 0;
		ucr.totalBillableHoursCreatedAfterGracePeriod = ucrDTO.TotalBillableHoursCreatedAfterGracePeriod
			? ucrDTO.TotalBillableHoursCreatedAfterGracePeriod
			: 0;
		ucr.assistantComplianceResult = ucrDTO.AssistantComplianceResult;
		ucr.velocityComplianceResult = ucrDTO.VelocityComplianceResult;
		ucr.minMaxHoursComplianceResult = ucrDTO.MinMaxHoursComplianceResult;
		ucr.itkUsageComplianceResult = ucrDTO.ITKUsageComplianceResult;
		ucr.itkCreatedByOwnerCount = ucrDTO.ITKCreatedByOwnerCount;
		ucr.itkCreatedByDelegateCount = ucrDTO.ITKCreatedByDelegateCount;
		ucr.problemReason = ucrDTO.ProblemReason ? ucrDTO.ProblemReason : '';
		ucr.location = ucrDTO.Location ? ucrDTO.Location : '';
		ucr.title = ucrDTO.Title ? ucrDTO.Title : '';
		ucr.practiceArea = ucrDTO.PracticeArea ? ucrDTO.PracticeArea : '';

		return ucr;
	}
}

export class UserGoal {
	id: string;
	hours: string;
	goal: number;
	difference: string;
	userId: number;
	email: string;
	firstName: string;
	lastName: string;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}
	static createFrom(ugDTO: UsersGoalDTO) {
		let ug = new UserGoal();
		ug.hours = ugDTO.Hours.toFixed(2);
		ug.goal = ugDTO.Goal;
		ug.difference = ugDTO.Difference.toFixed(2);
		ug.userId = ugDTO.UserId;
		ug.email = ugDTO.UserEmail;
		ug.firstName = ugDTO.UserFirstName;
		ug.lastName = ugDTO.UserLastName;

		return ug;
	}
}

export class UserContemporaneousResult {
	id: string;
	contemporaneousHoursTotalPercent: string;
	contemporaneousCardsCountPercent: string;
	userId: number;
	email: string;
	firstName: string;
	lastName: string;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(ucrDTO: UserContemporaneousResultDTO) {
		let ucr = new UserContemporaneousResult();
		ucr.contemporaneousHoursTotalPercent = ucrDTO.ContemporaneousHoursTotalPercent.toFixed(2);
		ucr.contemporaneousCardsCountPercent = ucrDTO.ContemporaneousCardsCountPercent.toFixed(2);
		ucr.userId = ucrDTO.UserId;
		ucr.email = ucrDTO.UserEmail;
		ucr.firstName = ucrDTO.UserFirstName;
		ucr.lastName = ucrDTO.UserLastName;

		return ucr;
	}
}

export enum EngagementPropertyNames {
	TimetelligenceWeekly = 'TimeTelligence.Timekeeper.WeeklySummary',
	TimetelligenceMonthly = 'TimeTelligence.Timekeeper.MonthlySummary',
	BrazeDaily = 'iTK.Thrive.ThriveBrazeCampaigns.DailyReminder',
	BrazeWeekly = 'iTK.Thrive.ThriveBrazeCampaigns.WeeklyReminder',
}

export class EngagementProperties {
	id: string;
	timetelligenceWeekly: boolean;
	timetelligenceMonthly: boolean;
	brazeDaily: boolean;
	brazeWeekly: boolean;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(epDTO: EngagementPropertiesDTO) {
		let ep = new EngagementProperties();
		ep.timetelligenceWeekly = epDTO[EngagementPropertyNames.TimetelligenceWeekly];
		ep.timetelligenceMonthly = epDTO[EngagementPropertyNames.TimetelligenceMonthly];
		ep.brazeDaily = epDTO[EngagementPropertyNames.BrazeDaily];
		ep.brazeWeekly = epDTO[EngagementPropertyNames.BrazeWeekly];

		return ep;
	}
}

export class Property {
	id: string;
	name: string;
	value: string;
	level: number;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(pDTO: PropertyDTO) {
		let p = new Property();
		p.name = pDTO.PropertyName;
		p.value = pDTO.PropertyValue;
		p.level = pDTO.PropertyLevel;

		return p;
	}
}

export class PolicyMember {
	id: string;
	userId: number;
	mail: string;
	firstName: string;
	lastName: string;

	constructor() {
		this.id = NumberUtils.generateUUID();
	}

	static createFrom(userDTO: PolicyMemberDTO) {
		let user = new PolicyMember();
		user.userId = userDTO.UserId;
		user.mail = userDTO.UserName;
		user.firstName = userDTO.FirstName;
		user.lastName = userDTO.LastName;

		return user;
	}

	toString = () => `${this.firstName} ${this.lastName} ${this.mail}`;
}

export class PolicyUsersResponse {
	availableUsers: PolicyMember[];
	assignedUsers: PolicyMember[];

	static createFrom(urDTO: UsersResponseDTO) {
		let policyUsersResponse = new PolicyUsersResponse();
		policyUsersResponse.availableUsers = urDTO.AvailableUsers.map((u) => PolicyMember.createFrom(u));
		policyUsersResponse.assignedUsers = urDTO.AssignedUsers.map((u) => PolicyMember.createFrom(u));

		return policyUsersResponse;
	}
}

export class PolicyOwnersResponse {
	availableOwners: PolicyMember[];
	assignedOwners: PolicyMember[];

	static createFrom(orDTO: OwnersResponseDTO) {
		let policyOwnersResponse = new PolicyOwnersResponse();
		policyOwnersResponse.availableOwners = orDTO.AvailableOwners.map((o) => PolicyMember.createFrom(o));
		policyOwnersResponse.assignedOwners = orDTO.AssignedOwners.map((o) => PolicyMember.createFrom(o));

		return policyOwnersResponse;
	}
}
