import {tap} from 'rxjs/operators';
import {PolicyEditorService} from '../../../core/services/PolicyEditorService';
import {PolicyEditorUsersService} from '../../../core/services/policyEditorUsersService';
import {INavigationService} from '../../appServices/INavigationService';
import {INotificationService} from '../../appServices/INotificationService';
import {PolicyService} from '../../appServices/PolicyService';
import {InteractorBase} from '../shared/InteractorBase';

export class PolicyEditorUsersInteractor extends InteractorBase {
	private _queryForAvailableUsers: string = '';
	private _queryForAvailableOwners: string = '';
	private _queryForAssignedUsers: string = '';
	private _queryForAssignedOwners: string = '';
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private policyEditorUsersService: PolicyEditorUsersService,
		private policyService: PolicyService
	) {
		super(navigationService, notificationService);

		this.policyEditorUsersService.policyUsersStream
			.pipe(tap(this.notificationService.hideLoader))
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream.pipe(tap(this.notificationService.showLoader)).subscribe();
	}

	onRequestAddUser = (userId: number) => {
		this.notificationService.showLoader();
		return this.policyEditorUsersService.requestAddUser(userId).subscribe();
	};

	onRequestRemoveUser = (userId: number) => {
		this.notificationService.showLoader();
		return this.policyEditorUsersService.requestRemoveUser(userId).subscribe();
	};

	onRequestAddOwner = (ownerId: number) => {
		this.notificationService.showLoader();
		return this.policyEditorUsersService.requestAddOwner(ownerId).subscribe();
	};

	onRequestRemoveOwner = (ownerId: number) => {
		this.notificationService.showLoader();
		return this.policyEditorUsersService.requestRemoveOwner(ownerId).subscribe();
	};

	onFilterAvailableUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
		this._queryForAvailableUsers = e.target.value;
		this.updateView();
	};

	onClearAvailableUsersFilter = () => {
		this._queryForAvailableUsers = '';
		this.updateView();
	};

	onFilterAssignedUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
		this._queryForAssignedUsers = e.target.value;
		this.updateView();
	};

	onClearAssignedUsersFilter = () => {
		this._queryForAssignedUsers = '';
		this.updateView();
	};

	onFilterAvailableOwners = (e: React.ChangeEvent<HTMLInputElement>) => {
		this._queryForAvailableOwners = e.target.value;
		this.updateView();
	};

	onClearAvailableOwnersFilter = () => {
		this._queryForAvailableOwners = '';
		this.updateView();
	};

	onFilterAssignedOwners = (e: React.ChangeEvent<HTMLInputElement>) => {
		this._queryForAssignedOwners = e.target.value;
		this.updateView();
	};

	onClearAssignedOwnersFilter = () => {
		this._queryForAssignedOwners = '';
		this.updateView();
	};

	get availableUsers() {
		return this.policyEditorUsersService.policyAvailableUsers.filter((user) =>
			user.toString().toLocaleLowerCase().includes(this._queryForAvailableUsers.toLocaleLowerCase())
		);
	}

	get assignedUsers() {
		return this.policyEditorUsersService.policyAssignedUsers.filter((user) =>
			user.toString().toLocaleLowerCase().includes(this._queryForAssignedUsers.toLocaleLowerCase())
		);
	}

	get availableOwners() {
		return this.policyEditorUsersService.policyAvailableOwners.filter((owner) =>
			owner.toString().toLocaleLowerCase().includes(this._queryForAvailableOwners.toLocaleLowerCase())
		);
	}

	get assignedOwners() {
		return this.policyEditorUsersService.policyAssignedOwners.filter((owner) =>
			owner.toString().toLocaleLowerCase().includes(this._queryForAssignedOwners.toLocaleLowerCase())
		);
	}

	get queryForAvailableUsers() {
		return this._queryForAvailableUsers;
	}

	get queryForAvailableOwners() {
		return this._queryForAvailableOwners;
	}

	get queryForAssignedUsers() {
		return this._queryForAssignedUsers;
	}

	get queryForAssignedOwners() {
		return this._queryForAssignedOwners;
	}
}
