import {map} from 'rxjs/operators';
import {PolicyMapper} from '../../core/mappers/PolicyMapper';
import {
	CallContext,
	ServiceRequest,
	ServiceResponse,
	SetCampaignsEnabledStatusRequest,
	EngagementPropertiesDTO,
	SetEngagementPropertiesRequest,
} from '../../core/ModelContracts';
import {PolicyDTO} from '../../core/modelContracts/PolicyDto';
import {Policy} from '../../core/models/Policy';
import {PolicyEditorRepository} from '../../core/repositories/PolicyEditorRepository';
import {PolicyService} from '../appServices/PolicyService';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {BackendRoutes, Environment, UrlBuilder} from '../Routes';
import {of} from 'rxjs';
import {EngagementProperties} from '../../core/Models';
import {EngagementPropertiesMapper} from '../../core/mappers/EngagementPropertiesMapper';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class PolicyEditorApiRepository implements PolicyEditorRepository {
	constructor(
		private environmet: Environment,
		private requestHelper: RequestWrapper,
		private authenticationService: AuthenticationService
	) {}

	getPolicy = (policyId: number) =>
		this.requestPolicy(policyId).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((policyDTO) => Policy.createFrom(policyDTO))
		);

	requestPolicy = (policyId: number) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const getPolicyByIdUrl = urlBuilder.getBackendUrl(BackendRoutes.getPolicyById);
		const callContext = this.authenticationService.callContext;

		const request = policyId;
		const serviceRequest = ServiceRequest.create<number>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<PolicyDTO>>(getPolicyByIdUrl, 'POST', serviceRequest);
	};

	addPolicy = (policy: Policy) => {
		const mappedPolicy: PolicyDTO = PolicyMapper.fromPolicyToDto(policy);
		return this.requestAddPolicy(mappedPolicy).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((policyDTO) => Policy.createFrom(policyDTO))
		);
	};

	requestAddPolicy = (policyDTO: PolicyDTO) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const addPolicyUrl = urlBuilder.getBackendUrl(BackendRoutes.addPolicy);
		const callContext = this.authenticationService.callContext;

		const request = policyDTO;
		const serviceRequest = ServiceRequest.create<PolicyDTO>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<PolicyDTO>>(addPolicyUrl, 'POST', serviceRequest);
	};

	editPolicy = (policy: Policy) => {
		const mappedPolicy: PolicyDTO = PolicyMapper.fromPolicyToDto(policy);
		return this.requestEditPolicy(mappedPolicy).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((policyDTO) => Policy.createFrom(policyDTO))
		);
	};

	requestEditPolicy = (policyDTO: PolicyDTO) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const editPolicyUrl = urlBuilder.getBackendUrl(BackendRoutes.editPolicy);
		const callContext = this.authenticationService.callContext;

		const request = policyDTO;
		const serviceRequest = ServiceRequest.create<PolicyDTO>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<PolicyDTO>>(editPolicyUrl, 'POST', serviceRequest);
	};

	isPolicyEnabledById = (id: number) =>
		this.requestIsPolicyEnabledById(id).pipe(map((serviceResponse) => serviceResponse.Response));

	requestIsPolicyEnabledById = (id: number) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const isPolicyEnabledUrl = urlBuilder.getBackendUrl(BackendRoutes.isPolicyEnabled);
		const callContext = this.authenticationService.callContext;

		const request = id;
		const serviceRequest = ServiceRequest.create<number>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<boolean>>(isPolicyEnabledUrl, 'POST', serviceRequest);
	};

	enablePolicyById = (id: number) =>
		this.requestEnablePolicyById(id).pipe(map((serviceResponse) => serviceResponse.Response));

	requestEnablePolicyById = (id: number) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const finalizePolicyUrl = urlBuilder.getBackendUrl(BackendRoutes.finalizePolicy);
		const callContext = this.authenticationService.callContext;

		const request = id;
		const serviceRequest = ServiceRequest.create<number>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<{}>>(finalizePolicyUrl, 'POST', serviceRequest);
	};

	disablePolicyById = (id: number) =>
		this.requestDisablePolicyById(id).pipe(map((serviceResponse) => serviceResponse.Response));

	requestDisablePolicyById = (id: number) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const disablePolicyUrl = urlBuilder.getBackendUrl(BackendRoutes.disablePolicy);
		const callContext = this.authenticationService.callContext;

		const request = id;
		const serviceRequest = ServiceRequest.create<number>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<{}>>(disablePolicyUrl, 'POST', serviceRequest);
	};

	deletePolicyById = (id: number) =>
		this.requestDeletePolicyById(id).pipe(map((serviceResponse) => serviceResponse.Response));

	requestDeletePolicyById = (id: number) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const deletePolicyUrl = urlBuilder.getBackendUrl(BackendRoutes.deletePolicy);
		const callContext = this.authenticationService.callContext;

		const request = id;
		const serviceRequest = ServiceRequest.create<number>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<{}>>(deletePolicyUrl, 'POST', serviceRequest);
	};

	duplicatePolicy = (policy: Policy) => {
		const mappedPolicy: PolicyDTO = PolicyMapper.fromPolicyToDto(policy);
		return this.requestDuplicatePolicy(mappedPolicy).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((policyDTO) => Policy.createFrom(policyDTO))
		);
	};

	requestDuplicatePolicy = (policyDTO: PolicyDTO) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const duplicatePolicyUrl = urlBuilder.getBackendUrl(BackendRoutes.duplicatePolicy);
		const callContext = this.authenticationService.callContext;

		const request = policyDTO;
		const serviceRequest = ServiceRequest.create<PolicyDTO>({Request: request, CallContext: callContext});
		return this.requestHelper.makeRequest<ServiceResponse<PolicyDTO>>(duplicatePolicyUrl, 'POST', serviceRequest);
	};

	getEngagementProperties = (policyId: number) =>
		this.requestEngagementProperties(policyId).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((epDTO) => EngagementProperties.createFrom(epDTO))
		);

	private requestEngagementProperties = (policyId: number) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const engagementPropertiesUrl = urlBuilder.getBackendUrl(BackendRoutes.getCampaignsProperties);
		const callContext = this.authenticationService.callContext;

		const request = policyId;
		const serviceRequest = ServiceRequest.create<number>({
			Request: request,
			CallContext: callContext,
		});
		return this.requestHelper.makeRequest<ServiceResponse<EngagementPropertiesDTO>>(
			engagementPropertiesUrl,
			'POST',
			serviceRequest
		);
	};

	setEngagementPropertyStatus = (policy: Policy) => {
		const mappedProperties = EngagementPropertiesMapper.fromEngagementPropertiesToDto(policy);
		return this.requestSetEngagementPropertyStatus(mappedProperties).pipe(
			map((serviceResponse) => serviceResponse.Response)
		);
	};

	requestSetEngagementPropertyStatus = (properties: SetEngagementPropertiesRequest) => {
		const urlBuilder = new UrlBuilder(this.environmet.endpointBase);
		const setEngagementPropertiesUrl = urlBuilder.getBackendUrl(BackendRoutes.setCampaignsProperties);
		const callContext = this.authenticationService.callContext;

		const request = properties;
		const serviceRequest = ServiceRequest.create<SetEngagementPropertiesRequest>({
			Request: request,
			CallContext: callContext,
		});
		return this.requestHelper.makeRequest<ServiceResponse<{}>>(setEngagementPropertiesUrl, 'POST', serviceRequest);
	};
}
