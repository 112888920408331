import * as React from 'react';
import {ReactNode} from 'react';
import {Transcript} from '../../../core/Transcript';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {ChartComponent} from '../shared/chart/ChartComponent';
import {Layout} from '../shared/layout/Layout';
import {PolicySelectorComponentWrapped} from '../shared/policySelector/PolicySelectorComponent';
import {WrapperReactState} from '../shared/WrapperReactState';
import {ContemporaneousHistoryInteractor} from './ContemporaneousHistoryInteractor';
import ReactTable from 'react-table-v6';
import {ReactTableHelper} from '../../helpers/ReactTableHelper';

export const ContemporaneousHistoryComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getContemporaneousHistoryInteractor()}
		functionComponent={ContemporaneousHistoryComponent}
	/>
);

const ContemporaneousHistoryComponent = (props: {interactor: ContemporaneousHistoryInteractor}) => {
	const onChart = (index: number) => {
		const label = props.interactor.dateLabels()[index];
		props.interactor
			.onChartClick(index)
			.subscribe(() =>
				props.interactor.pushModal(<UserContemporaneousComponent interactor={props.interactor} label={label} />)
			);
	};

	return (
		<Layout title={'Contemporaneous History'} navbarRight={<NavbarRight interactor={props.interactor} />}>
			<GraphContainer>
				<ChartComponent
					data={props.interactor.prepareChartDataset()}
					kind="line"
					hasAnimated={true}
					legendPosition="bottom"
					showYAxeLabel={true}
					yAxeLabel="Percentage"
					onClick={onChart}
				/>
			</GraphContainer>
		</Layout>
	);
};

const UserContemporaneousComponent = (props: {interactor: ContemporaneousHistoryInteractor; label: string}) => {
	return (
		<div>
			<div className="table-header">
				<h4>{`Contemporaneous Time Entry (${props.label})`}</h4>
				<span className="export-to-excel" onClick={props.interactor.exportToExcel}>
					Export to <i className="icon-icon-exportxls"></i>
				</span>
			</div>
			<ReactTable
				data={props.interactor.userContemporaneousResults}
				columns={props.interactor.columns}
				filterable={true}
				showPageJump={false}
				defaultFilterMethod={ReactTableHelper.filterCaseInsensitive}
			/>
		</div>
	);
};

const NavbarRight = (props: {interactor: ContemporaneousHistoryInteractor}) => {
	const fromKindToOptions = () =>
		props.interactor.contemporaneousHistoryKinds.map((kind) => (
			<option key={kind} value={kind}>
				{Transcript.transcriptKind(kind)}
			</option>
		));

	return (
		<div className="navbar-right">
			<div className="select-wrapper">
				<PolicySelectorComponentWrapped />
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentPeriodKind} onChange={props.interactor.onPeriodChange}>
					{fromKindToOptions()}
				</select>
			</div>
		</div>
	);
};

const GraphContainer = (props: {children: ReactNode}) => (
	<div className="graphs-canvas">
		<div className="graph-title">
			<h4>Contemporaneous History</h4>
		</div>
		<div className="note">
			This line graph represents both the % of hours worked that your users entered contemporaneous and the % of
			timecards they created contemporaneously.
		</div>
		<div className="graphs-wrapper container chart-container">{props.children}</div>
	</div>
);
