import {Subject} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {PolicyMember, PolicyOwnersResponse, PolicyUsersResponse} from '../Models';
import {PolicyEditorUsersRepository} from '../repositories/policyEditorUsersRepository';

export class PolicyEditorUsersService {
	private _policyAvailableUsers: PolicyMember[] = [];
	private _policyAssignedUsers: PolicyMember[] = [];
	private _policyAvailableOwners: PolicyMember[] = [];
	private _policyAssignedOwners: PolicyMember[] = [];
	private _policyUsersStream: Subject<PolicyUsersResponse | number> = new Subject();

	constructor(private repo: PolicyEditorUsersRepository) {}

	initialRequestForAdmin = () => this.requestOwners().pipe(mergeMap(() => this.requestUsers()));

	initialRequestForPolicyOwnerAndPolicyOwnerHr = () => this.requestUsers();

	requestUsers = () => this.repo.getSubscriptionUsers().pipe(tap(this.notifyPolicyUsers));

	private notifyPolicyUsers = (policyUsers: PolicyUsersResponse) => {
		this._policyAvailableUsers = policyUsers.availableUsers;
		this._policyAssignedUsers = policyUsers.assignedUsers;
		this._policyUsersStream.next(policyUsers);
	};

	requestOwners = () => this.repo.getSubscriptionOwners().pipe(tap(this.notifyPolicyOwners));

	private notifyPolicyOwners = (policyOwners: PolicyOwnersResponse) => {
		this._policyAvailableOwners = policyOwners.availableOwners;
		this._policyAssignedOwners = policyOwners.assignedOwners;
	};

	requestAddUser = (userId: number) => this.repo.addUserToPolicy(userId).pipe(tap(() => this.notifyAddUser(userId)));

	private notifyAddUser = (userId: number) => {
		const addedUser = this._policyAvailableUsers.filter((u) => u.userId == userId)[0];
		this._policyAssignedUsers = [addedUser, ...this._policyAssignedUsers];
		this._policyAvailableUsers = this._policyAvailableUsers.filter((u) => u.userId != userId);
		this._policyUsersStream.next(userId);
	};

	requestRemoveUser = (userId: number) =>
		this.repo.removeUserFromPolicy(userId).pipe(tap(() => this.notifyRemoveUser(userId)));

	private notifyRemoveUser = (userId: number) => {
		const removedUser = this._policyAssignedUsers.filter((u) => u.userId == userId)[0];
		this._policyAvailableUsers = [removedUser, ...this._policyAvailableUsers];
		this._policyAssignedUsers = this._policyAssignedUsers.filter((u) => u.userId != userId);
		this._policyUsersStream.next(userId);
	};

	requestAddOwner = (ownerId: number) =>
		this.repo.addOwnerToPolicy(ownerId).pipe(tap(() => this.notifyAddOwner(ownerId)));

	private notifyAddOwner = (ownerId: number) => {
		const addedOwner = this._policyAvailableOwners.filter((o) => o.userId == ownerId)[0];
		this._policyAssignedOwners = [addedOwner, ...this._policyAssignedOwners];
		this._policyAvailableOwners = this._policyAvailableOwners.filter((o) => o.userId != ownerId);
		this._policyUsersStream.next(ownerId);
	};

	requestRemoveOwner = (ownerId: number) =>
		this.repo.removeOwnerFromPolicy(ownerId).pipe(tap(() => this.notifyRemoveOwner(ownerId)));

	private notifyRemoveOwner = (ownerId: number) => {
		const removedOwner = this._policyAssignedOwners.filter((o) => o.userId == ownerId)[0];
		this._policyAvailableOwners = [removedOwner, ...this._policyAvailableOwners];
		this._policyAssignedOwners = this._policyAssignedOwners.filter((o) => o.userId != ownerId);
		this._policyUsersStream.next(ownerId);
	};

	get policyAvailableUsers() {
		return this._policyAvailableUsers;
	}

	get policyAvailableOwners() {
		return this._policyAvailableOwners;
	}

	get policyAssignedUsers() {
		return this._policyAssignedUsers;
	}

	get policyAssignedOwners() {
		return this._policyAssignedOwners;
	}

	get policyUsersStream() {
		return this._policyUsersStream;
	}
}
