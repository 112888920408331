import {INotificationService} from '../../appServices/INotificationService';
import {INavigationService} from '../../appServices/INavigationService';

export abstract class InteractorBase {
	onUpdate: (i: InteractorBase) => void;

	constructor(protected navigationService: INavigationService, protected notificationService: INotificationService) {}

	updateView = () => this.onUpdate(this);

	pushModal = this.navigationService.modalService.push;

	closeModal = this.navigationService.modalService.close;

	navigateTo = (route: string) => this.navigationService.navigate(route);
}
