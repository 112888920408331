import {TimescoreForPeriod, UserTimescoreForPeriod, UserTimescoreHistory} from '../Models';
import {PeriodKind} from '../ModelContracts';
import {Subject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TimescoreRepository} from '../repositories/TimescoreRepository';

export class TimescoreService {
	private _timescores: TimescoreForPeriod[] = [];
	private _userTimescores: UserTimescoreForPeriod[] = [];
	private _userTimescoreHistories: UserTimescoreHistory[] = [];
	private _timescoresStream: Subject<TimescoreForPeriod[]> = new Subject();
	private _userTimescoresStream: Subject<UserTimescoreForPeriod[]> = new Subject();
	private _userTimescoreHistoriesStream: Subject<UserTimescoreHistory[]> = new Subject();
	private _userTimescoreHistoriesCurrentUser: string = '';

	constructor(private repo: TimescoreRepository) {}

	timescoresInitialRequest = () => this.requestTimescoreSummary('M');

	requestTimescoreSummary = (periodKind: PeriodKind) =>
		this.repo.getTimescoreHistory(periodKind).pipe(tap(this.notifyTimeScoresUpdated));

	private notifyTimeScoresUpdated = (timescores: TimescoreForPeriod[]) => {
		this._timescores = timescores.filter(this.timescoreHasUsers);
		this._timescoresStream.next(this._timescores);
	};

	private timescoreHasUsers = (timescore: TimescoreForPeriod) =>
		timescore.numberOfUsers != 0 || timescore.numberOfUsersInPreviousPeriod != 0;

	userTimescoresinitialRequest = () => this.requestUsersTimescore('M');

	requestUsersTimescore = (periodKind: PeriodKind) =>
		this.repo.getUsersTimescores(periodKind).pipe(tap(this.notifyUserTimescoresUpdated));

	notifyUserTimescoresUpdated = (userTimescores: UserTimescoreForPeriod[]) => {
		this._userTimescores = userTimescores;
		this._userTimescoresStream.next(userTimescores);
	};

	requestUserTimescoreHistory = (userId: string) =>
		this.repo.getUserTimescoreHistory(userId).pipe(
			tap(() => (this._userTimescoreHistoriesCurrentUser = userId)),
			tap(this.notifyUserTimescoreHistoriesUpdated)
		);

	notifyUserTimescoreHistoriesUpdated = (userTimescoreHistories: UserTimescoreHistory[]) => {
		this._userTimescoreHistories = userTimescoreHistories;
		this._userTimescoreHistoriesStream.next(userTimescoreHistories);
	};

	get timescores() {
		return this._timescores;
	}

	get timescoresStream() {
		return this._timescoresStream;
	}

	get userTimescores() {
		return this._userTimescores;
	}

	get userTimescoresStream() {
		return this._userTimescoresStream;
	}

	get userTimescoreHistories() {
		return this._userTimescoreHistories;
	}

	get userTimescoreHistoriesStream() {
		return this._userTimescoreHistoriesStream;
	}

	get userTimescoreHistoriesCurrentUser() {
		return this._userTimescoreHistoriesCurrentUser;
	}
}
