import * as React from 'react';
import {ReactNode} from 'react';
import {Transcript} from '../../../core/Transcript';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {ChartComponent} from '../shared/chart/ChartComponent';
import {Layout} from '../shared/layout/Layout';
import {WrapperReactState} from '../shared/WrapperReactState';
import {VelocityHistoryInteractor} from './VelocityHistoryInteractor';
import {PolicySelectorComponentWrapped} from '../shared/policySelector/PolicySelectorComponent';
import ReactTable from 'react-table-v6';
import {ReactTableHelper} from '../../helpers/ReactTableHelper';
import {PeriodKind} from '../../../core/ModelContracts';

export const VelocityHistoryComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getVelocityHistoryInteractor()}
		functionComponent={VelocityHistoryComponent}
	/>
);

const VelocityHistoryComponent = (props: {interactor: VelocityHistoryInteractor}) => {
	const onChart = (index: number) => {
		const label = props.interactor.dateLabels()[index];

		props.interactor
			.onChartClick(index)
			.subscribe(() =>
				props.interactor.pushModal(<UserComplianceComponent interactor={props.interactor} label={label} />)
			);
	};

	return (
		<Layout title={'Velocity History'} navbarRight={<NavbarRight interactor={props.interactor} />}>
			<GraphContainer interactor={props.interactor}>
				<ChartComponent
					data={props.interactor.prepareChartDataset()}
					kind="line"
					hasAnimated={true}
					legendPosition="bottom"
					showYAxeLabel={true}
					yAxeLabel="Velocity Days"
					onClick={onChart}
					disableOnClickLegend={true}
				/>
			</GraphContainer>
		</Layout>
	);
};

const UserComplianceComponent = (props: {interactor: VelocityHistoryInteractor; label: string}) => {
	return (
		<div>
			<div className="table-header">
				<h4>{`Velocity (${props.label})`}</h4>
				<span className="export-to-excel" onClick={props.interactor.exportToExcel}>
					Export to <i className="icon-icon-exportxls"></i>
				</span>
			</div>
			<div className="header-separator"></div>
			<ReactTable
				data={props.interactor.usersComplianceResults}
				columns={props.interactor.columns}
				filterable={true}
				showPageJump={false}
				defaultFilterMethod={ReactTableHelper.filterCaseInsensitive}
			/>
		</div>
	);
};

const NavbarRight = (props: {interactor: VelocityHistoryInteractor}) => {
	const fromKindToOptions = () =>
		props.interactor.velocityHistoryKinds.map((kind) => (
			<option key={kind} value={kind}>
				{Transcript.transcriptKind(kind)}
			</option>
		));

	return (
		<div className="navbar-right">
			<div className="select-wrapper">
				<PolicySelectorComponentWrapped />
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentPeriodKind} onChange={props.interactor.onPeriodChange}>
					{fromKindToOptions()}
				</select>
			</div>
		</div>
	);
};

const GraphContainer = (props: {children: ReactNode; interactor: VelocityHistoryInteractor}) => {
	const currentPeriodKind = props.interactor.currentPeriodKind;
	const chartLabels = props.interactor.dateLabels();
	const hasData = chartLabels.length != 0;

	const getNoteBy = (period: PeriodKind) => {
		switch (period) {
			case 'M':
				return `This line graph represents your firm's velocity over a ${chartLabels.length} month period.`;
			case 'W':
				return `This line graph represents your firm's velocity over a ${chartLabels.length} week period.`;
			case 'D':
				return `This line graph represents your firm's velocity over a ${chartLabels.length} day (s) period.`;
		}
	};

	return (
		<div className="graphs-canvas">
			<div className="graph-title">
				<h4>Velocity</h4>
			</div>
			<div className="note">{hasData ? getNoteBy(currentPeriodKind) : 'There is no data.'}</div>
			<div className="graphs-wrapper container chart-container">{props.children}</div>
		</div>
	);
};
