import {TimescoreRepository} from '../../core/repositories/TimescoreRepository';
import {
	PeriodKind,
	CallContext,
	TimescoreSummaryForPeriodRequest,
	ServiceRequest,
	ServiceResponse,
	TimescoreForPeriodDTO,
	UsersTimescoreForPeriodRequest,
	UserTimescoreForPeriodDTO,
	UserTimescoreHistoryDTO,
} from '../../core/ModelContracts';
import {map} from 'rxjs/operators';
import {TimescoreForPeriod, UserTimescoreForPeriod, UserTimescoreHistory} from '../../core/Models';
import {UrlBuilder, BackendRoutes, Environment} from '../Routes';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyService} from '../appServices/PolicyService';
import {PeriodsService} from '../appServices/PeriodsService';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class TimescoreApiRepository implements TimescoreRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private periodService: PeriodsService,
		private authenticationService: AuthenticationService
	) {}

	getTimescoreHistory = (periodType: PeriodKind) =>
		this.requestTimescoreHistory(periodType).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((tfpDTOs) => tfpDTOs.map(TimescoreForPeriod.createFrom))
		);

	requestTimescoreHistory = (periodType: PeriodKind) => {
		const currentPeriodFormated = this.periodService.timescoreBreakdownCurrentPeriod.format('YYYY-MM-DD');
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const timescoreSummaryForPeriodUrl = urlBuilder.getBackendUrl(BackendRoutes.timescoresummaryforperiod);
		const callContext = this.authenticationService.callContext;

		const request = TimescoreSummaryForPeriodRequest.create(
			this.policyService.currentPolicyId,
			periodType,
			currentPeriodFormated
		);
		const serviceRequest = ServiceRequest.create<TimescoreSummaryForPeriodRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<TimescoreForPeriodDTO[]>>(
			timescoreSummaryForPeriodUrl,
			'POST',
			serviceRequest
		);
	};

	getUsersTimescores = (periodType: PeriodKind) =>
		this.requestUsersTimescores(periodType).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((utfpDTOs) => utfpDTOs.map((utfpDTO) => UserTimescoreForPeriod.createFrom(utfpDTO)))
		);

	requestUsersTimescores = (periodType: PeriodKind) => {
		const currentPeriodFormated = this.periodService.timescoreBreakdownCurrentPeriod.format('YYYY-MM-DD');
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const usertimescoresforperiodUrl = urlBuilder.getBackendUrl(BackendRoutes.usertimescoresforperiod);
		const callContext = this.authenticationService.callContext;

		const request = UsersTimescoreForPeriodRequest.create(
			this.policyService.currentPolicyId,
			periodType,
			currentPeriodFormated
		);
		const serviceRequest = ServiceRequest.create<UsersTimescoreForPeriodRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<UserTimescoreForPeriodDTO[]>>(
			usertimescoresforperiodUrl,
			'POST',
			serviceRequest
		);
	};

	getUserTimescoreHistory = (userId: string) =>
		this.requestUserTimescoreHistory(userId).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((uthDTOs) => uthDTOs.map((uthDTO) => UserTimescoreHistory.createFrom(uthDTO)))
		);

	requestUserTimescoreHistory = (userId: string) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const usertimescorehistoryUrl = urlBuilder.getBackendUrl(BackendRoutes.usertimescorehistory);
		const callContext = this.authenticationService.callContext;

		const request = userId;
		const serviceRequest = ServiceRequest.create<string>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<UserTimescoreHistoryDTO[]>>(
			usertimescorehistoryUrl,
			'POST',
			serviceRequest
		);
	};
}
