import {PolicyEditorService} from '../../../core/services/PolicyEditorService';
import {INotificationService} from '../../appServices/INotificationService';
import {PolicyService} from '../../appServices/PolicyService';
import {PolicyEditorInteractorBase} from './shared/PolicyEditorBaseInteractor';
import moment = require('moment');
import {INavigationService} from '../../appServices/INavigationService';
import {round} from 'lodash';
import {PolicyInitialMonthUtils} from './PolicyInitialMonthUtils';
import {InteractorFactory} from '../../factories/InteractorFactory';

export enum DeadlineForm {
	DailyGoal = 'DailyGoal',
	WeeklyGoal = 'WeeklyGoal',
	NotCompliance = 'NotCompliant',
	Compliance = 'Compliant',
	UseBillableForEvaluation = 'UseBillableForEvaluation',
}

export class PolicyEditorDeadlineDisciplineInteractor extends PolicyEditorInteractorBase {
	private _shouldShowDailyGoalInfo = false;
	private _shouldShowWeeklyGoalInfo = false;
	private _policyInitialMonth: number = 6;
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		policyEditorService: PolicyEditorService,
		policyService: PolicyService
	) {
		super(navigationService, notificationService, policyEditorService, policyService);
	}

	onChangeMonthlyGoal = (monthIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		const monthlyGoal = round(parseFloat(e.target.value), 2);
		if (DeadlineDisciplineValidator.isMonthlyGoalValid(monthlyGoal)) {
			policy.minMaxHours.setMinHoursElementByIndex(monthIndex, monthlyGoal);
		} else {
			this.notificationService.warn('Value must be a number between 0 and 740 with up to two decimal places.');
		}

		this.updateView();
	};

	onChangePolicyInitialMonth = (initialMonth: string) => {
		const policy = this.policyEditorService.policy;
		const previousMonthIndex = policy.initialMonth - 1;
		const initialMonthParsed = parseInt(initialMonth);
		const initialMonthIndex = initialMonthParsed - 1;
		policy.setInitialMonth(initialMonthParsed);
		policy.deadlines.setGracedays(
			PolicyInitialMonthUtils.orderByPolicyInitialMonth(
				policy.deadlines.graceDays,
				previousMonthIndex,
				initialMonthIndex
			)
		);

		policy.minMaxHours.setMinHoursList(
			PolicyInitialMonthUtils.orderByPolicyInitialMonth(
				policy.minMaxHours.minHoursList,
				previousMonthIndex,
				initialMonthIndex
			)
		);
		this.updateView();
	};

	onChangeMinimumDailyGoal = (e: React.ChangeEvent<HTMLInputElement>) => {
		const dailyGoal = parseInt(e.target.value);
		if (DeadlineDisciplineValidator.isDailyGoalValid(dailyGoal)) {
			this.policyEditorService.policy.minMaxHoursDaily.setMinGoal(dailyGoal);
			this.policyEditorService.policy.minMaxHoursDaily.setMaxGoal(dailyGoal);
		} else {
			this.notificationService.warn('Value must be a whole number between 0 and 24.');
		}

		this.updateView();
	};

	onChangeMinimumWeeklyGoal = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		const weeklyGoal = round(parseFloat(e.target.value), 2);
		if (DeadlineDisciplineValidator.isWeeklyGoalValid(weeklyGoal)) {
			policy.minMaxHoursWeekly.setMinGoal(weeklyGoal);
			policy.minMaxHoursWeekly.setMaxGoal(weeklyGoal);
		} else {
			this.notificationService.warn('Value must be a number between 0 and 168 with up to two decimal places.');
		}

		this.updateView();
	};

	onChangeUseBillableHoursForEvaluation = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		policy.minMaxHours.setUseBillableForEvaluation(e.target.checked);
		policy.minMaxHoursDaily.setUseBillableForEvaluation(e.target.checked);
		policy.minMaxHoursWeekly.setUseBillableForEvaluation(e.target.checked);
		this.updateView();
	};

	onChangeNotCompliance = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		const notCompliance = parseInt(e.target.value);
		if (DeadlineDisciplineValidator.isNotComplianceValid(notCompliance)) {
			policy.minMaxHours.setRewardsNotCompliance(notCompliance);
			policy.minMaxHoursDaily.setRewardsNotCompliance(notCompliance);
			policy.minMaxHoursWeekly.setRewardsNotCompliance(notCompliance);
		} else {
			this.notificationService.warn('Value must be negative and between -1000 to 0.');
		}

		this.updateView();
	};

	onChangeCompliance = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		const compliance = parseInt(e.target.value);
		if (DeadlineDisciplineValidator.isComplianceValid(compliance)) {
			policy.minMaxHours.setRewardsCompliance(compliance);
			policy.minMaxHoursDaily.setRewardsCompliance(compliance);
			policy.minMaxHoursWeekly.setRewardsCompliance(compliance);
		} else {
			this.notificationService.warn('Value must be non-negative whole number and between 0 to 1000.');
		}

		this.updateView();
	};

	onSave = () => {
		this.onSavePolicy(InteractorFactory.getPolicyEditorTimescoreInteractor().temporaryTimescores);
	};

	onShowDailyGoalInfo = () => {
		this._shouldShowDailyGoalInfo = !this.shouldShowDailyGoalInfo;
		this.updateView();
	};

	onShowWeeklyGoalInfo = () => {
		this._shouldShowWeeklyGoalInfo = !this._shouldShowWeeklyGoalInfo;
		this.updateView();
	};

	get minimumMonthlyHours() {
		return this.policyEditorService.policy.minMaxHours.minHoursList;
	}

	get minimumAnnualGoal() {
		return this.policyEditorService.policy.minMaxHours.minHoursList.reduce((acc, i) => acc + i).toFixed(2);
	}

	get minMax() {
		return this.policyEditorService.policy.minMaxHours;
	}

	get minMaxDaily() {
		return this.policyEditorService.policy.minMaxHoursDaily;
	}

	get minMaxWeekly() {
		return this.policyEditorService.policy.minMaxHoursWeekly;
	}

	get shouldShowDailyGoalInfo() {
		return this._shouldShowDailyGoalInfo;
	}

	get shouldShowWeeklyGoalInfo() {
		return this._shouldShowWeeklyGoalInfo;
	}

	get formattedMonths() {
		return PolicyInitialMonthUtils.getOrderedMonthsFormatted(this.policyEditorService.policy.initialMonth);
	}

	get policyInitialMonth() {
		return this.policyEditorService.policy.initialMonth;
	}
}

export class DeadLineQuery {
	static getMonthNameByIndex = (index: number) => moment().month(index).format('MMMM YYYY');
}

export class DeadlineDisciplineValidator {
	static isMonthlyGoalValid = (value: number) => value >= 0 && value <= 740;
	static isDailyGoalValid = (value: number) => value >= 0 && value <= 24;
	static isWeeklyGoalValid = (value: number) => value >= 0 && value <= 168;
	static isNotComplianceValid = (value: number) => value >= -1000 && value <= 0;
	static isComplianceValid = (value: number) => value >= 0 && value <= 1000;
}
