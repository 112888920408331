import {InteractorBase} from '../../shared/InteractorBase';
import {INotificationService} from '../../../appServices/INotificationService';
import {PolicyEditorService} from '../../../../core/services/PolicyEditorService';
import {PolicyService} from '../../../appServices/PolicyService';
import {tap, mergeMap} from 'rxjs/operators';
import {Routes} from '../../../Routes';
import {PolicyEditorInteractorBase} from './PolicyEditorBaseInteractor';
import {INavigationService} from '../../../appServices/INavigationService';

export enum GlobalForm {
	PolicyName = 'PolicyName',
	IsEnabled = 'IsEnabled',
}

export class PolicyEditorNameInteractor extends PolicyEditorInteractorBase {
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		policyEditorService: PolicyEditorService,
		policyService: PolicyService
	) {
		super(navigationService, notificationService, policyEditorService, policyService);
	}

	onChangePolicyName = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.policyEditorService.policy.setName(e.target.value);
		this.updateView();
	};

	get policy() {
		return this.policyEditorService.policy;
	}
}
