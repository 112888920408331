import {PeriodKind} from '../ModelContracts';
import {TimescoreForPeriod, UserTimescoreForPeriod, UserTimescoreHistory} from '../Models';
import {Observable, of} from 'rxjs';

export interface TimescoreRepository {
	getTimescoreHistory: (periodKind: PeriodKind) => Observable<TimescoreForPeriod[]>;
	getUsersTimescores: (periodKind: PeriodKind) => Observable<UserTimescoreForPeriod[]>;
	getUserTimescoreHistory: (userId: string) => Observable<UserTimescoreHistory[]>;
}

export class TimescoreInMemoryRepository implements TimescoreRepository {
	constructor(
		private timescoreHistory: TimescoreForPeriod[],
		private usersTimescores: UserTimescoreForPeriod[],
		private userTimescoreHistory: UserTimescoreHistory[]
	) {}
	getTimescoreHistory = (periodKind: PeriodKind) => of(this.timescoreHistory);

	getUsersTimescores = (periodKind: PeriodKind) => of(this.usersTimescores);

	getUserTimescoreHistory = (userId: string) => of(this.userTimescoreHistory);
}
