import {UserGoal} from '../Models';
import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UsersGoalRepository} from '../repositories/UsersGoalRepository';
import {PeriodKind} from '../ModelContracts';

export class UsersGoalService {
	private _usersGoal: UserGoal[] = [];
	private _usersGoalStream: Subject<UserGoal[]> = new Subject();
	constructor(private repo: UsersGoalRepository) {}

	initialRequest = () => this.requestUsersGoal('M');

	requestUsersGoal = (periodType: PeriodKind) =>
		this.repo.getAllBy(periodType).pipe(tap(this.notifyUsersGoalUpdated));

	private notifyUsersGoalUpdated = (usersGoal: UserGoal[]) => {
		this._usersGoal = usersGoal;
		this._usersGoalStream.next(usersGoal);
	};

	get usersGoal() {
		return this._usersGoal;
	}

	get usersGoalStream() {
		return this._usersGoalStream;
	}
}
