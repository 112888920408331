import {applyPolyfills} from './helpers/Polyfills';
applyPolyfills();
import 'ie11-custom-properties/ie11CustomProperties.js';
import * as ReactDOM from 'react-dom';
import {ServiceFactory} from './factories/ServiceFactory';
import {ThriveInitializer} from './factories/ThriveInitializer';
import {LoginHelper} from './helpers/LoginHelper';
import './index.scss';
import {Router} from './Router';
import {Environment} from './Routes';
import {RepositoryFactory} from './factories/RepositoryFactory';
import {logVersion} from './helpers/DebugUtils';

logVersion();

const startApplication = () => {
	const render = () => ReactDOM.render(Router(), document.getElementById('root'));

	RepositoryFactory.initializeEnvironment(Environment.getCurrentEnvironment());

	ServiceFactory.getAuthenticationService().token = LoginHelper.getTokenFromUrl();

	ServiceFactory.getAuthenticationService().hasToken()
		? ThriveInitializer.init().subscribe(render)
		: LoginHelper.redirectToPortalLogin();
};

startApplication();
