import {INotificationService} from '../../appServices/INotificationService';
import {PolicyEditorService} from '../../../core/services/PolicyEditorService';
import {PolicyService} from '../../appServices/PolicyService';
import {tap, mergeMap} from 'rxjs/operators';
import {Policy} from '../../../core/models/Policy';
import {PolicyEditorInteractorBase} from './shared/PolicyEditorBaseInteractor';
import {INavigationService} from '../../appServices/INavigationService';
import {InteractorFactory} from '../../factories/InteractorFactory';

export class PolicyEditorEngagementInteractor extends PolicyEditorInteractorBase {
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		policyEditorService: PolicyEditorService,
		policyService: PolicyService
	) {
		super(navigationService, notificationService, policyEditorService, policyService);
	}

	onChangeBrazeDailyReminder = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.policyEditorService.policy.setBrazeDailyEnabled(e.target.checked);
		this.updateView();
	};

	onChangeBrazeWeeklyReminder = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.policyEditorService.policy.setBrazeWeeklyEnabled(e.target.checked);
		this.updateView();
	};

	onChangeTimetelligenceWeeklySummary = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.policyEditorService.policy.setTimetelligenceWeeklyEnabled(e.target.checked);
		this.updateView();
	};

	onChangeTimetelligenceMonthlySummary = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.policyEditorService.policy.setTimetelligenceMonthlyEnabled(e.target.checked);
		this.updateView();
	};

	onSave = () => {
		this.onSavePolicy(InteractorFactory.getPolicyEditorTimescoreInteractor().temporaryTimescores);
	};

	get policy() {
		return this.policyEditorService.policy;
	}
}
