import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ContemporaneousHistory} from '../Models';
import {PeriodKind} from '../ModelContracts';
import {ContemporaneousRepository} from '../repositories/ContemporaneousRepository';

export class ContemporaneousHistoryService {
	private _contemporaneousHistories: ContemporaneousHistory[] = [];
	private _contemporaneousHistoriesStream: Subject<ContemporaneousHistory[]> = new Subject();

	constructor(private repo: ContemporaneousRepository) {}

	initialRequest = () => this.requestContemporaneousHistory('M');

	requestContemporaneousHistory = (periodKind: PeriodKind) =>
		this.repo.getAllByPeriod(periodKind).pipe(tap(this.notifyContemporaneousHistoriesUpdated));

	private notifyContemporaneousHistoriesUpdated = (contemporaneousHistories: ContemporaneousHistory[]) => {
		this._contemporaneousHistories = contemporaneousHistories;
		this._contemporaneousHistoriesStream.next(contemporaneousHistories);
	};

	get contemporaneousHistories() {
		return this._contemporaneousHistories;
	}

	get contemporaneousHistoriesStream() {
		return this._contemporaneousHistoriesStream;
	}
}
