import {PeriodKind} from '../ModelContracts';
import {Observable, of} from 'rxjs';

import {Maybe} from 'monet';
import {PolicyDashboardComparison} from '../Models';

export interface DashboardRepository {
	getBy: (periodKind: PeriodKind) => Observable<Maybe<PolicyDashboardComparison>>;
}

export class DashboardInMemoryRepository implements DashboardRepository {
	constructor(private policyDashboardComparison?: PolicyDashboardComparison) {}

	getBy = (periodKind: PeriodKind) => of(Maybe.fromNull(this.policyDashboardComparison));
}
