import * as moment from 'moment';
import {PeriodKind} from './ModelContracts';

export class DatesFormatter {
	private static readonly DATE_FORMAT = 'YYYY-MM-DD';
	private static readonly MONTH_FORMAT = 'MMM YYYY';

	public static formatDateByPeriodKind = (date: string, periodKind: PeriodKind) => {
		if (periodKind === 'M') return moment(date).format(DatesFormatter.MONTH_FORMAT);

		if (periodKind === 'W')
			return `${moment(date).startOf('week').format(DatesFormatter.DATE_FORMAT)} - ${moment(date)
				.endOf('week')
				.format(DatesFormatter.DATE_FORMAT)}`;

		return moment(date).format(DatesFormatter.DATE_FORMAT);
	};
}
