import * as React from 'react';
import {Route, Switch} from 'react-router';
import {HashRouter} from 'react-router-dom';
import {InteractorFactory} from './factories/InteractorFactory';
import {ServiceFactory} from './factories/ServiceFactory';
import {Routes} from './Routes';
import {ComplianceHistoryComponentWrapped} from './views/complianceHistory/ComplianceHistoryComponent';
import {ContemporaneousHistoryComponentWrapped} from './views/contemporaneousHistory/ContemporaneousHistoryComponent';
import {DashboardComparisonsComponentWrapped} from './views/dashboard/DashboardComparisonsComponent';
import {WorkHoursOverviewComponentWrapped} from './views/usersGoals/WorkHoursOverviewComponent';
import {
	PenaltiesAndRewardsComponentWrapped,
	PenaltiesAndRewardsForHrAndPolicyOwnerComponentWrapped,
} from './views/PenaltiesAndRewards/PenaltiesAndRewardsComponent';
import {PolicyEditorVelocityComponentWrapped} from './views/policyEditor/PolicyEditorVelocityComponent';
import {TimescoreBreakdownComponentWrapped} from './views/timescoreBreakdown/TimescoreBreakdownComponent';
import {VelocityHistoryComponentWrapped} from './views/velocityHistory/VelocityHistoryComponent';
import {PolicyEditorDeadlineDisciplineWrapped} from './views/policyEditor/PolicyEditorDeadlineDisciplineComponent';
import {PolicyEditorGracePeriodWrapped} from './views/policyEditor/PolicyEditorGracePeriodComponent';
import {PolicyEditorTimescoreComponentWrapped} from './views/policyEditor/policyEditorTimescoreComponent';
import {
	PolicyEditorEngagement,
	PolicyEditorEngagementComponentWrapped,
} from './views/policyEditor/PolicyEditorEngagementComponent';
import {InfoComponent, NotFoundComponent} from './views/shared/layout/Layout';
import {PolicyEditorUsersWrapped} from './views/policyEditor/policyEditorUsersComponent';

const navWrapper = (children, context) => {
	ServiceFactory.getNavigationService().setContext(context);
	return children;
};

export const Router = () => {
	if (
		ServiceFactory.getAuthenticationService().credentials.hasAdminAccess() ||
		ServiceFactory.getAuthenticationService().credentials.hasPolicyOwnerOnlyAccess()
	) {
		return RouterForThriveAccess(false);
	}

	if (ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesAndPolicyOwnerAccess()) {
		return RouterForThriveAccess(true);
	}

	if (ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesOnlyAccess()) {
		return RouterForHumanResourceAccess();
	}

	return RouterForNotAuthorized();
};

const RouterForNotAuthorized = () => (
	<HashRouter>
		<Switch>
			<Route
				exact
				path={Routes.notAuthorized}
				render={(c) => navWrapper(<InfoComponent message="Not Authorized" />, c)}
			/>
		</Switch>
		,
		<Route render={() => <NotFoundComponent navigateTo={Routes.notAuthorized} />} />
	</HashRouter>
);

const RouterForHumanResourceAccess = () => (
	<HashRouter>
		<Switch>
			<Route
				exact
				path={Routes.penaltiesAndRewards}
				render={(c) => navWrapper(<PenaltiesAndRewardsForHrAndPolicyOwnerComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.noEnabledPolicies}
				render={(c) => navWrapper(<InfoComponent message="HR, there are not enabled policies" />, c)}
			/>
			,
			<Route render={() => <NotFoundComponent navigateTo={Routes.penaltiesAndRewards} />} />
		</Switch>
	</HashRouter>
);

const RouterForThriveAccess = (shouldRenderForHr: boolean) => (
	<HashRouter>
		<Switch>
			<Route
				exact
				path={Routes.dashboard}
				render={(c) => navWrapper(<DashboardComparisonsComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.complianceHistory}
				render={(c) => navWrapper(<ComplianceHistoryComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.velocityHistory}
				render={(c) => navWrapper(<VelocityHistoryComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.contemporaneousHistory}
				render={(c) => navWrapper(<ContemporaneousHistoryComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.timescoreBreakdown}
				render={(c) => navWrapper(<TimescoreBreakdownComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.workHoursOverview}
				render={(c) => navWrapper(<WorkHoursOverviewComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.penaltiesAndRewards}
				render={(c) =>
					navWrapper(
						shouldRenderForHr ? (
							<PenaltiesAndRewardsForHrAndPolicyOwnerComponentWrapped />
						) : (
							<PenaltiesAndRewardsComponentWrapped />
						),
						c
					)
				}
			/>
			,
			<Route
				exact
				path={Routes.policyEditorVelocity}
				render={(c) => navWrapper(<PolicyEditorVelocityComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.policyEditorDeadlineDiscipline}
				render={(c) => navWrapper(<PolicyEditorDeadlineDisciplineWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.policyEditorGraceperiod}
				render={(c) => navWrapper(<PolicyEditorGracePeriodWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.policyEditorEngagement}
				render={(c) => navWrapper(<PolicyEditorEngagementComponentWrapped />, c)}
			/>
			,
			<Route exact path={Routes.policyEditorUsers} render={(c) => navWrapper(<PolicyEditorUsersWrapped />, c)} />
			,
			<Route
				exact
				path={Routes.policyEditorTimescore}
				render={(c) => navWrapper(<PolicyEditorTimescoreComponentWrapped />, c)}
			/>
			,
			<Route
				exact
				path={Routes.noPolicyOwnerRoutes}
				render={(c) =>
					navWrapper(<InfoComponent message="Policy Owner, you don´t have policies assigned" />, c)
				}
			/>
			,
			<Route render={() => <NotFoundComponent navigateTo={Routes.dashboard} />} />
		</Switch>
	</HashRouter>
);
