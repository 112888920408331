import {Observable, of} from 'rxjs';
import {PeriodKind} from '../ModelContracts';
import {UserGoal} from '../Models';

export interface UsersGoalRepository {
	getAllBy: (periodType: PeriodKind) => Observable<UserGoal[]>;
}

export class UsersGoalInMemoryRepository implements UsersGoalRepository {
	constructor(private usersGoals: UserGoal[]) {}
	getAllBy = () => of(this.usersGoals);
}
