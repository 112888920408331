import * as React from 'react';
import {ReactNode} from 'react';
import {Transcript} from '../../../core/Transcript';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {ChartComponent} from '../shared/chart/ChartComponent';
import {Layout} from '../shared/layout/Layout';
import {PolicySelectorComponentWrapped} from '../shared/policySelector/PolicySelectorComponent';
import {WrapperReactState} from '../shared/WrapperReactState';
import {TimescoreBreakdownTableWrapped} from '../timescoreBreakdown/TimescoreBreakdownTableComponent';
import {TimescoreBreakdownInteractor} from './TimescoreBreakdownInteractor';
import {TimescoreBreakdownUserHistoryWrapped} from './TimescoreBreakdownUserHistoryComponent';
import ReactTable from 'react-table-v6';
import {UserTimescoreForPeriod} from '../../../core/Models';
import {ReactTableHelper} from '../../helpers/ReactTableHelper';
import {PolicyEditorTimescoreInteractor} from '../policyEditor/policyEditorTimescoreInteractor';
import {Routes} from '../../Routes';

export const TimescoreBreakdownComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getTimescoreBreakdownInteractor()}
		functionComponent={TimescoreBreakdownComponent}
	/>
);

const TimescoreBreakdownComponent = (props: {interactor: TimescoreBreakdownInteractor}) => {
	const onChart = (index: number, datasetIndex: number) => {
		const getPeriod = () =>
			datasetIndex == 0
				? props.interactor.getPreviousPeriodLabel()
				: props.interactor.formatPeriod(props.interactor.currentPeriodForPolicy);
		const label = props.interactor.timescoreLabels()[index];
		props.interactor.onChartClick(index, datasetIndex);
		props.interactor.pushModal(
			<UserTimescoreComponent interactor={props.interactor} label={label} period={getPeriod()} />
		);
	};

	return (
		<Layout title={'Timescore Breakdown'} navbarRight={<NavbarRight interactor={props.interactor} />}>
			<GraphContainer interactor={props.interactor}>
				<ChartComponent
					data={props.interactor.prepareChartDataset()}
					kind="bar"
					hasAnimated={true}
					legendPosition="bottom"
					showYAxeLabel={true}
					yAxeLabel="# Of Users"
					onClick={onChart}
					stepSize={1}
				/>
			</GraphContainer>
		</Layout>
	);
};

const NavbarRight = (props: {interactor: TimescoreBreakdownInteractor}) => {
	const fromKindToOptions = () =>
		props.interactor.timescoreBreakdownKinds.map((kind) => (
			<option key={kind} value={kind}>
				{Transcript.transcriptKind(kind)}
			</option>
		));

	const fromPeriodsToOptions = () =>
		props.interactor.periods.map((period, index) => (
			<option key={index} value={period}>
				{props.interactor.formatPeriod(period)}
			</option>
		));

	return (
		<div className="navbar-right">
			<div className="select-wrapper">
				<PolicySelectorComponentWrapped />
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentPeriodKind} onChange={props.interactor.onPeriodChange}>
					{fromKindToOptions()}
				</select>
			</div>
			<div className="select-wrapper">
				<select
					value={props.interactor.currentPeriodForPolicy}
					onChange={props.interactor.onPeriodForPolicyChange}
				>
					{fromPeriodsToOptions()}
				</select>
			</div>
		</div>
	);
};

const GraphContainer = (props: {interactor: TimescoreBreakdownInteractor; children: ReactNode}) => {
	const onClickInfo = () => {
		props.interactor.pushModal(
			<TimescoreInfoModal interactor={InteractorFactory.getPolicyEditorTimescoreInteractor()} />
		);
	};

	return (
		<div className="graphs-canvas">
			<div className="graph-title">
				<h4>Timescore Breakdown ({props.interactor.formatPeriod(props.interactor.currentPeriodForPolicy)})</h4>
				<i onClick={onClickInfo} className="icon-icon-information"></i>
			</div>
			<div className="note">
				This bar graph shows the number of users per grade for the selected period compared to the previous
				period.
			</div>
			<div className="chart-container container">{props.children}</div>
			<TimescoreBreakdownTableWrapped />
		</div>
	);
};

const UserTimescoreComponent = (props: {interactor: TimescoreBreakdownInteractor; label: string; period: string}) => {
	const onUserClick = (user: UserTimescoreForPeriod) => {
		props.interactor
			.onUsersTableClick(user.userId.toString())
			.subscribe(() => props.interactor.pushModal(<TimescoreBreakdownUserHistoryWrapped />));
	};

	const rowProperties = (table, rowInfo) => ({
		onClick: () => onUserClick(rowInfo.original as UserTimescoreForPeriod),
	});

	return (
		<div>
			<div className="table-header">
				<h4>{`Users with ${props.label} Timescore (${props.period})`}</h4>
			</div>
			<ReactTable
				data={props.interactor.userTimescores}
				columns={props.interactor.columns}
				filterable={true}
				getTrProps={rowProperties}
				showPageJump={false}
				defaultFilterMethod={ReactTableHelper.filterCaseInsensitive}
			/>
		</div>
	);
};

export const TimescoreInfoModal = (props: {interactor: PolicyEditorTimescoreInteractor}) => {
	const gradeATimescoreLimit = props.interactor.timescores[0].limit;
	const gradeBTimescoreLimit = props.interactor.timescores[1].limit;
	const gradeCTimescoreLimit = props.interactor.timescores[2].limit;
	const gradeDTimescoreLimit = props.interactor.timescores[3].limit;

	const navigateToPolicyEditor = () => {
		props.interactor.navigateTo(Routes.policyEditorTimescore);
		props.interactor.closeModal();
	};

	return (
		<div className="timescore-granularity-info">
			<p>
				The velocity is the number of days between when the work is completed and when the timecard for that
				work is submitted.
			</p>
			<p className="velocity-info">
				Velocity = {'{'}submission date{'}'} - {'{'}work date{'}'}
			</p>
			<p>
				To change the velocity ranges for each timescore grade{' '}
				<a onClick={navigateToPolicyEditor}>click here</a>
			</p>
			<table id="timescore-info">
				<thead>
					<tr>
						<td>Velocity Range</td>
						<td>Timescore Grade</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>0 - {gradeATimescoreLimit.toFixed(2)}</td>
						<td>A</td>
					</tr>
					<tr>
						<td>
							{(gradeATimescoreLimit + 0.01).toFixed(2)} - {gradeBTimescoreLimit.toFixed(2)}
						</td>
						<td>B</td>
					</tr>
					<tr>
						<td>
							{(gradeBTimescoreLimit + 0.01).toFixed(2)} - {gradeCTimescoreLimit.toFixed(2)}
						</td>
						<td>C</td>
					</tr>
					<tr>
						<td>
							{(gradeCTimescoreLimit + 0.01).toFixed(2)} - {gradeDTimescoreLimit.toFixed(2)}
						</td>
						<td>D</td>
					</tr>
					<tr>
						<td>{(gradeDTimescoreLimit + 0.01).toFixed(2) + '+'}</td>
						<td>F</td>
					</tr>
				</tbody>
			</table>
			<p>The Granularity (average number of work hours per timecard) decides the plus/minus.</p>
			<table id="granularity-info">
				<thead>
					<tr>
						<td>Granularity</td>
						<td>Grading</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{'<0.5'}</td>
						<td>(+)</td>
					</tr>
					<tr>
						<td>0.6 - 1</td>
						<td>Letter grade only</td>
					</tr>
					<tr>
						<td>{'>1'}</td>
						<td>(-)</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};
