import {reverse} from 'lodash';
import {tap} from 'rxjs/operators';
import {PeriodKind} from '../../../core/ModelContracts';
import {VelocityHistory, UserComplianceResult} from '../../../core/Models';
import {VelocityHistoryService} from '../../../core/services/VelocityHistoryService';
import {LocaleService} from '../../appServices/LocaleService';
import {INotificationService} from '../../appServices/INotificationService';
import {PolicyService} from '../../appServices/PolicyService';
import {ChartColors, ChartData, ChartThemeColors} from '../shared/chart/ChartComponent';
import {InteractorBase} from '../shared/InteractorBase';
import * as moment from 'moment';
import {UsersComplianceResultsService} from '../../../core/services/UsersComplianceResultsService';
import {PropertiesService} from '../../../core/services/propertiesService';
import {PolicyEditorService} from '../../../core/services/PolicyEditorService';
import {INavigationService} from '../../appServices/INavigationService';
import {ChartMonthLabelToDateConverter} from '../complianceHistory/ComplianceHistoryInteractor';
import {ExcelVelocityService} from '../../appServices/ExportToExcelService';

type Column = {Header: string; accessor: string};

export class VelocityHistoryInteractor extends InteractorBase {
	protected readonly initialPeriodKind = 'M';
	protected readonly _velocityHistoryKinds: PeriodKind[] = ['D', 'W', 'M'];
	protected _currentPeriodKind: PeriodKind = this.initialPeriodKind;
	private readonly DATE_FORMAT = 'YYYY-MM-DD';
	private readonly MONTH_FORMAT = 'MMM YYYY';
	private _formattedDate: string;

	private _userCompliances: UserComplianceResult[] = [];

	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private velocityHistoryService: VelocityHistoryService,
		private policyService: PolicyService,
		private userComplianceResultsService: UsersComplianceResultsService,
		private propertiesService: PropertiesService,
		private policyEditorService: PolicyEditorService
	) {
		super(navigationService, notificationService);

		this.velocityHistoryService.velocityHistoriesStream
			.pipe(tap(this.notificationService.hideLoader))
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream
			.pipe(tap(this.resetCurrentPeriod), tap(this.notificationService.showLoader))
			.subscribe();

		this.propertiesService.changeThemeStream.subscribe(this.updateView);
	}

	private resetCurrentPeriod = () => (this._currentPeriodKind = this.initialPeriodKind);

	onPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentPeriodKind = event.target.value as PeriodKind;
		this.notificationService.showLoader();
		this.onRequestVelocityHistory();
	};

	onRequestVelocityHistory = () =>
		this.velocityHistoryService.requestVelocityHistory(this._currentPeriodKind).subscribe();

	prepareChartDataset = (): ChartData => {
		const averageVelocitiesSorted = reverse(
			this.velocityHistories.map((vh: VelocityHistory) => vh.averageVelocity)
		);

		const getThemeColors = () => {
			if (this.propertiesService.theme == 'ADERANT') {
				return ChartThemeColors.aderant;
			}
			if (this.propertiesService.theme == 'BELLEFIELD') {
				return ChartThemeColors.bellefield;
			}
			if (this.propertiesService.theme == 'AQUA') {
				return ChartThemeColors.aqua;
			}
			if (this.propertiesService.theme == 'DAWN') {
				return ChartThemeColors.dawn;
			}
		};

		const dataset = [
			{
				label: 'Your Firm´s Velocity Goal',
				data: new Array(averageVelocitiesSorted.length).fill(this.policyEditorService.policy.velocity.goal),
				backgroundColor: getThemeColors()[1],
				fill: false,
				borderColor: getThemeColors()[1],
			},
			{
				label: 'Actual velocity',
				data: averageVelocitiesSorted,
				backgroundColor: getThemeColors()[2],
				fill: false,
				borderColor: getThemeColors()[2],
			},
		];

		return {
			labels: this.dateLabels(),
			dataset: dataset,
		};
	};

	private fromVelocityHistoryToDatesFormatted = (vh: VelocityHistory) =>
		LocaleService.fromDateToLocaleFormattedPeriod(vh.date, this._currentPeriodKind);

	dateLabels = () => reverse(this.velocityHistories.map(this.fromVelocityHistoryToDatesFormatted));

	onChartClick = (index: number) => {
		const label = this.dateLabels()[index];
		this._formattedDate =
			this._currentPeriodKind == 'M'
				? ChartMonthLabelToDateConverter.getMonthlyFormattedDate(label)
				: moment(label, 'MM-DD-YYYY').format('YYYY-MM-DD');
		return this.userComplianceResultsService
			.requestUserComplianceWithoutNotify(this._formattedDate, this._currentPeriodKind)
			.pipe(tap((ucs) => (this._userCompliances = ucs)));
	};

	get velocityHistories() {
		return this.velocityHistoryService.velocityHistories;
	}

	get velocityHistoryKinds() {
		return this._velocityHistoryKinds;
	}

	get currentPeriodKind() {
		return this._currentPeriodKind;
	}

	get columns(): Column[] {
		return [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: 'Average Velocity',
				accessor: 'averageVelocity',
			},
		];
	}

	exportToExcel = () => {
		ExcelVelocityService.generateFile(this.getDateForExcelFileName(this._formattedDate), this._userCompliances);
	};

	getDateForExcelFileName(date: string) {
		if (this.currentPeriodKind === 'M') return moment(date).format(this.MONTH_FORMAT);

		if (this.currentPeriodKind === 'W') return `${moment(date).startOf('week').format(this.DATE_FORMAT)}`;

		return moment(date).format(this.DATE_FORMAT);
	}

	get usersComplianceResults() {
		return this._userCompliances;
	}
}
