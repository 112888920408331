import {reverse} from 'lodash';
import * as moment from 'moment';
import {tap} from 'rxjs/operators';
import {PeriodKind} from '../../../core/ModelContracts';
import {ContemporaneousHistory, UserContemporaneousResult} from '../../../core/Models';
import {ContemporaneousHistoryService} from '../../../core/services/ContemporaneousHistoryService';
import {PropertiesService} from '../../../core/services/propertiesService';
import {UsersContemporaneousResultsService} from '../../../core/services/UsersContemporaneousResultsService';
import {INotificationService} from '../../appServices/INotificationService';
import {LocaleService} from '../../appServices/LocaleService';
import {PolicyService} from '../../appServices/PolicyService';
import {ChartData, ChartThemeColors} from '../shared/chart/ChartComponent';
import {InteractorBase} from '../shared/InteractorBase';
import {INavigationService} from '../../appServices/INavigationService';
import {ChartMonthLabelToDateConverter} from '../complianceHistory/ComplianceHistoryInteractor';
import {ExcelContemporaneusService} from '../../appServices/ExportToExcelService';

type Column = {Header: string; accessor: string};

export class ContemporaneousHistoryInteractor extends InteractorBase {
	readonly contemporaneousHistoryKinds: PeriodKind[] = ['D', 'W', 'M'];
	private readonly initialPeriodKind = 'M';
	private _currentPeriodKind: PeriodKind = this.initialPeriodKind;
	private _mustShowLoader: boolean = false;
	private readonly DATE_FORMAT = 'YYYY-MM-DD';
	private readonly MONTH_FORMAT = 'MMM YYYY';
	private _formattedDate: string;
	private _userContemporaneous: UserContemporaneousResult[] = [];

	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private contemporaneousHistoryService: ContemporaneousHistoryService,
		private policyService: PolicyService,
		private usersContemporaneousResultsService: UsersContemporaneousResultsService,
		private propertiesService: PropertiesService
	) {
		super(navigationService, notificationService);

		this.contemporaneousHistoryService.contemporaneousHistoriesStream
			.pipe(tap(this.notificationService.hideLoader))
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream
			.pipe(tap(this.resetCurrentPeriod), tap(this.notificationService.showLoader))
			.subscribe();

		this.propertiesService.changeThemeStream.subscribe(this.updateView);
	}

	private resetCurrentPeriod = () => (this._currentPeriodKind = this.initialPeriodKind);

	onPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentPeriodKind = event.target.value as PeriodKind;
		this.notificationService.showLoader();
		this.onRequestContemporaneousHistory();
	};

	onRequestContemporaneousHistory = () => {
		this.contemporaneousHistoryService.requestContemporaneousHistory(this._currentPeriodKind).subscribe();
	};

	onChartClick = (index: number) => {
		const label = this.dateLabels()[index];
		this._formattedDate =
			this._currentPeriodKind == 'M'
				? ChartMonthLabelToDateConverter.getMonthlyFormattedDate(label)
				: moment(label, 'MM-DD-YYYY').format('YYYY-MM-DD');

		return this.usersContemporaneousResultsService
			.requestUserContemporaneousWithoutNotify(this._formattedDate, this._currentPeriodKind)
			.pipe(tap((ucs) => (this._userContemporaneous = ucs)));
	};

	prepareChartDataset = (): ChartData => {
		const totalContemporaneousHoursPercentSorted = reverse(
			this.contemporaneousHistories.map((ch) => Math.round(ch.totalContemporaneousHoursPercent * 100) / 100)
		);
		const totalContemporaneousCardsPercentSorted = reverse(
			this.contemporaneousHistories.map((ch) => Math.round(ch.totalContemporaneousCardsPercent * 100) / 100)
		);

		const getThemeColors = () => {
			if (this.propertiesService.theme == 'ADERANT') {
				return ChartThemeColors.aderant;
			}
			if (this.propertiesService.theme == 'BELLEFIELD') {
				return ChartThemeColors.bellefield;
			}
			if (this.propertiesService.theme == 'AQUA') {
				return ChartThemeColors.aqua;
			}
			if (this.propertiesService.theme == 'DAWN') {
				return ChartThemeColors.dawn;
			}
		};

		const dataset = [
			{
				label: '% of Timecards Created Contemporaneously',
				data: totalContemporaneousCardsPercentSorted,
				backgroundColor: getThemeColors()[1],
				fill: false,
				borderColor: getThemeColors()[1],
			},
			{
				label: '% of Hours Worked Entered Contemporaneously',
				data: totalContemporaneousHoursPercentSorted,
				backgroundColor: getThemeColors()[2],
				fill: false,
				borderColor: getThemeColors()[2],
			},
		];

		return {
			labels: this.dateLabels(),
			dataset: dataset,
		};
	};

	dateLabels = () => reverse(this.contemporaneousHistories.map(this.fromContemporaneousHistoryToDatesFormatted));

	private fromContemporaneousHistoryToDatesFormatted = (ch: ContemporaneousHistory) =>
		LocaleService.fromDateToLocaleFormattedPeriod(ch.date, this._currentPeriodKind);

	get contemporaneousHistories() {
		return this.contemporaneousHistoryService.contemporaneousHistories;
	}

	get currentPeriodKind() {
		return this._currentPeriodKind;
	}

	get columns(): Column[] {
		return [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: '% Timecards Created Contemporaneously',
				accessor: 'contemporaneousCardsCountPercent',
			},
			{
				Header: '% Worked Hours Entered Contemporaneously',
				accessor: 'contemporaneousHoursTotalPercent',
			},
		];
	}

	exportToExcel = () =>
		ExcelContemporaneusService.generateFile(
			this.getDateForExcelFileName(this._formattedDate),
			this._userContemporaneous
		);

	getDateForExcelFileName(date: string) {
		if (this.currentPeriodKind === 'M') return moment(date).format(this.MONTH_FORMAT);

		if (this.currentPeriodKind === 'W') return `${moment(date).startOf('week').format(this.DATE_FORMAT)}`;

		return moment(date).format(this.DATE_FORMAT);
	}

	get userContemporaneousResults() {
		return this._userContemporaneous;
	}
}
