import {Layout} from '../shared/layout/Layout';
import * as React from 'react';
import {PolicyEditorNameComponent} from './shared/PolicyEditorNameComponent';
import {HeaderTabs} from './shared/HeaderTabsComponent';
import {WrapperReactState} from '../shared/WrapperReactState';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {PolicyEditorEngagementInteractor} from './PolicyEditorEngagementInteractor';
import {NavbarRight} from './shared/NavbarRightComponent';
import {ServiceFactory} from '../../factories/ServiceFactory';
import {PolicyEditorActionsComponent} from './shared/PolicyEditorActionsComponent';
import {PolicyEditorGracePeriod} from './PolicyEditorGracePeriodComponent';

export const PolicyEditorEngagementComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPolicyEditorEngagementInteractor()}
		functionComponent={PolicyEditorEngagement}
	/>
);

export const PolicyEditorEngagement = (props: {interactor: PolicyEditorEngagementInteractor}) => (
	<Layout title={'Policy Editor'} navbarRight={<NavbarRight />}>
		<div className="policy-editor-wrapper">
			<div className="policy-name-component">
				<PolicyEditorNameComponent />
				{ServiceFactory.getAuthenticationService().credentials.hasAdministerRole() ? (
					<PolicyEditorActionsComponent />
				) : null}
			</div>
			<HeaderTabs />
			<div className="engagement">
				<h5>In-App Notifications:</h5>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0',
					}}
				>
					<p>
						Enable Daily Reminder: - Gentle morning (local time) notification only if missing min
						requirements for the prior day to timekeepers
					</p>
					<label className="custom-checkbox">
						<input
							type="checkbox"
							checked={props.interactor.policy.brazeDailyEnabled}
							onChange={(e) => props.interactor.onChangeBrazeDailyReminder(e)}
						/>
						<span className="checkmark"></span>
					</label>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0',
					}}
				>
					<p>
						Enable Weekly Reminder: - Monday notification of missing time for the prior week (Sunday to
						Saturday) min requirements sent to timekeepers
					</p>
					<label className="custom-checkbox">
						<input
							type="checkbox"
							checked={props.interactor.policy.brazeWeeklyEnabled}
							onChange={(e) => props.interactor.onChangeBrazeWeeklyReminder(e)}
						/>
						<span className="checkmark"></span>
					</label>
				</div>
				<h5>Email Notifications:</h5>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0',
					}}
				>
					<p>
						TimeTelligence Weekly: - Weekly notification of progress against policy goals sent to
						timekeepers
					</p>
					<label className="custom-checkbox">
						<input
							type="checkbox"
							checked={props.interactor.policy.timetelligenceWeeklyEnabled}
							onChange={(e) => props.interactor.onChangeTimetelligenceWeeklySummary(e)}
						/>
						<span className="checkmark"></span>
					</label>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '10px 0',
					}}
				>
					<p>
						TimeTelligence Monthly: - Monthly notification of progress against policy goals sent to
						timekeepers
					</p>
					<label className="custom-checkbox">
						<input
							type="checkbox"
							checked={props.interactor.policy.timetelligenceMonthlyEnabled}
							onChange={(e) => props.interactor.onChangeTimetelligenceMonthlySummary(e)}
						/>
						<span className="checkmark"></span>
					</label>
				</div>
			</div>
			<div className="button-container">
				<button className="btn btn-primary" onClick={props.interactor.onSave}>
					SAVE POLICY
				</button>
				{/* <button className="btn btn-outline-primary">GENERATE PDF</button> */}
			</div>
		</div>
	</Layout>
);
