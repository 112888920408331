import {InteractorBase} from '../shared/InteractorBase';
import {INotificationService} from '../../appServices/INotificationService';
import {PolicyEditorService} from '../../../core/services/PolicyEditorService';
import {PolicyService} from '../../appServices/PolicyService';
import {tap, mergeMap} from 'rxjs/operators';
import {Policy} from '../../../core/models/Policy';
import {PolicyEditorInteractorBase} from './shared/PolicyEditorBaseInteractor';
import {INavigationService} from '../../appServices/INavigationService';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {PropertiesService} from '../../../core/services/propertiesService';

export enum VelocityForm {
	Goal = 'Goal',
	NotCompliance = 'NotCompliant',
	Compliance = 'Compliant',
	RockStar = 'RockStar',
}

export class PolicyEditorVelocityInteractor extends PolicyEditorInteractorBase {
	private _goalValidationMessage: string;
	private _notComplianceValidationMessage: string;
	private _complianceValidationMessage: string;
	private _rockstarValidationMessage: string;

	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		policyEditorService: PolicyEditorService,
		policyService: PolicyService
	) {
		super(navigationService, notificationService, policyEditorService, policyService);
	}

	onChangeVelocityDisabled = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		policy.velocity.setDisabled(e.target.checked);
		this.updateView();
	};

	onChangeGoal = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log(InteractorFactory.getPolicyEditorTimescoreInteractor().temporaryTimescores);
		const goal = parseInt(e.target.value);
		if (VelocityValidator.isGoalValid(goal)) {
			this.policyEditorService.policy.velocity.setGoal(goal);
		} else {
			this.notificationService.warn('Velocity goal must be a whole number between 0 and 15.');
		}

		this.updateView();
	};

	onChangeNotCompliance = (e: React.ChangeEvent<HTMLInputElement>) => {
		const notCompliance = parseInt(e.target.value);
		if (VelocityValidator.isNotComplianceValid(notCompliance)) {
			this.policyEditorService.policy.velocity.setRewardsNotCompliance(notCompliance);
		} else {
			this.notificationService.warn('Velocity penalty must be negative and between -1000 to 0.');
		}
		this.updateView();
	};

	onChangeCompliance = (e: React.ChangeEvent<HTMLInputElement>) => {
		const compliance = parseInt(e.target.value);
		if (VelocityValidator.isComplianceValid(compliance)) {
			this.policyEditorService.policy.velocity.setRewardsCompliance(compliance);
		} else {
			this.notificationService.warn('Velocity reward must be non-negative whole number and between 0 to 1000.');
		}

		this.updateView();
	};

	onSave = () => {
		this.onSavePolicy(InteractorFactory.getPolicyEditorTimescoreInteractor().temporaryTimescores);
	};

	onChangeRockstar = (e: React.ChangeEvent<HTMLInputElement>) => {
		const rockstar = parseInt(e.target.value);
		if (VelocityValidator.isRockstarValid(rockstar)) {
			this.policyEditorService.policy.velocity.setRewardsRockstar(rockstar);
		} else {
			this.notificationService.warn(
				'Rockstar velocity reward must be non-negative whole number and between 0 to 1000.'
			);
		}

		this.updateView();
	};

	get velocity() {
		return this.policyEditorService.policy.velocity;
	}

	get goalValidationMessage() {
		return this._goalValidationMessage;
	}

	get notComplianceValidationMessage() {
		return this._notComplianceValidationMessage;
	}

	get complianceValidationMessage() {
		return this._complianceValidationMessage;
	}

	get rockstarValidationMessage() {
		return this._rockstarValidationMessage;
	}
}

export class VelocityValidator {
	static isGoalValid = (value: number) => value >= 0 && value <= 15;
	static isNotComplianceValid = (value: number) => value >= -1000 && value <= 0;
	static isComplianceValid = (value: number) => value >= 0 && value <= 1000;
	static isRockstarValid = (value: number) => value >= 0 && value <= 1000;
}
