import {Layout} from '../shared/layout/Layout';
import * as React from 'react';
import {WrapperReactState} from '../shared/WrapperReactState';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {PolicyEditorNameComponent} from './shared/PolicyEditorNameComponent';
import {HeaderTabs} from './shared/HeaderTabsComponent';
import {NavbarRight} from './shared/NavbarRightComponent';
import {PolicyEditorVelocityInteractor, VelocityForm} from './PolicyEditorVelocityInteractor';
import {loaderGif} from '../shared/layout/assets/loaderGif';
import {ServiceFactory} from '../../factories/ServiceFactory';
import {PolicyEditorActionsComponent} from './shared/PolicyEditorActionsComponent';
import {TimescoreInfoModal} from '../timescoreBreakdown/TimescoreBreakdownComponent';

export const PolicyEditorVelocityComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPolicyEditorVelocityInteractor()}
		functionComponent={PolicyEditorVelocity}
	/>
);

export const PolicyEditorVelocity = (props: {interactor: PolicyEditorVelocityInteractor}) => {
	const onClickInfo = () => {
		props.interactor.pushModal(
			<TimescoreInfoModal interactor={InteractorFactory.getPolicyEditorTimescoreInteractor()} />
		);
	};
	return (
		<Layout title={'Policy Editor'} navbarRight={<NavbarRight />}>
			<div className="policy-editor-wrapper">
				<div className="policy-name-component">
					<PolicyEditorNameComponent />
					{ServiceFactory.getAuthenticationService().credentials.hasAdministerRole() ? (
						<PolicyEditorActionsComponent />
					) : null}
				</div>
				<HeaderTabs />
				<div className="velocity">
					<p>
						Velocity is the number of days between when the work is completed and when the timecard for that
						work is submitted. Velocity = {'{'}submission date{'}'} - {'{'}work date{'}'}.
					</p>
					<p>
						The goal is for Timekeepers to have an average Velocity of zero (0) days. In other words, become
						contemporaneous Timekeepers. To learn more <a onClick={onClickInfo}>click here</a>
					</p>
					<p>
						Disable Velocity In Calculations:
						<label className="custom-checkbox">
							<input
								type="checkbox"
								checked={props.interactor.velocity.getDisable()}
								onChange={(e) => props.interactor.onChangeVelocityDisabled(e)}
								onFocus={(e) => e.target.select()}
							/>
							<span className="checkmark"></span>
						</label>
					</p>
					<p>
						Your Firm's Velocity Goal is
						<input
							className="policy-editor-input"
							type="number"
							onChange={props.interactor.onChangeGoal}
							value={props.interactor.velocity.goal}
						/>
						day (s) (0-15)
					</p>
					<p>
						Thrive policy allows you to set penalties and rewards based on compliance or non-compliance for
						each of the policy criteria.
					</p>
					<p>
						Your Firm's Velocity Penalty is
						<input
							className="policy-editor-input velocity-penalty"
							type="number"
							onChange={props.interactor.onChangeNotCompliance}
							value={props.interactor.velocity.getNotCompliance()}
							disabled={props.interactor.velocity.getDisable()}
							onFocus={(e) => e.target.select()}
						/>
					</p>
					<p>
						Your Firm's Velocity Reward is
						<input
							className="policy-editor-input velocity-reward"
							type="number"
							onChange={props.interactor.onChangeCompliance}
							value={props.interactor.velocity.getCompliance()}
							disabled={props.interactor.velocity.getDisable()}
							onFocus={(e) => e.target.select()}
						/>
					</p>
					<p>
						Your Firm's Rockstar Velocity Reward is
						<input
							className="policy-editor-input velocity-rockstar"
							type="number"
							onChange={props.interactor.onChangeRockstar}
							value={props.interactor.velocity.getRockstar()}
							disabled={props.interactor.velocity.getDisable()}
							onFocus={(e) => e.target.select()}
						/>
					</p>
				</div>
				<div className="button-container">
					<button className="btn btn-primary" onClick={props.interactor.onSave}>
						SAVE POLICY
					</button>
					{/* <button className="btn btn-outline-primary">GENERATE PDF</button> */}
				</div>
			</div>
		</Layout>
	);
};
