import {InteractorBase} from '../../shared/InteractorBase';
import {NavigationService} from '../../../appServices/NavigationService';
import {INotificationService} from '../../../appServices/INotificationService';
import {PolicyEditorService} from '../../../../core/services/PolicyEditorService';
import {PolicyService} from '../../../appServices/PolicyService';
import {tap, mergeMap} from 'rxjs/operators';
import {Policy, TimescoreRangeByGrade} from '../../../../core/models/Policy';
import {INavigationService} from '../../../appServices/INavigationService';
import {cloneDeep} from 'lodash';

export class PolicyEditorInteractorBase extends InteractorBase {
	protected _formattedTimescoreValues: string[] = [];
	protected _temporaryTimescores: TimescoreRangeByGrade[];
	protected _timescoreErrors: string[] = Array(4).fill('');
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		protected policyEditorService: PolicyEditorService,
		protected policyService: PolicyService
	) {
		super(navigationService, notificationService);

		this.policyEditorService = policyEditorService;
		this.policyService = policyService;

		this.policyEditorService.policyStream
			.pipe(
				tap(this.notificationService.hideLoader),
				tap(
					() =>
						(this._formattedTimescoreValues = policyEditorService.policy.timescores.map((timescore) =>
							timescore.limit.toFixed(2)
						))
				),
				tap(() => (this._temporaryTimescores = cloneDeep(policyEditorService.policy.timescores))),
				tap(() => (this._timescoreErrors = Array(4).fill('')))
			)
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream
			.pipe(tap(this.notificationService.showLoader))
			.subscribe(this.updateView);

		this._formattedTimescoreValues = policyEditorService.policy.timescores.map((timescore) =>
			timescore.limit.toFixed(2)
		);

		this._temporaryTimescores = cloneDeep(policyEditorService.policy.timescores);
	}

	onSavePolicy = (temporaryTimescores: TimescoreRangeByGrade[]) => {
		const timescoresAreAscending = TimescoreValidator.timescoreLimitsAreAscending(temporaryTimescores);
		if (!timescoresAreAscending) {
			return this.notificationService.error(
				'The velocity values for each timescore grade must be in ascending order.'
			);
		}

		this.notificationService.showLoader();
		const policy = this.policyEditorService.policy;
		policy.setTimescores(temporaryTimescores);
		policy.isEnabled ? this.enablePolicy(policy) : this.disablePolicy(policy);
		this.saveEngagementProperties(policy);
		this.requestEditPolicy(policy);
	};

	private enablePolicy = (policy: Policy) => {
		this.policyEditorService.enablePolicy(policy).subscribe();
	};

	private disablePolicy = (policy: Policy) => {
		this.policyEditorService.disablePolicy(policy).subscribe();
	};

	private saveEngagementProperties = (policy: Policy) => {
		this.policyEditorService.requestSetEngagementPropertyStatus(policy).subscribe();
	};

	private requestEditPolicy = (policy: Policy) => {
		const refreshPolicies = () => this.policyService.requestPoliciesForSubscription();

		this.policyEditorService
			.editPolicyWithoutUpdatePolicy(policy)
			.pipe(
				tap(() => this.notificationService.log(`Policy ${policy.id} Succesfully Saved`)),
				mergeMap(refreshPolicies)
			)
			.subscribe();
	};
}

export class TimescoreValidator {
	static currentTimescoreIsLowerThanPrevious = (timescores: TimescoreRangeByGrade[], index: number): boolean => {
		return (
			timescores.map((timescore) => timescore.limit)[index] <=
			timescores.map((timescore) => timescore.limit)[index - 1]
		);
	};

	static currentTimescoreIsGreaterThanNext = (timescores: TimescoreRangeByGrade[], index: number): boolean => {
		return (
			timescores.map((timescore) => timescore.limit)[index] >=
			timescores.map((timescore) => timescore.limit)[index + 1]
		);
	};

	static timescoreLimitsAreAscending = (timescores: TimescoreRangeByGrade[]): boolean => {
		const limitValues = timescores.map((timescore) => timescore.limit);
		return limitValues.filter((timescore, i) => timescore >= limitValues[i + 1]).length === 0;
	};
}
