import {InteractorBase} from '../shared/InteractorBase';
import {INotificationService} from '../../appServices/INotificationService';
import {UsersComplianceResultsService} from '../../../core/services/UsersComplianceResultsService';
import {PeriodKind, ComplianceKind} from '../../../core/ModelContracts';
import {PeriodsService} from '../../appServices/PeriodsService';
import {PolicyService} from '../../appServices/PolicyService';
import {tap, mergeMap} from 'rxjs/operators';
import {Moment} from 'moment';
import * as moment from 'moment';
import {INavigationService} from '../../appServices/INavigationService';
import {ExcelPenaltiesAndRewardsService} from '../../appServices/ExportToExcelService';
import {DatesFormatter} from '../../../core/datesFormatter';

type Column = {Header: string; accessor: string};

type Filters = 'Both' | 'Not Compliant' | 'Compliant';

export class PenaltiesAndRewardsInteractor extends InteractorBase {
	private readonly DATE_FORMAT = 'YYYY-MM-DD';
	private readonly MONTH_FORMAT = 'MMM YYYY';
	private readonly initialPeriodKind = 'M';
	private readonly _usersComplianceResultsKinds: PeriodKind[] = ['M', 'W'];
	private readonly _filters: Filters[] = ['Both', 'Not Compliant', 'Compliant'];
	private readonly initialFilter = 'Both';
	private _currentFilter: Filters = this.initialFilter;
	private _currentPeriodKind: PeriodKind = this.initialPeriodKind;
	private _currentPeriodForPolicy: Moment;

	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private usersComplianceResultsService: UsersComplianceResultsService,
		private periodsService: PeriodsService,
		private policyService: PolicyService
	) {
		super(navigationService, notificationService);

		this.usersComplianceResultsService.usersComplianceResultsStream
			.pipe(tap(this.updateCurrentPeriodForPolicy), tap(this.notificationService.hideLoader))
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream
			.pipe(tap(this.resetCurrentPeriod), tap(this.resetCurrentFilter), tap(this.notificationService.showLoader))
			.subscribe();

		this._currentPeriodForPolicy = this.periodsService.penaltiesAndRewardsCurrentPeriod;
	}

	updateCurrentPeriodForPolicy = () =>
		(this._currentPeriodForPolicy = this.periodsService.penaltiesAndRewardsCurrentPeriod);

	private resetCurrentPeriod = () => (this._currentPeriodKind = this.initialPeriodKind);

	private resetCurrentFilter = () => (this._currentFilter = this.initialFilter);

	onPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentPeriodKind = event.target.value as PeriodKind;
		this.notificationService.showLoader();
		this.periodsService
			.getPenaltiesAndRewardsPeriodsForPolicy(this._currentPeriodKind)
			.pipe(
				tap(this.periodsService.initializePenaltiesAndRewardsPeriod),
				tap(this.updateCurrentPeriodForPolicy),
				tap(this.resetCurrentFilter),
				mergeMap(() =>
					this.usersComplianceResultsService.requestUsersComplianceresults(this._currentPeriodKind)
				)
			)
			.subscribe();
	};

	onPeriodForPolicyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this.resetCurrentFilter();
		this._currentPeriodForPolicy = moment(event.target.value);
		this.periodsService.penaltiesAndRewardsCurrentPeriod = moment(event.target.value);
		this.notificationService.showLoader();
		this.usersComplianceResultsService.requestUsersComplianceresults(this._currentPeriodKind).subscribe();
	};

	onFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentFilter = event.target.value as Filters;
		this.updateView();
	};

	formatPeriod(date: string) {
		return DatesFormatter.formatDateByPeriodKind(date, this._currentPeriodKind);
	}

	onExportUsersToExcel = () => {
		ExcelPenaltiesAndRewardsService.generateFile(
			this.formatPeriod(this.currentPeriodForPolicy),
			this.usersComplianceResults,
			this.getComplianceType()
		);
	};

	getComplianceType = () => (this._currentFilter == 'Both' ? 'PenaltiesAndRewards' : this._currentFilter);

	get usersComplianceResults() {
		if (this._currentFilter === 'Compliant')
			return this.usersComplianceResultsService.usersComplianceResults.filter(
				(user) => user.complianceStatus == 'Compliant' || user.complianceStatus == 'Rockstar'
			);

		if (this._currentFilter === 'Not Compliant')
			return this.usersComplianceResultsService.usersComplianceResults.filter(
				(user) => user.complianceStatus == 'Not Compliant'
			);

		return this.usersComplianceResultsService.usersComplianceResults;
	}

	get currentPeriodKind() {
		return this._currentPeriodKind;
	}

	get usersComplianceResultsKinds() {
		return this._usersComplianceResultsKinds;
	}

	get periods() {
		return this.periodsService.penaltiesAndRewardsPeriodsForPolicy.map((d) => d.format(this.DATE_FORMAT));
	}

	get currentPeriodForPolicy() {
		return this._currentPeriodForPolicy.format(this.DATE_FORMAT);
	}

	get filters() {
		return this._filters;
	}

	get currentFilter() {
		return this._currentFilter;
	}

	get columns(): Column[] {
		return [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: 'Email',
				accessor: 'email',
			},
			{
				Header: 'Reward',
				accessor: 'totalCashReward',
			},
			{
				Header: 'Penalty',
				accessor: 'totalCashPenalty',
			},
		];
	}
}
