import * as React from 'react';
import {ServiceFactory} from '../../../factories/ServiceFactory';
import {Routes} from '../../../Routes';
import {Link} from 'react-router-dom';

export const HeaderTabs = () => {
	const activeTab = 'label-selected';
	const disableTab = 'label-unselected';

	const prepareClassNameBy = (route: string) =>
		ServiceFactory.getNavigationService().isCurrentRoute(route) ? activeTab : disableTab;

	const shouldShowUsersTab = () =>
		ServiceFactory.getAuthenticationService().credentials.hasAdminAccess() ||
		ServiceFactory.getAuthenticationService().credentials.hasPolicyOwnerOnlyAccess() ||
		ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesAndPolicyOwnerAccess();

	const shouldShowTimescoreTab = () =>
		ServiceFactory.getAuthenticationService().credentials.hasAdminAccess() ||
		ServiceFactory.getAuthenticationService().credentials.hasPolicyOwnerOnlyAccess() ||
		ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesAndPolicyOwnerAccess();

	return (
		<nav className="policy-editor-nav">
			<li className={prepareClassNameBy(Routes.policyEditorVelocity)}>
				<Link className="link" to={Routes.policyEditorVelocity}>
					Velocity
				</Link>
			</li>
			<li className={prepareClassNameBy(Routes.policyEditorDeadlineDiscipline)}>
				<Link className="link" to={Routes.policyEditorDeadlineDiscipline}>
					Work Hours
				</Link>
			</li>
			<li className={prepareClassNameBy(Routes.policyEditorGraceperiod)}>
				<Link className="link" to={Routes.policyEditorGraceperiod}>
					Grace Period
				</Link>
			</li>
			<li className={prepareClassNameBy(Routes.policyEditorEngagement)}>
				<Link className="link" to={Routes.policyEditorEngagement}>
					Engagement
				</Link>
			</li>
			{shouldShowUsersTab() ? (
				<li className={prepareClassNameBy(Routes.policyEditorUsers)}>
					<Link className="link" to={Routes.policyEditorUsers}>
						Users
					</Link>
				</li>
			) : null}
			{shouldShowTimescoreTab() ? (
				<li className={prepareClassNameBy(Routes.policyEditorTimescore)}>
					<Link className="link" to={Routes.policyEditorTimescore}>
						Timescore
					</Link>
				</li>
			) : null}
		</nav>
	);
};
