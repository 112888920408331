import moment = require('moment/moment');

export class ActivityService {
	static executeWhenAppIsActive = (command: () => void, frequencyInSeconds: number) => {
		let initialTime = moment();
		const onActive = () => {
			const now = moment();
			const canExecute = initialTime.clone().add(frequencyInSeconds, 'seconds') < now;
			if (canExecute) {
				initialTime = now;
				command();
			}
		};

		document.onmousemove = onActive;
		document.onmousedown = onActive;
		document.onmouseup = onActive;
		document.onkeydown = onActive;
		document.onkeyup = onActive;
	};
}
