import {WrapperReactState} from '../WrapperReactState';
import {InteractorFactory} from '../../../factories/InteractorFactory';
import * as React from 'react';
import {PolicySelectorInteractor} from './PolicySelectorInteractor';

export const PolicySelectorComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPolicySelectorInteractor()}
		functionComponent={PolicySelectorComponent}
	/>
);

export const PolicySelectorForHumanResourcesComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPolicySelectorInteractorForHumanResources()}
		functionComponent={PolicySelectorComponent}
	/>
);

const PolicySelectorComponent = (props: {interactor: PolicySelectorInteractor}) => {
	const fromPoliciesToOptions = () =>
		props.interactor.policies.map((policy) => (
			<option key={policy.policyId} value={policy.policyId}>{`${policy.policyName} (${policy.policyId})`}</option>
		));

	return (
		<div>
			<select
				value={props.interactor.currentPolicy}
				onChange={(e) => props.interactor.updateCurrentPolicy(e.target.value)}
			>
				{fromPoliciesToOptions()}
			</select>
		</div>
	);
};
