import {Observable, of} from 'rxjs';
import {Policy} from '../models/Policy';
import {EngagementProperties} from '../Models';

export interface PolicyEditorRepository {
	getPolicy: (policyId) => Observable<Policy>;
	addPolicy: (policy: Policy) => Observable<Policy>;
	editPolicy: (policy: Policy) => Observable<Policy>;
	isPolicyEnabledById: (id: number) => Observable<boolean>;
	enablePolicyById: (id: number) => Observable<{}>;
	disablePolicyById: (id: number) => Observable<{}>;
	deletePolicyById: (id: number) => Observable<{}>;
	duplicatePolicy: (policy: Policy) => Observable<Policy>;
	getEngagementProperties: (policyId: number) => Observable<EngagementProperties>;
	setEngagementPropertyStatus: (policy: Policy) => Observable<{}>;
}

export class PolicyEditorInMemoryRepository implements PolicyEditorRepository {
	constructor(private policy: Policy, private newPolicy: Policy) {}

	getPolicy = () => of(this.policy);

	addPolicy = () => of(this.newPolicy);

	editPolicy = () => of(this.policy);

	isPolicyEnabledById = () => of(true);

	enablePolicyById = () => of({});

	disablePolicyById = () => of({});

	deletePolicyById = () => of({});

	duplicatePolicy = () => of(this.policy);

	getEngagementProperties = () => of({} as EngagementProperties);

	setEngagementPropertyStatus = () => of({});
}
