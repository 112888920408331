import {InteractorBase} from '../InteractorBase';
import {ModalService} from '../../../appServices/ModalService';
import {INotificationService} from '../../../appServices/INotificationService';
import {INavigationService} from '../../../appServices/INavigationService';

export class ModalInteractor extends InteractorBase {
	constructor(navigationService: INavigationService, notificationService: INotificationService) {
		super(navigationService, notificationService);

		this.navigationService.modalService.modalsStream.subscribe(this.updateView);
	}

	shouldShowWrapper = () => this.modals.length > 0;

	get modals() {
		return this.navigationService.modalService.modals;
	}
}
