import * as React from 'react';
import {Transcript} from '../../../core/Transcript';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {ChartComponent} from '../shared/chart/ChartComponent';
import {Layout} from '../shared/layout/Layout';
import {WrapperReactState} from '../shared/WrapperReactState';
import {DashboardComparisonsInteractor} from './DashboardComparisonsInteractor';
import {PolicySelectorComponentWrapped} from '../shared/policySelector/PolicySelectorComponent';
import {ReactNode} from 'react';
import ReactTable from 'react-table-v6';
import {ServiceFactory} from '../../factories/ServiceFactory';
import {ReactTableHelper} from '../../helpers/ReactTableHelper';
import {TimescoreInfoModal} from '../timescoreBreakdown/TimescoreBreakdownComponent';
import {Just, None, Some} from 'monet';

export const DashboardComparisonsComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getDashboardComparisonsInteractor()}
		functionComponent={DashboardComparisonsComponent}
	/>
);

export const DashboardComparisonsComponent = (props: {interactor: DashboardComparisonsInteractor}) => {
	const onChart = (index: number) => {
		props.interactor
			.onChartClick(index)
			.subscribe(() =>
				props.interactor.pushModal(<UserComplianceComponent interactor={props.interactor} index={index} />)
			);
	};

	return (
		<Layout title={'Dashboard'} navbarRight={<NavbarRight interactor={props.interactor} />}>
			<GraphContainer interactor={props.interactor}>
				<ChartComponent
					data={props.interactor.prepareChartDataset()}
					kind="pie"
					hasAnimated={true}
					hideAxes={true}
					tooltipCallback={props.interactor.tooltipLabelCallback as any}
					legendPosition="bottom"
					showYAxeLabel={false}
					yAxeLabel=""
					hideLegend={false}
					legendCallback={() => '<h1>Carlos</h1>'}
					discardAspectRatio={true}
					onClick={props.interactor.currentPeriodKind == 'D' ? () => {} : onChart}
				/>
			</GraphContainer>
			<ResultsOverview interactor={props.interactor} />
		</Layout>
	);
};

const UserComplianceComponent = (props: {interactor: DashboardComparisonsInteractor; index: number}) => {
	const headerTextByIndex = (index: number) => {
		if (index == 0) return 'Rockstars';
		if (index == 1) return 'Compliant';
		return 'Not Compliant';
	};

	return (
		<div>
			<div className="table-header">
				<h4>{`${headerTextByIndex(props.index)} (${props.interactor.formatPeriod(
					props.interactor.currentPeriodForPolicy
				)})`}</h4>
				<span className="export-to-excel" onClick={props.interactor.exportToExcel}>
					Export to <i className="icon-icon-exportxls"></i>
				</span>
			</div>
			<div className="header-separator"></div>
			<ReactTable
				data={props.interactor.usersComplianceResults}
				columns={props.interactor.columns}
				filterable={true}
				showPageJump={false}
				defaultFilterMethod={ReactTableHelper.filterCaseInsensitive}
			/>
		</div>
	);
};

const NavbarRight = (props: {interactor: DashboardComparisonsInteractor}) => {
	const fromKindToOptions = () =>
		props.interactor.dashboardComparisonsKinds.map((kind) => (
			<option key={kind} value={kind}>
				{Transcript.transcriptKind(kind)}
			</option>
		));

	const fromPeriodsToOptions = () =>
		props.interactor.periods.map((period, index) => (
			<option key={index} value={period}>
				{props.interactor.formatPeriod(period)}
			</option>
		));

	return (
		<div className="navbar-right">
			<div className="select-wrapper">
				<PolicySelectorComponentWrapped />
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentPeriodKind} onChange={props.interactor.onPeriodChange}>
					{fromKindToOptions()}
				</select>
			</div>
			<div className="select-wrapper">
				<select
					//hidden={props.interactor.mustShowLoader}
					value={props.interactor.currentPeriodForPolicy}
					onChange={props.interactor.onPeriodForPolicyChange}
				>
					{fromPeriodsToOptions()}
				</select>
			</div>
		</div>
	);
};

const GraphContainer = (props: {interactor: DashboardComparisonsInteractor; children: ReactNode}) => (
	<div className="graphs-canvas">
		<div className="graph-title">
			<h4>Thrive Policy Breakdown ({props.interactor.formatPeriod(props.interactor.currentPeriodForPolicy)})</h4>
		</div>
		<div className="note">
			The Thrive Policy dashboard displays compliancy information pertaining to your firm’s timekeeping policy.
		</div>
		<div className="chart-container container">{props.children}</div>
	</div>
);

const ResultsOverview = (props: {interactor: DashboardComparisonsInteractor}) => {
	const onClickInfo = () => {
		props.interactor.pushModal(
			<TimescoreInfoModal interactor={InteractorFactory.getPolicyEditorTimescoreInteractor()} />
		);
	};

	const isExportToExcelActive = () =>
		props.interactor.dashboardComparisons
			.map((v) => v.numberOfUsers)
			.flatMap<boolean>((v) => (v != 0 ? Some(true) : None()))
			.orSome(false);

	return (
		<div className="results-overview">
			<div className="overview-title">
				<h4>Results Overview</h4>
				<span
					className={isExportToExcelActive() ? 'export-to-excel' : 'export-to-excel inactive'}
					onClick={isExportToExcelActive() ? props.interactor.onExportAllUsersToExcel : null}
				>
					Export User Results<i className="icon-icon-exportxls"></i>
				</span>
			</div>
			<div className="overview-wrapper">
				<div className="box">
					<p>Velocity Goal</p>
					<div className="box-content">
						<p>
							{props.interactor.dashboardComparisons.map((v) => v.periodConfiguredVelocityGoal).orSome(0)}
							<span>days</span>
						</p>
					</div>
				</div>
				<div className="box">
					<p>Velocity</p>
					<div className="box-content">
						<p>
							{props.interactor.dashboardComparisons.flatMap((v) => v.periodAverageVelocity).orSome(0)}
							<span>days</span>
						</p>
					</div>
					<div className="box-bottom">
						<p>
							All firms:{' '}
							{props.interactor.dashboardComparisons
								.flatMap((v) => v.periodSystemAverageVelocity)
								.orSome(0)}{' '}
							days
						</p>
					</div>
				</div>
				<div className="box last">
					<p>Policy Timescore</p>
					<i onClick={onClickInfo} className="icon-icon-information"></i>
					<div className="box-content">
						<p>
							{props.interactor.dashboardComparisons.map((v) =>
								v.firmTimeScore ? v.firmTimeScore : '-'
							)}
						</p>
					</div>
				</div>
			</div>
			<div className="overview-wrapper">
				<div className="box">
					<p>Contemporaneous Hours</p>
					<div className="box-content">
						<p>
							{Math.round(
								props.interactor.dashboardComparisons
									.map((v) => v.contemporaneousHoursTotalPercent * 100)
									.orSome(0)
							)}
							.00%
						</p>
					</div>
				</div>
				<div className="box">
					<p>Contemporaneous Entries</p>
					<div className="box-content">
						<p>
							{Math.round(
								props.interactor.dashboardComparisons
									.map((v) => v.contemporaneousCardsCountPercent * 100)
									.orSome(0)
							)}
							.00%
						</p>
					</div>
				</div>
				<div className="box last">
					<p>Total Delinquent Hours</p>
					<div className="box-content">
						<p>
							{props.interactor.dashboardComparisons
								.map((v) => v.totalHoursCreatedAfterGracePeriod)
								.orSome(0)}
						</p>
					</div>
					<div className="box-bottom"></div>
					<p>Billable Delinquent Hours</p>
					<div className="box-content">
						<p>
							{props.interactor.dashboardComparisons
								.map((v) => v.totalBillableHoursCreatedAfterGracePeriod)
								.orSome(0)}
						</p>
					</div>
				</div>
			</div>
			<div className="overwiew-summary">
				<p>
					Your firm averaged{' '}
					{props.interactor.dashboardComparisons
						.map((v) => v.periodTotalWorkHours / v.numberOfUsers)
						.flatMap<string>((v) => (isNaN(v) ? None() : Some(`${v.toFixed(2)} `)))
						.orSome('-- ')}
					hours per attorney and{' '}
					{props.interactor.dashboardComparisons
						.map((v) => v.periodTotalBillableWorkHours / v.numberOfUsers)
						.flatMap<string>((v) => (isNaN(v) ? None() : Some(`${v.toFixed(2)} `)))
						.orSome('-- ')}
					billable hours per attorney.
				</p>
				<p>
					There were{' '}
					{props.interactor.dashboardComparisons.map((v) => v.totalTimekeepersWithRewards).orSome(0)}{' '}
					attorneys rewarded for a total of{' '}
					{props.interactor.dashboardComparisons.map((v) => v.totalCashRewards).orSome(0)}
				</p>
				<p>
					There were{' '}
					{props.interactor.dashboardComparisons.map((v) => v.totalTimekeepersWithPenalties).orSome(0)}{' '}
					attorneys penalized for a total of{' '}
					{props.interactor.dashboardComparisons.map((v) => v.totalCashPenalties).orSome(0)}
				</p>
			</div>
		</div>
	);
};
