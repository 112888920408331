import * as React from 'react';
import {WrapperReactState} from '../shared/WrapperReactState';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {TimescoreBreakdownUserHistoryInteractor} from './TimescoreBreakdownUserHistoryInteractor';
import {ChartComponent} from '../shared/chart/ChartComponent';
import {UserTimescoreHistory} from '../../../core/Models';

export const TimescoreBreakdownUserHistoryWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getTimescoreBreakdownUserHistoryInteractor()}
		functionComponent={TimescoreBreakdownUserHistory}
	/>
);

export const TimescoreBreakdownUserHistory = (props: {interactor: TimescoreBreakdownUserHistoryInteractor}) => {
	const showLoader = () => props.interactor.mustShowLoader && <div className="loadingSpinner">Loading ...</div>;

	const fromUsersToOptions = () =>
		props.interactor.userTimescores.map((timescore) => (
			<option
				key={timescore.userId}
				value={timescore.userId}
			>{`${timescore.firstName} ${timescore.lastName}`}</option>
		));
	return (
		<div style={{height: 'auto', width: '90vw'}}>
			<select value={props.interactor.userTimescoreHistoriesCurrentUser} onChange={props.interactor.onUserChange}>
				{fromUsersToOptions()}
			</select>
			<div className="chart-container">
				{showLoader()}
				<ChartComponent
					data={props.interactor.prepareChartDataSet()}
					kind="line"
					hasAnimated={!props.interactor.mustShowLoader}
					transformYValues={UserTimescoreHistory.fromNumberToTimeScore}
					type="time"
					tooltipCallback={props.interactor.tooltipLabelCallback}
					title={`Timescore Per Day`}
					legendPosition="bottom"
					showYAxeLabel={true}
					yAxeLabel="Timescore"
					stepSize={1}
					onClick={() => {}}
					inModal={true}
					discardAspectRatio={true}
				/>
			</div>
		</div>
	);
};
