import {PropertiesRepository} from '../repositories/propertiesRepository';
import {Property} from '../Models';
import {tap} from 'rxjs/operators';
import {Subject} from 'rxjs';

export class PropertiesService {
	private _theme: string;
	private _properties: Property[];
	private _changeThemeStream: Subject<string> = new Subject();
	constructor(private repo: PropertiesRepository) {}

	initialRequest = () => {
		const properties = ['Applications.UserTheme.ColorTheme'];
		return this.requestProperties(properties);
	};

	requestProperties = (properties: string[]) => this.repo.getProperties(properties).pipe(tap(this.setProperties));

	private setProperties = (properties: Property[]) => {
		if (properties.find((property) => property.name == 'Applications.UserTheme.ColorTheme') == null) {
			return (this._theme = 'ADERANT');
		}
		this._theme = properties.find((property) => property.name == 'Applications.UserTheme.ColorTheme').value;
	};

	setThemeAndNotify = (theme: string) => {
		this._theme = theme;
		this._changeThemeStream.next(theme);
	};

	requestSetProperties = (properties: Property[]) => {
		this.repo.setProperties(properties);
	};

	get theme() {
		return this._theme;
	}

	get changeThemeStream() {
		return this._changeThemeStream;
	}
}
