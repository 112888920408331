import * as React from 'react';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {Layout} from '../shared/layout/Layout';
import {WrapperReactState} from '../shared/WrapperReactState';
import ReactTable from 'react-table-v6';
import {PenaltiesAndRewardsInteractor} from './PenaltiesAndRewardsInteractor';
import {Transcript} from '../../../core/Transcript';
import {
	PolicySelectorComponentWrapped,
	PolicySelectorForHumanResourcesComponentWrapped,
} from '../shared/policySelector/PolicySelectorComponent';
import {ServiceFactory} from '../../factories/ServiceFactory';
import {ReactTableHelper} from '../../helpers/ReactTableHelper';

export const PenaltiesAndRewardsComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPenaltiesAndRewardsInteractor()}
		functionComponent={PenaltiesAndRewardsComponent}
	/>
);

export const PenaltiesAndRewardsForHrAndPolicyOwnerComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPenaltiesAndRewardsInteractorForHrAndPolicyOwner()}
		functionComponent={PenaltiesAndRewardsComponentHr}
	/>
);

const PenaltiesAndRewardsComponent = (props: {interactor: PenaltiesAndRewardsInteractor}) => {
	const isExportToExcelActive = () => props.interactor.usersComplianceResults.length > 0;
	return (
		<Layout title={'Penalties And Rewards'} navbarRight={<NavbarRight interactor={props.interactor} />}>
			<div className="container">
				<div className="export-to-excel-wrapper">
					<span
						className={isExportToExcelActive() ? 'export-to-excel' : 'export-to-excel inactive'}
						onClick={isExportToExcelActive() ? props.interactor.onExportUsersToExcel : null}
					>
						Export to <i className="icon-icon-exportxls"></i>
					</span>
				</div>
				<ReactTable
					data={props.interactor.usersComplianceResults}
					columns={props.interactor.columns}
					filterable={true}
					showPageJump={false}
					defaultFilterMethod={ReactTableHelper.filterCaseInsensitive}
				/>
			</div>
		</Layout>
	);
};

const NavbarRight = (props: {interactor: PenaltiesAndRewardsInteractor}) => {
	const fromKindToOptions = () =>
		props.interactor.usersComplianceResultsKinds.map((kind) => (
			<option key={kind} value={kind}>
				{Transcript.transcriptKind(kind)}
			</option>
		));

	const fromPeriodsToOptions = () =>
		props.interactor.periods.map((period, index) => (
			<option key={index} value={period}>
				{props.interactor.formatPeriod(period)}
			</option>
		));

	const fromFiltersToOptions = () =>
		props.interactor.filters.map((filter) => (
			<option key={filter} value={filter}>
				{filter}
			</option>
		));

	return (
		<div className="navbar-right">
			<div className="select-wrapper">
				<PolicySelectorComponentWrapped />
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentPeriodKind} onChange={props.interactor.onPeriodChange}>
					{fromKindToOptions()}
				</select>
			</div>
			<div className="select-wrapper">
				<select
					value={props.interactor.currentPeriodForPolicy}
					onChange={props.interactor.onPeriodForPolicyChange}
				>
					{fromPeriodsToOptions()}
				</select>
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentFilter} onChange={props.interactor.onFilterChange}>
					{fromFiltersToOptions()}
				</select>
			</div>
		</div>
	);
};

const PenaltiesAndRewardsComponentHr = (props: {interactor: PenaltiesAndRewardsInteractor}) => {
	const isExportToExcelActive = () => props.interactor.usersComplianceResults.length > 0;
	return (
		<Layout title={'Penalties And Rewards'} navbarRight={<NavbarRightHr interactor={props.interactor} />}>
			<div className="container">
				<div className="export-to-excel-wrapper">
					<span
						className={isExportToExcelActive() ? 'export-to-excel' : 'export-to-excel inactive'}
						onClick={isExportToExcelActive() ? props.interactor.onExportUsersToExcel : null}
					>
						Export to <i className="icon-icon-exportxls"></i>
					</span>
				</div>
				<ReactTable
					data={props.interactor.usersComplianceResults}
					columns={props.interactor.columns}
					filterable={true}
					showPageJump={false}
				/>
			</div>
		</Layout>
	);
};

const NavbarRightHr = (props: {interactor: PenaltiesAndRewardsInteractor}) => {
	const fromKindToOptions = () =>
		props.interactor.usersComplianceResultsKinds.map((kind) => (
			<option key={kind} value={kind}>
				{Transcript.transcriptKind(kind)}
			</option>
		));

	const fromPeriodsToOptions = () =>
		props.interactor.periods.map((period, index) => (
			<option key={index} value={period}>
				{props.interactor.formatPeriod(period)}
			</option>
		));

	const fromFiltersToOptions = () =>
		props.interactor.filters.map((filter) => (
			<option key={filter} value={filter}>
				{filter}
			</option>
		));

	return (
		<div className="navbar-right">
			<div className="select-wrapper">
				<PolicySelectorForHumanResourcesComponentWrapped />
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentPeriodKind} onChange={props.interactor.onPeriodChange}>
					{fromKindToOptions()}
				</select>
			</div>
			<div className="select-wrapper">
				<select
					value={props.interactor.currentPeriodForPolicy}
					onChange={props.interactor.onPeriodForPolicyChange}
				>
					{fromPeriodsToOptions()}
				</select>
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentFilter} onChange={props.interactor.onFilterChange}>
					{fromFiltersToOptions()}
				</select>
			</div>
		</div>
	);
};
