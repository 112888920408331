import * as React from 'react';
import {PolicyMember} from '../../../core/Models';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {ServiceFactory} from '../../factories/ServiceFactory';
import {Layout} from '../shared/layout/Layout';
import {WrapperReactState} from '../shared/WrapperReactState';
import {PolicyEditorUsersInteractor} from './policyEditorUsersInteractor';
import {HeaderTabs} from './shared/HeaderTabsComponent';
import {NavbarRight} from './shared/NavbarRightComponent';
import {PolicyEditorActionsComponent} from './shared/PolicyEditorActionsComponent';
import {PolicyEditorNameComponent} from './shared/PolicyEditorNameComponent';

export const PolicyEditorUsersWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPolicyEditorUsersInteractor()}
		functionComponent={PolicyEditorUsers}
	/>
);

export const PolicyEditorUsers = (props: {interactor: PolicyEditorUsersInteractor}) => {
	const shouldShowOwners = () => ServiceFactory.getAuthenticationService().credentials.hasAdminAccess();

	return (
		<Layout title={'Policy Editor'} navbarRight={<NavbarRight />}>
			<div className="policy-editor-wrapper">
				<div className="policy-name-component hidden">
					<PolicyEditorNameComponent />
					{ServiceFactory.getAuthenticationService().credentials.hasAdministerRole() ? (
						<PolicyEditorActionsComponent />
					) : null}
				</div>
				<HeaderTabs />
				<div className="policy-users">
					<div className="users-area">
						<div className="user-list">
							<h4>Available Users</h4>
							<MembersFilter
								onFilter={props.interactor.onFilterAvailableUsers}
								onClearFilter={props.interactor.onClearAvailableUsersFilter}
								queryForMembers={props.interactor.queryForAvailableUsers}
							/>
							<div className="available-users">
								{props.interactor.availableUsers.map((u) => (
									<AvailableMember
										key={u.id}
										onAddMember={props.interactor.onRequestAddUser}
										member={u}
									/>
								))}
							</div>
						</div>
						<div className="separator">
							<i className="icon-icon-2arrows"></i>
						</div>
						<div className="user-list">
							<h4>Assigned Users</h4>
							<MembersFilter
								onFilter={props.interactor.onFilterAssignedUsers}
								onClearFilter={props.interactor.onClearAssignedUsersFilter}
								queryForMembers={props.interactor.queryForAssignedUsers}
							/>
							<div className="assigned-users">
								{props.interactor.assignedUsers.map((u) => (
									<AssignedMember
										key={u.id}
										onRemoveMember={props.interactor.onRequestRemoveUser}
										member={u}
									/>
								))}
							</div>
						</div>
					</div>
					{shouldShowOwners() ? <div className="separator"></div> : null}
					{shouldShowOwners() ? (
						<div className="owners-area">
							<div className="owners-list">
								<h4>Available Owners</h4>
								<MembersFilter
									onFilter={props.interactor.onFilterAvailableOwners}
									onClearFilter={props.interactor.onClearAvailableOwnersFilter}
									queryForMembers={props.interactor.queryForAvailableOwners}
								/>
								<div className="available-owners">
									{props.interactor.availableOwners.map((o) => (
										<AvailableMember
											key={o.id}
											onAddMember={props.interactor.onRequestAddOwner}
											member={o}
										/>
									))}
								</div>
							</div>
							<div className="separator">
								<i className="icon-icon-2arrows"></i>
							</div>
							<div className="owners-list">
								<h4>Assigned Owners</h4>
								<MembersFilter
									onFilter={props.interactor.onFilterAssignedOwners}
									onClearFilter={props.interactor.onClearAssignedOwnersFilter}
									queryForMembers={props.interactor.queryForAssignedOwners}
								/>
								<div className="assigned-owners">
									{props.interactor.assignedOwners.map((o) => (
										<AssignedMember
											key={o.id}
											onRemoveMember={props.interactor.onRequestRemoveOwner}
											member={o}
										/>
									))}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</Layout>
	);
};

const AvailableMember = (props: {onAddMember: (userId: number) => void; member: PolicyMember}) => (
	<li className="add" onClick={() => props.onAddMember(props.member.userId)}>
		<div className={'name'}>
			{props.member.lastName}, {props.member.firstName}
		</div>
		<div className={'email'}>{props.member.mail}</div>
	</li>
);

const AssignedMember = (props: {onRemoveMember: (userId: number) => void; member: PolicyMember}) => (
	<li className="remove" onClick={() => props.onRemoveMember(props.member.userId)}>
		<div className={'name'}>
			{props.member.lastName}, {props.member.firstName}
		</div>
		<div className={'email'}>{props.member.mail}</div>
	</li>
);

interface MemberFilterProps {
	onFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onClearFilter: () => void;
	queryForMembers: string;
}

const MembersFilter = (props: MemberFilterProps) => (
	<div className="filter">
		<i className="icon-icon-filter"></i>
		<input type="text" placeholder={'Filter'} value={props.queryForMembers} onChange={props.onFilter} />
		<i className="icon-icon-close" onClick={props.onClearFilter}></i>
	</div>
);
