import {PropertiesRepository} from '../../core/repositories/propertiesRepository';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {UrlBuilder, BackendRoutes, ItkPropertiesRoutes, Environment} from '../Routes';
import {CallContext, ServiceRequest, ServiceResponse, PropertyDTO, SetPropertyDTO} from '../../core/ModelContracts';
import {map} from 'rxjs/operators';
import {Property} from '../../core/Models';
import {PropertiesMapper} from '../../core/mappers/propertiesMapper';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class PropertiesApiRepository implements PropertiesRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private authenticationService: AuthenticationService
	) {}

	getProperties = (properties: string[]) =>
		this.requestProperties(properties).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((pDTOs) => pDTOs.map(Property.createFrom))
		);

	requestProperties = (properties: string[]) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBasePropertiesItk);
		const propertiesUrl = urlBuilder.getBackendUrl(ItkPropertiesRoutes.getProperties);
		const callContext = this.authenticationService.callContext;

		const request = properties;
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<PropertyDTO[]>>(propertiesUrl, 'POST', serviceRequest);
	};

	setProperties = (properties: Property[]) => {
		const mappedProperties = PropertiesMapper.fromPropertiesToDto(properties);
		return this.requestSetProperties(mappedProperties).pipe(map((serviceResponse) => serviceResponse.Response));
	};

	requestSetProperties = (properties: SetPropertyDTO[]) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBasePropertiesItk);
		const setPropertiesUrl = urlBuilder.getBackendUrl(ItkPropertiesRoutes.setProperties);
		const callContext = this.authenticationService.callContext;

		const request = properties;
		const serviceRequest = ServiceRequest.create<SetPropertyDTO[]>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<[]>>(setPropertiesUrl, 'POST', serviceRequest);
	};
}
