import * as moment from 'moment';
import {Moment} from 'moment';
import {mergeMap, tap} from 'rxjs/operators';
import {ComplianceKind, PeriodKind} from '../../../core/ModelContracts';
import {PolicyDashboardComparison, UserComplianceResult} from '../../../core/Models';
import {DashboardComparisonsService} from '../../../core/services/DashboardComparisonsService';
import {PropertiesService} from '../../../core/services/propertiesService';
import {UsersComplianceResultsService} from '../../../core/services/UsersComplianceResultsService';
import {INotificationService} from '../../appServices/INotificationService';
import {PeriodsService} from '../../appServices/PeriodsService';
import {PolicyService} from '../../appServices/PolicyService';
import {ChartData, ChartThemeColors} from '../shared/chart/ChartComponent';
import {InteractorBase} from '../shared/InteractorBase';
import {ServiceFactory} from '../../factories/ServiceFactory';
import {Routes} from '../../Routes';
import {AuthenticationService} from '../../appServices/AuthenticationService';
import {INavigationService} from '../../appServices/INavigationService';
import {ExcelUsersComplianceService} from '../../appServices/ExportToExcelService';
import {orderBy} from 'lodash';
import {None, Nothing} from 'monet';
import {DatesFormatter} from '../../../core/datesFormatter';

type Column = {Header: string; accessor: string};

export class DashboardComparisonsInteractor extends InteractorBase {
	private readonly DATE_FORMAT = 'YYYY-MM-DD';
	private readonly MONTH_FORMAT = 'MMM YYYY';
	private readonly initialPeriodKind = 'M';
	private readonly _dashboardComparisonsKinds: PeriodKind[] = ['D', 'W', 'M'];
	private readonly ROCKSTARS = 0;
	private readonly COMPLIANT = 1;
	private readonly NOT_COMPLIANT = 2;
	private _currentPeriodKind: PeriodKind = this.initialPeriodKind;
	private _currentPeriodForPolicy: Moment;
	currentChartElement: '' | 'NOT_COMPLIANT' | 'COMPLIANT' | 'ROCKSTAR';
	private _userCompliances: UserComplianceResult[] = [];
	private _formattedDate: string;
	private _complianceType: 'AllUserResults' | 'Compliant' | 'Not Compliant' | 'Rockstars';

	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private dashboardComparisonsService: DashboardComparisonsService,
		private policyService: PolicyService,
		private periodsService: PeriodsService,
		private usersComplianceResultsService: UsersComplianceResultsService,
		private propertiesService: PropertiesService
	) {
		super(navigationService, notificationService);

		this.initialize();
	}

	initialize() {
		this.dashboardComparisonsService.dashboardComparisonsStream
			.pipe(tap(this.updateCurrentPeriodForPolicy), tap(this.notificationService.hideLoader))
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream
			.pipe(tap(this.resetCurrentPeriod), tap(this.notificationService.showLoader))
			.subscribe();

		this.propertiesService.changeThemeStream.subscribe(this.updateView);

		this._currentPeriodForPolicy = this.periodsService.dashboardCurrentPeriod;
	}

	private resetCurrentPeriod = () => (this._currentPeriodKind = this.initialPeriodKind);

	onPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentPeriodKind = event.target.value as PeriodKind;
		this.notificationService.showLoader();
		this.periodsService
			.getDashboardPeriodsForPolicy(this._currentPeriodKind)
			.pipe(
				tap(this.periodsService.initializeDashboardPeriod),
				tap(this.updateCurrentPeriodForPolicy),
				mergeMap(() => this.dashboardComparisonsService.requestDashboardComparisons(this._currentPeriodKind))
			)
			.subscribe();
	};

	updateCurrentPeriodForPolicy = () => (this._currentPeriodForPolicy = this.periodsService.dashboardCurrentPeriod);

	onPeriodForPolicyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentPeriodForPolicy = moment(event.target.value);
		this.periodsService.dashboardCurrentPeriod = moment(event.target.value);
		this.notificationService.showLoader();
		this.dashboardComparisonsService.requestDashboardComparisons(this._currentPeriodKind).subscribe();
	};

	prepareChartDataset = (): ChartData => {
		const labels = ['Rockstars', 'Compliant', 'Not Compliant'];

		const getThemeColors = () => {
			if (this.propertiesService.theme == 'ADERANT') {
				return ChartThemeColors.aderant;
			}
			if (this.propertiesService.theme == 'BELLEFIELD') {
				return ChartThemeColors.bellefield;
			}
			if (this.propertiesService.theme == 'AQUA') {
				return ChartThemeColors.aqua;
			}
			if (this.propertiesService.theme == 'DAWN') {
				return ChartThemeColors.dawn;
			}
		};

		const nothing = () => ({
			labels: labels,
			dataset: [],
		});

		const just = (dashboardComparison: PolicyDashboardComparison) => {
			const notCompliantUsers = dashboardComparison.resultComparisons[this.NOT_COMPLIANT].numberOfUsers;
			const compliantUsers = dashboardComparison.resultComparisons[this.COMPLIANT].numberOfUsers;
			const rockstarsUsers = dashboardComparison.resultComparisons[this.ROCKSTARS].numberOfUsers;

			const dataset = [
				{
					label: '',
					data: [rockstarsUsers, compliantUsers, notCompliantUsers],
					backgroundColor: getThemeColors(),
				},
			];

			return {
				labels: labels,
				dataset: dataset,
			};
		};

		return this.dashboardComparisons.cata(nothing, just);
	};

	onChartClick = (index: number) => {
		if (index === this.NOT_COMPLIANT)
			return this.usersComplianceResultsService
				.requestUserComplianceWithoutNotify(
					this.currentPeriodForPolicy,
					this.currentPeriodKind,
					ComplianceKind.NotCompliance
				)
				.pipe(
					tap(() => (this.currentChartElement = 'NOT_COMPLIANT')),
					tap((ucs) => (this._userCompliances = ucs)),
					tap(() => (this._complianceType = 'Not Compliant')),
					tap(() => (this._formattedDate = this.formatPeriod(this.currentPeriodForPolicy)))
				);

		if (index === this.COMPLIANT)
			return this.usersComplianceResultsService
				.requestUserComplianceWithoutNotify(
					this.currentPeriodForPolicy,
					this.currentPeriodKind,
					ComplianceKind.Compliance
				)
				.pipe(
					tap(() => (this.currentChartElement = 'COMPLIANT')),
					tap((ucs) => (this._userCompliances = ucs)),
					tap(() => (this._complianceType = 'Compliant')),
					tap(() => (this._formattedDate = this.formatPeriod(this.currentPeriodForPolicy)))
				);

		return this.usersComplianceResultsService
			.requestUserComplianceWithoutNotify(
				this.currentPeriodForPolicy,
				this.currentPeriodKind,
				ComplianceKind.RockStar
			)
			.pipe(
				tap(() => (this.currentChartElement = 'ROCKSTAR')),
				tap((ucs) => (this._userCompliances = ucs)),
				tap(() => (this._complianceType = 'Rockstars')),
				tap(() => (this._formattedDate = this.formatPeriod(this.currentPeriodForPolicy)))
			);
	};

	tooltipLabelCallback = (value): string => {
		const nothing = () => '';
		const just = (dashboardComparison: PolicyDashboardComparison) => {
			if (value.index === this.ROCKSTARS)
				return 'Rockstars: ' + dashboardComparison.resultComparisons[this.ROCKSTARS].numberOfUsers;
			if (value.index === this.COMPLIANT)
				return 'Compliant: ' + dashboardComparison.resultComparisons[this.COMPLIANT].numberOfUsers;

			return 'Not Compliant: ' + dashboardComparison.resultComparisons[this.NOT_COMPLIANT].numberOfUsers;
		};

		return this.dashboardComparisons.cata(nothing, just);
	};

	formatPeriod(date: string) {
		return DatesFormatter.formatDateByPeriodKind(date, this._currentPeriodKind);
	}

	get dashboardComparisons() {
		return this.dashboardComparisonsService.dashboardComparisons;
		//return Nothing<PolicyDashboardComparison>()
	}

	get dashboardComparisonsKinds() {
		return this._dashboardComparisonsKinds;
	}

	get currentPeriodKind() {
		return this._currentPeriodKind;
	}

	get periods() {
		return this.periodsService.dashboardPeriodsForPolicy.map((d) => d.format(this.DATE_FORMAT));
	}

	get currentPeriodForPolicy() {
		return this._currentPeriodForPolicy.format(this.DATE_FORMAT);
	}

	get columns(): Column[] {
		return [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: 'Velocity (days)',
				accessor: 'averageVelocity',
			},
			{
				Header: 'Granularity',
				accessor: 'averageGranularity',
			},
			{
				Header: 'Work Hours',
				accessor: 'totalWorkHours',
			},
			{
				Header: 'Timescore',
				accessor: 'timescore',
			},
			{
				Header: 'Delinquent Timecards (hours)',
				accessor: 'totalHoursCreatedAfterGracePeriod',
			},
			{
				Header: this.currentChartElement === 'NOT_COMPLIANT' ? 'Penalty' : 'Reward',
				accessor: this.currentChartElement === 'NOT_COMPLIANT' ? 'totalCashPenalty' : 'totalCashReward',
			},
		];
	}

	exportToExcel = () => {
		ExcelUsersComplianceService.generateFile(
			this._formattedDate.replace(/\s/g, ''),
			this._userCompliances,
			this._complianceType
		);
	};

	onExportAllUsersToExcel = () => {
		return this.usersComplianceResultsService
			.requestUserComplianceWithoutNotify(this.currentPeriodForPolicy, this.currentPeriodKind)
			.pipe(
				tap((ucs) => (this._userCompliances = orderBy(ucs, ['complianceStatus']))),
				tap(() => (this._formattedDate = this.formatPeriod(this.currentPeriodForPolicy))),
				tap(() => (this._complianceType = 'AllUserResults'))
			)
			.subscribe(this.exportToExcel);
	};

	get usersComplianceResults() {
		return this._userCompliances;
	}
}
