import {PeriodKind} from '../ModelContracts';
import {Observable, of} from 'rxjs';
import {ContemporaneousHistory} from '../Models';

export interface ContemporaneousRepository {
	getAllByPeriod: (periodKind: PeriodKind) => Observable<ContemporaneousHistory[]>;
}

export class ContemporaneousInMemoryRepository implements ContemporaneousRepository {
	constructor(private contemporaneousHistories: ContemporaneousHistory[]) {}

	getAllByPeriod = () => of(this.contemporaneousHistories);
}
