import {PeriodsRepository} from '../../core/repositories/PeriodsRepository';
import {InteractorFactory} from '../factories/InteractorFactory';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyService} from '../appServices/PolicyService';
import {
	PeriodKind,
	CallContext,
	PolicyPeriodsRequest,
	ServiceRequest,
	ServiceResponse,
} from '../../core/ModelContracts';
import {UrlBuilder, BackendRoutes, Environment} from '../Routes';
import {map, tap} from 'rxjs/operators';
import {Moment} from 'moment';
import * as moment from 'moment';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class PeriodsApiRepository implements PeriodsRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private authenticationService: AuthenticationService
	) {}

	getPolicyPeriods = (periodKind: PeriodKind) =>
		this.requestPeriods(periodKind).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((periods) => periods.map((period) => moment(period)))
		);

	requestPeriods = (periodKind: PeriodKind) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const periodsForPolicyUrl = urlBuilder.getBackendUrl(BackendRoutes.periodsforpolicy);
		const callContext = this.authenticationService.callContext;

		const request = PolicyPeriodsRequest.create(this.policyService.currentPolicyId, periodKind);
		const serviceRequest = ServiceRequest.create<PolicyPeriodsRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<string[]>>(periodsForPolicyUrl, 'POST', serviceRequest);
	};

	getPolicyPeriodsWithCurrentPeriod = (periodKind: PeriodKind) => {
		return this.requestPeriodsWithCurrentDate(periodKind).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((periods) => periods.map((period) => moment.utc(period)))
		);
	};

	requestPeriodsWithCurrentDate = (periodKind: PeriodKind) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const periodsForPolicyWithCurrentDateUrl = urlBuilder.getBackendUrl(
			BackendRoutes.periodsforpolicywithcurrentperiod
		);
		const callContext = this.authenticationService.callContext;

		const request = PolicyPeriodsRequest.create(this.policyService.currentPolicyId, periodKind);
		const serviceRequest = ServiceRequest.create<PolicyPeriodsRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<string[]>>(
			periodsForPolicyWithCurrentDateUrl,
			'POST',
			serviceRequest
		);
	};
}

export class PeriodsApiRepositoryForHR implements PeriodsRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private authenticationService: AuthenticationService
	) {}

	getPolicyPeriods = (periodKind: PeriodKind) =>
		this.requestPeriods(periodKind).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((periods) => periods.map((period) => moment(period)))
		);

	requestPeriods = (periodKind: PeriodKind) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const periodsForPolicyUrl = urlBuilder.getBackendUrl(BackendRoutes.periodsforpolicy);
		const callContext = this.authenticationService.callContext;

		const request = PolicyPeriodsRequest.create(this.policyService.currentPolicyId, periodKind);
		const serviceRequest = ServiceRequest.create<PolicyPeriodsRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<string[]>>(periodsForPolicyUrl, 'POST', serviceRequest);
	};

	getPolicyPeriodsWithCurrentPeriod = (periodKind: PeriodKind) =>
		this.requestPeriodsWithCurrentDate(periodKind).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((periods) => periods.map((period) => moment(period)))
		);

	requestPeriodsWithCurrentDate = (periodKind: PeriodKind) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const periodsForPolicyWithCurrentDateUrl = urlBuilder.getBackendUrl(
			BackendRoutes.periodsforpolicywithcurrentperiod
		);
		const callContext = this.authenticationService.callContext;

		const request = PolicyPeriodsRequest.create(this.policyService.currentPolicyId, periodKind);
		const serviceRequest = ServiceRequest.create<PolicyPeriodsRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<string[]>>(
			periodsForPolicyWithCurrentDateUrl,
			'POST',
			serviceRequest
		);
	};
}
