import {Component, FunctionComponent} from 'react';
import * as React from 'react';

import {InteractorBase} from './InteractorBase';
import {InteractorFactory} from '../../factories/InteractorFactory';

type WrapperProps = {
	interactor: InteractorBase;
	functionComponent: FunctionComponent<{interactor: InteractorBase}>;
};

type WrapperState = {
	interactorState: InteractorBase;
};

export class WrapperReactState extends Component<WrapperProps, WrapperState> {
	constructor(props: WrapperProps) {
		super(props);
		this.state = {interactorState: this.props.interactor};
	}

	subscribeSetStateToOnUpdate = () =>
		(this.props.interactor.onUpdate = (interactor: InteractorBase) => this.setState({interactorState: interactor}));

	unsubscribeSetStateFromOnUpdate = () => (this.props.interactor.onUpdate = () => {});

	render = () => this.props.functionComponent({interactor: this.props.interactor});

	componentDidMount = this.subscribeSetStateToOnUpdate;

	componentWillUnmount = this.unsubscribeSetStateFromOnUpdate;

	static create = (
		functionComponent: FunctionComponent<{interactor: InteractorBase}>,
		interactor: InteractorBase
	) => <WrapperReactState interactor={interactor} functionComponent={functionComponent} />;
}
