import {InteractorBase} from '../InteractorBase';
import {Message, NotificationKind, NotificationService} from '../../../appServices/INotificationService';
import {NavigationService} from '../../../appServices/NavigationService';
import {tap} from 'rxjs/operators';
import {last} from 'lodash';

export class NotificationInteractor extends InteractorBase {
	currentMessage: Message;

	constructor(navService: NavigationService, notificationService: NotificationService) {
		super(navService, notificationService);
		this.notificationService = notificationService;

		(this.notificationService as NotificationService).messagesBus
			.pipe(tap((m) => this.updateCurrentMessage(last(m))))
			.subscribe(this.updateView);
	}

	updateCurrentMessage = (message: Message) => {
		this.currentMessage = message;
		setTimeout(this.deleteCurrentMessageAndUpdateView, 5000);
	};

	deleteCurrentMessageAndUpdateView = () => {
		this.currentMessage = null;
		this.updateView();
	};

	getCurrentMessage() {
		return this.currentMessage;
	}

	deleteMessage = (m: Message) => {
		this.notificationService.delete(m);
		this.deleteCurrentMessageAndUpdateView();
	};
}

export class NotificationPanelInteractor extends InteractorBase {
	notificationService: NotificationService;
	private _shouldShowPanel = false;

	constructor(navService: NavigationService, notificationService: NotificationService) {
		super(navService, notificationService);
		this.notificationService = notificationService;

		this.notificationService.messagesBus.subscribe(this.updateView);
	}

	deleteAll() {
		this.notificationService.deleteAll();
		this.updateView();
	}

	deleteMessage = (m: Message) => {
		this.notificationService.delete(m);
		this.updateView();
	};

	onShowPanel = () => {
		this._shouldShowPanel = !this._shouldShowPanel;
		this.updateView();
	};

	get messages() {
		return this.notificationService.messages;
	}

	get shouldShowPanel() {
		return this._shouldShowPanel;
	}
}
