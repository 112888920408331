import {TimescoreService} from '../../../core/services/TimescoreService';
import {INotificationService} from '../../appServices/INotificationService';
import {PeriodsService} from '../../appServices/PeriodsService';
import {InteractorBase} from '../shared/InteractorBase';
import {TimescoreBreakdownInteractor} from './TimescoreBreakdownInteractor';
import {UserTimescoreForPeriod} from '../../../core/Models';
import {INavigationService} from '../../appServices/INavigationService';
import {ExcelTimescoreService} from '../../appServices/ExportToExcelService';
import {ServiceFactory} from '../../factories/ServiceFactory';

type Column = {Header: string; accessor: string};
// export type UserTimescoreForPeriodWithTitleAndPracticeArea = UserTimescoreForPeriod & {
// 	title: string;
// 	practiceArea: string;
// };

export class TimescoreBreakdownTableInteractor extends InteractorBase {
	private readonly DATE_FORMAT = 'YYYY-MM-DD';
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private timescoreService: TimescoreService,
		private periodsService: PeriodsService,
		private timescoreBreakdownInteractor: TimescoreBreakdownInteractor
	) {
		super(navigationService, notificationService);
		this.timescoreService.userTimescoresStream.subscribe(this.updateView);
	}

	onUsersTableClick = (user: string) => {
		return this.timescoreService.requestUserTimescoreHistory(user);
	};

	exportToExcel = () => {
		ExcelTimescoreService.generateFile(
			this.currentPeriodLabel().replace(/\s/g, ''),
			this.previousPeriodLabel().replace(/\s/g, ''),
			this.timescoreService.userTimescores

			//this.getUserTimescoresWithPracticeAreaAndTitle()
		);
	};

	// getUserTimescoresWithPracticeAreaAndTitle = (): UserTimescoreForPeriodWithTitleAndPracticeArea[] => {
	// 	const usersTimescoresForPeriodWithTitleAndPracticeArea: UserTimescoreForPeriodWithTitleAndPracticeArea[] = [];
	// 	const userTimescoresForPeriod = this.timescoreService.userTimescores;
	// 	const userComplianceResults = ServiceFactory.getUsersComplianceResultsService().usersComplianceResults;
	// 	const titleAndPracticeArea = userComplianceResults.map((cr) => {
	// 		return {
	// 			userId: cr.userId,
	// 			practiceArea: cr.practiceArea,
	// 			title: cr.title,
	// 		};
	// 	});
	// 	userTimescoresForPeriod.forEach((ut, i) => {
	// 		const complianceResultMatch = titleAndPracticeArea.find((tapa) => tapa.userId == ut.userId);
	// 		const userTimescoresForPeriodWithTitleAndPracticeArea = {...ut, ...complianceResultMatch};
	// 		usersTimescoresForPeriodWithTitleAndPracticeArea.push(userTimescoresForPeriodWithTitleAndPracticeArea);
	// 	});
	// 	return usersTimescoresForPeriodWithTitleAndPracticeArea;
	// };

	get userTimescores() {
		return this.timescoreService.userTimescores;
	}
	get columns(): Column[] {
		return [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: this.previousPeriodLabel(),
				accessor: 'previousTimescore',
			},
			{
				Header: this.currentPeriodLabel(),
				accessor: 'currentTimescore',
			},
		];
	}

	private previousPeriodLabel = () => this.timescoreBreakdownInteractor.getPreviousPeriodLabel();

	currentPeriodLabel = () =>
		this.timescoreBreakdownInteractor.formatPeriod(
			this.periodsService.timescoreBreakdownCurrentPeriod.format(this.DATE_FORMAT)
		);
}
