import {from, throwError} from 'rxjs';
import {mergeMap, map, tap} from 'rxjs/operators';

import {ServiceResponse} from '../../core/ModelContracts';

export class RequestWrapper {
	prepareRequest = (url: string, method: string, data?: any) => {
		if (method.toLocaleLowerCase() === 'get') return new Request(url, {method: method, headers: this.headers()});
		return new Request(url, {method: method, body: JSON.stringify(data), headers: this.headers()});
	};

	makeRequest<T>(url: string, method: string, data?: any) {
		const requestStream = from(fetch(this.prepareRequest(url, method, data)));

		const resolveRequest = (res: any) =>
			res.ok
				? from(res.clone().json())
				: throwError(new Error(`Server Response ${res.status} ${res.statusText} URL ${url} Method: ${method}`));

		return requestStream.pipe(
			mergeMap(resolveRequest),
			map((value) => value as T)
		);
	}

	headers = () => {
		return {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		};
	};

	static create() {
		return new RequestWrapper();
	}
}
