import * as React from 'react';
import {ReactNode} from 'react';
import {Transcript} from '../../../core/Transcript';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {ChartComponent} from '../shared/chart/ChartComponent';
import {Layout} from '../shared/layout/Layout';
import {PolicySelectorComponentWrapped} from '../shared/policySelector/PolicySelectorComponent';
import {WrapperReactState} from '../shared/WrapperReactState';
import {ComplianceHistoryInteractor} from './ComplianceHistoryInteractor';
import ReactTable from 'react-table-v6';
import {ReactTableHelper} from '../../helpers/ReactTableHelper';
import {PeriodKind} from '../../../core/ModelContracts';

export const ComplianceHistoryComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getComplianceHistoryInteractor()}
		functionComponent={ComplianceHistoryComponent}
	/>
);

const ComplianceHistoryComponent = (props: {interactor: ComplianceHistoryInteractor}) => {
	const onChart = (index: number, datasetIndex: number) => {
		const label = props.interactor.dateLabels()[index];

		props.interactor
			.onChartClick(index, datasetIndex)
			.subscribe(() =>
				props.interactor.pushModal(
					<UserComplianceComponent interactor={props.interactor} label={label} datasetIndex={datasetIndex} />
				)
			);
	};

	return (
		<Layout title={'Compliance History'} navbarRight={<NavbarRight interactor={props.interactor} />}>
			<GraphContainer interactor={props.interactor}>
				<ChartComponent
					data={props.interactor.prepareChartDataset()}
					kind="bar"
					hasAnimated={true}
					legendPosition="bottom"
					showYAxeLabel={true}
					yAxeLabel="# Of Users"
					onClick={props.interactor.currentPeriodKind == 'D' ? () => {} : onChart}
				/>
			</GraphContainer>
		</Layout>
	);
};

const UserComplianceComponent = (props: {
	interactor: ComplianceHistoryInteractor;
	label: string;
	datasetIndex: number;
}) => {
	const headerTextByDatasetIndex = (datasetIndex: number) => {
		if (datasetIndex == 0) return 'Rockstars';
		if (datasetIndex == 1) return 'Compliant';
		return 'Not Compliant';
	};
	return (
		<div>
			<div className="table-header">
				<h4>{`${headerTextByDatasetIndex(props.datasetIndex)} (${props.label})`}</h4>
				<span className="export-to-excel" onClick={props.interactor.exportToExcel}>
					Export to <i className="icon-icon-exportxls"></i>
				</span>
			</div>
			<div className="header-separator"></div>
			<ReactTable
				data={props.interactor.usersComplianceResults}
				columns={props.interactor.columns}
				filterable={true}
				showPageJump={false}
				defaultFilterMethod={ReactTableHelper.filterCaseInsensitive}
			/>
		</div>
	);
};

const NavbarRight = (props: {interactor: ComplianceHistoryInteractor}) => {
	const fromKindToOptions = () =>
		props.interactor.complianceHistoryKinds.map((kind) => (
			<option key={kind} value={kind}>
				{Transcript.transcriptKind(kind)}
			</option>
		));

	return (
		<div className="navbar-right">
			<div className="select-wrapper">
				<PolicySelectorComponentWrapped />
			</div>
			<div className="select-wrapper">
				<select value={props.interactor.currentPeriodKind} onChange={props.interactor.onPeriodChange}>
					{fromKindToOptions()}
				</select>
			</div>
		</div>
	);
};

const GraphContainer = (props: {children: ReactNode; interactor: ComplianceHistoryInteractor}) => {
	const isExportToExcelActive = () => props.interactor.complianceHistories.length > 0;
	const currentPeriodKind = props.interactor.currentPeriodKind;
	const chartLabels = props.interactor.dateLabels();
	const hasData = chartLabels.length != 0;

	const getNoteBy = (period: PeriodKind) => {
		switch (period) {
			case 'M':
				return `This bar graph represents the trend in user compliance over a ${chartLabels.length} month period. Click on the bar chart to
				see each individual user in that category and their compliance detail.`;
			case 'W':
				return `This bar graph represents the trend in user compliance over a ${chartLabels.length} week period. Click on the bar chart to
				see each individual user in that category and their compliance detail.`;
			case 'D':
				return `This bar graph represents the trend in user compliance over a ${chartLabels.length} day (s) period. Click on the bar chart to
				see each individual user in that category and their compliance detail.`;
		}
	};

	return (
		<div className="graphs-canvas">
			<div className="graph-title-compliance">
				<h4>Compliance history</h4>
				<span
					className={isExportToExcelActive() ? '' : 'inactive'}
					onClick={isExportToExcelActive() ? props.interactor.onExportAllUsersAcrossMonthsToExcel : null}
				>
					Export to<i className="icon-icon-exportxls"></i>
				</span>
			</div>
			<div className="note">{hasData ? getNoteBy(currentPeriodKind) : 'There is no data.'}</div>
			<div className="graphs-wrapper container chart-container">{props.children}</div>
		</div>
	);
};
