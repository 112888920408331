import * as React from 'react';
import ReactTable from 'react-table-v6';
import {WrapperReactState} from '../shared/WrapperReactState';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {TimescoreBreakdownTableInteractor} from './TimescoreBreakdownTableInteractor';
import {UserTimescoreForPeriod} from '../../../core/Models';
import {TimescoreBreakdownUserHistoryWrapped} from './TimescoreBreakdownUserHistoryComponent';
import {ReactTableHelper} from '../../helpers/ReactTableHelper';

export const TimescoreBreakdownTableWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getTimescoreBreakdownTableInteractor()}
		functionComponent={TimescoreBreakdownTable}
	/>
);

const TimescoreBreakdownTable = (props: {interactor: TimescoreBreakdownTableInteractor}) => {
	const onShowTable = () => {
		props.interactor.pushModal(<TimescoreTable interactor={props.interactor} />);
	};

	return (
		<div>
			<button className="btn btn-outline-primary" onClick={onShowTable}>
				Show Users
			</button>
		</div>
	);
};

const TimescoreTable = (props: {interactor: TimescoreBreakdownTableInteractor}) => {
	const onUserClick = (user: UserTimescoreForPeriod) => {
		props.interactor
			.onUsersTableClick(user.userId.toString())
			.subscribe(() => props.interactor.pushModal(<TimescoreBreakdownUserHistoryWrapped />));
	};

	const rowProperties = (table, rowInfo) => ({
		onClick: () => onUserClick(rowInfo.original as UserTimescoreForPeriod),
	});

	return (
		<div>
			<div className="table-header">
				<h4>{`Timescore User Results (${props.interactor.currentPeriodLabel()})`}</h4>
				<span className="export-to-excel" onClick={props.interactor.exportToExcel}>
					Export to <i className="icon-icon-exportxls"></i>
				</span>
			</div>
			<ReactTable
				data={props.interactor.userTimescores}
				columns={props.interactor.columns}
				filterable={true}
				getTrProps={rowProperties}
				defaultFilterMethod={ReactTableHelper.filterCaseInsensitive}
			/>
		</div>
	);
};
