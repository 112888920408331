import {Observable, of, zip} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {ServiceFactory} from './ServiceFactory';
import {LoginHelper} from '../helpers/LoginHelper';
import {ActivityService} from '../appServices/ActivityService';
import {Routes} from '../Routes';
import {RepositoryFactory} from './RepositoryFactory';

export class ThriveInitializer {
	static init() {
		LoginHelper.removeTokenAddSubscriptionAndRedirectIfNeeded();
		LoginHelper.navigateToDefaultIfNeeded();
		ThriveInitializer.initRenewToken();

		if (ServiceFactory.getAuthenticationService().credentials.hasNotThriveAccess()) {
			return ThriveInitializer.requestEmpty();
		}

		if (ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesOnlyAccess()) {
			return ServiceFactory.getPolicyServiceHr()
				.initialRequestHumanResources()
				.pipe(mergeMap((policyId) => this.checkNoPoliciesEnabledHr(policyId)));
		}

		if (ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesAndPolicyOwnerAccess()) {
			return ServiceFactory.getPolicyServiceHr()
				.initialRequestHumanResources()
				.pipe(
					mergeMap(ServiceFactory.getPolicyService().initialRequestPolicyOwner),
					mergeMap((policyId) => ThriveInitializer.checkNoPoliciesAssignedToOwner(policyId))
				);
		}

		if (ServiceFactory.getAuthenticationService().credentials.hasPolicyOwnerOnlyRole()) {
			return ServiceFactory.getPolicyService()
				.initialRequestPolicyOwner()
				.pipe(mergeMap((policyId) => this.checkNoPoliciesAssignedToOwner(policyId)));
		}

		return ServiceFactory.getPolicyService().initialRequest().pipe(mergeMap(ThriveInitializer.initialRequests));
	}

	static checkNoPoliciesAssignedToOwner = (policyId: number) => {
		if (policyId != null) {
			return ThriveInitializer.initialRequests(policyId);
		}
		window.history.pushState({}, '', `#${Routes.noPolicyOwnerRoutes}`);
		return ThriveInitializer.requestEmpty();
	};

	static checkNoPoliciesEnabledHr = (policyId: number) => {
		if (policyId != null) {
			return ThriveInitializer.initialRequests(policyId);
		}
		window.history.pushState({}, '', `#${Routes.noEnabledPolicies}`);
		return ThriveInitializer.requestEmpty();
	};

	//Used in init and PolicySelectorInteractor
	static initialRequests = (policyId: number): Observable<void> => {
		const requestForAdminOrPolicyOwnerOrPolicyOwnerHr = (): (() => Observable<void>) => {
			if (ServiceFactory.getAuthenticationService().credentials.hasAdminAccess())
				return ThriveInitializer.requestsForAdmin;

			if (ServiceFactory.getAuthenticationService().credentials.hasPolicyOwnerOnlyAccess())
				return ThriveInitializer.requestsForPolicyOwnerOnly;

			if (ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesAndPolicyOwnerAccess())
				return ThriveInitializer.requestsForPolicyOwnerHr;

			return ThriveInitializer.requestEmpty;
		};

		if (ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesOnlyAccess())
			return ThriveInitializer.initialRequestsForHr();

		return ServiceFactory.getPolicyEditorService()
			.initialRequest(policyId)
			.pipe(
				mergeMap(() => ServiceFactory.getPropertiesService().initialRequest()),
				mergeMap(() => ServiceFactory.getPeriodsServiceHr().initialRequest()),
				mergeMap(() =>
					ServiceFactory.getPeriodsService()
						.initialRequest()
						.pipe(mergeMap(requestForAdminOrPolicyOwnerOrPolicyOwnerHr()))
				)
			);
	};

	private static requestsForAdmin = () =>
		zip(
			ServiceFactory.getVelocityHistoryService().initialRequest(),
			ServiceFactory.getContemporaneousHistoryService().initialRequest(),
			ServiceFactory.getComplianceHistoryService().initialRequest(),
			ServiceFactory.getDashboarComparisonsService().initialRequest(),
			ServiceFactory.getUsersComplianceResultsService().initialRequest(),
			ServiceFactory.getTimescoreService().timescoresInitialRequest(),
			ServiceFactory.getTimescoreService().userTimescoresinitialRequest(),
			ServiceFactory.getUsersGoalService().initialRequest(),
			ServiceFactory.getPolicyEditorUsersService().initialRequestForAdmin(),
			() => {}
		);

	private static requestsForPolicyOwnerOnly = () =>
		zip(
			ServiceFactory.getVelocityHistoryService().initialRequest(),
			ServiceFactory.getContemporaneousHistoryService().initialRequest(),
			ServiceFactory.getComplianceHistoryService().initialRequest(),
			ServiceFactory.getDashboarComparisonsService().initialRequest(),
			ServiceFactory.getUsersComplianceResultsService().initialRequest(),
			ServiceFactory.getTimescoreService().timescoresInitialRequest(),
			ServiceFactory.getTimescoreService().userTimescoresinitialRequest(),
			ServiceFactory.getUsersGoalService().initialRequest(),
			ServiceFactory.getPolicyEditorUsersService().initialRequestForPolicyOwnerAndPolicyOwnerHr(),
			() => {}
		);

	private static requestsForPolicyOwnerHr = () =>
		zip(
			ServiceFactory.getVelocityHistoryService().initialRequest(),
			ServiceFactory.getContemporaneousHistoryService().initialRequest(),
			ServiceFactory.getComplianceHistoryService().initialRequest(),
			ServiceFactory.getDashboarComparisonsService().initialRequest(),
			ServiceFactory.getUsersComplianceResultsServiceForHr().initialRequest(),
			ServiceFactory.getTimescoreService().timescoresInitialRequest(),
			ServiceFactory.getTimescoreService().userTimescoresinitialRequest(),
			ServiceFactory.getUsersGoalService().initialRequest(),
			ServiceFactory.getPolicyEditorUsersService().initialRequestForPolicyOwnerAndPolicyOwnerHr(),
			() => {}
		);

	static initialRequestsForHr = () =>
		ServiceFactory.getPeriodsServiceHr()
			.initialRequest()
			.pipe(mergeMap(ThriveInitializer.requestsForHumanResourceAccess));

	private static requestsForHumanResourceAccess = () =>
		ServiceFactory.getUsersComplianceResultsServiceForHr()
			.initialRequest()
			.pipe(
				mergeMap(() => ServiceFactory.getPropertiesService().initialRequest()),
				map(() => {})
			);

	private static requestEmpty = () => of([]).pipe(map(() => {}));

	private static initRenewToken = () => {
		const fiveMinutesInSeconds = 300;
		ActivityService.executeWhenAppIsActive(
			ServiceFactory.getAuthenticationService().requestRenewToken,
			fiveMinutesInSeconds
		);
	};
}
