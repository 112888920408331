export const applyPolyfills = () => {
	require('es6-promise').polyfill();
	require('symbol-es6');
	require('array.prototype.find').shim();
	require('array.prototype.findindex').shim();
	require('object.values').shim();
	require('array-includes').shim();
	require('es6-object-assign').polyfill();
	require('isomorphic-fetch');

	if (!String.prototype.includes) {
		String.prototype.includes = function (searchString: string, position?: number) {
			return this.valueOf().toLowerCase().indexOf(searchString.toLowerCase()) > -1;
		};
	}

	if (!Array.prototype.fill) {
		Array.prototype.fill = function (value) {
			let O = Object(this);
			let len = parseInt(O.length, 10);
			let start = arguments[1];
			let relativeStart = parseInt(start, 10) || 0;
			let k = relativeStart < 0 ? Math.max(len + relativeStart, 0) : Math.min(relativeStart, len);
			let end = arguments[2];
			let relativeEnd = end === undefined ? len : parseInt(end) || 0;
			let final = relativeEnd < 0 ? Math.max(len + relativeEnd, 0) : Math.min(relativeEnd, len);

			for (; k < final; k++) {
				O[k] = value;
			}

			return O;
		};
	}

	if (!String.prototype.startsWith) {
		Object.defineProperty(String.prototype, 'startsWith', {
			value: function (search, pos) {
				return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
			},
		});
	}

	if (!String.prototype.endsWith) {
		String.prototype.endsWith = function (search, this_len) {
			if (this_len === undefined || this_len > this.length) {
				this_len = this.length;
			}
			return this.substring(this_len - search.length, this_len) === search;
		};
	}

	Number.isNaN =
		Number.isNaN ||
		function (value) {
			return typeof value === 'number' && isNaN(value);
		};

	Math.trunc =
		Math.trunc ||
		function (x) {
			return x < 0 ? Math.ceil(x) : Math.floor(x);
		};
};
