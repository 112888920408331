import {Observable, of, Subject} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {PolicyRepository} from '../../core/repositories/PolicyServiceRepository';
import {Policy, PolicyReader} from '../../core/models/Policy';
import {first} from 'lodash';
import {PolicyEditorApiRepository} from '../repositories/PolicyEditorApiRepository';
import {PolicyEditorRepository} from '../../core/repositories/PolicyEditorRepository';
import {INavigationService} from './INavigationService';
import {Routes} from '../Routes';

export class PolicyService {
	private _currentPolicyId: number;
	private _policiesForSubscription: PolicyReader[] = [];
	private _policiesForSubscriptionStream: Subject<PolicyReader[]> = new Subject();
	private _currentPolicyChangedStream: Subject<number> = new Subject();

	constructor(
		private policyRepo: PolicyRepository,
		private policyEditorRepo: PolicyEditorRepository,
		private navigationService: INavigationService
	) {}

	initialRequest = () =>
		this.requestPoliciesForSubscription().pipe(
			mergeMap(this.requestDefaultPolicyIfNoPolicies),
			tap(this.setInitialPolicy),
			map(() => this._currentPolicyId)
		);

	initialRequestPolicyOwner = () =>
		this.requestPoliciesForSubscription().pipe(
			map((policies) => {
				this.setInitialPolicy(policies);
				return this._currentPolicyId;
			})
		);

	initialRequestHumanResources = () =>
		this.requestPoliciesForSubscription().pipe(
			map((policies) => {
				this.setInitialPolicy(policies);
				return this._currentPolicyId;
			})
		);

	private setInitialPolicy = (policies: PolicyReader[]) => (this._currentPolicyId = first(policies)?.policyId);

	private requestDefaultPolicyIfNoPolicies = (policies: PolicyReader[]): Observable<PolicyReader[]> => {
		const hasPolicies = policies.length > 0;
		const newPolicy = Policy.createDefault('First policy');

		return hasPolicies
			? of(policies)
			: this.policyEditorRepo.addPolicy(newPolicy).pipe(
					map((policy) => [PolicyReader.createFromPolicy(policy)]),
					tap(this.notifyPoliciesForSubscriptionUpdated),
					tap(this.showWelcomeMessageAndNavigate)
			  );
	};

	private showWelcomeMessageAndNavigate = () => {
		const navigateToEditor = () => this.navigationService.navigate(Routes.policyEditorVelocity);
		this.navigationService.modalService.openOkDialog('Welcome to Thrive. Edit your first policy', navigateToEditor);
	};

	updateCurrentPolicy(policyId: number) {
		this._currentPolicyId = policyId;
		this._currentPolicyChangedStream.next(policyId);
	}

	updateCurrentPolicyWithoutNotify(policyId: number) {
		this._currentPolicyId = policyId;
	}

	requestPoliciesForSubscription = () => {
		//return of([]).pipe(tap(this.notifyPoliciesForSubscriptionUpdated));
		return this.policyRepo.getPolicies().pipe(tap(this.notifyPoliciesForSubscriptionUpdated));
	};

	private notifyPoliciesForSubscriptionUpdated = (policiesForSubscription: PolicyReader[]) => {
		this._policiesForSubscription = policiesForSubscription;
		this._policiesForSubscriptionStream.next(policiesForSubscription);
	};

	get currentPolicyId() {
		return this._currentPolicyId;
	}

	get policiesForSubscription() {
		return this._policiesForSubscription;
	}

	get policiesForSubscriptionStream() {
		return this._policiesForSubscriptionStream;
	}

	get currentPolicyChangedStream() {
		return this._currentPolicyChangedStream;
	}
}
