import {Observable, of} from 'rxjs';
import {Property} from '../Models';

export interface PropertiesRepository {
	getProperties: (properties: string[]) => Observable<Property[]>;

	setProperties: (properties: Property[]) => Observable<[]>;
}

export class PropertiesInMemoryRepository implements PropertiesRepository {
	constructor(private responseGetProperties: Property[], private responseSetProperties: []) {}

	getProperties = () => of(this.responseGetProperties);

	setProperties = () => of(this.responseSetProperties);
}
