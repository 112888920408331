import {Subject, Observable, of} from 'rxjs';
import {ComplianceKind, PeriodKind} from '../ModelContracts';
import {getAllBy, getAllByWithPeriod, UserComplianceResult} from '../Models';
import {tap} from 'rxjs/operators';
import {UserscomplianceRepository} from '../repositories/UsersComplianceRepository';

export class UsersComplianceResultsService {
	private _usersComplianceResults: UserComplianceResult[] = [];
	private _usersComplianceResultsStream: Subject<UserComplianceResult[]> = new Subject();

	constructor(private repo: UserscomplianceRepository) {}

	initialRequest = () => this.requestUsersComplianceresults('M');

	requestUsersComplianceresults = (periodKind: PeriodKind, complianceKind?: ComplianceKind) =>
		this.repo.getAllBy(periodKind, complianceKind).pipe(
			tap((results) => (this._usersComplianceResults = results)),
			tap(this.notifyUserscomplianceResultsUpdated)
		);

	requestUserComplianceWithoutNotify = (
		periodPolicy: string,
		periodKind: PeriodKind,
		complianceKind?: ComplianceKind
	) => this.repo.getAllByWithPeriod(periodPolicy, periodKind, complianceKind);

	private notifyUserscomplianceResultsUpdated = (usersComplianceResults: UserComplianceResult[]) => {
		this._usersComplianceResults = usersComplianceResults;
		this._usersComplianceResultsStream.next(usersComplianceResults);
	};

	get usersComplianceResults() {
		return this._usersComplianceResults;
	}

	get usersComplianceResultsStream() {
		return this._usersComplianceResultsStream;
	}
}
