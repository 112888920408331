import {first, some} from 'lodash';
import * as moment from 'moment';
import {Moment} from 'moment';
import {mergeMap, tap} from 'rxjs/operators';
import {PeriodKind} from '../../core/ModelContracts';
import {PeriodsRepository} from '../../core/repositories/PeriodsRepository';

export class PeriodsService {
	private _dashboardPeriodsForPolicy: Moment[] = [];
	private _timescoreBreakdownPeriodsForPolicy: Moment[] = [];
	private _penaltiesAndRewardsPeriodsForPolicy: Moment[] = [];
	private _workHoursOverviewPeriodsForPolicy: Moment[] = [];
	private _dashboardCurrentPeriod: Moment = moment();
	private _timescoreBreakdownCurrentPeriod: Moment = moment();
	private _penaltiesAndRewardsCurrentPeriod: Moment = moment();
	private _workHoursOverviewCurrentPeriod: Moment = moment();

	constructor(private repo: PeriodsRepository) {}

	initialRequest = () =>
		this.repo.getPolicyPeriods('M').pipe(
			tap(this.initializeDashboardPeriod),
			tap(this.setDashboardPeriodsForPolicy),
			tap(this.initializeTimescorePeriod),
			tap(this.setTimescoreBreakdownPeriodsForPolicy),
			tap(this.initializePenaltiesAndRewardsPeriod),
			tap(this.setPenaltiesAndRewardsPeriodsForPolicy),
			mergeMap(() => this.repo.getPolicyPeriodsWithCurrentPeriod('M')),
			tap(this.setWorkHoursOverviewPeriodsForPolicy),
			tap(this.initializeWorkHoursOverviewPeriod)
		);

	initializeDashboardPeriod = (periods: Moment[]) =>
		(this._dashboardCurrentPeriod = some(periods) ? first(periods) : moment());

	initializeTimescorePeriod = (periods: Moment[]) =>
		(this._timescoreBreakdownCurrentPeriod = some(periods) ? first(periods) : moment());

	initializePenaltiesAndRewardsPeriod = (periods: Moment[]) =>
		(this._penaltiesAndRewardsCurrentPeriod = some(periods) ? first(periods) : moment());

	initializeWorkHoursOverviewPeriod = (periods: Moment[]) =>
		(this._workHoursOverviewCurrentPeriod = some(periods) ? first(periods) : moment());

	getDashboardPeriodsForPolicy = (periodType: PeriodKind) =>
		this.repo.getPolicyPeriods(periodType).pipe(tap(this.setDashboardPeriodsForPolicy));

	getTimescoreBreakdownPeriodsForPolicy = (periodType: PeriodKind) =>
		this.repo.getPolicyPeriods(periodType).pipe(tap(this.setTimescoreBreakdownPeriodsForPolicy));

	getPenaltiesAndRewardsPeriodsForPolicy = (periodType: PeriodKind) =>
		this.repo.getPolicyPeriods(periodType).pipe(tap(this.setPenaltiesAndRewardsPeriodsForPolicy));

	getUserGoalsPeriodsForPolicy = (periodType: PeriodKind) =>
		this.repo.getPolicyPeriodsWithCurrentPeriod(periodType).pipe(tap(this.setWorkHoursOverviewPeriodsForPolicy));

	private setDashboardPeriodsForPolicy = (periodsForPolicy: Moment[]) => {
		this._dashboardPeriodsForPolicy = periodsForPolicy;
	};

	private setTimescoreBreakdownPeriodsForPolicy = (periodsForPolicy: Moment[]) => {
		this._timescoreBreakdownPeriodsForPolicy = periodsForPolicy;
	};

	private setPenaltiesAndRewardsPeriodsForPolicy = (periodsForPolicy: Moment[]) => {
		this._penaltiesAndRewardsPeriodsForPolicy = periodsForPolicy;
	};

	private setWorkHoursOverviewPeriodsForPolicy = (periodsForPolicy: Moment[]) => {
		this._workHoursOverviewPeriodsForPolicy = periodsForPolicy;
	};

	get dashboardPeriodsForPolicy() {
		return this._dashboardPeriodsForPolicy;
	}

	get dashboardCurrentPeriod() {
		return this._dashboardCurrentPeriod;
	}

	set dashboardCurrentPeriod(value) {
		this._dashboardCurrentPeriod = value;
	}

	get timescoreCurrentPeriod() {
		return this._timescoreBreakdownCurrentPeriod;
	}

	get timescoreBreakdownPeriodsForPolicy() {
		return this._timescoreBreakdownPeriodsForPolicy;
	}

	get timescoreBreakdownCurrentPeriod() {
		return this._timescoreBreakdownCurrentPeriod;
	}

	set timescoreBreakdownCurrentPeriod(value) {
		this._timescoreBreakdownCurrentPeriod = value;
	}

	get penaltiesAndRewardsCurrentPeriod() {
		return this._penaltiesAndRewardsCurrentPeriod;
	}

	set penaltiesAndRewardsCurrentPeriod(value) {
		this._penaltiesAndRewardsCurrentPeriod = value;
	}

	get penaltiesAndRewardsPeriodsForPolicy() {
		return this._penaltiesAndRewardsPeriodsForPolicy;
	}

	get workHoursOverviewCurrentPeriod() {
		return this._workHoursOverviewCurrentPeriod;
	}

	set workHoursOverviewCurrentPeriod(value) {
		this._workHoursOverviewCurrentPeriod = value;
	}

	get workHoursOverviewPeriodsForPolicy() {
		return this._workHoursOverviewPeriodsForPolicy;
	}
}
