import * as moment from 'moment';
import {Moment} from 'moment';
import {ChangeEvent} from 'react';
import {mergeMap, tap} from 'rxjs/operators';
import {PeriodKind} from '../../../core/ModelContracts';
import {UserTimescoreForPeriod} from '../../../core/Models';
import {PropertiesService} from '../../../core/services/propertiesService';
import {TimescoreService} from '../../../core/services/TimescoreService';
import {INotificationService} from '../../appServices/INotificationService';
import {PeriodsService} from '../../appServices/PeriodsService';
import {PolicyService} from '../../appServices/PolicyService';
import {ChartData, ChartThemeColors} from '../shared/chart/ChartComponent';
import {InteractorBase} from '../shared/InteractorBase';
import {INavigationService} from '../../appServices/INavigationService';
import {DatesFormatter} from '../../../core/datesFormatter';

type Column = {Header: string; accessor: string};

export class TimescoreBreakdownInteractor extends InteractorBase {
	private readonly DATE_FORMAT = 'YYYY-MM-DD';
	private readonly MONTH_FORMAT = 'MMM YYYY';
	private readonly initialPeriodKind = 'M';
	private readonly _timescoreBreakdownKinds: PeriodKind[] = ['D', 'W', 'M'];
	private _currentPeriodKind: PeriodKind = this.initialPeriodKind;
	private _currentPeriodForPolicy: Moment;
	private _mustShowUserChart: boolean = false;
	private _userTimescores: UserTimescoreForPeriod[] = [];

	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private timescoreService: TimescoreService,
		private policyService: PolicyService,
		private periodsService: PeriodsService,
		private propertiesService: PropertiesService
	) {
		super(navigationService, notificationService);

		this.timescoreService.timescoresStream
			.pipe(tap(this.updateCurrentPeriodForPolicy), tap(this.notificationService.hideLoader))
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream
			.pipe(tap(this.resetCurrentPeriod), tap(this.notificationService.showLoader))
			.subscribe();

		this._currentPeriodForPolicy = this.periodsService.timescoreCurrentPeriod;

		this.propertiesService.changeThemeStream.subscribe(this.updateView);
	}

	private resetCurrentPeriod = () => (this._currentPeriodKind = this.initialPeriodKind);

	onPeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
		this._currentPeriodKind = event.target.value as PeriodKind;
		this.notificationService.showLoader();
		this.periodsService
			.getTimescoreBreakdownPeriodsForPolicy(this._currentPeriodKind)
			.pipe(
				tap(this.periodsService.initializeTimescorePeriod),
				tap(this.updateCurrentPeriodForPolicy),
				mergeMap(() => this.timescoreService.requestTimescoreSummary(this._currentPeriodKind)),
				mergeMap(() => this.timescoreService.requestUsersTimescore(this._currentPeriodKind))
			)
			.subscribe();
	};

	onPeriodForPolicyChange = (event: ChangeEvent<HTMLSelectElement>) => {
		this.notificationService.showLoader();
		this._currentPeriodForPolicy = moment(event.target.value);
		this.periodsService.timescoreBreakdownCurrentPeriod = moment(event.target.value);
		this.timescoreService.requestTimescoreSummary(this._currentPeriodKind).subscribe();
		this.timescoreService.requestUsersTimescore(this._currentPeriodKind).subscribe();
	};

	onChartClick = (index: number, datasetIndex: number) => {
		if (datasetIndex == 0)
			return (this._userTimescores = this.timescoreService.userTimescores.filter(
				(timescore) => timescore.previousTimescore === this.timescoreLabels()[index]
			));

		this._userTimescores = this.timescoreService.userTimescores.filter(
			(timescore) => timescore.currentTimescore === this.timescoreLabels()[index]
		);
	};

	onUsersTableClick = (user: string) => {
		return this.timescoreService.requestUserTimescoreHistory(user);
	};

	updateCurrentPeriodForPolicy = () =>
		(this._currentPeriodForPolicy = this.periodsService.timescoreBreakdownCurrentPeriod);

	prepareChartDataset = (): ChartData => {
		const numberOfUsersCurrentPeriod = this.timescores.map((t) => t.numberOfUsers);
		const numberOfUsersPreviosPeriod = this.timescores.map((t) => t.numberOfUsersInPreviousPeriod);

		const getThemeColors = () => {
			if (this.propertiesService.theme == 'ADERANT') {
				return ChartThemeColors.aderant;
			}
			if (this.propertiesService.theme == 'BELLEFIELD') {
				return ChartThemeColors.bellefield;
			}
			if (this.propertiesService.theme == 'AQUA') {
				return ChartThemeColors.aqua;
			}
			if (this.propertiesService.theme == 'DAWN') {
				return ChartThemeColors.dawn;
			}
		};

		const dataset = [
			{
				label: this.getPreviousPeriodLabel(),
				data: numberOfUsersPreviosPeriod,
				backgroundColor: getThemeColors()[1],
			},
			{
				label: this.formatPeriod(this.periodsService.timescoreBreakdownCurrentPeriod.format(this.DATE_FORMAT)),
				data: numberOfUsersCurrentPeriod,
				backgroundColor: getThemeColors()[2],
			},
		];

		return {
			labels: this.timescoreLabels(),
			dataset: dataset,
		};
	};

	timescoreLabels = () => this.timescores.map((twu) => twu.timescore);

	getPreviousPeriodLabel = () => {
		const previousMonthLabel = this.periodsService.timescoreBreakdownCurrentPeriod
			.clone()
			.subtract(1, 'month')
			.format(this.MONTH_FORMAT);
		const previousWeekLabel = this.periodsService.timescoreBreakdownCurrentPeriod
			.clone()
			.subtract(1, 'week')
			.format(this.DATE_FORMAT);
		const previousDayLabel = this.periodsService.timescoreBreakdownCurrentPeriod
			.clone()
			.subtract(1, 'day')
			.format(this.DATE_FORMAT);

		if (this.currentPeriodKind === 'M') return previousMonthLabel;

		if (this.currentPeriodKind === 'W') return this.formatPeriod(previousWeekLabel);

		return previousDayLabel;
	};

	formatPeriod(date: string) {
		return DatesFormatter.formatDateByPeriodKind(date, this._currentPeriodKind);
	}

	get timescores() {
		return this.timescoreService.timescores;
	}

	get timescoreBreakdownKinds() {
		return this._timescoreBreakdownKinds;
	}

	get currentPeriodKind() {
		return this._currentPeriodKind;
	}

	get periods() {
		return this.periodsService.timescoreBreakdownPeriodsForPolicy.map((d) => d.format(this.DATE_FORMAT));
	}

	get currentPeriodForPolicy() {
		return this._currentPeriodForPolicy.format(this.DATE_FORMAT);
	}

	get mustShowUserChart() {
		return this._mustShowUserChart;
	}

	get userTimescores() {
		return this._userTimescores;
	}

	get columns(): Column[] {
		return [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: this.getPreviousPeriodLabel(),
				accessor: 'previousTimescore',
			},
			{
				Header: this.formatPeriod(this.periodsService.timescoreBreakdownCurrentPeriod.format(this.DATE_FORMAT)),
				accessor: 'currentTimescore',
			},
		];
	}
}
