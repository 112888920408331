import {map, tap} from 'rxjs/operators';
import {
	UsersResponseDTO,
	ServiceRequest,
	ServiceResponse,
	OwnersResponseDTO,
	SetOrRemovePolicyUserOrOwnerRequestDTO,
} from '../../core/ModelContracts';
import {PolicyOwnersResponse, PolicyUsersResponse} from '../../core/Models';
import {PolicyEditorUsersRepository} from '../../core/repositories/policyEditorUsersRepository';
import {AuthenticationService} from '../appServices/AuthenticationService';
import {PolicyService} from '../appServices/PolicyService';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {BackendRoutes, Environment, UrlBuilder} from '../Routes';

export class PolicyEditorUsersApiRepository implements PolicyEditorUsersRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private authenticationService: AuthenticationService,
		private policyService: PolicyService
	) {}

	getSubscriptionUsers = () =>
		this.requestSubscriptionUsers().pipe(
			map((serviceResponse) => serviceResponse.Response[0]),
			map((response) => PolicyUsersResponse.createFrom(response))
		);

	requestSubscriptionUsers = () => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const usersUrl = urlBuilder.getBackendUrl(BackendRoutes.getPolicyUsers);
		const callContext = this.authenticationService.callContext;
		const request = this.policyService.currentPolicyId;
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<UsersResponseDTO[]>>(usersUrl, 'POST', serviceRequest);
	};

	getSubscriptionOwners = () =>
		this.requestSubscriptionOwners().pipe(
			map((serviceResponse) => serviceResponse.Response[0]),
			map((response) => PolicyOwnersResponse.createFrom(response))
		);

	requestSubscriptionOwners = () => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const ownersUrl = urlBuilder.getBackendUrl(BackendRoutes.getPolicyOwners);
		const callContext = this.authenticationService.callContext;
		const request = this.policyService.currentPolicyId;
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<OwnersResponseDTO[]>>(ownersUrl, 'POST', serviceRequest);
	};

	addUserToPolicy = (userId: number) => this.requestAddUser(userId);

	requestAddUser = (userId: number) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const addUserUrl = urlBuilder.getBackendUrl(BackendRoutes.addUserToPolicy);
		const callContext = this.authenticationService.callContext;
		const request = SetOrRemovePolicyUserOrOwnerRequestDTO.create(this.policyService.currentPolicyId, userId);
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<{}>>(addUserUrl, 'POST', serviceRequest);
	};

	removeUserFromPolicy = (userId: number) => this.requestRemoveUser(userId);

	requestRemoveUser = (userId: number) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const removeUserUrl = urlBuilder.getBackendUrl(BackendRoutes.removeUserFromPolicy);
		const callContext = this.authenticationService.callContext;
		const request = SetOrRemovePolicyUserOrOwnerRequestDTO.create(this.policyService.currentPolicyId, userId);
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<{}>>(removeUserUrl, 'POST', serviceRequest);
	};

	addOwnerToPolicy = (userId: number) => this.requestAddOwner(userId);

	requestAddOwner = (userId: number) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const addOwnerUrl = urlBuilder.getBackendUrl(BackendRoutes.addOwnerToPolicy);
		const callContext = this.authenticationService.callContext;
		const request = SetOrRemovePolicyUserOrOwnerRequestDTO.create(this.policyService.currentPolicyId, userId);
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<{}>>(addOwnerUrl, 'POST', serviceRequest);
	};

	removeOwnerFromPolicy = (userId: number) => this.requestRemoveOwner(userId);

	requestRemoveOwner = (userId: number) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const removeOwnerUrl = urlBuilder.getBackendUrl(BackendRoutes.removeOwnerFromPolicy);
		const callContext = this.authenticationService.callContext;
		const request = SetOrRemovePolicyUserOrOwnerRequestDTO.create(this.policyService.currentPolicyId, userId);
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<{}>>(removeOwnerUrl, 'POST', serviceRequest);
	};
}
