import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PeriodKind} from '../ModelContracts';
import {VelocityHistory} from '../Models';
import {VelocityRepository} from '../repositories/VelocityRepository';

export class VelocityHistoryService {
	private _velocityHistories: VelocityHistory[] = [];
	private _velocityHistoriesStream: Subject<VelocityHistory[]> = new Subject();

	constructor(private repo: VelocityRepository) {}

	initialRequest = () => this.requestVelocityHistory('M');

	requestVelocityHistory = (periodKind: PeriodKind) =>
		this.repo.getAllByPeriod(periodKind).pipe(tap(this.notifyVelocityHistoriesUpdated));

	private notifyVelocityHistoriesUpdated = (velocityHistories: VelocityHistory[]) => {
		this._velocityHistories = velocityHistories;
		this._velocityHistoriesStream.next(velocityHistories);
	};

	get velocityHistories() {
		return this._velocityHistories;
	}

	get velocityHistoriesStream() {
		return this._velocityHistoriesStream;
	}
}
