import * as React from 'react';
import {TimescoreRangeByGrade} from '../../../core/models/Policy';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {ServiceFactory} from '../../factories/ServiceFactory';
import {Layout} from '../shared/layout/Layout';
import {WrapperReactState} from '../shared/WrapperReactState';
import {PolicyEditorTimescoreInteractor} from './policyEditorTimescoreInteractor';
import {HeaderTabs} from './shared/HeaderTabsComponent';
import {NavbarRight} from './shared/NavbarRightComponent';
import {PolicyEditorActionsComponent} from './shared/PolicyEditorActionsComponent';
import {PolicyEditorNameComponent} from './shared/PolicyEditorNameComponent';

export const PolicyEditorTimescoreComponentWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPolicyEditorTimescoreInteractor()}
		functionComponent={PolicyEditorTimescoreComponent}
	/>
);

const PolicyEditorTimescoreComponent = (props: {interactor: PolicyEditorTimescoreInteractor}) => {
	return (
		<Layout title={'Policy Editor'} navbarRight={<NavbarRight />}>
			<div className="policy-editor-wrapper">
				<div className="policy-name-component">
					<PolicyEditorNameComponent />
					{ServiceFactory.getAuthenticationService().credentials.hasAdministerRole() ? (
						<PolicyEditorActionsComponent />
					) : null}
				</div>
				<HeaderTabs />
				<div className="timescore">
					<TimescoreGrades interactor={props.interactor} />
				</div>
				<div className="button-container">
					<button className="btn btn-primary" onClick={props.interactor.onSave}>
						SAVE POLICY
					</button>
				</div>
			</div>
		</Layout>
	);
};

const TimescoreGrades = (props: {interactor: PolicyEditorTimescoreInteractor}) => {
	const formattedTimescores = props.interactor.formattedTimescoreValues;
	const fGrade = {
		limit: undefined,
		grade: 'F',
	};

	const renderGrades = () =>
		props.interactor.temporaryTimescores.map((timescore, i) => {
			let previousRangeValue = props.interactor.temporaryTimescoreValues[i - 1];
			return (
				<TimescoreGrade
					key={i}
					timescoreRange={timescore}
					previousRangeValue={previousRangeValue}
					index={i}
					timescoreHandler={props.interactor.onChangeTimescore}
					formattedValue={formattedTimescores[i]}
					blurHandler={props.interactor.onBlurTimescore}
					timescoreErrors={props.interactor.timescoreErrors}
					onClickError={props.interactor.onClickError}
				/>
			);
		});

	return (
		<div className="timescore-grades">
			<p>Please, set the maximum velocity value for each timescore grade.</p>
			<div className="timescore-grades-header">
				<div className="min-velocity-header">Min. Velocity</div>
				<div className="max-velocity-header">Max. Velocity</div>
			</div>
			{renderGrades()}
			<TimescoreGrade timescoreRange={fGrade} previousRangeValue={props.interactor.temporaryTimescoreValues[3]} />
			<div className="granularity">
				Include Granularity:
				<label className="custom-checkbox">
					<input
						type="checkbox"
						checked={props.interactor.includeGranularity}
						onChange={props.interactor.onChangeIncludeGranularity}
					/>
					<span className="checkmark"></span>
				</label>
				<p>(If enabled, the timescore is calculated depending also on granularity.)</p>
			</div>
		</div>
	);
};

interface ITimescoreGradeProps {
	index?: number;
	timescoreRange: TimescoreRangeByGrade;
	previousRangeValue: number;
	timescoreHandler?: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
	blurHandler?: (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
	formattedValue?: string;
	timescoreErrors?: string[];
	onClickError?: (msg: string) => void;
}

const TimescoreGrade = (props: ITimescoreGradeProps) => {
	const getPreviousRangeValue = () => {
		if (props.previousRangeValue === undefined) return '0';
		if (props.previousRangeValue === 0) return '0.01';
		if (props.index == null) return (props.previousRangeValue + 0.01).toFixed(2) + '+';

		return (props.previousRangeValue + 0.01).toFixed(2);
	};

	const hasErrors = () => props?.timescoreErrors && props.timescoreErrors[props.index];

	const getSeparatorCssClass = () => (hasErrors() ? 'separator error' : 'separator');

	return (
		<div className="timescore-grade">
			<span className="grade">{props.timescoreRange.grade}</span>
			<span className="points">:</span>
			<input className="policy-editor-input" type="text" value={getPreviousRangeValue()} disabled={true} />
			{props.index != null ? <div className={getSeparatorCssClass()}></div> : null}

			{props.index != null ? (
				<input
					className="policy-editor-input"
					type="number"
					value={props.formattedValue}
					onChange={props.timescoreHandler(props.index)}
					onFocus={(e) => e.target.select()}
					min={'0'}
					step={'0.01'}
					onBlur={props.blurHandler(props.index)}
					placeholder={'0.00'}
				/>
			) : null}
			{hasErrors() ? (
				<i
					className="icon-icon-alert-error"
					onClick={() => props.onClickError(props.timescoreErrors[props.index])}
				/>
			) : null}
		</div>
	);
};
