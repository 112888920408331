import * as React from 'react';
import {WrapperReactState} from '../../shared/WrapperReactState';
import {InteractorFactory} from '../../../factories/InteractorFactory';
import {LoadingInput} from './LoadingInputComponent';
import {PolicyEditorNameInteractor, GlobalForm} from './PolicyEditorNameInteractor';
import {PolicyEditorActionsInteractor} from './PolicyEditorActionsInteractor';

export const PolicyEditorActionsComponent = () => {
	const PolicyEditorActions = (props: {interactor: PolicyEditorActionsInteractor}) => {
		const onAddModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.preventDefault();
			props.interactor.pushModal(<PolicyAdd interactor={props.interactor} />);
		};

		const onDeleteModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.preventDefault();
			props.interactor.pushModal(<PolicyDelete interactor={props.interactor} />);
		};

		return (
			<div>
				<div>
					<label htmlFor="">Is Enabled</label>

					<label className="custom-checkbox is-enabled">
						<input
							type="checkbox"
							name={GlobalForm.PolicyName}
							checked={props.interactor.isPolicyEnabled}
							onChange={(e) => props.interactor.onChangePolicyIsEnabled(e)}
						/>
						<span className="checkmark"></span>
					</label>
				</div>
				<div>
					<button className="btn btn-outline-primary" onClick={onAddModal}>
						Add
					</button>
				</div>
				<div>
					<button className="btn btn-outline-primary" onClick={onDeleteModal}>
						Delete
					</button>
				</div>
				<div>
					<button className="btn btn-outline-primary" onClick={props.interactor.onDuplicatePolicy}>
						Duplicate
					</button>
				</div>
			</div>
		);
	};

	return (
		<WrapperReactState
			interactor={InteractorFactory.getPolicyEditorActionsInteractor()}
			functionComponent={PolicyEditorActions}
		/>
	);
};

const PolicyAdd = (props: {interactor: PolicyEditorActionsInteractor}) => {
	let policyName = React.createRef<HTMLInputElement>();

	return (
		<div className="add-policy-modal">
			<input className="policy-editor-input" placeholder={'Type Policy Name'} ref={policyName} />
			<button className="btn btn-primary" onClick={props.interactor.onAddNewPolicy(policyName)}>
				Add new policy
			</button>
		</div>
	);
};

const PolicyDelete = (props: {interactor: PolicyEditorActionsInteractor}) => {
	return (
		<div>
			<p>Are you sure you want to delete this policy?</p>
			<div style={{display: 'flex'}}>
				<button className="btn btn-primary" onClick={props.interactor.onDeletePolicy}>
					Yes
				</button>
				<button className="btn btn-primary" onClick={props.interactor.closeModal}>
					No
				</button>
			</div>
		</div>
	);
};
