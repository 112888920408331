import {VelocityRepository} from '../../core/repositories/VelocityRepository';
import {
	PeriodKind,
	VelocityHistoryRequest,
	VelocityHistoryDTO,
	CallContext,
	ServiceRequest,
	ServiceResponse,
} from '../../core/ModelContracts';
import {UrlBuilder, BackendRoutes, Environment} from '../Routes';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyService} from '../appServices/PolicyService';
import {PeriodsService} from '../appServices/PeriodsService';
import {map} from 'rxjs/operators';
import {VelocityHistory} from '../../core/Models';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class VelocityApiRepository implements VelocityRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private authenticationService: AuthenticationService
	) {}

	getAllByPeriod = (periodType: PeriodKind) =>
		this.requestVelocityHistory(periodType).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((vhDTOs) => vhDTOs.map(VelocityHistory.createFrom))
		);

	requestVelocityHistory = (periodType: PeriodKind) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const velocityUrl = urlBuilder.getBackendUrl(BackendRoutes.velocityHistory);
		const callContext = this.authenticationService.callContext;
		const request = VelocityHistoryRequest.create(this.policyService.currentPolicyId, periodType);
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<VelocityHistoryDTO[]>>(
			velocityUrl,
			'POST',
			serviceRequest
		);
	};
}
