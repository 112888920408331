import {PolicyRepository} from '../../core/repositories/PolicyServiceRepository';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {BackendRoutes, Environment, UrlBuilder} from '../Routes';
import {
	CallContext,
	PoliciesForSubscriptionResponseDTO,
	ServiceRequest,
	ServiceResponse,
} from '../../core/ModelContracts';
import {map} from 'rxjs/operators';
import {PolicyReader} from '../../core/models/Policy';
import {AuthenticationService} from '../appServices/AuthenticationService';
import {of} from 'rxjs';
import {FakeData} from '../../data/FakeData';

export class PolicyApiRepository implements PolicyRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private authenticationService: AuthenticationService
	) {}

	getPolicies = () =>
		this.requestPolicies().pipe(
			map((serviceResponse) => serviceResponse.Response.Records),
			map((pDTOs) => pDTOs.map(PolicyReader.createFrom))
		);

	requestPolicies = () => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const policiesforsubscriptionUrl = urlBuilder.getBackendUrl(BackendRoutes.policiesforsubscription);
		const callContext = this.authenticationService.callContext;

		const request = {};
		const serviceRequest = ServiceRequest.create<{}>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<PoliciesForSubscriptionResponseDTO>>(
			policiesforsubscriptionUrl,
			'POST',
			serviceRequest
		);
	};

	getPoliciesHr = () =>
		this.requestPoliciesHr().pipe(
			map((serviceResponse) => serviceResponse.Response.Records),
			map((pDTOs) => pDTOs.map(PolicyReader.createFrom))
		);

	requestPoliciesHr = () => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const policiesforsubscriptionHrUrl = urlBuilder.getBackendUrl(BackendRoutes.policiesforsubscriptionhr);
		const callContext = this.authenticationService.callContext;

		const request = {};
		const serviceRequest = ServiceRequest.create<{}>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<PoliciesForSubscriptionResponseDTO>>(
			policiesforsubscriptionHrUrl,
			'POST',
			serviceRequest
		);
	};
}

export class PolicyApiRepositoryForHR implements PolicyRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private authenticationService: AuthenticationService
	) {}

	getPolicies = () =>
		this.requestPolicies().pipe(
			map((serviceResponse) => serviceResponse.Response.Records),
			map((pDTOs) => pDTOs.map(PolicyReader.createFrom))
		);

	requestPolicies = () => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const policiesforsubscriptionUrl = urlBuilder.getBackendUrl(BackendRoutes.policiesforsubscriptionhr);
		const callContext = this.authenticationService.callContext;

		const request = {};
		const serviceRequest = ServiceRequest.create<{}>({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<PoliciesForSubscriptionResponseDTO>>(
			policiesforsubscriptionUrl,
			'POST',
			serviceRequest
		);
	};
}
