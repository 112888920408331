import {Assistant, MinMax, Policy, SharedPolicyProperties, Velocity, DeadLine, ItkUsage} from '../models/Policy';
import {
	AssistantDTO,
	ComplianceLevelRewardDTO,
	MinMaxDTO,
	SharedPolicyPropertiesDTO,
	VelocityDTO,
	DeadLineDTO,
	TimescoreRangeByGradeDTO,
} from '../modelContracts/PolicyDto';

export class PolicyMapper {
	static fromPolicyToDto(policy: Policy) {
		return {
			PolicyId: policy.id,
			PolicyVersion: policy.version,
			EngineVersion: policy.engineVersion,
			IncludeGranularity: policy.includeGranularity,
			Timescores: policy.timescores.map(TimescoreRangeByGradeDTO.createFrom),
			PolicyName: policy.name,
			FiscalYearStartMonth: policy.initialMonth,
			Velocity: PolicyMapper.getVelocity(policy.velocity),
			MinMaxHours: PolicyMapper.getMinMax(policy.minMaxHours),
			MinMaxHoursDaily: PolicyMapper.getMinMax(policy.minMaxHoursDaily),
			MinMaxHoursWeekly: PolicyMapper.getMinMax(policy.minMaxHoursWeekly),
			Assistant: PolicyMapper.getAssistant(policy.assistant),
			Deadlines: PolicyMapper.getDeadlines(policy.deadlines),
			DeadlinesWeekly: PolicyMapper.getDeadlines(policy.deadlinesWeekly),
			iTKUsage: PolicyMapper.getItkUsage(policy.itkUsage),
		};
	}

	private static getItkUsage = (itkUsage: ItkUsage) => ({
		MinimumPercentageTimecards: itkUsage.minimumPercentageTimecards,
		...PolicyMapper.getSharedProperties(itkUsage),
	});

	private static getDeadlines = (deadlines: DeadLine, policyInitialMonth?: number): DeadLineDTO => ({
		GraceDays: deadlines.graceDays,
		...PolicyMapper.getSharedProperties(deadlines),
	});

	private static getAssistant = (assistant: Assistant): AssistantDTO => ({
		MaxAssistantPercentage: assistant.maxAssistantPercentage,
		MaxAssistantPercentageRockstar: assistant.maxAssistantPercentageRockstar,
		...PolicyMapper.getSharedProperties(assistant),
	});

	private static getVelocity = (velocity: Velocity): VelocityDTO => ({
		AverageVelocity: velocity.goal,
		AverageVelocityRockstar: velocity.goalRockstar,
		...PolicyMapper.getSharedProperties(velocity),
	});

	private static getMinMax = (minMax: MinMax): MinMaxDTO => ({
		MinHours: minMax.minGoal,
		MaxHours: minMax.maxGoal,
		MinHoursList: minMax.minHoursList,
		UseBillableForEvaluation: minMax.useBillableForEvaluation,
		...PolicyMapper.getSharedProperties(minMax),
	});

	private static getSharedProperties = (sharedProperties: SharedPolicyProperties): SharedPolicyPropertiesDTO => {
		const getRewards = (sharedProperties: SharedPolicyProperties): ComplianceLevelRewardDTO[] =>
			sharedProperties.rewards.map((r) => ({
				LevelName: r.levelName,
				Level: r.level,
				Reward: {
					Cash: r.reward.cash,
					NonCash: r.reward.nonCash,
				},
			}));

		return {
			Disabled: sharedProperties.disabled,
			Rewards: getRewards(sharedProperties),
			Notifications: sharedProperties.notifications,
		};
	};
}
