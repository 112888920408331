import * as React from 'react';
import desktopLogo from '../../../../assets/img/thrive-logo.svg';
import mobileLogo from '../../../../assets/img/thrive-logo-icon.svg';
import footerLogo from '../../../../assets/img/aderant-logo.svg';
import {ReactNode, useState} from 'react';
import {Link} from 'react-router-dom';
import {Routes} from '../../../Routes';
import {ServiceFactory} from '../../../factories/ServiceFactory';
import {ModalComponentWrapped} from '../modal/Modals';
import {loaderGif} from './assets/loaderGif';
import {NotificationBarWrapper, NotificationPanelWrapper} from '../notification/NotificationComponent';
import {LoaderInteractor} from '../loader/loaderInteractor';
import {WrapperReactState} from '../WrapperReactState';
import {InteractorFactory} from '../../../factories/InteractorFactory';
import {SettingsPanelWrapper} from '../settings/settingsComponent';
import moment = require('moment');
import {thriveLogoSvg, thriveMobileLogoSvg} from './assets/assets';

type LayoutProps = {title?: string; navbarRight?: ReactNode; children: ReactNode};

export const Layout = (props: LayoutProps) => {
	const shouldRenderAllNav =
		ServiceFactory.getAuthenticationService().credentials.hasAdminAccess() ||
		ServiceFactory.getAuthenticationService().credentials.hasPolicyOwnerOnlyAccess() ||
		ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesAndPolicyOwnerAccess();

	return (
		<div>
			<Header />
			<NotificationBarWrapper />
			<MainNavbar title={props.title}>{props.navbarRight}</MainNavbar>
			<div className="container">
				<div className="main-content">
					{shouldRenderAllNav ? <SecondNavForThriveAccess /> : <SecondNavForHumanResourcesAccess />}
					<LoaderComponentWrapped />
					{props.children}
				</div>
			</div>
			<Footer />
			<ModalComponentWrapped />
		</div>
	);
};

const LoaderComponentWrapped = () => WrapperReactState.create(LoaderComponent, InteractorFactory.getLoaderInteractor());

const LoaderComponent = (props: {interactor: LoaderInteractor}) =>
	props.interactor.shouldShowloader() ? (
		<div className="loader-container">
			<div className="loadingSpinner">
				<img height="100" src={loaderGif} />
			</div>
		</div>
	) : (
		<div />
	);

const Header = () => (
	<div className="topbar">
		<div className="topbar-left">
			<div className="desktop-logo">{thriveLogoSvg}</div>
			<div className="mobile-logo">{thriveMobileLogoSvg}</div>
		</div>
		<nav className="navbar-custom topbar-right">
			<ul className="list-unstyled topbar-nav">
				<li>
					<NotificationPanelWrapper />
				</li>
				<div className="separator"></div>
				<li>
					<SettingsPanelWrapper />
				</li>
				<div className="separator"></div>
				<li className="dropdown">
					<div className="main-profile-menu">
						{ServiceFactory.getAuthenticationService().credentials.getUserInitials()}
					</div>
				</li>
			</ul>
		</nav>
	</div>
);

const MainNavbar = (props: {title: string; children: ReactNode}) => {
	return (
		<div className="container">
			<div className="navbar-container">
				<div className="navbar-left">
					<div className="page-title">
						<h1>{props.title}</h1>
					</div>
				</div>
				{props.children}
			</div>
		</div>
	);
};

const NavItem = (props: {to: string; title: string; className?: string}) => {
	const prepareClassNameBy = (route: string) =>
		ServiceFactory.getNavigationService().isCurrentRoute(route)
			? 'btn  btn-primary btn-selected'
			: 'btn  btn-outline-primary';

	return (
		<li>
			<div
				className={props.className ? props.className : prepareClassNameBy(props.to)}
				onClick={() => ServiceFactory.getNavigationService().navigate(props.to)}
			>
				<span>{props.title}</span>
			</div>
		</li>
	);
};

const SecondNavForThriveAccess = () => {
	const activeButtonStyle = 'btn  btn-primary btn-selected';
	const disableButtonStyle = 'btn  btn-outline-primary';

	const prepareClassNameForDashboard = () =>
		ServiceFactory.getNavigationService().isHome() ? activeButtonStyle : disableButtonStyle;

	const prepareClassForPolicyRoute = () =>
		Routes.isPolicyRoute(ServiceFactory.getNavigationService().getCurrentRoute())
			? activeButtonStyle
			: disableButtonStyle;

	return (
		<div className="second-nav">
			<ul>
				<NavItem to={Routes.dashboard} title={'Dashboard'} className={prepareClassNameForDashboard()} />
				<NavItem to={Routes.complianceHistory} title={'Compliance History'} />
				<NavItem to={Routes.velocityHistory} title={'Velocity History'} />
				<NavItem to={Routes.contemporaneousHistory} title={'Contemporaneous History'} />
				<NavItem to={Routes.timescoreBreakdown} title={'Timescore Breakdown'} />
				<NavItem to={Routes.workHoursOverview} title={'Work Hours Overview'} />
				<NavItem to={Routes.penaltiesAndRewards} title={'Penalties And Rewards'} />
				<NavItem
					to={Routes.policyEditorVelocity}
					title={'Policy Editor'}
					className={prepareClassForPolicyRoute()}
				/>
			</ul>
		</div>
	);
};

const SecondNavForHumanResourcesAccess = () => (
	<div className="second-nav">
		<ul>
			<NavItem to={Routes.penaltiesAndRewards} title={'Penalties And Rewards'} />
		</ul>
	</div>
);

const Footer = () => (
	<footer>
		<img className="logo-footer" src={footerLogo} alt="Aderant Thrive" title="Aderant Thrive" />
		<div>
			<p>© {moment().year()} Aderant. All rights reserved.</p>
		</div>
	</footer>
);

export const InfoComponent = (props: {message: string}) => (
	<div>
		<div className="topbar">
			<div className="topbar-left">
				<img className="logo desktop-logo" src={desktopLogo} alt="Aderant Thrive" title="Aderant Thrive" />
				<img className="logo mobile-logo" src={mobileLogo} alt="Aderant Thrive" title="Aderant Thrive" />
			</div>
		</div>
		<div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
			<h1 style={{color: '#0682c2'}}>{props.message}</h1>
		</div>
	</div>
);

export const NotFoundComponent = (props: {navigateTo: string}) => (
	<div>
		<div className="topbar">
			<div className="topbar-left">
				<img className="logo desktop-logo" src={desktopLogo} alt="Aderant Thrive" title="Aderant Thrive" />
				<img className="logo mobile-logo" src={mobileLogo} alt="Aderant Thrive" title="Aderant Thrive" />
			</div>
		</div>
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<h1 style={{color: '#0682c2'}}>404 Not Found</h1>
			<h4>
				<Link to={props.navigateTo}>Go Home</Link>
			</h4>
		</div>
	</div>
);
