import * as React from 'react';
import {PolicySelectorComponentWrapped} from '../../shared/policySelector/PolicySelectorComponent';

export const NavbarRight = () => (
	<div className="navbar-right">
		<div className="select-wrapper">
			<PolicySelectorComponentWrapped />
		</div>
	</div>
);
