import {PeriodKind} from '../ModelContracts';
import {UserContemporaneousResult} from '../Models';
import {Observable, of} from 'rxjs';

export interface UsersContemporaneousRepository {
	getAllByWithPeriod: (period: string, periodKind: PeriodKind) => Observable<UserContemporaneousResult[]>;
}

export class UsersContemporaneousInMemoryRepository implements UsersContemporaneousRepository {
	constructor(private usersContemporaneous: UserContemporaneousResult[]) {}

	getAllByWithPeriod = (period: string, periodKind: PeriodKind) => of(this.usersContemporaneous);
}
