import {PeriodKind, ComplianceKind} from '../ModelContracts';
import {Observable, of} from 'rxjs';
import {UserComplianceResult} from '../Models';

export interface UserscomplianceRepository {
	getAllBy: (periodKind: PeriodKind, complianceKind?: ComplianceKind) => Observable<UserComplianceResult[]>;
	getAllByWithPeriod: (
		period: string,
		periodKind: PeriodKind,
		complianceKind?: ComplianceKind
	) => Observable<UserComplianceResult[]>;
}

export class UsersComplianceInMemoryRepository implements UserscomplianceRepository {
	constructor(private usersCompliances: UserComplianceResult[]) {}

	getAllBy = (periodKind: PeriodKind, complianceKind?: ComplianceKind) => of(this.usersCompliances);

	getAllByWithPeriod = (period: string, periodKind: PeriodKind, complianceKind?: ComplianceKind) =>
		of(this.usersCompliances);
}
