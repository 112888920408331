import {ComplianceHistoryInteractor} from '../views/complianceHistory/ComplianceHistoryInteractor';
import {ContemporaneousHistoryInteractor} from '../views/contemporaneousHistory/ContemporaneousHistoryInteractor';
import {DashboardComparisonsInteractor} from '../views/dashboard/DashboardComparisonsInteractor';
import {ExcelContemporaneusService} from '../appServices/ExportToExcelService';
import {PeriodSelectorInteractor} from '../views/shared/periodSelector/PeriodSelectorInteractor';
import {PolicySelectorInteractor} from '../views/shared/policySelector/PolicySelectorInteractor';
import {TimescoreBreakdownInteractor} from '../views/timescoreBreakdown/TimescoreBreakdownInteractor';
import {TimescoreBreakdownTableInteractor} from '../views/timescoreBreakdown/TimescoreBreakdownTableInteractor';
import {VelocityHistoryInteractor} from '../views/velocityHistory/VelocityHistoryInteractor';
import {ServiceFactory} from './ServiceFactory';
import {TimescoreBreakdownUserHistoryInteractor} from '../views/timescoreBreakdown/TimescoreBreakdownUserHistoryInteractor';
import {PenaltiesAndRewardsInteractor} from '../views/PenaltiesAndRewards/PenaltiesAndRewardsInteractor';
import {ModalInteractor} from '../views/shared/modal/ModalInteractor';
import {WorkHoursOverviewInteractor} from '../views/usersGoals/WorkHoursOverviewInteractor';
import {ThriveInitializer} from './ThriveInitializer';
import {PolicyEditorVelocityInteractor} from '../views/policyEditor/PolicyEditorVelocityInteractor';
import {PolicyEditorDeadlineDisciplineInteractor} from '../views/policyEditor/PolicyEditorDeadlineDisciplineInteractor';
import {PolicyEditorGracePeriodInteractor} from '../views/policyEditor/PolicyEditorGracePeriodInteractor';
import {PolicyEditorNameInteractor} from '../views/policyEditor/shared/PolicyEditorNameInteractor';
import {PolicyEditorEngagementInteractor} from '../views/policyEditor/PolicyEditorEngagementInteractor';
import {NotificationInteractor, NotificationPanelInteractor} from '../views/shared/notification/NotificationInteractor';
import {LoaderInteractor} from '../views/shared/loader/loaderInteractor';
import {SettingsInteractor} from '../views/shared/settings/settingsInteractor';
import {PolicyEditorActionsInteractor} from '../views/policyEditor/shared/PolicyEditorActionsInteractor';
import {PolicyEditorUsersInteractor} from '../views/policyEditor/policyEditorUsersInteractor';
import {PolicyEditorTimescoreInteractor} from '../views/policyEditor/policyEditorTimescoreInteractor';

export class InteractorFactory {
	private static velocityHistoryInteractor: VelocityHistoryInteractor;
	private static contemporaneousHistoryInteractor: ContemporaneousHistoryInteractor;
	private static complianceHistoryInteractor: ComplianceHistoryInteractor;
	private static policySelectorInteractor: PolicySelectorInteractor;
	private static policySelectorInteractorForHumanResources: PolicySelectorInteractor;
	private static periodSelectorInteractor: PeriodSelectorInteractor;
	private static dashboardComparisonsInteractor: DashboardComparisonsInteractor;
	private static timescoreBreakdownInteractor: TimescoreBreakdownInteractor;
	private static timescoreBreakdownTableInteractor: TimescoreBreakdownTableInteractor;
	private static timescoreBreakdownUserHistoryInteractor: TimescoreBreakdownUserHistoryInteractor;
	private static penaltiesAndRewardsInteractor: PenaltiesAndRewardsInteractor;
	private static penaltiesAndRewardsInteractorForHrAndPolicyOwner: PenaltiesAndRewardsInteractor;
	private static modalInteractor: ModalInteractor;
	private static workHoursOverviewInteractor: WorkHoursOverviewInteractor;
	private static policyEditorVelocityInteractor: PolicyEditorVelocityInteractor;
	private static policyEditorDeadlineDisciplineInteractor: PolicyEditorDeadlineDisciplineInteractor;
	private static policyEditorGracePeriodInteractor: PolicyEditorGracePeriodInteractor;
	private static policyEditorEngagementInteractor: PolicyEditorEngagementInteractor;
	private static policyEditorNameInteractor: PolicyEditorNameInteractor;
	private static policyEditorActionsInteractor: PolicyEditorActionsInteractor;
	private static notificationInteractor: NotificationInteractor;
	private static notificationPanelInteractor: NotificationPanelInteractor;
	private static loaderInteractor: LoaderInteractor;
	private static settingsInteractor: SettingsInteractor;
	private static policyEditorUsersInteractor: PolicyEditorUsersInteractor;
	private static policyEditorTimescoreInteractor: PolicyEditorTimescoreInteractor;

	static getVelocityHistoryInteractor = () => {
		if (InteractorFactory.velocityHistoryInteractor == null)
			InteractorFactory.velocityHistoryInteractor = new VelocityHistoryInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getVelocityHistoryService(),
				ServiceFactory.getPolicyService(),
				ServiceFactory.getUsersComplianceResultsService(),
				ServiceFactory.getPropertiesService(),
				ServiceFactory.getPolicyEditorService()
			);

		return InteractorFactory.velocityHistoryInteractor;
	};

	static getContemporaneousHistoryInteractor = () => {
		if (InteractorFactory.contemporaneousHistoryInteractor == null)
			InteractorFactory.contemporaneousHistoryInteractor = new ContemporaneousHistoryInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getContemporaneousHistoryService(),
				ServiceFactory.getPolicyService(),
				ServiceFactory.getUsersContemporaneousResultsService(),
				ServiceFactory.getPropertiesService()
			);

		return InteractorFactory.contemporaneousHistoryInteractor;
	};

	static getComplianceHistoryInteractor = () => {
		if (InteractorFactory.complianceHistoryInteractor == null)
			InteractorFactory.complianceHistoryInteractor = new ComplianceHistoryInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getComplianceHistoryService(),
				ServiceFactory.getPolicyService(),
				ServiceFactory.getUsersComplianceResultsService(),
				ServiceFactory.getPropertiesService()
			);

		return InteractorFactory.complianceHistoryInteractor;
	};

	static getDashboardComparisonsInteractor = () => {
		if (InteractorFactory.dashboardComparisonsInteractor == null)
			InteractorFactory.dashboardComparisonsInteractor = new DashboardComparisonsInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getDashboarComparisonsService(),
				ServiceFactory.getPolicyService(),
				ServiceFactory.getPeriodsService(),
				ServiceFactory.getUsersComplianceResultsService(),
				ServiceFactory.getPropertiesService()
			);

		return InteractorFactory.dashboardComparisonsInteractor;
	};

	static getTimescoreBreakdownInteractor = () => {
		if (InteractorFactory.timescoreBreakdownInteractor == null)
			InteractorFactory.timescoreBreakdownInteractor = new TimescoreBreakdownInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getTimescoreService(),
				ServiceFactory.getPolicyService(),
				ServiceFactory.getPeriodsService(),
				ServiceFactory.getPropertiesService()
			);

		return InteractorFactory.timescoreBreakdownInteractor;
	};

	static getTimescoreBreakdownTableInteractor = () => {
		if (InteractorFactory.timescoreBreakdownTableInteractor == null)
			InteractorFactory.timescoreBreakdownTableInteractor = new TimescoreBreakdownTableInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getTimescoreService(),
				ServiceFactory.getPeriodsService(),
				InteractorFactory.getTimescoreBreakdownInteractor()
			);

		return InteractorFactory.timescoreBreakdownTableInteractor;
	};

	static getTimescoreBreakdownUserHistoryInteractor = () => {
		if (InteractorFactory.timescoreBreakdownUserHistoryInteractor == null)
			InteractorFactory.timescoreBreakdownUserHistoryInteractor = new TimescoreBreakdownUserHistoryInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getTimescoreService(),
				ServiceFactory.getPropertiesService()
			);

		return InteractorFactory.timescoreBreakdownUserHistoryInteractor;
	};

	static getPeriodSelectorInteractor = () => {
		if (InteractorFactory.periodSelectorInteractor == null)
			InteractorFactory.periodSelectorInteractor = new PeriodSelectorInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPeriodsService()
			);

		return InteractorFactory.periodSelectorInteractor;
	};

	static getPolicySelectorInteractor = () => {
		if (InteractorFactory.policySelectorInteractor == null)
			InteractorFactory.policySelectorInteractor = new PolicySelectorInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyService(),
				ThriveInitializer.initialRequests
			);

		return InteractorFactory.policySelectorInteractor;
	};

	static getPolicySelectorInteractorForHumanResources = () => {
		if (InteractorFactory.policySelectorInteractorForHumanResources == null)
			InteractorFactory.policySelectorInteractorForHumanResources = new PolicySelectorInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyServiceHr(),
				ThriveInitializer.initialRequestsForHr
			);

		return InteractorFactory.policySelectorInteractorForHumanResources;
	};

	static getPenaltiesAndRewardsInteractor = () => {
		if (InteractorFactory.penaltiesAndRewardsInteractor == null)
			InteractorFactory.penaltiesAndRewardsInteractor = new PenaltiesAndRewardsInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getUsersComplianceResultsService(),
				ServiceFactory.getPeriodsService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.penaltiesAndRewardsInteractor;
	};

	static getPenaltiesAndRewardsInteractorForHrAndPolicyOwner = () => {
		if (InteractorFactory.penaltiesAndRewardsInteractorForHrAndPolicyOwner == null)
			InteractorFactory.penaltiesAndRewardsInteractorForHrAndPolicyOwner = new PenaltiesAndRewardsInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getUsersComplianceResultsServiceForHr(),
				ServiceFactory.getPeriodsServiceHr(),
				ServiceFactory.getPolicyServiceHr()
			);

		return InteractorFactory.penaltiesAndRewardsInteractorForHrAndPolicyOwner;
	};

	static getWorkHoursOverviewInteractor = () => {
		if (InteractorFactory.workHoursOverviewInteractor == null)
			InteractorFactory.workHoursOverviewInteractor = new WorkHoursOverviewInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getUsersGoalService(),
				ServiceFactory.getPeriodsService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.workHoursOverviewInteractor;
	};

	static getPolicyEditorVelocityInteractor = () => {
		if (InteractorFactory.policyEditorVelocityInteractor == null)
			InteractorFactory.policyEditorVelocityInteractor = new PolicyEditorVelocityInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyEditorService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.policyEditorVelocityInteractor;
	};

	static getPolicyEditorDeadlineDisciplineInteractor = () => {
		if (InteractorFactory.policyEditorDeadlineDisciplineInteractor == null)
			InteractorFactory.policyEditorDeadlineDisciplineInteractor = new PolicyEditorDeadlineDisciplineInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyEditorService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.policyEditorDeadlineDisciplineInteractor;
	};

	static getPolicyEditorGracePeriodInteractor = () => {
		if (InteractorFactory.policyEditorGracePeriodInteractor == null)
			InteractorFactory.policyEditorGracePeriodInteractor = new PolicyEditorGracePeriodInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyEditorService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.policyEditorGracePeriodInteractor;
	};

	static getPolicyEditorEngagementInteractor = () => {
		if (InteractorFactory.policyEditorEngagementInteractor == null)
			InteractorFactory.policyEditorEngagementInteractor = new PolicyEditorEngagementInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyEditorService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.policyEditorEngagementInteractor;
	};

	static getPolicyEditorNameInteractor = () => {
		if (InteractorFactory.policyEditorNameInteractor == null)
			InteractorFactory.policyEditorNameInteractor = new PolicyEditorNameInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyEditorService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.policyEditorNameInteractor;
	};

	static getPolicyEditorActionsInteractor = () => {
		if (InteractorFactory.policyEditorActionsInteractor == null)
			InteractorFactory.policyEditorActionsInteractor = new PolicyEditorActionsInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyEditorService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.policyEditorActionsInteractor;
	};

	static getModalInteractor = () => {
		if (InteractorFactory.modalInteractor == null)
			InteractorFactory.modalInteractor = new ModalInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService()
			);

		return InteractorFactory.modalInteractor;
	};

	static getNotificationInteractor() {
		if (InteractorFactory.notificationInteractor == null)
			InteractorFactory.notificationInteractor = new NotificationInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService()
			);

		return InteractorFactory.notificationInteractor;
	}

	static getNotificationPanelInteractor() {
		if (InteractorFactory.notificationPanelInteractor == null)
			InteractorFactory.notificationPanelInteractor = new NotificationPanelInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService()
			);

		return InteractorFactory.notificationPanelInteractor;
	}

	static getLoaderInteractor() {
		if (InteractorFactory.loaderInteractor == null)
			InteractorFactory.loaderInteractor = new LoaderInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService()
			);

		return InteractorFactory.loaderInteractor;
	}

	static getSettingsInteractor() {
		if (InteractorFactory.settingsInteractor == null)
			InteractorFactory.settingsInteractor = new SettingsInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPropertiesService()
			);

		return InteractorFactory.settingsInteractor;
	}

	static getPolicyEditorUsersInteractor() {
		if (InteractorFactory.policyEditorUsersInteractor == null)
			InteractorFactory.policyEditorUsersInteractor = new PolicyEditorUsersInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyEditorUsersService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.policyEditorUsersInteractor;
	}

	static getPolicyEditorTimescoreInteractor() {
		if (InteractorFactory.policyEditorTimescoreInteractor == null)
			InteractorFactory.policyEditorTimescoreInteractor = new PolicyEditorTimescoreInteractor(
				ServiceFactory.getNavigationService(),
				ServiceFactory.getNotificationService(),
				ServiceFactory.getPolicyEditorService(),
				ServiceFactory.getPolicyService()
			);

		return InteractorFactory.policyEditorTimescoreInteractor;
	}
}
