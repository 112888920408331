import * as uuid from 'uuid';

export class DateUtils {
	public static GetNow() {
		return new Date();
	}
}

export class StringUtils {
	public static isNullOrEmpty(str: string) {
		return str == null || str == '';
	}

	public static truncate = (s: string, n: number) => {
		if (s.length > n) return s.substring(0, n - 5) + ' ...';
		return s;
	};
}

export class NumberUtils {
	public static generateUUID() {
		return uuid.v1();
	}
}
