import * as React from 'react';
import {InteractorFactory} from '../../factories/InteractorFactory';
import {Layout} from '../shared/layout/Layout';
import {WrapperReactState} from '../shared/WrapperReactState';
import {
	DeadlineForm,
	DeadLineQuery,
	PolicyEditorDeadlineDisciplineInteractor,
} from './PolicyEditorDeadlineDisciplineInteractor';
import {HeaderTabs} from './shared/HeaderTabsComponent';
import {NavbarRight} from './shared/NavbarRightComponent';
import {PolicyEditorNameComponent} from './shared/PolicyEditorNameComponent';
import {ServiceFactory} from '../../factories/ServiceFactory';
import {PolicyEditorActionsComponent} from './shared/PolicyEditorActionsComponent';

export const PolicyEditorDeadlineDisciplineWrapped = () => (
	<WrapperReactState
		interactor={InteractorFactory.getPolicyEditorDeadlineDisciplineInteractor()}
		functionComponent={PolicyEditorDeadlineDiscipline}
	/>
);

export const PolicyEditorDeadlineDiscipline = (props: {interactor: PolicyEditorDeadlineDisciplineInteractor}) => {
	const minimumMonthlyHoursToInputs = () =>
		props.interactor.minimumMonthlyHours.map((v, i) => (
			<DeadlineDisciplineInput monthIndex={i} monthlyGoal={v} key={i} interactor={props.interactor} />
		));

	const fromMonthsToOptions = () =>
		new Array(12).fill('').map((period, i) => (
			<option key={i} value={i + 1}>
				{DeadLineQuery.getMonthNameByIndex(i)}
			</option>
		));

	return (
		<Layout title={'Policy Editor'} navbarRight={<NavbarRight />}>
			<div className="policy-editor-wrapper">
				<div className="policy-name-component">
					<PolicyEditorNameComponent />
					{ServiceFactory.getAuthenticationService().credentials.hasAdministerRole() ? (
						<PolicyEditorActionsComponent />
					) : null}
				</div>
				<HeaderTabs />
				<div className="min-max">
					<p>
						Within the Thrive policy you are able to choose your firm's preferred minimum hours per month.
					</p>
					<p>
						Your Firm's Annual Goal is:
						<input
							className="policy-editor-input anual-goal"
							type="number"
							value={props.interactor.minimumAnnualGoal}
							disabled={true}
						/>
					</p>
					<div className="policy-initial-month-selector">
						<span>Policy Initial Month:</span>
						<div className="select-wrapper">
							<select
								value={props.interactor.policyInitialMonth}
								onChange={(e) => props.interactor.onChangePolicyInitialMonth(e.target.value)}
							>
								{fromMonthsToOptions()}
							</select>
						</div>
					</div>
					<div className="days-container">{minimumMonthlyHoursToInputs()}</div>

					<p>
						Your Firm's Minimum Daily Goal is:
						<input
							className="policy-editor-input"
							type="number"
							name={DeadlineForm.DailyGoal}
							value={props.interactor.minMaxDaily.minGoal}
							onChange={props.interactor.onChangeMinimumDailyGoal}
							onFocus={(e) => e.target.select()}
						/>
						<i onClick={props.interactor.onShowDailyGoalInfo} className="icon-icon-information" />
						{props.interactor.shouldShowDailyGoalInfo ? (
							<span className="info">Daily goal required for missing time calculations in iTimekeep</span>
						) : (
							''
						)}
					</p>
					<p>
						Your Firm's Minimum Weekly Goal is:
						<input
							className="policy-editor-input"
							type="number"
							name={DeadlineForm.WeeklyGoal}
							value={props.interactor.minMaxWeekly.minGoal}
							onChange={props.interactor.onChangeMinimumWeeklyGoal}
							onFocus={(e) => e.target.select()}
						/>
						<i onClick={props.interactor.onShowWeeklyGoalInfo} className="icon-icon-information" />
						{props.interactor.shouldShowWeeklyGoalInfo ? (
							<span className="info">Weekly goal required for Timetelligence emails</span>
						) : (
							''
						)}
					</p>
					<p>
						Use Only Billable Hours For Evaluation:
						<label className="custom-checkbox">
							<input
								type="checkbox"
								checked={props.interactor.minMax.useBillableForEvaluation}
								onChange={props.interactor.onChangeUseBillableHoursForEvaluation}
								onFocus={(e) => e.target.select()}
							/>
							<span className="checkmark"></span>
						</label>
					</p>
					<p className="policy-info">
						Thrive policy allows you to set penalties and rewards based on compliance or non-compliance for
						each of the policy criteria.
					</p>
					<p>
						Your Firm's Penalty for being under Min.:
						<input
							className="policy-editor-input"
							type="number"
							name={DeadlineForm.NotCompliance}
							value={props.interactor.minMax.getNotCompliance()}
							onChange={props.interactor.onChangeNotCompliance}
							onFocus={(e) => e.target.select()}
						/>
					</p>
					<p>
						Your Firm's Reward for Exceeding Min.:
						<input
							className="policy-editor-input"
							type="number"
							name={DeadlineForm.Compliance}
							value={props.interactor.minMax.getCompliance()}
							onChange={props.interactor.onChangeCompliance}
							onFocus={(e) => e.target.select()}
						/>
					</p>
				</div>
				<div className="button-container">
					<button className="btn btn-primary" onClick={props.interactor.onSave}>
						SAVE POLICY
					</button>
					{/* <button className="btn btn-outline-primary">GENERATE PDF</button> */}
				</div>
			</div>
		</Layout>
	);
};

interface DeadlineDisciplineInputProps {
	monthIndex: number;
	monthlyGoal: number;
	interactor: PolicyEditorDeadlineDisciplineInteractor;
}

const DeadlineDisciplineInput = (props: DeadlineDisciplineInputProps) => {
	const itemName = props.interactor.formattedMonths[props.monthIndex];

	return (
		<div className="days">
			<h2>{props.monthIndex + 1}</h2>
			<p>{itemName}</p>
			<input
				className="policy-editor-input"
				name={itemName}
				type="number"
				onChange={props.interactor.onChangeMonthlyGoal(props.monthIndex)}
				value={props.monthlyGoal}
				onFocus={(e) => e.target.select()}
			/>
			<p>MONTHLY GOAL</p>
		</div>
	);
};
