import {PeriodKind} from '../ModelContracts';
import {Observable, of} from 'rxjs';
import {Moment} from 'moment';

export interface PeriodsRepository {
	getPolicyPeriods: (periodKind: PeriodKind) => Observable<Moment[]>;
	getPolicyPeriodsWithCurrentPeriod: (periodKind: PeriodKind) => Observable<Moment[]>;
}

export class PeriodsInMemoryRepository implements PeriodsRepository {
	constructor(private policyPeriods: Moment[], private policyPeriodsWithCurrentDate: Moment[]) {}

	getPolicyPeriods = (periodKind: PeriodKind) => of(this.policyPeriods);

	getPolicyPeriodsWithCurrentPeriod = (periodKind: PeriodKind) => of(this.policyPeriodsWithCurrentDate);
}
