import {Subject} from 'rxjs';
import {NumberUtils} from '../../core/CoreUtils';
import {EntityQuery} from '../../core/Models';
import {last} from 'lodash';

export type INotificationService = {
	log(msg: string): void;
	warn(msg: string): void;
	error(msg: string): void;
	delete(msg: Message): void;
	hideLoader: () => void;
	showLoader: () => void;
};

export class NotificationService implements INotificationService {
	messagesBus: Subject<Message[]> = new Subject<Message[]>();
	private _messages: Message[] = [];
	private _shouldShowLoader: boolean = false;
	private _shouldShowLoaderStream = new Subject<boolean>();

	log = (msg: string) => {
		this.push(Message.create(msg, NotificationKind.info));
	};

	warn = (msg: string) => {
		this.push(Message.create(msg, NotificationKind.warning));
	};

	error = (msg: string) => {
		this.push(Message.create(msg, NotificationKind.error));
	};

	private push(msg: Message) {
		if (msg.kind === NotificationKind.log) {
			return;
		}

		this._messages.push(msg);
		this.messagesBus.next(this._messages);
	}

	delete(msg: Message) {
		this._messages = EntityQuery.delete(this._messages, msg);
		this.messagesBus.next(this._messages);
	}

	deleteAll() {
		this._messages = [];
		this.messagesBus.next(this._messages);
	}

	shouldShowloader = () => this._shouldShowLoader;

	showLoaderStream = () => this._shouldShowLoaderStream;

	hideLoader = () => {
		this._shouldShowLoader = false;
		this._shouldShowLoaderStream.next();
	};

	showLoader = () => {
		this._shouldShowLoader = true;
		this._shouldShowLoaderStream.next();
	};

	get currentMessage() {
		return last(this._messages);
	}

	get messages() {
		return this._messages;
	}
}

export enum NotificationKind {
	info = 'info',
	warning = 'warning',
	error = 'error',
	log = 'log',
}

export class Message {
	id: string;
	body: string;
	kind: NotificationKind;
	date: Date;
	error: Error;
	static create(body: string, kind: NotificationKind) {
		let msg = new Message();
		msg.id = NumberUtils.generateUUID();
		msg.kind = kind;
		msg.body = body;
		msg.date = new Date();

		return msg;
	}

	static createInfo = (body: string) => Message.create(body, NotificationKind.info);
	static createWarning = (body: string) => Message.create(body, NotificationKind.warning);
	static createError = (e: Error, title?: string) => {
		let msg = Message.create(title ? title : e.message, NotificationKind.error);
		msg.error = e;
		return msg;
	};
}
