import {Observable} from 'rxjs';
import {INotificationService} from '../../../appServices/INotificationService';
import {PolicyService} from '../../../appServices/PolicyService';
import {InteractorBase} from '../InteractorBase';
import {tap} from 'rxjs/operators';
import {ServiceFactory} from '../../../factories/ServiceFactory';
import {INavigationService} from '../../../appServices/INavigationService';

export class PolicySelectorInteractor extends InteractorBase {
	constructor(
		navigationService: INavigationService,
		protected notificationService: INotificationService,
		private policyService: PolicyService,
		private makeInitialRequests: (policyId: number) => Observable<void>
	) {
		super(navigationService, notificationService);

		this.policyService.policiesForSubscriptionStream.subscribe(this.updateView);
	}

	updateCurrentPolicy = (policyId: string) => {
		const idParsed = parseInt(policyId);
		this.policyService.updateCurrentPolicy(idParsed);
		this.makeInitialRequests(idParsed)
			.pipe(tap(() => this.notifyPolicyChanged(policyId)))
			.subscribe();
	};

	private notifyPolicyChanged = (policyId: string) => {
		this.notificationService.log(`Policy changed: ${policyId}`);
	};

	get currentPolicy() {
		return this.policyService.currentPolicyId;
	}

	get policies() {
		return this.policyService.policiesForSubscription;
	}
}
