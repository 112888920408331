import {cloneDeep, first} from 'lodash';
import {Moment} from 'moment';
import {PolicyEditorService} from '../../../core/services/PolicyEditorService';
import {INotificationService} from '../../appServices/INotificationService';
import {PolicyService} from '../../appServices/PolicyService';
import {PolicyEditorInteractorBase} from './shared/PolicyEditorBaseInteractor';
import moment = require('moment');
import {INavigationService} from '../../appServices/INavigationService';
import {PolicyInitialMonthUtils} from './PolicyInitialMonthUtils';
import {InteractorFactory} from '../../factories/InteractorFactory';

export enum GraceDaysForm {
	PenaltyForDeliquentSubmission = 'PenaltyForDeliquentSubmission',
	RewardsForTimelySubmission = 'RewardsForTimelySubmission',
	WeeklyGraceDays = 'WeeklyGraceDays',
}

export class PolicyEditorGracePeriodInteractor extends PolicyEditorInteractorBase {
	private _dueDate: string;
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		policyEditorService: PolicyEditorService,
		policyService: PolicyService
	) {
		super(navigationService, notificationService, policyEditorService, policyService);
		this.getDayNameByGraceDays(first(this.policyEditorService.policy.deadlinesWeekly.graceDays));
	}

	onChangeWeeklyGraceDays = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		const weeklyGraceDays = parseInt(e.target.value);
		if (GraceDaysValidator.isWeeklyGraceDaysValid(weeklyGraceDays)) {
			policy.deadlinesWeekly.setAllWeeksForYearBy(weeklyGraceDays);
		} else {
			this.notificationService.warn('Value must be a whole number between 0 and 5.');
		}

		this.getDayNameByGraceDays(weeklyGraceDays);
		this.updateView();
	};

	getDayNameByGraceDays = (weeklyGraceDays: number) => {
		switch (weeklyGraceDays) {
			case 0:
				return (this._dueDate = '');
			case 1:
				return (this._dueDate = 'Monday');
			case 2:
				return (this._dueDate = 'Tuesday');
			case 3:
				return (this._dueDate = 'Wednesday');
			case 4:
				return (this._dueDate = 'Thursday');
			case 5:
				return (this._dueDate = 'Friday');
		}
	};

	onChangeMonthlyGraceDays = (monthIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		const monthlyGraceDays = parseInt(e.target.value);
		if (GraceDaysValidator.isMonthlyGraceDaysValid(monthlyGraceDays)) {
			policy.deadlines.setElementGraceDaysByIndex(monthIndex, monthlyGraceDays);
		} else {
			this.notificationService.warn('Value must be a whole number between 0 and 15.');
		}
		this.updateView();
	};

	onChangeNotCompliance = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		const notCompliance = parseInt(e.target.value);
		if (GraceDaysValidator.isNotComplianceValid(notCompliance)) {
			policy.deadlines.setRewardsNotCompliance(notCompliance);
			policy.deadlinesWeekly.setRewardsNotCompliance(notCompliance);
		} else {
			this.notificationService.warn('Value must be negative and between -1000 to 0.');
		}
		this.updateView();
	};

	onChangeCompliance = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		const compliance = parseInt(e.target.value);
		if (GraceDaysValidator.isComplianceValid(compliance)) {
			policy.deadlines.setRewardsCompliance(compliance);
			policy.deadlinesWeekly.setRewardsCompliance(compliance);
		} else {
			this.notificationService.warn('Value must be non-negative whole number and between 0 to 1000.');
		}

		this.updateView();
	};

	onSave = () => {
		this.onSavePolicy(InteractorFactory.getPolicyEditorTimescoreInteractor().temporaryTimescores);
	};

	get deadLinesMonthlyGraceDays() {
		return this.policyEditorService.policy.deadlines.graceDays;
	}

	get deadLinesWeekly() {
		return this.policyEditorService.policy.deadlinesWeekly;
	}

	get deadLines() {
		return this.policyEditorService.policy.deadlines;
	}

	get dueDate() {
		return this._dueDate;
	}

	get formattedMonths() {
		return PolicyInitialMonthUtils.getOrderedMonthsFormatted(this.policyEditorService.policy.initialMonth);
	}

	get orderedMonthsIndexes() {
		return PolicyInitialMonthUtils.getOrderedMonthsIndexes(this.policyEditorService.policy.initialMonth);
	}
}

export class GraceDaysQuery {
	static getMonthNameByIndex = (index: number) => moment().month(index).format('MMMM');
}

export class GraceDaysValidator {
	static isWeeklyGraceDaysValid = (value: number) => value >= 0 && value <= 5;
	static isMonthlyGraceDaysValid = (value: number) => value >= 0 && value <= 15;
	static isNotComplianceValid = (value: number) => value >= -1000 && value <= 0;
	static isComplianceValid = (value: number) => value >= 0 && value <= 1000;
}

export class GraceDaysCalculator {
	format = 'ddd, MMM D YYYY';
	constructor(private monthIndex: number, private graceDays: number) {}

	static create(monthIndex: number, graceDays: number) {
		return new GraceDaysCalculator(monthIndex, graceDays);
	}

	generateCurrentYearFormatted = (currentDate: Moment): string => {
		const currentYearLimitDate = currentDate.set('month', this.monthIndex).endOf('month');

		return this.addBusinessdays(currentYearLimitDate, this.graceDays);
	};

	generateNextYearFormatted = (currentDate: Moment): string => {
		const nextYearLimitDate = currentDate.set('month', this.monthIndex).add(1, 'year').endOf('month');

		return this.addBusinessdays(nextYearLimitDate, this.graceDays);
	};

	addBusinessdays = (date: Moment, graceDays: number) => {
		date = moment(date);
		while (graceDays > 0) {
			date = date.add(1, 'days');
			if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
				graceDays -= 1;
			}
		}
		if (date.isoWeekday() == 7) {
			date = date.subtract(2, 'days');
		}

		if (date.isoWeekday() == 6) {
			date = date.subtract(1, 'days');
		}

		return date.format(this.format);
	};
}
