import {Observable, of} from 'rxjs';
import {PolicyReader} from '../models/Policy';

export interface PolicyRepository {
	getPolicies: () => Observable<PolicyReader[]>;
}

export class PolicyInMemoryrepository implements PolicyRepository {
	constructor(private policies: PolicyReader[]) {}
	getPolicies = () => of(this.policies);
}
