import {DateUtils, StringUtils} from '../../core/CoreUtils';
import {
	CallContext,
	PolicyDashboardComparisonDTO,
	PolicyDashboardComparisonRequest,
	ServiceRequest,
	ServiceResponse,
} from '../../core/ModelContracts';
import {NavigationService} from './NavigationService';
import {timer} from 'rxjs';
import {BackendRoutes, Environment, UrlBuilder} from '../Routes';
import {RequestWrapper} from '../helpers/RequestWrapper';

type TokenInformation = {
	given_name: string;
	family_name: string;
	email: string;
	exp: number;
};

enum TokenSchema {
	subSchema = 'http://schemas.bellefield.com/targetsubscriptionid',
	permissionsSchema = 'http://schemas.bellefield.com/permisions',
}

export class Credentials {
	subscriptionId: string;
	permissions: string; // ["Access","Delegate"]
	username: string;
	givenName: string;
	familyName: string;
	expiration: Date;

	private constructor() {}

	static createFromToken(token: string) {
		const tokenParsed = JSON.parse(atob(token.split('.')[1])) as TokenInformation;
		let credentials = new Credentials();
		credentials.givenName = tokenParsed.given_name;
		credentials.familyName = tokenParsed.family_name;
		credentials.username = tokenParsed.email;
		credentials.expiration = new Date(tokenParsed.exp * 1000); //THE TOKEN WILL LAST FOR AN HOUR
		credentials.subscriptionId = tokenParsed[TokenSchema.subSchema];
		credentials.permissions = tokenParsed[TokenSchema.permissionsSchema];

		return credentials;
	}

	hasPolicyOwnerOnlyRole() {
		//It includes policy owner. Policy owner should be controlling in backend side.
		const policyOwnerOnlyPermissions = ['Subscription:thrive_access', 'Subscription:PolicyOwner'];
		const otherPermissions = ['Subscription:TimeEntryPolicyHR', 'Subscription:Administer'];
		const hasPolicyOwnerOnlyPermissions = () =>
			policyOwnerOnlyPermissions.every((permission) => this.permissions.indexOf(permission) !== -1);
		const hasNotOtherPermissions = () =>
			otherPermissions.every((permission) => this.permissions.indexOf(permission) == -1);

		return hasPolicyOwnerOnlyPermissions() && hasNotOtherPermissions();
	}

	hasHumanResourceOnlyRole() {
		//HumanResource (hr) only can see penalties and rewards
		const humanResourcesOnlyPermissions = ['Subscription:thrive_access', 'Subscription:TimeEntryPolicyHR'];
		const otherPermissions = ['Subscription:PolicyOwner', 'Subscription:Administer'];
		const hasHumanResourcesOnlyPermissions = () =>
			humanResourcesOnlyPermissions.every((permission) => this.permissions.indexOf(permission) !== -1);
		const hasNotOtherPermissions = () =>
			otherPermissions.every((permission) => this.permissions.indexOf(permission) == -1);

		return hasHumanResourcesOnlyPermissions() && hasNotOtherPermissions();
	}

	hasAdministerRole() {
		const permissions = ['Subscription:Administer', 'Subscription:thrive_access'];
		return permissions.every((permission) => this.permissions.indexOf(permission) !== -1);
	}

	hasPolicyOwnerAndHrRole() {
		const permissions = [
			'Subscription:thrive_access',
			'Subscription:PolicyOwner',
			'Subscription:TimeEntryPolicyHR',
		];
		return (
			!this.hasAdministerRole() && permissions.every((permission) => this.permissions.indexOf(permission) !== -1)
		);
	}

	hasHumanResourcesOnlyAccess() {
		return this.hasHumanResourceOnlyRole();
	}

	hasAdminAccess() {
		return this.hasAdministerRole();
	}

	hasPolicyOwnerOnlyAccess() {
		return this.hasPolicyOwnerOnlyRole();
	}

	hasHumanResourcesAndPolicyOwnerAccess() {
		return this.hasPolicyOwnerAndHrRole();
	}

	hasNotThriveAccess() {
		return (
			!this.hasAdminAccess() &&
			!this.hasHumanResourceOnlyRole() &&
			!this.hasPolicyOwnerOnlyRole() &&
			!this.hasPolicyOwnerAndHrRole()
			//!this.credentials.isThriveCustomer()
		);
	}

	isExpired() {
		return this.expiration.valueOf() < DateUtils.GetNow().valueOf();
	}

	getUserInitials() {
		return `${this.givenName.charAt(0)}${this.familyName.charAt(0)}`;
	}
}

export class AuthenticationService {
	private _token: string;
	private _credentials: Credentials;

	constructor(
		private navigationService: NavigationService,
		private env: Environment,
		private requestHelper: RequestWrapper
	) {}

	requestRenewToken = () => {
		const serviceRequest = ServiceRequest.create<{}>({
			Request: {},
			CallContext: this.callContext,
		});

		return this.requestHelper
			.makeRequest<ServiceResponse<string>>(this.prepareUrl(), 'POST', serviceRequest)
			.subscribe((response) => {
				this.token = response.Response;
			});
	};

	private prepareUrl() {
		const urlBuilder = new UrlBuilder(this.env.endpointBase);
		return urlBuilder.getBackendUrl(BackendRoutes.renewToken);
	}

	hasToken() {
		return !StringUtils.isNullOrEmpty(this._token);
	}

	get credentials() {
		if (this._credentials == null) {
			this._credentials = Credentials.createFromToken(this._token);
			window['credentials'] = this._credentials;
		}

		this.checkIfCredentialsExpired();
		return this._credentials;
	}

	private checkIfCredentialsExpired() {
		const refresh = () => this.navigationService.refresh();
		const message = 'The session expired. You will need to refresh the browser';

		if (this._credentials.isExpired()) {
			this.navigationService.modalService.openOkDialog(message, refresh);
		}
	}

	get callContext() {
		return CallContext.createWithAuthToken(this._token);
	}

	get token() {
		return this._token;
	}

	set token(value: string) {
		this._token = value;
		window['token'] = value;
	}
}
