import {PeriodKind} from '../ModelContracts';
import {Observable, of} from 'rxjs';
import {VelocityHistory} from '../Models';

export interface VelocityRepository {
	getAllByPeriod: (periodKind: PeriodKind) => Observable<VelocityHistory[]>;
}

export class VelocityInMemoryRepository implements VelocityRepository {
	constructor(private velocityHistories: VelocityHistory[]) {}

	getAllByPeriod = (periodKind: PeriodKind) => of(this.velocityHistories);
}
