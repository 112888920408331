import {InteractorBase} from '../InteractorBase';
import {INotificationService} from '../../../appServices/INotificationService';
import {PeriodsService} from '../../../appServices/PeriodsService';
import {INavigationService} from '../../../appServices/INavigationService';

export class PeriodSelectorInteractor extends InteractorBase {
	constructor(
		navigationService: INavigationService,
		protected notificationService: INotificationService,
		private periodService: PeriodsService
	) {
		super(navigationService, notificationService);
	}

	get periods() {
		return this.periodService.dashboardPeriodsForPolicy;
	}
}
