import {InteractorBase} from '../InteractorBase';
import {NavigationService} from '../../../appServices/NavigationService';
import {NotificationService} from '../../../appServices/INotificationService';

export class LoaderInteractor extends InteractorBase {
	constructor(navigationService: NavigationService, notificationService: NotificationService) {
		super(navigationService, notificationService);
		(this.notificationService as NotificationService).showLoaderStream().subscribe(this.updateView);
	}

	shouldShowloader = () => (this.notificationService as NotificationService).shouldShowloader();
}
