import {EngagementPropertyNames} from '../Models';
import {Policy} from '../models/Policy';

export class EngagementPropertiesMapper {
	static fromEngagementPropertiesToDto(policy: Policy) {
		return {
			PolicyId: policy.id,
			Campaigns: [
				{
					Key: EngagementPropertyNames.TimetelligenceWeekly,
					Value: policy.timetelligenceWeeklyEnabled,
				},
				{
					Key: EngagementPropertyNames.TimetelligenceMonthly,
					Value: policy.timetelligenceMonthlyEnabled,
				},
				{
					Key: EngagementPropertyNames.BrazeDaily,
					Value: policy.brazeDailyEnabled,
				},
				{
					Key: EngagementPropertyNames.BrazeWeekly,
					Value: policy.brazeWeeklyEnabled,
				},
			],
		};
	}
}
