import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyService} from '../appServices/PolicyService';
import {
	PeriodKind,
	ComplianceHistoryRequest,
	ComplianceHistoryDTO,
	CallContext,
	ServiceRequest,
	ServiceResponse,
} from '../../core/ModelContracts';
import {UrlBuilder, BackendRoutes, Environment} from '../Routes';
import {ComplianceHistory} from '../../core/Models';
import {map} from 'rxjs/operators';
import {ComplianceRepository} from '../../core/repositories/ComplianceRepository';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class ComplianceApiRepository implements ComplianceRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private authenticationService: AuthenticationService
	) {}

	getAllByPeriod = (periodType: PeriodKind) =>
		this.requestComplianceHistory(periodType).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((chDTOs) => chDTOs.map(ComplianceHistory.createFrom))
		);

	requestComplianceHistory = (periodType: PeriodKind) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const complianceUrl = urlBuilder.getBackendUrl(BackendRoutes.compliancehistory);
		const callContext = this.authenticationService.callContext;

		const request = ComplianceHistoryRequest.create(this.policyService.currentPolicyId, periodType);
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<ComplianceHistoryDTO[]>>(
			complianceUrl,
			'POST',
			serviceRequest
		);
	};
}
