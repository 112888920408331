import {first} from 'lodash';
import {ServiceFactory} from '../factories/ServiceFactory';
import {Environment, Routes} from '../Routes';
import {RepositoryFactory} from '../factories/RepositoryFactory';

export class LoginHelper {
	static getTokenFromUrl() {
		return LoginHelper.getParameterValueFromQueryString('TOKEN');
	}

	private static getParameterValueFromQueryString(name: string) {
		const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	}

	private static addSubscriptionToUrl = () =>
		`?s=${ServiceFactory.getAuthenticationService().credentials.subscriptionId}`;

	static removeTokenAddSubscriptionAndRedirectIfNeeded() {
		const internalPath = this.getInternalPathFromRedirect();
		const currentUrl = window.location.href;
		const isFirstQueryString = currentUrl.includes('?TOKEN');
		const urlWithoutToken = isFirstQueryString
			? first(currentUrl.split('?TOKEN')) + this.addSubscriptionToUrl()
			: first(currentUrl.split('&TOKEN'));

		if (internalPath) {
			const url = `${urlWithoutToken}#${internalPath}`;
			window.location.hash = internalPath;
			window.history.pushState({path: url}, '', url);
		} else {
			window.history.pushState({path: urlWithoutToken}, '', urlWithoutToken);
		}
	}

	private static getInternalPathFromRedirect() {
		const result = /path=([^&]+)&?/.exec(window.location.search);
		return result && result[1] && decodeURIComponent(result[1]);
	}

	static redirectToPortalLogin() {
		const removeAfterHash = (s: string) => s.split('#')[0];
		const getInternalPath = () => (window.location.hash.length > 0 ? window.location.hash.substring(1) : '');
		const subIdFromUrl = LoginHelper.getSubscriptionFromUrl() ? LoginHelper.getSubscriptionFromUrl() : '';
		const currentUrl = window.location.href;
		const isLocalhost = currentUrl.includes('localhost');
		const protocol = window.location.protocol;
		const redirectPath = removeAfterHash(currentUrl.replace(protocol, '').replace('//', ''));
		const internalPath = getInternalPath();
		const redirectPathWithInternalPath = `${redirectPath}&path=${internalPath}`;
		const finalUrl = `${LoginHelper.getLoginUrl()}?r=${redirectPathWithInternalPath}&s=${subIdFromUrl}${
			isLocalhost ? '&p=http' : ''
		}`;
		window.location.replace(finalUrl);
	}

	private static getLoginUrl() {
		return RepositoryFactory.environment.endpointPortalLogin;
	}

	private static getSubscriptionFromUrl() {
		return LoginHelper.getParameterValueFromQueryString('s');
	}

	static navigateToDefaultIfNeeded = () => {
		const shouldNavigateToPenaltiesAndRewards =
			!ServiceFactory.getAuthenticationService().credentials.hasAdminAccess() &&
			!ServiceFactory.getAuthenticationService().credentials.hasPolicyOwnerOnlyAccess() &&
			ServiceFactory.getAuthenticationService().credentials.hasHumanResourcesOnlyAccess() &&
			!ServiceFactory.getNavigationService().isCurrentRoute(Routes.penaltiesAndRewards);

		const shouldNavigateToNotAuthorized =
			ServiceFactory.getAuthenticationService().credentials.hasNotThriveAccess() &&
			!ServiceFactory.getNavigationService().isCurrentRoute(Routes.notAuthorized);

		if (shouldNavigateToPenaltiesAndRewards) {
			window.history.pushState({}, '', `#${Routes.penaltiesAndRewards}`);
		}

		if (shouldNavigateToNotAuthorized) {
			window.history.pushState({}, '', `#${Routes.notAuthorized}`);
		}
	};
}
