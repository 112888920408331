import {RouteComponentProps} from 'react-router';
import {IModalService} from './IModalService';
import {INavigationService} from './INavigationService';

export class NavigationService implements INavigationService {
	constructor(public modalService: IModalService) {}
	private _context: RouteComponentProps;

	navigate = (route: string) => this._context.history.push(route);

	isCurrentRoute = (route: string) => this.getCurrentRoute().includes(route);

	getCurrentRoute = () => window.location.hash;

	setContext = (context: RouteComponentProps) => (this._context = context);

	isHome = () => window.location.hash === '#/';

	refresh = () => window.location.reload();
}
