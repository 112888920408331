import {DateUtils} from './CoreUtils';

type Nullable<T> = T | null;

export class ServiceRequest<T> {
	CallContext: CallContext;
	Request: T;

	static create<T>(json: {Request: T; CallContext: CallContext}) {
		let request = new ServiceRequest<T>();
		request.Request = json.Request;
		request.CallContext = json.CallContext;
		return request;
	}

	static createWithParser<T>(json: any, parser: (json: any) => T) {
		let request = new ServiceRequest<T>();
		request.Request = parser(json.Request);
		request.CallContext = json.CallContext;
		return request;
	}
}

export class CallContext {
	Credentials: any;
	TargetSubscriptionId: number;
	CallerUTCTime: any;
	ServiceCallTraceId: string;
	APICaller: APICaller;
	TargetUserId: number;

	static createWithAuthAndTarget(authToken: string, targetSubscriptionId: number, targetUserId: number) {
		let cc = this.createWithAuthToken(authToken);
		cc.TargetSubscriptionId = targetSubscriptionId;
		cc.TargetUserId = targetUserId;

		return cc;
	}

	static createWithBasicAuth(username: string, pass: string, targetSubId?: number) {
		let cc = new CallContext();
		cc.Credentials = {UserName: username, Password: pass};
		cc.CallerUTCTime = {
			DT: DateUtils.GetNow().toISOString().replace('Z', ''),
			TZ: {Offset: '-05:00', TimeZoneId: '3'},
		}; //TODO GET THIS FROM DATEUTILS

		cc.TargetSubscriptionId = targetSubId;
		return cc;
	}

	static createWithAPIToken(apiToken: string) {
		let cc = new CallContext();
		cc.APICaller = new APICaller();
		cc.APICaller.APIToken = apiToken;
		return cc;
	}

	static createWithAPITokenAndRealm(apiToken: string, realm: 'itk' | 'tb') {
		let cc = new CallContext();
		cc.APICaller = new APICaller();
		cc.APICaller.APIToken = apiToken;
		cc.APICaller.Realm = realm;
		return cc;
	}

	static createWithAuthToken(authToken: string) {
		let cc = new CallContext();
		cc.Credentials = {AuthenticationToken: authToken};
		cc.CallerUTCTime = {
			DT: DateUtils.GetNow().toISOString().replace('Z', ''),
			TZ: {Offset: '-05:00', TimeZoneId: '3'},
		}; //TODO GET THIS FROM DATEUTILS

		return cc;
	}
}

export class APICaller {
	APIToken: string;
	AppIdentifier: string;
	DevideId: string;
	Realm: string;
}

export class GenericError {
	static OK = 'OK';
	ErrorCode = GenericError.OK;
	ErrorLabel: string;
	ErrorDescription: string;
}

export class ServiceResponse<T> {
	Status: GenericError;
	Response: T;

	static create<T>(value: T) {
		let res = new ServiceResponse<T>();
		res.Response = value;
		res.Status = new GenericError();
		return res;
	}

	static createWithStatusFailed(description: string) {
		let res = new ServiceResponse();
		res.Status = new GenericError();
		res.Status.ErrorCode = 'FAILED';
		res.Status.ErrorLabel = 'Failed';
		console.warn(description);
		res.Status.ErrorDescription = description;
		return res;
	}

	static createWithUnauthorized(description: string) {
		let res = new ServiceResponse();
		res.Status = new GenericError();
		res.Status.ErrorCode = 'NO_AUTHORIZED';
		res.Status.ErrorLabel = 'No authorized';
		res.Status.ErrorDescription = description;
		return res;
	}
}
export type PeriodKind = 'D' | 'W' | 'M';

export class VelocityHistoryRequest {
	PolicyId: number;
	PeriodType: PeriodKind; //W, M, D

	static create(PolicyId: number, PeriodType: 'D' | 'W' | 'M') {
		let velocityHistoryRequest = new VelocityHistoryRequest();
		velocityHistoryRequest.PolicyId = PolicyId;
		velocityHistoryRequest.PeriodType = PeriodType;

		return velocityHistoryRequest;
	}
}

export class VelocityHistoryDTO {
	Period: string;
	AverageVelocity: number;
	FormattedPeriod: string;
	PeriodType: string;
}

export class ContemporaneousHistoryRequest {
	PolicyId: number;
	PeriodType: PeriodKind;

	static create(PolicyId: number, PeriodType: 'D' | 'W' | 'M') {
		let contemporaneousHistoryRequest = new ContemporaneousHistoryRequest();
		contemporaneousHistoryRequest.PolicyId = PolicyId;
		contemporaneousHistoryRequest.PeriodType = PeriodType;

		return contemporaneousHistoryRequest;
	}
}

export class ContemporaneousHistoryDTO {
	Period: string;
	ContemporaneousHoursTotalPercent: number;
	ContemporaneousCardsCountPercent: number;
	FormattedPeriod: string;
	PeriodType: string;
}

export class ComplianceHistoryRequest {
	PolicyId: number;
	PeriodType: PeriodKind;

	static create(PolicyId: number, PeriodType: 'D' | 'W' | 'M') {
		let complianceHistoryRequest = new ComplianceHistoryRequest();
		complianceHistoryRequest.PolicyId = PolicyId;
		complianceHistoryRequest.PeriodType = PeriodType;

		return complianceHistoryRequest;
	}
}

export class ComplianceHistoryDTO {
	Period: string;
	PolicyId: number;
	Rockstars_Count: number;
	Compliant_Count: number;
	NotCompliant_Count: number;
	GraceDays: number;
	TotalHoursCreatedAfterGracePeriod: number;
	TotalTimecardsCreatedAfterGracePeriod: number;
	TotalBillableHoursCreatedAfterGracePeriod: number;
	TotalBillableTimecardsCreatedAfterGracePeriod: number;
}

export class PolicyDashboardComparisonRequest {
	PolicyId: number;
	PeriodType: PeriodKind;
	Period: string;
	static create(PolicyId: number, PeriodType: 'D' | 'W' | 'M', Period: string) {
		let policyDashboardComparisonRequest = new PolicyDashboardComparisonRequest();
		policyDashboardComparisonRequest.PolicyId = PolicyId;
		policyDashboardComparisonRequest.PeriodType = PeriodType;
		policyDashboardComparisonRequest.Period = Period;

		return policyDashboardComparisonRequest;
	}
}

export class PolicyDashboardComparisonDTO {
	PolicyId: number;
	Period: string;
	PeriodDisplay: string;
	PeriodGraceDays: number;
	PeriodAverageVelocity: number;
	PeriodAverageGranularity: number;
	FirmTimeScore: string;
	ContemporaneousHoursTotalPercent: number;
	ContemporaneousCardsCountPercent: number;
	NumberOfUsers: number;
	TotalHoursCreatedAfterGracePeriod: number;
	TotalTimecardsCreatedAfterGracePeriod: number;
	PeriodTotalWorkHours: number;
	TotalBillableHoursCreatedAfterGracePeriod: number;
	TotalBillableTimecardsCreatedAfterGracePeriod: number;
	PeriodTotalBillableWorkHours: number;
	TotalTimekeepersUsingiTK: number;
	TotalTimekeepersUsingDelegates: number;
	PeriodSystemAverageGranularity: number;
	PeriodSystemTimeScore: string;
	PeriodSystemAverageVelocity: number;
	PeriodConfiguredVelocityGoal: number;
	TotalTimekeepersWithRewards: number;
	TotalCashRewards: number;
	TotalTimekeepersWithPenalties: number;
	TotalCashPenalties: number;
	ResultComparisons: ResultComparisonDTO[];
}

export class ResultComparisonDTO {
	ComplianceResult: number;
	NumberOfUsers: number;
	PercentageOfTotalUsers: number;
	NumberOfUsersInPreviousPeriod: number;
	PercentageOfTotalUsersInPreviousPeriod: number;
	PercentageDifference: number;
}

export class TimescoreSummaryForPeriodRequest {
	PolicyId: number;
	PeriodType: PeriodKind;
	Period: string;

	static create(PolicyId: number, PeriodType: 'D' | 'W' | 'M', Period: string) {
		let timescoreSummaryForPeriodRequest = new TimescoreSummaryForPeriodRequest();
		timescoreSummaryForPeriodRequest.PolicyId = PolicyId;
		timescoreSummaryForPeriodRequest.PeriodType = PeriodType;
		timescoreSummaryForPeriodRequest.Period = Period;

		return timescoreSummaryForPeriodRequest;
	}
}

export class TimescoreForPeriodDTO {
	Timescore: string;
	NumberOfUsers: number;
	PercentageOfTotalUsers: number;
	NumberOfUsersInPreviousPeriod: number;
	PercentageOfTotalUsersInPreviousPeriod: number;
	PercentageDifference: number;
}

export class UsersTimescoreForPeriodRequest {
	PolicyId: number;
	PeriodType: PeriodKind;
	Period: string;

	static create(PolicyId: number, PeriodType: 'D' | 'W' | 'M', Period: string) {
		let usersTimescoreForPeriodRequest = new TimescoreSummaryForPeriodRequest();
		usersTimescoreForPeriodRequest.PolicyId = PolicyId;
		usersTimescoreForPeriodRequest.PeriodType = PeriodType;
		usersTimescoreForPeriodRequest.Period = Period;

		return usersTimescoreForPeriodRequest;
	}
}

export class UserTimescoreForPeriodDTO {
	Timescore: Nullable<string>;
	PreviousTimescore: Nullable<string>;
	UserId: number;
	UserEmail: string;
	UserFirstName: string;
	UserLastName: string;
	Title: Nullable<string>;
	PracticeArea: Nullable<string>;
}

export class UserTimescoreHistoryDTO {
	Day: string;
	Timescore: string;
	FormattedDay: string;
}

export class PolicyForSubscriptionDTO {
	PolicyId: number;
	SubscriptionId: number;
	SubscriptionName: string;
	CreatedOn: string;
	CreatedByUserId: number;
	CreatedByUserName: string;
	UpdatedByUserId: Nullable<number>;
	UpdatedByUserName: string;
	UpdatedOn: Nullable<string>;
	LastRanDate: Nullable<string>;
	CurrentPeriod: Nullable<string>;
	NextPeriodToRun: Nullable<string>;
	NextPeriodEffectiveGraceDays: Nullable<number>;
	PolicySerialized: null;
	PolicyName: string;
}

export class PoliciesForSubscriptionResponseDTO {
	TotalRecordCount: number;
	Records: PolicyForSubscriptionDTO[];
}

export class PolicyPeriodsRequest {
	PolicyId: number;
	PeriodType: PeriodKind;

	static create(PolicyId: number, PeriodType: 'D' | 'W' | 'M') {
		let policyPeriodsRequest = new PolicyPeriodsRequest();
		policyPeriodsRequest.PolicyId = PolicyId;
		policyPeriodsRequest.PeriodType = PeriodType;

		return policyPeriodsRequest;
	}
}

export enum ComplianceKind {
	NotCompliance = '2000',
	Compliance = '3000',
	RockStar = '4000',
}

export class UsersComplianceResultsRequest {
	PolicyId: number;
	Period: string;
	PeriodType: PeriodKind;
	ComplianceResult: ComplianceKind;

	static create(policyId: number, period: string, periodType: PeriodKind, complianceResult: ComplianceKind) {
		let usersComplianceResultsRequest = new UsersComplianceResultsRequest();
		usersComplianceResultsRequest.PolicyId = policyId;
		usersComplianceResultsRequest.Period = period;
		usersComplianceResultsRequest.PeriodType = periodType;
		usersComplianceResultsRequest.ComplianceResult = complianceResult;

		return usersComplianceResultsRequest;
	}
}

export class UserComplianceResultDTO {
	UserId: number;
	UserEmail: string;
	UserFirstName: string;
	UserLastName: string;
	ComplianceStatus: string;
	TotalCashReward: number;
	TotalCashPenalty: number;
	FormattedAverageVelocity: string;
	AverageVelocity: number;
	TotalWorkHours: number;
	TotalBillableWorkHours: number;
	AverageGranularity: number;
	FormattedAverageGranularity: string;
	Timescore: Nullable<string>;
	HadTimecardCreatedAfterGracePeriod: string;
	TotalHoursCreatedAfterGracePeriod: Nullable<number>;
	TotalBillableHoursCreatedAfterGracePeriod: Nullable<number>;
	AssistantComplianceResult: number;
	VelocityComplianceResult: number;
	MinMaxHoursComplianceResult: number;
	ITKUsageComplianceResult: number;
	ITKCreatedByOwnerCount: number;
	ITKCreatedByDelegateCount: number;
	ProblemReason: Nullable<string>;
	Location: Nullable<string>;
	Title: Nullable<string>;
	PracticeArea: Nullable<string>;
}

export class UsersGoalRequest {
	policyId: number;
	period: string;
	periodType: PeriodKind;

	static create(policyId: number, period: string, periodType: PeriodKind) {
		let usersGoalRequest = new UsersGoalRequest();
		usersGoalRequest.policyId = policyId;
		usersGoalRequest.period = period;
		usersGoalRequest.periodType = periodType;

		return usersGoalRequest;
	}
}

export class UsersGoalDTO {
	Hours: number;
	Goal: number;
	Difference: number;
	UserId: number;
	UserEmail: string;
	UserFirstName: string;
	UserLastName: string;
}

export class UserContemporaneousResultsRequest {
	PolicyId: number;
	Period: string;
	PeriodType: PeriodKind;

	static create(PolicyId: number, Period: string, PeriodType: PeriodKind) {
		let usersContemporaneousResultsRequest = new UserContemporaneousResultsRequest();
		usersContemporaneousResultsRequest.PolicyId = PolicyId;
		usersContemporaneousResultsRequest.Period = Period;
		usersContemporaneousResultsRequest.PeriodType = PeriodType;

		return usersContemporaneousResultsRequest;
	}
}

export class UserContemporaneousResultDTO {
	ContemporaneousHoursTotalPercent: number;
	ContemporaneousCardsCountPercent: number;
	UserId: number;
	UserEmail: string;
	UserFirstName: string;
	UserLastName: string;
}

class PolicyDTO {
	PolicyId: number;
	PolicyVersion: number;
	EngineVersion: number;
	PolicyName: string;
	Velocity: VelocityDTO;
	MinMaxHours: MinMaxDTO;
	MinMaxHoursDaily: MinMaxDTO;
	MinMaxHoursWeekly: MinMaxDTO;
	Assistant: AssistantDTO;
	Deadlines: DeadLineDTO;
	DeadlinesWeekly: DeadLineDTO;
	iTKUsage: iTimeKeepUsageDTO;
	static createFrom(policy: any) {
		let policyDTO = new PolicyDTO();
		policyDTO.PolicyId = policy.id;
		policyDTO.PolicyVersion = policy.version;
		policyDTO.EngineVersion = policy.engineVersion;
		policyDTO.PolicyName = policy.name;
		policyDTO.Velocity = VelocityDTO.createFrom(policy.velocity);
		policyDTO.MinMaxHours = MinMaxDTO.createFrom(policy.minMaxHours);
		policyDTO.MinMaxHoursDaily = MinMaxDTO.createFrom(policy.minMaxHoursDaily);
		policyDTO.MinMaxHoursWeekly = MinMaxDTO.createFrom(policy.minMaxHoursWeekly);
		policyDTO.Assistant = AssistantDTO.createFrom(policy.assistant);
		policyDTO.Deadlines = DeadLineDTO.createFrom(policy.deadlines);
		policyDTO.DeadlinesWeekly = DeadLineDTO.createFrom(policy.deadlinesWeekly);
		policyDTO.iTKUsage = iTimeKeepUsageDTO.createFrom(policy.itkUsage);

		return policyDTO;
	}
}

class VelocityDTO {
	Disabled: boolean;
	Rewards: ComplianceLevelRewardDTO[];
	Notifications: PolicyNotificationsDTO;
	AverageVelocity: number;
	AverageVelocityRockstar: number;
	static createFrom(vc: any) {
		let vcDTO = new VelocityDTO();
		vcDTO.Disabled = vc.disabled;
		vcDTO.Rewards = vc.rewards.map(ComplianceLevelRewardDTO.createFrom);
		vcDTO.Notifications = PolicyNotificationsDTO.createFrom();
		vcDTO.AverageVelocity = vc.goal;
		vcDTO.AverageVelocityRockstar = vc._goalRockstar;

		return vcDTO;
	}
}

class MinMaxDTO {
	Disabled: boolean;
	Rewards: ComplianceLevelRewardDTO[];
	Notifications: PolicyNotificationsDTO;
	MinHours: number;
	MaxHours: number;
	MinHoursList: Nullable<number[]>;
	UseBillableForEvaluation: boolean;
	static createFrom(mmc: any) {
		let mmcDTO = new MinMaxDTO();
		mmcDTO.Disabled = mmc.disabled;
		mmcDTO.Rewards = mmc.rewards.map(ComplianceLevelRewardDTO.createFrom);
		mmcDTO.Notifications = PolicyNotificationsDTO.createFrom();
		mmcDTO.MinHours = mmc.minHours;
		mmcDTO.MaxHours = mmc.maxHours;
		mmcDTO.MinHoursList = mmc.minHoursMonthList.length == 0 ? null : mmc.minHoursMonthList;
		mmcDTO.UseBillableForEvaluation = mmc.useBillableForEvaluation;

		return mmcDTO;
	}
}

class AssistantDTO {
	Disabled: boolean;
	Rewards: ComplianceLevelRewardDTO[];
	Notifications: PolicyNotificationsDTO;
	MaxAssistantPercentage: number;
	MaxAssistantPercentageRockstar: number;
	static createFrom(ac: any) {
		let acDTO = new AssistantDTO();
		acDTO.Disabled = ac.disabled;
		acDTO.Rewards = ac.rewards.map(ComplianceLevelRewardDTO.createFrom);
		acDTO.Notifications = PolicyNotificationsDTO.createFrom();
		acDTO.MaxAssistantPercentage = ac.maxAssistantPercentage;
		acDTO.MaxAssistantPercentageRockstar = ac.maxAssistantPercentageRockstar;

		return acDTO;
	}
}

class DeadLineDTO {
	Disabled: boolean;
	Rewards: ComplianceLevelRewardDTO[];
	Notifications: PolicyNotificationsDTO;
	GraceDays: Nullable<number[]>;
	static createFrom(dlc: any) {
		let dlcDTO = new DeadLineDTO();
		dlcDTO.Disabled = dlc.disabled;
		dlcDTO.Rewards = dlc.rewards.map(ComplianceLevelRewardDTO.createFrom);
		dlcDTO.Notifications = PolicyNotificationsDTO.createFrom();
		dlcDTO.GraceDays = dlc.graceDays.length == 0 ? null : dlc.graceDays;

		return dlcDTO;
	}
}

class iTimeKeepUsageDTO {
	Disabled: boolean;
	Rewards: ComplianceLevelRewardDTO[];
	Notifications: PolicyNotificationsDTO;
	MinimumPercentageTimecards: number;

	static createFrom(iuc: any) {
		let iucDTO = new iTimeKeepUsageDTO();
		iucDTO.Disabled = iuc.disabled;
		iucDTO.Rewards = iuc.rewards.map(ComplianceLevelRewardDTO.createFrom);
		iucDTO.Notifications = PolicyNotificationsDTO.createFrom();
		iucDTO.MinimumPercentageTimecards = iuc.minimumPercentageTimecards;

		return iucDTO;
	}
}

class ComplianceLevelRewardDTO {
	LevelName: string;
	Level: number;
	Reward: ComplianceRewardDTO;

	static createFrom(clr: any) {
		let clrDTO = new ComplianceLevelRewardDTO();
		clrDTO.LevelName = clr.levelName;
		clrDTO.Level = clr.level;
		clrDTO.Reward = ComplianceRewardDTO.createFrom(clr.reward);

		return clrDTO;
	}
}

class PolicyNotificationsDTO {
	static createFrom() {
		let pnDTO = new PolicyNotificationsDTO();
		return pnDTO;
	}
}

class ComplianceRewardDTO {
	Cash: number;
	NonCash: string[];
	static createFrom(cr: any) {
		let crDTO = new ComplianceRewardDTO();
		crDTO.Cash = cr.cash;
		crDTO.NonCash = cr.nonCash;

		return crDTO;
	}
}

export class SetCampaignsEnabledStatusRequest {
	PolicyId: number;
	PropertyName: string;
	Enabled: boolean;

	static create(policyId: number, propertyName: string, enabled: boolean) {
		let setCampaignsEnabledStatusRequest = new SetCampaignsEnabledStatusRequest();
		setCampaignsEnabledStatusRequest.PolicyId = policyId;
		setCampaignsEnabledStatusRequest.PropertyName = propertyName;
		setCampaignsEnabledStatusRequest.Enabled = enabled;

		return setCampaignsEnabledStatusRequest;
	}
}

type EngagementProperty = {
	Key: string;
	Value: boolean;
};

export class SetEngagementPropertiesRequest {
	PolicyId: number;
	Campaigns: EngagementProperty[];
}

export class EngagementPropertiesDTO {
	'TimeTelligence.Timekeeper.WeeklySummary': boolean;
	'TimeTelligence.Timekeeper.MonthlySummary': boolean;
	'iTK.Thrive.ThriveBrazeCampaigns.DailyReminder': boolean;
	'iTK.Thrive.ThriveBrazeCampaigns.WeeklyReminder': boolean;
}

export class PropertyDTO {
	PropertyName: string;
	PropertyValue: string;
	PropertyLevel: number;
}

export type SetPropertyDTO = {
	ApplySettings: boolean;
	Settings: {
		AllowOverride: boolean;
		MinRoleLevelForAccess: number;
	};
	Info: {
		PropertyName: string;
		PropertyValue: string;
		PropertyLevel: number;
	};
};

export class PolicyMemberDTO {
	UserId: number;
	UserName: string;
	FirstName: string;
	LastName: string;
}

export class SetOrRemovePolicyUserOrOwnerRequestDTO {
	PolicyId: number;
	UserId: number;

	static create = (policyId: number, userId: number) => {
		let setOrRemovePolicyUserOrOwnerRequest = new SetOrRemovePolicyUserOrOwnerRequestDTO();
		setOrRemovePolicyUserOrOwnerRequest.PolicyId = policyId;
		setOrRemovePolicyUserOrOwnerRequest.UserId = userId;

		return setOrRemovePolicyUserOrOwnerRequest;
	};
}

export class UsersResponseDTO {
	AvailableUsers: PolicyMemberDTO[];
	AssignedUsers: PolicyMemberDTO[];
}

export class OwnersResponseDTO {
	AvailableOwners: PolicyMemberDTO[];
	AssignedOwners: PolicyMemberDTO[];
}
