import {Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ComplianceHistory} from '../../core/Models';
import {PeriodKind} from '../../core/ModelContracts';
import {ComplianceRepository} from '../repositories/ComplianceRepository';

export class ComplianceHistoryService {
	private _complianceHistories: ComplianceHistory[] = [];
	private _complianceHistoriesStream: Subject<ComplianceHistory[]> = new Subject();

	constructor(private repo: ComplianceRepository) {}

	initialRequest = () => this.requestComplianceHistory('M');

	requestComplianceHistory = (periodKind: PeriodKind) =>
		this.repo.getAllByPeriod(periodKind).pipe(tap(this.notifyComplianceHistoriesUpdated));

	private notifyComplianceHistoriesUpdated = (complianceHistories: ComplianceHistory[]) => {
		this._complianceHistories = complianceHistories;
		this._complianceHistoriesStream.next(complianceHistories);
	};

	get complianceHistories() {
		return this._complianceHistories;
	}

	get complianceHistoriesStream() {
		return this._complianceHistoriesStream;
	}
}
