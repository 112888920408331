import {InteractorBase} from '../shared/InteractorBase';
import {INotificationService} from '../../appServices/INotificationService';
import {UsersGoalService} from '../../../core/services/UsersGoalsService';
import {PeriodsService} from '../../appServices/PeriodsService';
import {PolicyService} from '../../appServices/PolicyService';
import {Moment} from 'moment';
import * as moment from 'moment';
import {mergeMap, tap} from 'rxjs/operators';
import {INavigationService} from '../../appServices/INavigationService';
import {PeriodKind} from '../../../core/ModelContracts';
import {DatesFormatter} from '../../../core/datesFormatter';
import {ExcelUsersGoalService} from '../../appServices/ExportToExcelService';

type Column = {
	Header: string;
	accessor: string;
	sortMethod?: (a: number, b: number) => number;
};

export class WorkHoursOverviewInteractor extends InteractorBase {
	private readonly DATE_FORMAT = 'YYYY-MM-DD';
	private readonly _usersGoalsPeriodKinds: PeriodKind[] = ['M', 'W', 'D'];
	private readonly initialPeriodKind: PeriodKind = 'M';
	private _currentPeriodKind: PeriodKind = this.initialPeriodKind;
	private _currentPeriodForPolicy: Moment;
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private usersGoalService: UsersGoalService,
		private periodService: PeriodsService,
		private policyService: PolicyService
	) {
		super(navigationService, notificationService);
		this.usersGoalService.usersGoalStream
			.pipe(tap(this.updateCurrentPeriodForPolicy), tap(this.notificationService.hideLoader))
			.subscribe(this.updateView);

		this.policyService.currentPolicyChangedStream
			.pipe(tap(this.resetCurrentperiod), tap(this.notificationService.showLoader))
			.subscribe();

		this._currentPeriodForPolicy = this.periodService.workHoursOverviewCurrentPeriod;
	}

	private resetCurrentperiod = () => (this._currentPeriodKind = this.initialPeriodKind);

	updateCurrentPeriodForPolicy = () =>
		(this._currentPeriodForPolicy = this.periodService.workHoursOverviewCurrentPeriod);

	onDatePickerPeriodChange = (date: Date) => {
		if (!moment(date).isValid()) {
			return;
		}

		this.periodService.workHoursOverviewCurrentPeriod = moment(date);
		this.notificationService.showLoader();
		this.usersGoalService.requestUsersGoal(this._currentPeriodKind).subscribe();
	};

	onMonthlyOrWeeklyPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentPeriodForPolicy = moment(e.target.value);
		this.periodService.workHoursOverviewCurrentPeriod = this._currentPeriodForPolicy;
		this.notificationService.showLoader();
		this.usersGoalService.requestUsersGoal(this._currentPeriodKind).subscribe();
	};

	onPeriodKindChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this._currentPeriodKind = e.target.value as PeriodKind;
		this.notificationService.showLoader();
		this.periodService
			.getUserGoalsPeriodsForPolicy(this._currentPeriodKind)
			.pipe(
				tap(this.periodService.initializeWorkHoursOverviewPeriod),
				tap(this.updateCurrentPeriodForPolicy),
				mergeMap(() => this.usersGoalService.requestUsersGoal(this._currentPeriodKind))
			)
			.subscribe();
	};

	formatPeriod(date: string) {
		return DatesFormatter.formatDateByPeriodKind(date, this._currentPeriodKind);
	}

	onExportUsersGoalToExcel = () => {
		ExcelUsersGoalService.generateFile(this.formatPeriod(this.currentPeriodForPolicy), this.usersGoal);
	};

	private customSortFn = (x: number, y: number): number => x - y;

	get usersGoal() {
		return this.usersGoalService.usersGoal;
	}

	get workHoursOverviewCurrentPeriod() {
		return this.periodService.workHoursOverviewCurrentPeriod;
	}

	get workHoursOverviewPeriodsForPolicy() {
		return this.periodService.workHoursOverviewPeriodsForPolicy.map((period) => period.format(this.DATE_FORMAT));
	}

	get usersGoalPeriodKinds() {
		return this._usersGoalsPeriodKinds;
	}

	get currentPeriodKind() {
		return this._currentPeriodKind;
	}

	get currentPeriodForPolicy() {
		return this._currentPeriodForPolicy.format(this.DATE_FORMAT);
	}

	get columns(): Column[] {
		return [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: 'Hours',
				accessor: 'hours',
				sortMethod: this.customSortFn,
			},
			{
				Header: 'Goal',
				accessor: 'goal',
				sortMethod: this.customSortFn,
			},
			{
				Header: 'Difference',
				accessor: 'difference',
				sortMethod: this.customSortFn,
			},
		];
	}
}
