import * as React from 'react';
import {WrapperReactState} from '../../shared/WrapperReactState';
import {InteractorFactory} from '../../../factories/InteractorFactory';
import {LoadingInput} from './LoadingInputComponent';
import {PolicyEditorNameInteractor, GlobalForm} from './PolicyEditorNameInteractor';

export const PolicyEditorNameComponent = () => {
	const PolicyEditorName = (props: {interactor: PolicyEditorNameInteractor}) => {
		return (
			<div>
				<div>
					<label>Policy Name</label>

					<input
						type="text"
						value={props.interactor.policy.name}
						name={GlobalForm.PolicyName}
						onChange={props.interactor.onChangePolicyName}
					/>
				</div>
			</div>
		);
	};

	return (
		<WrapperReactState
			interactor={InteractorFactory.getPolicyEditorNameInteractor()}
			functionComponent={PolicyEditorName}
		/>
	);
};
