import * as React from 'react';
import {WrapperReactState} from '../WrapperReactState';
import {InteractorFactory} from '../../../factories/InteractorFactory';
import {ModalInteractor} from './ModalInteractor';
import {last} from 'lodash';

export const ModalComponentWrapped = () => (
	<WrapperReactState interactor={InteractorFactory.getModalInteractor()} functionComponent={ModalWrapper} />
);

const ModalWrapper = (props: {interactor: ModalInteractor}) => {
	const modals = props.interactor.modals.map((element, i) => (
		<ModalItem key={i} onClose={props.interactor.closeModal}>
			{element}
		</ModalItem>
	));

	const modalWrapper = props.interactor.shouldShowWrapper() && <div className={'modal-wrapper'}>{last(modals)}</div>;

	if (props.interactor.shouldShowWrapper()) {
		document.body.classList.add('body-overflow-hidden');
	} else {
		document.body.classList.remove('body-overflow-hidden');
	}

	return modalWrapper;
};

const ModalItem = (props: {children; onClose: () => void}) => {
	const modalRef = React.useRef(null);

	React.useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const handleClickOutside = (e) => {
		if (!modalRef.current.contains(e.target)) {
			props.onClose();
		}
	};

	return (
		<div className={'modal'} ref={modalRef} onClick={handleClickOutside}>
			<i className="icon-icon-close" onClick={props.onClose} />

			<div className={'content'}>{props.children}</div>
		</div>
	);
};
export const OkComponent = (message: string, ok: () => void) => {
	return (
		<div>
			<div>
				{message}
				<br />
			</div>
			<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<button className="btn btn-primary" onClick={ok}>
					OK
				</button>
			</div>
		</div>
	);
};
