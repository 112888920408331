import {ComplianceHistoryService} from '../../core/services/ComplianceHistoryService';
import {ContemporaneousHistoryService} from '../../core/services/ContemporaneousHistoryService';
import {DashboardComparisonsService} from '../../core/services/DashboardComparisonsService';
import {TimescoreService} from '../../core/services/TimescoreService';
import {VelocityHistoryService} from '../../core/services/VelocityHistoryService';
import {PeriodsService} from '../appServices/PeriodsService';
import {PolicyService} from '../appServices/PolicyService';
import {ModalService} from '../appServices/ModalService';
import {NavigationService} from '../appServices/NavigationService';
import {UsersComplianceResultsService} from '../../core/services/UsersComplianceResultsService';
import {UsersGoalService} from '../../core/services/UsersGoalsService';
import {UsersContemporaneousResultsService} from '../../core/services/UsersContemporaneousResultsService';
import {RepositoryFactory} from './RepositoryFactory';
import {PolicyEditorService} from '../../core/services/PolicyEditorService';
import {INotificationService, NotificationService} from '../appServices/INotificationService';
import {PropertiesService} from '../../core/services/propertiesService';
import {AuthenticationService} from '../appServices/AuthenticationService';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyEditorUsersService} from '../../core/services/policyEditorUsersService';

export class ServiceFactory {
	private static velocityHistoryService: VelocityHistoryService;
	private static contemporaneousHistoryService: ContemporaneousHistoryService;
	private static complianceHistoryService: ComplianceHistoryService;
	private static dashboardComparisonsService: DashboardComparisonsService;
	private static policyService: PolicyService;
	private static policyServiceHr: PolicyService;
	private static periodsService: PeriodsService;
	private static periodsServiceHr: PeriodsService;
	private static timescoreService: TimescoreService;
	private static usersComplianceResultsService: UsersComplianceResultsService;
	private static usersComplianceResultsServiceForHr: UsersComplianceResultsService;
	private static usersContemporaneousResultsService: UsersContemporaneousResultsService;
	private static usersGoalService: UsersGoalService;
	private static modalService: ModalService;
	private static navigationService: NavigationService;
	private static notificationService: NotificationService;
	private static policyEditorService: PolicyEditorService;
	private static policyEditorUsersService: PolicyEditorUsersService;
	private static propertiesService: PropertiesService;
	private static authenticationService: AuthenticationService;

	static getVelocityHistoryService = () => {
		if (ServiceFactory.velocityHistoryService == null)
			ServiceFactory.velocityHistoryService = new VelocityHistoryService(
				RepositoryFactory.getVelocityRepository()
			);

		return ServiceFactory.velocityHistoryService;
	};

	static getContemporaneousHistoryService = () => {
		if (ServiceFactory.contemporaneousHistoryService == null)
			ServiceFactory.contemporaneousHistoryService = new ContemporaneousHistoryService(
				RepositoryFactory.getContemporaneousRepository()
			);

		return ServiceFactory.contemporaneousHistoryService;
	};

	static getComplianceHistoryService = () => {
		if (ServiceFactory.complianceHistoryService == null)
			ServiceFactory.complianceHistoryService = new ComplianceHistoryService(
				RepositoryFactory.getComplianceRepository()
			);

		return ServiceFactory.complianceHistoryService;
	};

	static getPolicyService = () => {
		if (ServiceFactory.policyService == null)
			ServiceFactory.policyService = new PolicyService(
				RepositoryFactory.getPolicyRepository(),
				RepositoryFactory.getPolicyEditorRepository(),
				ServiceFactory.getNavigationService()
			);

		return ServiceFactory.policyService;
	};

	static getPolicyServiceHr = () => {
		if (ServiceFactory.policyServiceHr == null)
			ServiceFactory.policyServiceHr = new PolicyService(
				RepositoryFactory.getPolicyRepositoryForHr(),
				RepositoryFactory.getPolicyEditorRepository(),
				ServiceFactory.getNavigationService()
			);

		return ServiceFactory.policyServiceHr;
	};

	static getPeriodsService = () => {
		if (ServiceFactory.periodsService == null)
			ServiceFactory.periodsService = new PeriodsService(RepositoryFactory.getPeriodsRepository());

		return ServiceFactory.periodsService;
	};

	static getPeriodsServiceHr = () => {
		if (ServiceFactory.periodsServiceHr == null)
			ServiceFactory.periodsServiceHr = new PeriodsService(RepositoryFactory.getPeriodsRepositoryForHr());

		return ServiceFactory.periodsServiceHr;
	};

	static getDashboarComparisonsService = () => {
		if (ServiceFactory.dashboardComparisonsService == null)
			ServiceFactory.dashboardComparisonsService = new DashboardComparisonsService(
				RepositoryFactory.getDashboardRepository()
			);

		return ServiceFactory.dashboardComparisonsService;
	};

	static getTimescoreService = () => {
		if (ServiceFactory.timescoreService == null)
			ServiceFactory.timescoreService = new TimescoreService(RepositoryFactory.getTimescoreRepository());

		return ServiceFactory.timescoreService;
	};

	static getUsersComplianceResultsService = () => {
		if (ServiceFactory.usersComplianceResultsService == null)
			ServiceFactory.usersComplianceResultsService = new UsersComplianceResultsService(
				RepositoryFactory.getUsersComplianceRepository()
			);

		return ServiceFactory.usersComplianceResultsService;
	};

	static getUsersComplianceResultsServiceForHr = () => {
		if (ServiceFactory.usersComplianceResultsServiceForHr == null)
			ServiceFactory.usersComplianceResultsServiceForHr = new UsersComplianceResultsService(
				RepositoryFactory.getUsersComplianceRepositoryForHr()
			);

		return ServiceFactory.usersComplianceResultsServiceForHr;
	};

	static getUsersContemporaneousResultsService = () => {
		if (ServiceFactory.usersContemporaneousResultsService == null)
			ServiceFactory.usersContemporaneousResultsService = new UsersContemporaneousResultsService(
				RepositoryFactory.getUsersContemporaneousRepository()
			);

		return ServiceFactory.usersContemporaneousResultsService;
	};

	static getUsersGoalService = () => {
		if (ServiceFactory.usersGoalService == null)
			ServiceFactory.usersGoalService = new UsersGoalService(RepositoryFactory.getUsersGoalRepository());

		return ServiceFactory.usersGoalService;
	};

	static getModalService = () => {
		if (ServiceFactory.modalService == null) ServiceFactory.modalService = new ModalService();

		return ServiceFactory.modalService;
	};

	static getNavigationService = () => {
		if (ServiceFactory.navigationService == null)
			ServiceFactory.navigationService = new NavigationService(ServiceFactory.getModalService());

		return ServiceFactory.navigationService;
	};

	static getNotificationService = () => {
		if (ServiceFactory.notificationService == null) ServiceFactory.notificationService = new NotificationService();

		return ServiceFactory.notificationService;
	};

	static getPolicyEditorService = () => {
		if (ServiceFactory.policyEditorService == null)
			ServiceFactory.policyEditorService = new PolicyEditorService(RepositoryFactory.getPolicyEditorRepository());

		return ServiceFactory.policyEditorService;
	};

	static getPolicyEditorUsersService = () => {
		if (ServiceFactory.policyEditorUsersService == null)
			ServiceFactory.policyEditorUsersService = new PolicyEditorUsersService(
				RepositoryFactory.getPolicyEditorUsersRepository()
			);

		return ServiceFactory.policyEditorUsersService;
	};

	static getPropertiesService = () => {
		if (ServiceFactory.propertiesService == null)
			ServiceFactory.propertiesService = new PropertiesService(RepositoryFactory.getPropertiesRepository());

		return ServiceFactory.propertiesService;
	};

	static getAuthenticationService = () => {
		if (ServiceFactory.authenticationService == null)
			ServiceFactory.authenticationService = new AuthenticationService(
				ServiceFactory.getNavigationService(),
				RepositoryFactory.environment,
				RequestWrapper.create()
			);

		return ServiceFactory.authenticationService;
	};
}
