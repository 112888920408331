import {Nullable} from '../Models';
import {TimescoreRangeByGrade} from '../models/Policy';

export type PolicyDTO = {
	PolicyId: number;
	PolicyVersion: number;
	EngineVersion: number;
	PolicyName: string;
	FiscalYearStartMonth: number;
	IncludeGranularity: boolean;
	Timescores: TimescoreRangeByGradeDTO[];
	Velocity: VelocityDTO;
	MinMaxHours: MinMaxDTO;
	MinMaxHoursDaily: MinMaxDTO;
	MinMaxHoursWeekly: MinMaxDTO;
	Assistant: AssistantDTO;
	Deadlines: DeadLineDTO;
	DeadlinesWeekly: DeadLineDTO;
	iTKUsage: iTimeKeepUsageDTO;
};

export type VelocityDTO = SharedPolicyPropertiesDTO & {
	AverageVelocity: number;
	AverageVelocityRockstar: number;
};

export type MinMaxDTO = SharedPolicyPropertiesDTO & {
	MinHours: number;
	MaxHours: number;
	MinHoursList: Nullable<number[]>;
	UseBillableForEvaluation: boolean;
};

export type AssistantDTO = SharedPolicyPropertiesDTO & {
	MaxAssistantPercentage: number;
	MaxAssistantPercentageRockstar: number;
};

export type DeadLineDTO = SharedPolicyPropertiesDTO & {
	GraceDays: Nullable<number[]>;
};

export type iTimeKeepUsageDTO = SharedPolicyPropertiesDTO & {
	MinimumPercentageTimecards: number;
};

export type SharedPolicyPropertiesDTO = {
	Disabled: boolean;
	Rewards: ComplianceLevelRewardDTO[];
	Notifications: PolicyNotificationsDTO;
};

export type ComplianceLevelRewardDTO = {
	LevelName: string;
	Level: number;
	Reward: ComplianceRewardDTO;
};

export type ComplianceRewardDTO = {
	Cash: number;
	NonCash: string[];
};

export type PolicyNotificationsDTO = {};

export class TimescoreRangeByGradeDTO {
	Grade: string;
	Limit: number;
	static createFrom(trbg: TimescoreRangeByGrade) {
		let trbgDTO = new TimescoreRangeByGradeDTO();
		trbgDTO.Grade = trbg.grade;
		trbgDTO.Limit = trbg.limit;

		return trbgDTO;
	}
}
