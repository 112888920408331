import {Property} from '../Models';

export class PropertiesMapper {
	static fromPropertyToDto(property: Property) {
		return {
			ApplySettings: false,
			Settings: {
				AllowOverride: true,
				MinRoleLevelForAccess: 3999,
			},
			Info: {
				PropertyName: property.name,
				PropertyValue: property.value,
				PropertyLevel: property.level,
			},
		};
	}

	static fromPropertiesToDto(properties: Property[]) {
		return properties.map(PropertiesMapper.fromPropertyToDto);
	}
}
