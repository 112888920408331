import {UsersContemporaneousRepository} from '../../core/repositories/UsersContemporaneousRepository';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyService} from '../appServices/PolicyService';
import {
	PeriodKind,
	CallContext,
	ServiceRequest,
	ServiceResponse,
	UserContemporaneousResultsRequest,
	UserContemporaneousResultDTO,
} from '../../core/ModelContracts';
import {map} from 'rxjs/operators';
import {UserContemporaneousResult} from '../../core/Models';
import {UrlBuilder, BackendRoutes, Environment} from '../Routes';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class UsersContemporaneousApiRepository implements UsersContemporaneousRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private authenticationService: AuthenticationService
	) {}

	getAllByWithPeriod = (periodPolicy: string, periodType: PeriodKind) =>
		this.requestUserContemporaneousWithPeriod(periodPolicy, periodType).pipe(
			map((response) => response.Response),
			map((ucrDTOs) => ucrDTOs.map((ucrDTO) => UserContemporaneousResult.createFrom(ucrDTO)))
		);

	requestUserContemporaneousWithPeriod = (periodPolicy: string, periodType: PeriodKind) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const usersContemporaneousResultsUrl = urlBuilder.getBackendUrl(BackendRoutes.userscontemporaneousresults);
		const callContext = this.authenticationService.callContext;

		const request = UserContemporaneousResultsRequest.create(
			this.policyService.currentPolicyId,
			periodPolicy,
			periodType
		);
		const serviceRequest = ServiceRequest.create<UserContemporaneousResultsRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<UserContemporaneousResultDTO[]>>(
			usersContemporaneousResultsUrl,
			'POST',
			serviceRequest
		);
	};
}
