import {
	AssistantDTO,
	ComplianceLevelRewardDTO,
	ComplianceRewardDTO,
	DeadLineDTO,
	iTimeKeepUsageDTO,
	MinMaxDTO,
	PolicyDTO,
	TimescoreRangeByGradeDTO,
	VelocityDTO,
} from '../modelContracts/PolicyDto';
import {PolicyForSubscriptionDTO} from '../ModelContracts';
import {first} from 'lodash';

export class PolicyReader {
	constructor(public readonly policyId: number, public readonly policyName: string) {}

	static createFrom(pfsDTO: PolicyForSubscriptionDTO) {
		let pfs = new PolicyReader(pfsDTO.PolicyId, pfsDTO.PolicyName);

		return pfs;
	}

	static createFromPolicy(policy: Policy) {
		let pfs = new PolicyReader(policy.id, policy.name);
		return pfs;
	}
}

export class Policy {
	id: number;
	private _includeGranularity: boolean;
	private _timescores: TimescoreRangeByGrade[];
	private _isEnabled: boolean = false;
	private _brazeDailyEnabled: boolean;
	private _brazeWeeklyEnabled: boolean;
	private _timetelligenceWeeklyEnabled: boolean;
	private _timetelligenceMonthlyEnabled: boolean;
	version: number;
	engineVersion: number;
	private _name: string;
	private _initialMonth: number;
	velocity: Velocity;
	minMaxHours: MinMax; //For now it only uses {minHoursList: number[]};
	minMaxHoursDaily: MinMax; //For now it only uses {minHours: number; maxHours: number;};
	minMaxHoursWeekly: MinMax; //For now it only uses {minHours: number; maxHours: number;};
	assistant: Assistant;
	deadlines: DeadLine;
	deadlinesWeekly: DeadLine;
	itkUsage: ItkUsage;

	get name() {
		return this._name;
	}

	setName = (name: string) => (this._name = name);

	get initialMonth() {
		return this._initialMonth;
	}

	setInitialMonth = (initialMonth: number) => (this._initialMonth = initialMonth);

	get isEnabled() {
		return this._isEnabled;
	}

	setIsEnabled = (isEnable: boolean) => (this._isEnabled = isEnable);

	get brazeDailyEnabled() {
		return this._brazeDailyEnabled;
	}

	setBrazeDailyEnabled = (isEnable: boolean) => (this._brazeDailyEnabled = isEnable);

	get brazeWeeklyEnabled() {
		return this._brazeWeeklyEnabled;
	}

	setBrazeWeeklyEnabled = (isEnable: boolean) => (this._brazeWeeklyEnabled = isEnable);

	get timetelligenceWeeklyEnabled() {
		return this._timetelligenceWeeklyEnabled;
	}

	setTimetelligenceWeeklyEnabled = (isEnable: boolean) => (this._timetelligenceWeeklyEnabled = isEnable);

	get timetelligenceMonthlyEnabled() {
		return this._timetelligenceMonthlyEnabled;
	}

	setTimetelligenceMonthlyEnabled = (isEnable: boolean) => (this._timetelligenceMonthlyEnabled = isEnable);

	get timescores() {
		return this._timescores;
	}

	setTimescores = (timescores: TimescoreRangeByGrade[]) => {
		this._timescores = timescores;
	};

	get includeGranularity() {
		return this._includeGranularity;
	}

	setIncludeGranularity(isEnabled: boolean) {
		this._includeGranularity = isEnabled;
	}

	static createFrom(policyDTO: PolicyDTO) {
		if (policyDTO == null) return new Policy();

		let policy = new Policy();
		policy.id = policyDTO.PolicyId;
		policy.version = policyDTO.PolicyVersion;
		policy.engineVersion = policyDTO.EngineVersion;
		policy._includeGranularity = policyDTO.IncludeGranularity;
		policy._timescores = policyDTO.Timescores.map(TimescoreRangeByGrade.createFrom);
		policy._name = policyDTO.PolicyName;
		policy._initialMonth = policyDTO.FiscalYearStartMonth;
		policy.velocity = Velocity.createFrom(policyDTO.Velocity);
		policy.minMaxHours = MinMax.createFromMonthly(policyDTO.MinMaxHours);
		policy.minMaxHoursDaily = MinMax.createFromDailyOrWeekly(policyDTO.MinMaxHoursDaily);
		policy.minMaxHoursWeekly = MinMax.createFromDailyOrWeekly(policyDTO.MinMaxHoursWeekly);
		policy.assistant = Assistant.createFrom(policyDTO.Assistant);
		policy.deadlines = DeadLine.createFromMonthly(policyDTO.Deadlines);
		policy.deadlinesWeekly = DeadLine.createFromWeekly(policyDTO.DeadlinesWeekly);
		policy.itkUsage = ItkUsage.createFrom(policyDTO.iTKUsage);

		return policy;
	}

	static createDefault(name: string) {
		let policy = new Policy();

		policy.setName(name);
		policy.id = 0;
		policy.version = 1;
		policy.engineVersion = 1;
		policy._initialMonth = 1;
		policy._includeGranularity = true;
		policy._timescores = [
			{
				grade: 'A',
				limit: 0.2,
			},
			{
				grade: 'B',
				limit: 1,
			},
			{
				grade: 'C',
				limit: 3,
			},
			{
				grade: 'D',
				limit: 7,
			},
		];
		policy.velocity = Velocity.createDefault();
		policy.minMaxHours = MinMax.createDefaultForMonthly();
		policy.minMaxHoursDaily = MinMax.createDefaultFromDailyOrWeekly(8, 8);
		policy.minMaxHoursWeekly = MinMax.createDefaultFromDailyOrWeekly(40, 40);
		policy.assistant = Assistant.createDefault();
		policy.deadlines = DeadLine.createDefaultForMonthly();
		policy.deadlinesWeekly = DeadLine.createDefaultForWeekly();
		policy.itkUsage = ItkUsage.createDefault();

		return policy;
	}
}

export abstract class SharedPolicyProperties {
	protected _disabled: boolean = false;
	rewards: ComplianceLevelReward[] = [];
	notifications: PolicyNotifications = {};

	get disabled() {
		return this._disabled;
	}

	setDisabled(value: boolean) {
		this._disabled = value;
	}
}

export class Velocity extends SharedPolicyProperties {
	private _goal: number = 0;
	private _goalRockstar: number = 0;

	get goal() {
		return this._goal;
	}

	setGoal(goal: number) {
		this._goal = goal;
	}

	get goalRockstar() {
		return this._goalRockstar;
	}

	getNotCompliance = () => first(this.rewards.filter((r) => r.level === ComplianceLevel.NotCompliance))?.reward?.cash;

	getCompliance = () => first(this.rewards.filter((r) => r.level === ComplianceLevel.Compliance))?.reward?.cash;

	getRockstar = () => first(this.rewards.filter((r) => r.level === ComplianceLevel.RockStar))?.reward?.cash;

	getDisable = () => this.disabled;

	setRewardsNotCompliance = (notComplianceCash: number) => {
		this.rewards.forEach((r) => {
			if (r.level === ComplianceLevel.NotCompliance) r.reward.cash = notComplianceCash;
		});
	};

	setRewardsCompliance = (complianceCash: number) => {
		this.rewards.forEach((r) => {
			if (r.level === ComplianceLevel.Compliance) r.reward.cash = complianceCash;
		});
	};

	setRewardsRockstar = (rockstarCash: number) => {
		this.rewards.forEach((r) => {
			if (r.level === ComplianceLevel.RockStar) r.reward.cash = rockstarCash;
		});
	};

	static createFrom(vcDTO: VelocityDTO) {
		if (vcDTO == null) return new Velocity();

		let vc = new Velocity();
		vc._disabled = vcDTO.Disabled;
		vc.rewards =
			vcDTO.Rewards.length > 0 ? vcDTO.Rewards.map(ComplianceLevelReward.createFrom) : this.initializeRewards();
		vc.notifications = PolicyNotifications.createFrom();
		vc._goal = vcDTO.AverageVelocity;
		vc._goalRockstar = vcDTO.AverageVelocityRockstar;

		return vc;
	}

	static createDefault() {
		let velocity = new Velocity();
		velocity.rewards = this.initializeRewards();
		velocity.notifications = PolicyNotifications.createFrom();

		return velocity;
	}

	private static initializeRewards = () => {
		let rewards = [];
		rewards[0] = ComplianceLevelReward.create(ComplianceLevelName.NotCompliance, ComplianceLevel.NotCompliance);
		rewards[1] = ComplianceLevelReward.create(ComplianceLevelName.Compliance, ComplianceLevel.Compliance);
		rewards[2] = ComplianceLevelReward.create(ComplianceLevelName.RockStar, ComplianceLevel.RockStar);

		return rewards;
	};
}

export class MinMax extends SharedPolicyProperties {
	private _minGoal: number = 0;
	private _maxGoal: number = 0;
	private _minHoursList: number[] = [];
	private _useBillableForEvaluation: boolean = false;

	get minGoal() {
		return this._minGoal;
	}

	setMinGoal = (minGoal: number) => {
		this._minGoal = minGoal;
	};

	get maxGoal() {
		return this._maxGoal;
	}

	setMaxGoal = (maxGoal: number) => {
		this._maxGoal = maxGoal;
	};

	get minHoursList() {
		return this._minHoursList;
	}

	setMinHoursList = (minHoursMonthList: number[]) => {
		this._minHoursList = minHoursMonthList;
	};

	setMinHoursElementByIndex = (index: number, value: number) => {
		this._minHoursList[index] = value;
	};

	get useBillableForEvaluation() {
		return this._useBillableForEvaluation;
	}

	setUseBillableForEvaluation = (useBillableForevaluation: boolean) => {
		this._useBillableForEvaluation = useBillableForevaluation;
	};

	getNotCompliance = () => first(this.rewards.filter((r) => r.level === ComplianceLevel.NotCompliance))?.reward?.cash;

	getCompliance = () => first(this.rewards.filter((r) => r.level === ComplianceLevel.Compliance))?.reward?.cash;

	setRewardsNotCompliance = (notComplianceCash: number) => {
		this.rewards.forEach((r) => {
			if (r.level === ComplianceLevel.NotCompliance) r.reward.cash = notComplianceCash;
		});
	};

	setRewardsCompliance = (complianceCash: number) => {
		this.rewards.forEach((r) => {
			if (r.level === ComplianceLevel.Compliance) r.reward.cash = complianceCash;
		});
	};

	static createFromMonthly(mmcDTO: MinMaxDTO) {
		if (mmcDTO == null) {
			return new MinMax();
		}

		let mmc = new MinMax();
		mmc._disabled = mmcDTO.Disabled;
		mmc.rewards =
			mmcDTO.Rewards.length > 0 ? mmcDTO.Rewards.map(ComplianceLevelReward.createFrom) : this.initializeRewards();
		mmc.notifications = PolicyNotifications.createFrom();
		mmc._minGoal = mmcDTO.MinHours;
		mmc._maxGoal = mmcDTO.MaxHours;
		mmc._minHoursList = mmcDTO.MinHoursList?.length > 0 ? mmcDTO.MinHoursList : this.initializeMonthList();
		mmc._useBillableForEvaluation = mmcDTO.UseBillableForEvaluation;

		return mmc;
	}

	static createDefaultForMonthly() {
		let mmc = new MinMax();

		mmc.rewards = this.initializeRewards();
		mmc.notifications = PolicyNotifications.createFrom();
		mmc._minHoursList = this.initializeMonthList();

		return mmc;
	}

	private static initializeMonthList = () => new Array(12).fill(0);

	static createFromDailyOrWeekly(mmcDTO: MinMaxDTO) {
		if (mmcDTO == null) {
			return new MinMax();
		}

		let mmc = new MinMax();
		mmc._disabled = mmcDTO.Disabled;
		mmc.rewards =
			mmcDTO.Rewards.length > 0 ? mmcDTO.Rewards.map(ComplianceLevelReward.createFrom) : this.initializeRewards();
		mmc.notifications = PolicyNotifications.createFrom();
		mmc._minGoal = mmcDTO.MinHours;
		mmc._maxGoal = mmcDTO.MaxHours;
		mmc._minHoursList = mmcDTO.MinHoursList ? mmcDTO.MinHoursList : [];
		mmc._useBillableForEvaluation = mmcDTO.UseBillableForEvaluation;

		return mmc;
	}

	static createDefaultFromDailyOrWeekly(minGoal: number, maxGoal: number) {
		let mmc = new MinMax();

		mmc.rewards = this.initializeRewards();
		mmc.notifications = PolicyNotifications.createFrom();
		mmc._minHoursList = [];
		mmc._minGoal = minGoal;
		mmc._maxGoal = maxGoal;

		return mmc;
	}

	private static initializeRewards = () => {
		let rewards = [];
		rewards[0] = ComplianceLevelReward.create(ComplianceLevelName.NotCompliance, ComplianceLevel.NotCompliance);
		rewards[1] = ComplianceLevelReward.create(ComplianceLevelName.Compliance, ComplianceLevel.Compliance);

		return rewards;
	};
}

export class Assistant extends SharedPolicyProperties {
	maxAssistantPercentage: number = 0;
	maxAssistantPercentageRockstar: number = 0;

	static createFrom(acDTO: AssistantDTO) {
		if (acDTO == null) {
			return new Assistant();
		}

		let ac = new Assistant();
		ac._disabled = acDTO.Disabled;
		ac.rewards = acDTO.Rewards.map(ComplianceLevelReward.createFrom);
		ac.notifications = PolicyNotifications.createFrom();
		ac.maxAssistantPercentage = acDTO.MaxAssistantPercentage;
		ac.maxAssistantPercentageRockstar = acDTO.MaxAssistantPercentageRockstar;

		return ac;
	}

	static createDefault() {
		return new Assistant();
	}
}

export class DeadLine extends SharedPolicyProperties {
	private _graceDays: number[];

	get graceDays() {
		return this._graceDays;
	}

	setGracedays = (graceDays: number[]) => {
		this._graceDays = graceDays;
	};

	setAllWeeksForYearBy = (value: number) => {
		this._graceDays = new Array(52).fill(value);
	};

	setElementGraceDaysByIndex = (index: number, value: number) => {
		this._graceDays[index] = value;
	};

	getPenaltyForDelinquentSubmission = () =>
		first(this.rewards.filter((r) => r.level === ComplianceLevel.NotCompliance))?.reward?.cash;

	getRewardsForTimelySubmission = () =>
		first(this.rewards.filter((r) => r.level === ComplianceLevel.Compliance))?.reward?.cash;

	setRewardsNotCompliance = (notComplianceCash: number) => {
		this.rewards.forEach((r) => {
			if (r.level === ComplianceLevel.NotCompliance) r.reward.cash = notComplianceCash;
		});
	};

	setRewardsCompliance = (complianceCash: number) => {
		this.rewards.forEach((r) => {
			if (r.level === ComplianceLevel.Compliance) r.reward.cash = complianceCash;
		});
	};

	setPenaltyAndReward = (notComplianceCash: number, complianceCash: number) => {
		this.rewards.forEach((r) => {
			if (r.level === ComplianceLevel.NotCompliance) r.reward.cash = notComplianceCash;

			if (r.level === ComplianceLevel.Compliance) r.reward.cash = complianceCash;
		});
	};

	static createFromMonthly(dlcDTO: DeadLineDTO) {
		if (dlcDTO == null) {
			return new DeadLine();
		}

		let dlc = new DeadLine();
		dlc._disabled = dlcDTO.Disabled;
		dlc.rewards =
			dlcDTO.Rewards.length > 0 ? dlcDTO.Rewards.map(ComplianceLevelReward.createFrom) : this.initializeRewards();
		dlc.notifications = PolicyNotifications.createFrom();
		dlc._graceDays = dlcDTO.GraceDays?.length > 0 ? dlcDTO.GraceDays : this.initializeMonthList();

		return dlc;
	}

	static createDefaultForMonthly() {
		let dlc = new DeadLine();
		dlc.rewards = this.initializeRewards();
		dlc._graceDays = this.initializeMonthList();

		return dlc;
	}

	static initializeMonthList = () => new Array(12).fill(0);

	static createFromWeekly(dlcDTO: DeadLineDTO) {
		if (dlcDTO == null) {
			return new DeadLine();
		}

		let dlc = new DeadLine();
		dlc._disabled = dlcDTO.Disabled;
		dlc.rewards =
			dlcDTO.Rewards.length > 0 ? dlcDTO.Rewards.map(ComplianceLevelReward.createFrom) : this.initializeRewards();
		dlc.notifications = PolicyNotifications.createFrom();
		dlc._graceDays = dlcDTO.GraceDays?.length > 0 ? dlcDTO.GraceDays : this.initializeWeeks();

		return dlc;
	}

	private static initializeWeeks = () => new Array(53).fill(0);

	static createDefaultForWeekly() {
		let dlc = new DeadLine();
		dlc.rewards = this.initializeRewards();
		dlc._graceDays = this.initializeWeeks();

		return dlc;
	}

	private static initializeRewards = () => {
		let rewards = [];
		rewards[0] = ComplianceLevelReward.create(ComplianceLevelName.NotCompliance, ComplianceLevel.NotCompliance);
		rewards[1] = ComplianceLevelReward.create(ComplianceLevelName.Compliance, ComplianceLevel.Compliance);

		return rewards;
	};
}

export class ItkUsage extends SharedPolicyProperties {
	minimumPercentageTimecards: number = 0;

	static createFrom(iucDTO: iTimeKeepUsageDTO) {
		if (iucDTO == null) {
			return new ItkUsage();
		}

		let iuc = new ItkUsage();
		iuc._disabled = iucDTO.Disabled;
		iuc.rewards = iucDTO.Rewards.map(ComplianceLevelReward.createFrom);
		iuc.notifications = PolicyNotifications.createFrom;
		iuc.minimumPercentageTimecards = iucDTO.MinimumPercentageTimecards;

		return iuc;
	}

	static createDefault() {
		return new ItkUsage();
	}
}

export enum ComplianceLevelName {
	NotCompliance = 'NotCompliant',
	Compliance = 'Compliant',
	RockStar = 'RockStar',
}

export enum ComplianceLevel {
	NotCompliance = 2000,
	Compliance = 3000,
	RockStar = 4000,
}

class ComplianceLevelReward {
	levelName: ComplianceLevelName;
	level: ComplianceLevel;
	reward: ComplianceReward;

	static createFrom(clrDTO: ComplianceLevelRewardDTO) {
		if (clrDTO == null) return new ComplianceLevelReward();

		let clr = new ComplianceLevelReward();
		clr.levelName = clrDTO.LevelName as ComplianceLevelName;
		clr.level = clrDTO.Level;
		clr.reward = ComplianceReward.createFrom(clrDTO.Reward);

		return clr;
	}

	static create(levelName: ComplianceLevelName, level: ComplianceLevel) {
		let clr = new ComplianceLevelReward();

		clr.levelName = levelName as ComplianceLevelName;
		clr.level = level as ComplianceLevel;
		clr.reward = ComplianceReward.create();

		return clr;
	}
}

class ComplianceReward {
	cash: number;
	nonCash: string[];

	static createFrom(crDTO: ComplianceRewardDTO) {
		if (crDTO == null) return new ComplianceReward();

		let cr = new ComplianceReward();
		cr.cash = crDTO.Cash;
		cr.nonCash = crDTO.NonCash;

		return cr;
	}

	static create() {
		let cr = new ComplianceReward();
		cr.cash = 0;
		cr.nonCash = [];

		return cr;
	}
}

class PolicyNotifications {
	static createFrom() {
		let pn = new PolicyNotifications();
		return pn;
	}
}

export class TimescoreRangeByGrade {
	grade: string;
	limit: number;
	static createFrom(trbgDTO: TimescoreRangeByGradeDTO) {
		if (trbgDTO == null) {
			trbgDTO = new TimescoreRangeByGradeDTO();
		}

		let trbg = new TimescoreRangeByGrade();
		trbg.grade = trbgDTO.Grade;
		trbg.limit = trbgDTO.Limit;

		return trbg;
	}
}

export class PolicyBuilder {
	static velocity: Velocity = {
		goal: 0,
		goalRockstar: 0,
		disabled: false,
		rewards: [
			{
				levelName: ComplianceLevelName.NotCompliance,
				level: ComplianceLevel.NotCompliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
			{
				levelName: ComplianceLevelName.Compliance,
				level: ComplianceLevel.Compliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
			{
				levelName: ComplianceLevelName.RockStar,
				level: ComplianceLevel.RockStar,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
		],
		notifications: {},
	} as Velocity;

	static minMaxHours: MinMax = {
		minGoal: 0,
		maxGoal: 0,
		minHoursList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		useBillableForEvaluation: false,
		disabled: false,
		rewards: [
			{
				levelName: ComplianceLevelName.NotCompliance,
				level: ComplianceLevel.NotCompliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
			{
				levelName: ComplianceLevelName.Compliance,
				level: ComplianceLevel.Compliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
		],
		notifications: {},
	} as MinMax;

	static minMaxHoursDaily: MinMax = {
		minGoal: 8,
		maxGoal: 8,
		minHoursList: [],
		useBillableForEvaluation: false,
		disabled: false,
		rewards: [
			{
				levelName: ComplianceLevelName.NotCompliance,
				level: ComplianceLevel.NotCompliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
			{
				levelName: ComplianceLevelName.Compliance,
				level: ComplianceLevel.Compliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
		],
		notifications: {},
	} as MinMax;

	static minMaxHoursWeekly: MinMax = {
		minGoal: 40,
		maxGoal: 40,
		minHoursList: [],
		useBillableForEvaluation: false,
		disabled: false,
		rewards: [
			{
				levelName: ComplianceLevelName.NotCompliance,
				level: ComplianceLevel.NotCompliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
			{
				levelName: ComplianceLevelName.Compliance,
				level: ComplianceLevel.Compliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
		],
		notifications: {},
	} as MinMax;

	static assistant: Assistant = {
		maxAssistantPercentage: 0,
		maxAssistantPercentageRockstar: 0,
		disabled: false,
		rewards: [],
		notifications: {},
	} as Assistant;

	static deadlines: DeadLine = {
		graceDays: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		disabled: false,
		rewards: [
			{
				levelName: ComplianceLevelName.NotCompliance,
				level: ComplianceLevel.NotCompliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
			{
				levelName: ComplianceLevelName.Compliance,
				level: ComplianceLevel.Compliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
		],
		notifications: {},
	} as DeadLine;

	static deadlinesWeekly: DeadLine = {
		graceDays: [
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
		],
		disabled: false,
		rewards: [
			{
				levelName: ComplianceLevelName.NotCompliance,
				level: ComplianceLevel.NotCompliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
			{
				levelName: ComplianceLevelName.Compliance,
				level: ComplianceLevel.Compliance,
				reward: {
					cash: 0,
					nonCash: [],
				},
			},
		],
		notifications: {},
	} as DeadLine;

	static itkUsage: ItkUsage = {
		minimumPercentageTimecards: 0,
		disabled: false,
		rewards: [],
	} as ItkUsage;

	static policy = {
		id: 0,
		version: 1,
		engineVersion: 1,
		name: '',
		velocity: PolicyBuilder.velocity,
		minMaxHours: PolicyBuilder.minMaxHours,
		minMaxHoursDaily: PolicyBuilder.minMaxHoursDaily,
		minMaxHoursWeekly: PolicyBuilder.minMaxHoursWeekly,
		assistant: PolicyBuilder.assistant,
		deadlines: PolicyBuilder.deadlines,
		deadlinesWeekly: PolicyBuilder.deadlinesWeekly,
		itkUsage: PolicyBuilder.itkUsage,
	};
}
