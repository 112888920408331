import {TimescoreRangeByGrade} from '../../../core/models/Policy';
import {PolicyEditorService} from '../../../core/services/PolicyEditorService';
import {INavigationService} from '../../appServices/INavigationService';
import {INotificationService} from '../../appServices/INotificationService';
import {PolicyService} from '../../appServices/PolicyService';
import {PolicyEditorInteractorBase, TimescoreValidator} from './shared/PolicyEditorBaseInteractor';

export class PolicyEditorTimescoreInteractor extends PolicyEditorInteractorBase {
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		policyEditorService: PolicyEditorService,
		policyService: PolicyService
	) {
		super(navigationService, notificationService, policyEditorService, policyService);
	}

	onChangeTimescore = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this._formattedTimescoreValues[index] = TimescoreParser.removeDecimals(e.target.value, 2);
		this.updateView();
	};

	onBlurTimescore = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const parsedTimescore = parseFloat(TimescoreParser.parseTimescore(e.target.value)).toFixed(2);
		this._formattedTimescoreValues[index] = parsedTimescore;
		this._temporaryTimescores[index].limit = parseFloat(parsedTimescore);
		if (TimescoreValidator.currentTimescoreIsLowerThanPrevious(this._temporaryTimescores, index)) {
			this._timescoreErrors[index] = `The max. velocity value for grade ${
				this.timescores[index].grade
			} must be higher than the max. velocity value for grade ${this.timescores[index - 1].grade}`;
			this.notificationService.error(
				`The max. velocity value for grade ${
					this.timescores[index].grade
				} must be higher than the max. velocity value for grade ${this.timescores[index - 1].grade}`
			);
		} else this._timescoreErrors[index] = '';
		if (TimescoreValidator.currentTimescoreIsGreaterThanNext(this._temporaryTimescores, index)) {
			this._timescoreErrors[index + 1] = `The max. velocity value for grade ${
				this.timescores[index + 1].grade
			} must be higher than the max. velocity value for grade ${this.timescores[index].grade}`;
		} else this._timescoreErrors[index + 1] = '';
		this.updateView();
	};

	onChangeIncludeGranularity = (e: React.ChangeEvent<HTMLInputElement>) => {
		const policy = this.policyEditorService.policy;
		policy.setIncludeGranularity(e.target.checked);
		this.updateView();
	};

	onClickError = (message: string): void => {
		this.notificationService.error(message);
	};

	onSave = () => {
		this.onSavePolicy(this.temporaryTimescores);
	};

	get timescores() {
		return this.policyEditorService.policy.timescores;
	}

	get temporaryTimescores() {
		return this._temporaryTimescores;
	}

	get temporaryTimescoreValues() {
		return this._temporaryTimescores.map((timescore) => timescore.limit);
	}

	get timescoreErrors() {
		return this._timescoreErrors;
	}

	get includeGranularity() {
		return this.policyEditorService.policy.includeGranularity;
	}

	get formattedTimescoreValues() {
		return this._formattedTimescoreValues;
	}
}

export class TimescoreParser {
	static parseTimescore = (timescore: string) => {
		const isNegative = timescore[0] === '-';
		const isEmpty = timescore === '';

		if (isEmpty) {
			return '0.00';
		}

		if (isNegative) {
			return timescore.split('-')[1];
		}

		return timescore;
	};

	static removeDecimals = (input: string, decimals: number) => {
		const dotIndex = input.indexOf('.');
		if (dotIndex == -1) {
			return input;
		}

		const chartsAfterDot = input.length - dotIndex - 1;

		if (chartsAfterDot < decimals) return input;

		return input.substring(0, dotIndex + decimals + 1);
	};
}
