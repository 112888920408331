import {InteractorBase} from '../InteractorBase';
import {NavigationService} from '../../../appServices/NavigationService';
import {NotificationService} from '../../../appServices/INotificationService';
import {PropertiesService} from '../../../../core/services/propertiesService';

export class SettingsInteractor extends InteractorBase {
	private _shouldShowSettings: boolean = false;

	constructor(
		navigationService: NavigationService,
		notificationService: NotificationService,
		private propertiesService: PropertiesService
	) {
		super(navigationService, notificationService);

		this.setTheme();
	}

	setTheme = () => {
		if (this.propertiesService.theme == 'ADERANT') {
			document.documentElement.style.setProperty('--primary', '#0782c2');
			document.documentElement.style.setProperty('--logoColor', '#0782c2');
		}
		if (this.propertiesService.theme == 'DAWN') {
			document.documentElement.style.setProperty('--primary', '#9a488a');
			document.documentElement.style.setProperty('--logoColor', 'rgb(102, 102, 102)');
		}
		if (this.propertiesService.theme == 'AQUA') {
			document.documentElement.style.setProperty('--primary', '#2db49d');
			document.documentElement.style.setProperty('--logoColor', 'rgb(102, 102, 102)');
		}
		if (this.propertiesService.theme == 'BELLEFIELD') {
			document.documentElement.style.setProperty('--primary', '#166d33');
			document.documentElement.style.setProperty('--logoColor', 'rgb(102, 102, 102)');
		}
	};
	onShowSettings = () => {
		this._shouldShowSettings = !this._shouldShowSettings;
		this.updateView();
	};

	onChangeTheme = (theme: string) => {
		this.propertiesService.setThemeAndNotify(theme);
		this.updateView();
		this.propertiesService.requestSetProperties([
			{id: '', name: 'Applications.UserTheme.ColorTheme', value: this.theme, level: 50},
		]);
		this.setTheme();
	};

	get shouldShowSettings() {
		return this._shouldShowSettings;
	}

	get theme() {
		return this.propertiesService.theme;
	}
}
