import {Subject} from 'rxjs';
import {Maybe, Nothing} from 'monet';

import {PolicyDashboardComparison} from '../Models';
import {PeriodKind} from '../ModelContracts';
import {tap} from 'rxjs/operators';
import {DashboardRepository} from '../repositories/DashboardRepository';

export class DashboardComparisonsService {
	private _dashboardComparisons: Maybe<PolicyDashboardComparison> = Nothing();
	private _dashboardComparisonsStream: Subject<Maybe<PolicyDashboardComparison>> = new Subject();

	constructor(private repo: DashboardRepository) {}

	initialRequest = () => this.requestDashboardComparisons('M');

	requestDashboardComparisons = (periodKind: PeriodKind) =>
		this.repo.getBy(periodKind).pipe(tap(this.notifyDasboardComparisonsUpdated));

	private notifyDasboardComparisonsUpdated = (dashboardComparisons: Maybe<PolicyDashboardComparison>) => {
		this._dashboardComparisons = dashboardComparisons;
		this._dashboardComparisonsStream.next(dashboardComparisons);
	};

	get dashboardComparisons() {
		return this._dashboardComparisons;
	}

	get dashboardComparisonsStream() {
		return this._dashboardComparisonsStream;
	}
}
