import * as React from 'react';
import {NotificationInteractor, NotificationPanelInteractor} from './NotificationInteractor';
import {Message, NotificationKind} from '../../../appServices/INotificationService';
import {WrapperReactState} from '../WrapperReactState';
import {InteractorFactory} from '../../../factories/InteractorFactory';

export const NotificationBarWrapper = () =>
	WrapperReactState.create(NotificationBar, InteractorFactory.getNotificationInteractor());

export const NotificationPanelWrapper = () =>
	WrapperReactState.create(NotificationPanel, InteractorFactory.getNotificationPanelInteractor());

const NotificationBar = (props: {interactor: NotificationInteractor}) => {
	let lastMessageAsNotification = props.interactor.getCurrentMessage() && (
		<NotificationItem
			message={props.interactor.getCurrentMessage()}
			onClose={() => props.interactor.deleteMessage(props.interactor.getCurrentMessage())}
		/>
	);

	return <div className="notification-bar">{lastMessageAsNotification}</div>;
};

const NotificationPanel = (props: {interactor: NotificationPanelInteractor}) => {
	const messagesAsNotification = props.interactor.messages.map((m) => (
		<NotificationItem key={m.id} message={m} onClose={() => props.interactor.deleteMessage(m)} />
	));

	const deleteAllButton =
		props.interactor.messages.length > 0 ? (
			<button className="btn btn-primary" onClick={() => props.interactor.deleteAll()}>
				CLEAR ALL
			</button>
		) : null;

	const notificationPanel = props.interactor.shouldShowPanel ? (
		<div className="notification-panel">
			<h2>Notifications</h2>
			<i className="icon-icon-close" onClick={props.interactor.onShowPanel} />
			<div className="notifications-list">
				{messagesAsNotification}
				{deleteAllButton}
			</div>
		</div>
	) : null;

	const notificationPanelOverlay = props.interactor.shouldShowPanel ? (
		<div className="notification-overlay" onClick={props.interactor.onShowPanel} />
	) : null;

	const notificationCounterButton = props.interactor.messages.length ? (
		<span className="pulse bg-danger" onClick={props.interactor.onShowPanel} />
	) : null;

	return (
		<a className="nav-link">
			<i className="icon-icon-alerts" onClick={props.interactor.onShowPanel} />
			{notificationCounterButton}
			{notificationPanel}
			{notificationPanelOverlay}
		</a>
	);
};

const NotificationItem = (props: {message: Message; onClose: () => void}) => {
	const getAlertKind = (kind: NotificationKind) => {
		if (kind == NotificationKind.warning) return 'alert-warning';

		if (kind == NotificationKind.error) return 'alert-error';

		return 'alert-success';
	};

	const getAlertIcon = (kind: NotificationKind) => {
		if (kind == NotificationKind.warning) return <i className="icon-icon-alert-warning" />;

		if (kind == NotificationKind.error) return <i className="icon-icon-alert-error" />;

		return <i className="icon-icon-alert-ok" />;
	};

	return (
		<div className={'alert ' + getAlertKind(props.message.kind)}>
			<i className="icon-icon-close" onClick={props.onClose} />
			<div className="alert-content">
				<div className={'alert-icon-container'}>{getAlertIcon(props.message.kind)}</div>
				<div className={'alert-text-container'}>{props.message.body}</div>
			</div>
		</div>
	);
};
