import {UserComplianceResult, UserContemporaneousResult, UserGoal, UserTimescoreForPeriod} from '../../core/Models';
import * as XLSX from 'xlsx';
//import {UserTimescoreForPeriodWithTitleAndPracticeArea} from '../views/timescoreBreakdown/TimescoreBreakdownTableInteractor';

export class ExcelVelocityService {
	static generateFile = (periodFormatted: string, complianceResults: UserComplianceResult[] = []) => {
		const complianceSheet = `${periodFormatted}`;
		let workBook = XLSX.utils.book_new();
		workBook.Props = ExcelServicesShared.getExcelBookProps();
		workBook.SheetNames.push(complianceSheet);
		workBook.Sheets[complianceSheet] = XLSX.utils.json_to_sheet(
			ExcelVelocityService.getExcelRowsVelocity(complianceResults)
		);
		workBook.Sheets[complianceSheet]['!cols'] = [{width: 20}, {width: 20}, {width: 50}, {width: 20}];

		return XLSX.writeFile(workBook, `${periodFormatted}_ThriveVelocity.xlsx`, {
			bookType: 'xlsx',
			type: 'file',
		});
	};

	private static getExcelRowsVelocity = (complianceResults: UserComplianceResult[] = []) => {
		const fromUserComplianceToVelocityExcelRow = (uc: UserComplianceResult) => {
			let velocityExcelRow = {
				'First Name': uc.firstName,
				'Last Name': uc.lastName,
				Email: uc.email,
				'Average Velocity': uc.averageVelocity,
			};

			return velocityExcelRow;
		};

		return complianceResults.map(fromUserComplianceToVelocityExcelRow);
	};
}

export class ExcelUsersGoalService {
	static generateFile = (periodFormatted: string, userGoals: UserGoal[] = []) => {
		const complianceSheet = `${periodFormatted}`;

		let workBook = XLSX.utils.book_new();
		workBook.Props = ExcelServicesShared.getExcelBookProps();

		workBook.SheetNames.push(complianceSheet);

		workBook.Sheets[complianceSheet] = XLSX.utils.json_to_sheet(
			ExcelUsersGoalService.getExcelRowsUsersGoal(userGoals)
		);

		workBook.Sheets[complianceSheet]['!cols'] = [{width: 40}, {width: 40}, {width: 20}, {width: 20}, {width: 20}];

		return XLSX.writeFile(workBook, `${periodFormatted}_Thrive_UserGoals.xlsx`, {
			bookType: 'xlsx',
			type: 'file',
		});
	};

	private static getExcelRowsUsersGoal = (userGoals: UserGoal[] = []) => {
		const fromUsersGoalToExcelRow = (ug: UserGoal) => {
			let excelRow = {
				'First Name': ug.firstName,
				'Last Name': ug.lastName,
				Hours: parseFloat(ug.hours),
				Goal: ug.goal,
				Difference: parseFloat(ug.difference),
			};

			return excelRow;
		};

		return userGoals.map(fromUsersGoalToExcelRow);
	};
}

export class ExcelContemporaneusService {
	static generateFile = (periodFormatted: string, contemporaneousResults: UserContemporaneousResult[] = []) => {
		const complianceSheet = `${periodFormatted}`;
		let workBook = XLSX.utils.book_new();
		workBook.Props = ExcelServicesShared.getExcelBookProps();
		workBook.SheetNames.push(complianceSheet);
		workBook.Sheets[complianceSheet] = XLSX.utils.json_to_sheet(
			ExcelContemporaneusService.getExcelRowsContemporaneous(contemporaneousResults)
		);
		workBook.Sheets[complianceSheet]['!cols'] = [{width: 20}, {width: 20}, {width: 50}, {width: 40}, {width: 40}];

		return XLSX.writeFile(workBook, `${periodFormatted}_ThriveContemporaneousTimeEntry.xlsx`, {
			bookType: 'xlsx',
			type: 'file',
		});
	};

	private static getExcelRowsContemporaneous = (contemporaneousResults: UserContemporaneousResult[] = []) => {
		const fromUserContemporaneousToContemporaneousExcelRow = (uc: UserContemporaneousResult) => {
			let contemporaneousExcelRow = {
				'First Name': uc.firstName,
				'Last Name': uc.lastName,
				Email: uc.email,
				'% Timecards Created Contemporaneously': uc.contemporaneousCardsCountPercent,
				'% Worked Hours Entered Contemporaneously': uc.contemporaneousHoursTotalPercent,
			};

			return contemporaneousExcelRow;
		};

		return contemporaneousResults.map(fromUserContemporaneousToContemporaneousExcelRow);
	};
}

export class ExcelPenaltiesAndRewardsService {
	static generateFile = (
		periodFormatted: string,
		complianceResults: UserComplianceResult[] = [],
		complianceType: string
	) => {
		const complianceSheet = `${periodFormatted}`;

		let workBook = XLSX.utils.book_new();
		workBook.Props = ExcelServicesShared.getExcelBookProps();

		workBook.SheetNames.push(complianceSheet);

		workBook.Sheets[complianceSheet] = XLSX.utils.json_to_sheet(
			ExcelPenaltiesAndRewardsService.getExcelRowsPenaltiesAndRewards(complianceResults)
		);

		workBook.Sheets[complianceSheet]['!cols'] = [
			{width: 50},
			{width: 20},
			{width: 20},
			{width: 20},
			{width: 20},
			{width: 20},
			{width: 20},
			{width: 30},
		];

		return XLSX.writeFile(workBook, `${periodFormatted}_Thrive_${complianceType}.xlsx`, {
			bookType: 'xlsx',
			type: 'file',
		});
	};

	private static getExcelRowsPenaltiesAndRewards = (complianceResults: UserComplianceResult[] = []) => {
		const fromUserComplianceToExcelRow = (uc: UserComplianceResult) => {
			let excelRow = {
				Email: uc.email,
				'First Name': uc.firstName,
				'Last Name': uc.lastName,
				Reward: uc.totalCashReward,
				Penalty: uc.totalCashPenalty,
				Location: uc.location,
				Title: uc.title,
				'Practice Area': uc.practiceArea,
			};

			return excelRow;
		};

		return complianceResults.map(fromUserComplianceToExcelRow);
	};
}

export class ExcelUsersComplianceAcrossMonthsService {
	static generateFile = (
		periodsFormatted: string[],
		complianceResults: UserComplianceResult[][] = [],
		period: string
	) => {
		let workBook = XLSX.utils.book_new();
		workBook.Props = ExcelServicesShared.getExcelBookProps();

		periodsFormatted.forEach((period, i) => {
			workBook.SheetNames.push(period);
			workBook.Sheets[period] = XLSX.utils.json_to_sheet(
				ExcelServicesShared.getExcelRowsCompliance(complianceResults[i])
			);
			workBook.Sheets[period]['!cols'] = ExcelServicesShared.complianceFormat;
		});

		return XLSX.writeFile(workBook, `Users_Compliance_Last_${period}.xlsx`, {
			bookType: 'xlsx',
			type: 'file',
		});
	};
}

export class ExcelUsersComplianceService {
	static generateFile = (
		periodFormatted: string,
		complianceResults: UserComplianceResult[] = [],
		complianceType: string
	) => {
		const complianceSheet = `${periodFormatted}`;

		let workBook = XLSX.utils.book_new();
		workBook.Props = ExcelServicesShared.getExcelBookProps();

		workBook.SheetNames.push(complianceSheet);

		workBook.Sheets[complianceSheet] = XLSX.utils.json_to_sheet(
			ExcelServicesShared.getExcelRowsCompliance(complianceResults)
		);

		workBook.Sheets[complianceSheet]['!cols'] = ExcelServicesShared.complianceFormat;

		return XLSX.writeFile(workBook, `${periodFormatted}_Thrive_${complianceType}.xlsx`, {
			bookType: 'xlsx',
			type: 'file',
		});
	};
}

export class ExcelTimescoreService {
	static generateFile = (
		currentPeriod: string,
		previousPeriod: string,
		timescoreResults: UserTimescoreForPeriod[] = []
	) => {
		const complianceSheet = `${currentPeriod}`;

		let workBook = XLSX.utils.book_new();
		workBook.Props = ExcelServicesShared.getExcelBookProps();

		workBook.SheetNames.push(complianceSheet);

		workBook.Sheets[complianceSheet] = XLSX.utils.json_to_sheet(
			ExcelTimescoreService.getExcelRowsTimescore(timescoreResults, currentPeriod, previousPeriod)
		);

		workBook.Sheets[complianceSheet]['!cols'] = [
			{width: 20},
			{width: 20},
			{width: 50},
			{width: 10},
			{width: 10},
			{width: 20},
			{width: 20},
		];

		return XLSX.writeFile(workBook, `${currentPeriod}_ThriveTimescoreUserBreakdown.xlsx`, {
			bookType: 'xlsx',
			type: 'file',
		});
	};

	static getExcelRowsTimescore = (
		timescoreResults: UserTimescoreForPeriod[],
		currentPeriod: string,
		previousPeriod: string
	) => {
		const fromUserTimescoreForPeriodToTimescoreExcelRow = (utfp: UserTimescoreForPeriod) => {
			let timescoreExcelRow = {
				'First Name': utfp.firstName,
				'Last Name': utfp.lastName,
				Email: utfp.email,
				[previousPeriod]: utfp.previousTimescore,
				[currentPeriod]: utfp.currentTimescore,
				Title: utfp.title,
				'Practice area': utfp.practiceArea,
			};

			return timescoreExcelRow;
		};

		return timescoreResults.map(fromUserTimescoreForPeriodToTimescoreExcelRow);
	};
}

class ExcelServicesShared {
	static complianceFormat = [
		{width: 50},
		{width: 20},
		{width: 20},
		{width: 20},
		{width: 10},
		{width: 10},
		{width: 10},
		{width: 12},
		{width: 20},
		{width: 12},
		{width: 10},
		{width: 32},
		{width: 32},
		{width: 32},
		{width: 32},
		{width: 30},
		{width: 30},
		{width: 20},
		{width: 30},
	];

	static getExcelRowsCompliance = (complianceResults: UserComplianceResult[] = []) => {
		const fromUserComplianceToExcelRow = (uc: UserComplianceResult) => {
			let excelRow = {
				Email: uc.email,
				'First Name': uc.firstName,
				'Last Name': uc.lastName,
				'Compliance Status': uc.complianceStatus,
				Reward: uc.totalCashReward,
				Penalty: uc.totalCashPenalty,
				Velocity: uc.averageVelocity,
				'Work Hours': uc.totalWorkHours,
				'Billable Work Hours': uc.totalBillableWorkHours,
				Granularity: uc.averageGranularity,
				Timescore: uc.timescore,
				'Delinquent Timecards Hours': uc.totalHoursCreatedAfterGracePeriod,
				'Delinquent Billable Timecard Hours': uc.totalBillableHoursCreatedAfterGracePeriod,
				'Timecards Created By Timekeeper': uc.itkCreatedByOwnerCount,
				'Timecards Created By Assistant': uc.itkCreatedByDelegateCount,
				'Reason For Penalty': uc.problemReason,
				Location: uc.location,
				Title: uc.title,
				'Practice Area': uc.practiceArea,
			};

			return excelRow;
		};

		return complianceResults.map(fromUserComplianceToExcelRow);
	};

	static getExcelBookProps = () => {
		return {
			Title: 'Compliance Report',
			Subject: 'Compliance Report',
			Author: 'Aderant',
		};
	};
}
