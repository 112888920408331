import * as Chart from 'chart.js';
import {ChartOptions, ChartScales} from 'chart.js';
import {first} from 'lodash';
import * as React from 'react';
import {createRef, useEffect} from 'react';

export class ChartColors {
	static mediumBlue = '#09a3f3';
	static lightBlue = '#88d7ff';
	static darkBlue = '#0782c2';
}

export class ChartThemeColors {
	static aderant = ['#88D7FF', '#09A3F3', '#0782C2'];
	static bellefield = ['#9FEEB9', '#27BC58', '#166D33'];
	static aqua = ['#B2F1E6', '#74E8D0', '#2DB49D'];
	static dawn = ['#E9B4DF', '#D973C5', '#9A488A'];
}

export type ChartData = {labels: string[]; dataset: DataSet[]};

type ChartKind = 'bar' | 'pie' | 'line';

type DataSet = {
	label: string;
	data: number[];
	backgroundColor: string | string[];
};

type LegendPosition = 'top' | 'left' | 'bottom' | 'right';

type ChartConfig = {
	kind: ChartKind;
	chartData: ChartData;
	hasAnimated: boolean;
	transformYValues: (value: number) => string;
	type: string;
	hideAxes: boolean;
	tooltipCallback: (item: Chart.ChartTooltipItem, data: Chart.ChartData) => string;
	beginAtZero: boolean;
	title: string;
	legendPosition: LegendPosition;
	showYAxeLabel: boolean;
	yAxeLabel: string;
	hideLegend: boolean;
	legendCallback: any;
	discardAspectRatio: boolean;
	onClick: (index: number, datasetIndex: number) => void;
	stepSize: number;
	inModal: boolean;
	disableOnClickLegend: boolean;
};

class ChartFactory {
	static chart: Chart;

	static modalChart: Chart;

	static createChart = (canvas, config: ChartConfig) => {
		ChartFactory.chart = new Chart(canvas, {
			type: config.kind,
			data: {labels: config.chartData.labels, datasets: config.chartData.dataset},
			options: ChartFactory.prepareOptions(config) as ChartOptions,
		});
		return ChartFactory.chart;
	};

	static createModalChart = (canvas, config: ChartConfig) => {
		ChartFactory.modalChart = new Chart(canvas, {
			type: config.kind,
			data: {labels: config.chartData.labels, datasets: config.chartData.dataset},
			options: ChartFactory.prepareOptions(config) as ChartOptions,
		});
		return ChartFactory.modalChart;
	};

	static prepareOptions = (config: ChartConfig) => {
		const maintainAspectRatio = config.discardAspectRatio;
		const animation = {
			duration: config.hasAnimated ? 1000 : 0,
		};

		const scales = {
			yAxes: [
				{
					display: config.hideAxes,
					ticks: {
						callback: config.transformYValues,
						beginAtZero: !config.beginAtZero,
						stepSize: config.stepSize,
					},
					scaleLabel: {
						display: config.showYAxeLabel,
						labelString: config.yAxeLabel,
					},
				},
			],
			xAxes: [
				{
					display: config.hideAxes,
					type: config.type,
					time: {
						unit: 'day',
						displayFormats: {
							day: 'MMM DD, YYYY',
						},
						stepSize: 5,
						tooltipFormat: 'ddd, MMM DD, YYYY',
					},
				},
			],
		} as ChartScales;

		const tooltips = {
			callbacks: {
				label: config.tooltipCallback,
			},
		};

		const title = {
			display: true,
			text: config.title,
		};

		const legend = () =>
			config.disableOnClickLegend
				? {
						position: config.legendPosition,
						display: config.hideLegend,
						onClick: null,
				  }
				: {
						position: config.legendPosition,
						display: config.hideLegend,
				  };

		const legendCallback = config.legendCallback;

		const elements = {
			point: {
				radius: 2.5,
			},
		};

		return {
			maintainAspectRatio,
			animation,
			scales,
			tooltips,
			title,
			legend: legend(),
			legendCallback,
			elements,
			onClick: (e) => {
				if (!ChartFactory.chart.getElementAtEvent(e).length) return;

				const element = first(ChartFactory.chart.getElementAtEvent(e)) as any;
				config.onClick(element._index, element._datasetIndex);
			},
		};
	};

	static deleteChart = () => ChartFactory.chart.destroy();
	static deleteModalChart = () => ChartFactory.modalChart.destroy();
}

type ChartProps = {
	data: ChartData;
	kind: ChartKind;
	hasAnimated: boolean;
	transformYValues?: (value: number) => string;
	type?: string;
	hideAxes?: boolean;
	tooltipCallback?: (item: Chart.ChartTooltipItem, data: Chart.ChartData) => string;
	beginAtZero?: boolean;
	title?: string;
	legendPosition: LegendPosition;
	showYAxeLabel: boolean;
	yAxeLabel: string;
	hideLegend?: boolean;
	legendCallback?: any;
	discardAspectRatio?: boolean;
	onClick?: (index: number, datasetIndex?: number) => void;
	stepSize?: number;
	inModal?: boolean;
	disableOnClickLegend?: boolean;
};

export const ChartComponent = (props: ChartProps) => {
	const canvasRef = createRef<HTMLCanvasElement>();
	const chartData = {labels: props.data.labels, dataset: props.data.dataset};
	const transformY = props.transformYValues ? props.transformYValues : (value) => value;
	const defaultTooltipCallback = (item: Chart.ChartTooltipItem, data: Chart.ChartData) => {
		let index = item.datasetIndex;
		return `${data.datasets[index].label}: ${item.value}`;
	};

	const tooltipCallback = props.tooltipCallback ? props.tooltipCallback : defaultTooltipCallback;
	const legendCallback = props.legendCallback ? props.legendCallback : () => {};

	const chartConfig: ChartConfig = {
		kind: props.kind,
		chartData: chartData,
		hasAnimated: props.hasAnimated,
		transformYValues: transformY,
		type: props.type,
		hideAxes: !props.hideAxes,
		tooltipCallback: tooltipCallback,
		beginAtZero: props.beginAtZero,
		title: props.title ? props.title : '',
		legendPosition: props.legendPosition,
		showYAxeLabel: props.showYAxeLabel,
		yAxeLabel: props.yAxeLabel,
		hideLegend: !props.hideLegend,
		legendCallback: legendCallback,
		discardAspectRatio: !props.discardAspectRatio,
		onClick: props.onClick,
		stepSize: props.stepSize,
		inModal: props.inModal,
		disableOnClickLegend: props.disableOnClickLegend,
	};

	const effect = () => {
		if (props.inModal) {
			ChartFactory.createModalChart(canvasRef.current, chartConfig);
			let legend = ChartFactory.chart.generateLegend();
			return ChartFactory.deleteModalChart;
		}
		ChartFactory.createChart(canvasRef.current, chartConfig);
		let legend = ChartFactory.chart.generateLegend();
		return ChartFactory.deleteChart;
	};

	useEffect(effect);

	if (props.inModal) {
		return <canvas height={chartConfig.discardAspectRatio ? '' : '700'} ref={canvasRef} />;
	}
	return <canvas height={chartConfig.discardAspectRatio ? '' : '500'} ref={canvasRef} />;
};
