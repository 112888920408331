import {ContemporaneousRepository} from '../../core/repositories/ContemporaneousRepository';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {
	PeriodKind,
	ContemporaneousHistoryRequest,
	ContemporaneousHistoryDTO,
	CallContext,
	ServiceRequest,
	ServiceResponse,
} from '../../core/ModelContracts';
import {PolicyService} from '../appServices/PolicyService';
import {UrlBuilder, BackendRoutes, Environment} from '../Routes';
import {map} from 'rxjs/operators';
import {ContemporaneousHistory} from '../../core/Models';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class ContemporaneousApiRepository implements ContemporaneousRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private authenticationService: AuthenticationService
	) {}

	getAllByPeriod = (periodType: PeriodKind) =>
		this.requestContemporaneousHistory(periodType).pipe(
			map((serviceResponse) => serviceResponse.Response),
			map((chDTOs) => chDTOs.map(ContemporaneousHistory.createFrom))
		);

	requestContemporaneousHistory = (periodType: PeriodKind) => {
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const contemporaneousUrl = urlBuilder.getBackendUrl(BackendRoutes.contemporaneousHistory);
		const callContext = this.authenticationService.callContext;

		const request = ContemporaneousHistoryRequest.create(this.policyService.currentPolicyId, periodType);
		const serviceRequest = ServiceRequest.create({Request: request, CallContext: callContext});

		return this.requestHelper.makeRequest<ServiceResponse<ContemporaneousHistoryDTO[]>>(
			contemporaneousUrl,
			'POST',
			serviceRequest
		);
	};
}
