import {reverse} from 'lodash';
import {UserTimescoreHistory} from '../../../core/Models';
import {PropertiesService} from '../../../core/services/propertiesService';
import {TimescoreService} from '../../../core/services/TimescoreService';
import {INotificationService} from '../../appServices/INotificationService';
import {ChartColors, ChartData, ChartThemeColors} from '../shared/chart/ChartComponent';
import {InteractorBase} from '../shared/InteractorBase';
import {INavigationService} from '../../appServices/INavigationService';

export class TimescoreBreakdownUserHistoryInteractor extends InteractorBase {
	private _mustShowLoader: boolean = false;
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		private timescoreService: TimescoreService,
		private propertiesService: PropertiesService
	) {
		super(navigationService, notificationService);

		this.timescoreService.userTimescoresStream.subscribe(this.updateView);

		this.timescoreService.userTimescoreHistoriesStream.subscribe(this.closeLoader);

		this.propertiesService.changeThemeStream.subscribe(this.updateView);
	}
	onUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		let currentUser = event.target.value;
		this.showLoader();
		this.onRequestUserTimescoreHistory(currentUser);
	};

	onRequestUserTimescoreHistory = (user: string) =>
		this.timescoreService.requestUserTimescoreHistory(user).subscribe();

	private showLoader = () => {
		this._mustShowLoader = true;
		this.updateView();
	};

	private closeLoader = () => {
		this._mustShowLoader = false;
		this.updateView();
	};

	prepareChartDataSet = (): ChartData => {
		const timescoreHistoryLabelsSorted = reverse(this.userTimescoreHistories.map((t) => t.day));
		const timescoresSorted = reverse(
			this.userTimescoreHistories
				.map((t) => t.timescore)
				.map((t) => UserTimescoreHistory.fromTimescoreToNumber(t))
		);

		const getThemeColors = () => {
			if (this.propertiesService.theme == 'ADERANT') {
				return ChartThemeColors.aderant;
			}
			if (this.propertiesService.theme == 'BELLEFIELD') {
				return ChartThemeColors.bellefield;
			}
			if (this.propertiesService.theme == 'AQUA') {
				return ChartThemeColors.aqua;
			}
			if (this.propertiesService.theme == 'DAWN') {
				return ChartThemeColors.dawn;
			}
		};

		const dataset = [
			{
				label: 'User Timescore History',
				data: timescoresSorted,
				backgroundColor: getThemeColors()[1],
				fill: false,
				borderColor: getThemeColors()[2],
			},
		];

		return {
			labels: timescoreHistoryLabelsSorted,
			dataset: dataset,
		};
	};

	tooltipLabelCallback = (tooltipItem, data) => {
		let value = parseInt(tooltipItem.value);
		return UserTimescoreHistory.fromNumberToTimeScore(value);
	};

	get userTimescores() {
		return this.timescoreService.userTimescores;
	}

	get userTimescoreHistories() {
		return this.timescoreService.userTimescoreHistories;
	}

	get userTimescoreHistoriesCurrentUser() {
		return this.timescoreService.userTimescoreHistoriesCurrentUser;
	}

	get mustShowLoader() {
		return this._mustShowLoader;
	}
}
