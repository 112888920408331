import {UsersGoalRepository} from '../../core/repositories/UsersGoalRepository';
import {RequestWrapper} from '../helpers/RequestWrapper';
import {PolicyService} from '../appServices/PolicyService';
import {PeriodsService} from '../appServices/PeriodsService';
import {map} from 'rxjs/operators';
import {UserGoal} from '../../core/Models';
import {UrlBuilder, BackendRoutes, Environment} from '../Routes';
import {
	CallContext,
	UsersGoalRequest,
	ServiceRequest,
	ServiceResponse,
	UsersGoalDTO,
	PeriodKind,
} from '../../core/ModelContracts';
import {AuthenticationService} from '../appServices/AuthenticationService';

export class UsersGoalApiRepository implements UsersGoalRepository {
	constructor(
		private environment: Environment,
		private requestHelper: RequestWrapper,
		private policyService: PolicyService,
		private periodService: PeriodsService,
		private authenticationService: AuthenticationService
	) {}

	getAllBy = (periodType: PeriodKind) =>
		this.requestUsersGoal(periodType).pipe(
			map((response) => response.Response),
			map((ugDTOs) => ugDTOs.map((ugDTO) => UserGoal.createFrom(ugDTO)))
		);

	requestUsersGoal = (periodType: PeriodKind) => {
		const currentPeriodFormated = this.periodService.workHoursOverviewCurrentPeriod.format('YYYY-MM-DD');
		const urlBuilder = new UrlBuilder(this.environment.endpointBase);
		const usersGoalUrl = urlBuilder.getBackendUrl(BackendRoutes.usersgoals);
		const callContext = this.authenticationService.callContext;

		const request = UsersGoalRequest.create(this.policyService.currentPolicyId, currentPeriodFormated, periodType);
		const serviceRequest = ServiceRequest.create<UsersGoalRequest>({
			Request: request,
			CallContext: callContext,
		});

		return this.requestHelper.makeRequest<ServiceResponse<UsersGoalDTO[]>>(usersGoalUrl, 'POST', serviceRequest);
	};
}
