import * as moment from 'moment';
import {Maybe} from 'monet';
import {PolicyDashboardComparisonDTO} from '../core/ModelContracts';
import {Entity, PolicyDashboardComparison, ResultComparison} from '../core/Models';
import _date = moment.unitOfTime._date;
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

export class FakeData {
	static policyDashboardComparisonDTO = {
		PolicyId: 66,
		Period: '2020-04-01',
		PeriodDisplay: 'Apr 2020',
		PeriodGraceDays: 2,
		PeriodAverageVelocity: 3.94,
		PeriodAverageGranularity: 1.86,
		FirmTimeScore: 'D-',
		ContemporaneousHoursTotalPercent: 0.46,
		ContemporaneousCardsCountPercent: 0.65,
		NumberOfUsers: 64,
		TotalHoursCreatedAfterGracePeriod: 14.5,
		TotalTimecardsCreatedAfterGracePeriod: 6,
		PeriodTotalWorkHours: 248.68,
		TotalBillableHoursCreatedAfterGracePeriod: 14.5,
		TotalBillableTimecardsCreatedAfterGracePeriod: 6,
		PeriodTotalBillableWorkHours: 247.68,
		TotalTimekeepersUsingiTK: 9,
		TotalTimekeepersUsingDelegates: 6,
		PeriodSystemAverageGranularity: 1.7,
		PeriodSystemTimeScore: 'C-',
		PeriodSystemAverageVelocity: 2.28,
		PeriodConfiguredVelocityGoal: 3.0,
		TotalTimekeepersWithRewards: 3,
		TotalCashRewards: 901.0,
		TotalTimekeepersWithPenalties: 61,
		TotalCashPenalties: -1812.0,
		TotalLateUsers: 4,
		ResultComparisons: [
			{
				ComplianceResult: 4000,
				NumberOfUsers: 2,
				PercentageOfTotalUsers: 3,
				NumberOfUsersInPreviousPeriod: 3,
				PercentageOfTotalUsersInPreviousPeriod: 4,
				PercentageDifference: -1,
			},
			{
				ComplianceResult: 3000,
				NumberOfUsers: 1,
				PercentageOfTotalUsers: 1,
				NumberOfUsersInPreviousPeriod: 5,
				PercentageOfTotalUsersInPreviousPeriod: 7,
				PercentageDifference: -6,
			},
			{
				ComplianceResult: 2000,
				NumberOfUsers: 61,
				PercentageOfTotalUsers: 95,
				NumberOfUsersInPreviousPeriod: 55,
				PercentageOfTotalUsersInPreviousPeriod: 87,
				PercentageDifference: 8,
			},
		],
	};

	static velocityHistoriesDTO = [
		{
			Period: '2020-05-01T00:00:00',
			AverageVelocity: 2.2,
			FormattedPeriod: 'May 2020',
			PeriodType: 'M',
		},
		{
			Period: '2020-04-01T00:00:00',
			AverageVelocity: 3.94,
			FormattedPeriod: 'Apr 2020',
			PeriodType: 'M',
		},
		{
			Period: '2020-03-01T00:00:00',
			AverageVelocity: 0.59,
			FormattedPeriod: 'Mar 2020',
			PeriodType: 'M',
		},
		{
			Period: '2020-02-01T00:00:00',
			AverageVelocity: 1.3,
			FormattedPeriod: 'Feb 2020',
			PeriodType: 'M',
		},
		{
			Period: '2020-01-01T00:00:00',
			AverageVelocity: 0.96,
			FormattedPeriod: 'Jan 2020',
			PeriodType: 'M',
		},
		{
			Period: '2019-12-01T00:00:00',
			AverageVelocity: 1.05,
			FormattedPeriod: 'Dec 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-11-01T00:00:00',
			AverageVelocity: 0.31,
			FormattedPeriod: 'Nov 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-10-01T00:00:00',
			AverageVelocity: 11.33,
			FormattedPeriod: 'Oct 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-09-01T00:00:00',
			AverageVelocity: 1.35,
			FormattedPeriod: 'Sep 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-08-01T00:00:00',
			AverageVelocity: 3.05,
			FormattedPeriod: 'Aug 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-07-01T00:00:00',
			AverageVelocity: 3.82,
			FormattedPeriod: 'Jul 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-06-01T00:00:00',
			AverageVelocity: 1.39,
			FormattedPeriod: 'Jun 2019',
			PeriodType: 'M',
		},
	];

	static complianceHistoriesDTO = [
		{
			Period: '2020-05-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 0,
			Compliant_Count: 3,
			NotCompliant_Count: 61,
			GraceDays: 2,
			TotalHoursCreatedAfterGracePeriod: 2.27,
			TotalTimecardsCreatedAfterGracePeriod: 6,
			TotalBillableHoursCreatedAfterGracePeriod: 2.27,
			TotalBillableTimecardsCreatedAfterGracePeriod: 6,
		},
		{
			Period: '2020-04-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 2,
			Compliant_Count: 1,
			NotCompliant_Count: 61,
			GraceDays: 2,
			TotalHoursCreatedAfterGracePeriod: 14.5,
			TotalTimecardsCreatedAfterGracePeriod: 6,
			TotalBillableHoursCreatedAfterGracePeriod: 14.5,
			TotalBillableTimecardsCreatedAfterGracePeriod: 6,
		},
		{
			Period: '2020-03-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 3,
			Compliant_Count: 5,
			NotCompliant_Count: 55,
			GraceDays: 2,
			TotalHoursCreatedAfterGracePeriod: 64.25,
			TotalTimecardsCreatedAfterGracePeriod: 3,
			TotalBillableHoursCreatedAfterGracePeriod: 64.25,
			TotalBillableTimecardsCreatedAfterGracePeriod: 3,
		},
		{
			Period: '2020-02-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 1,
			Compliant_Count: 5,
			NotCompliant_Count: 57,
			GraceDays: 2,
			TotalHoursCreatedAfterGracePeriod: 4.0,
			TotalTimecardsCreatedAfterGracePeriod: 1,
			TotalBillableHoursCreatedAfterGracePeriod: 4.0,
			TotalBillableTimecardsCreatedAfterGracePeriod: 1,
		},
		{
			Period: '2020-01-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 1,
			Compliant_Count: 1,
			NotCompliant_Count: 61,
			GraceDays: 2,
			TotalHoursCreatedAfterGracePeriod: 0.8,
			TotalTimecardsCreatedAfterGracePeriod: 9,
			TotalBillableHoursCreatedAfterGracePeriod: 0.8,
			TotalBillableTimecardsCreatedAfterGracePeriod: 2,
		},
		{
			Period: '2019-12-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 1,
			Compliant_Count: 10,
			NotCompliant_Count: 52,
			GraceDays: 3,
			TotalHoursCreatedAfterGracePeriod: 0.0,
			TotalTimecardsCreatedAfterGracePeriod: 0,
			TotalBillableHoursCreatedAfterGracePeriod: 0.0,
			TotalBillableTimecardsCreatedAfterGracePeriod: 0,
		},
		{
			Period: '2019-11-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 0,
			Compliant_Count: 5,
			NotCompliant_Count: 58,
			GraceDays: 3,
			TotalHoursCreatedAfterGracePeriod: 2.6,
			TotalTimecardsCreatedAfterGracePeriod: 1,
			TotalBillableHoursCreatedAfterGracePeriod: 2.6,
			TotalBillableTimecardsCreatedAfterGracePeriod: 1,
		},
		{
			Period: '2019-10-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 4,
			Compliant_Count: 3,
			NotCompliant_Count: 56,
			GraceDays: 3,
			TotalHoursCreatedAfterGracePeriod: 0.0,
			TotalTimecardsCreatedAfterGracePeriod: 0,
			TotalBillableHoursCreatedAfterGracePeriod: 0.0,
			TotalBillableTimecardsCreatedAfterGracePeriod: 0,
		},
		{
			Period: '2019-09-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 1,
			Compliant_Count: 2,
			NotCompliant_Count: 60,
			GraceDays: 9,
			TotalHoursCreatedAfterGracePeriod: 2.6,
			TotalTimecardsCreatedAfterGracePeriod: 1,
			TotalBillableHoursCreatedAfterGracePeriod: 2.6,
			TotalBillableTimecardsCreatedAfterGracePeriod: 1,
		},
		{
			Period: '2019-08-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 2,
			Compliant_Count: 2,
			NotCompliant_Count: 59,
			GraceDays: 3,
			TotalHoursCreatedAfterGracePeriod: 5.8,
			TotalTimecardsCreatedAfterGracePeriod: 2,
			TotalBillableHoursCreatedAfterGracePeriod: 5.8,
			TotalBillableTimecardsCreatedAfterGracePeriod: 2,
		},
		{
			Period: '2019-07-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 0,
			Compliant_Count: 0,
			NotCompliant_Count: 63,
			GraceDays: 3,
			TotalHoursCreatedAfterGracePeriod: 20.4,
			TotalTimecardsCreatedAfterGracePeriod: 9,
			TotalBillableHoursCreatedAfterGracePeriod: 19.4,
			TotalBillableTimecardsCreatedAfterGracePeriod: 8,
		},
		{
			Period: '2019-06-01T00:00:00',
			PolicyId: 66,
			Rockstars_Count: 0,
			Compliant_Count: 2,
			NotCompliant_Count: 61,
			GraceDays: 3,
			TotalHoursCreatedAfterGracePeriod: 5.81,
			TotalTimecardsCreatedAfterGracePeriod: 2,
			TotalBillableHoursCreatedAfterGracePeriod: 5.81,
			TotalBillableTimecardsCreatedAfterGracePeriod: 2,
		},
	];

	static contemporaneousHistoriesDTO = [
		{
			Period: '2020-05-01T00:00:00',
			ContemporaneousHoursTotalPercent: 71.27482383087764,
			ContemporaneousCardsCountPercent: 75.0,
			FormattedPeriod: 'May 2020',
			PeriodType: 'M',
		},
		{
			Period: '2020-04-01T00:00:00',
			ContemporaneousHoursTotalPercent: 46.17982949975872,
			ContemporaneousCardsCountPercent: 65.413533834586466,
			FormattedPeriod: 'Apr 2020',
			PeriodType: 'M',
		},
		{
			Period: '2020-03-01T00:00:00',
			ContemporaneousHoursTotalPercent: 71.85380425232823,
			ContemporaneousCardsCountPercent: 81.749049429657794,
			FormattedPeriod: 'Mar 2020',
			PeriodType: 'M',
		},
		{
			Period: '2020-02-01T00:00:00',
			ContemporaneousHoursTotalPercent: 62.98525131948897,
			ContemporaneousCardsCountPercent: 75.824175824175824,
			FormattedPeriod: 'Feb 2020',
			PeriodType: 'M',
		},
		{
			Period: '2020-01-01T00:00:00',
			ContemporaneousHoursTotalPercent: 97.88549121665582,
			ContemporaneousCardsCountPercent: 90.666666666666666,
			FormattedPeriod: 'Jan 2020',
			PeriodType: 'M',
		},
		{
			Period: '2019-12-01T00:00:00',
			ContemporaneousHoursTotalPercent: 65.43012365430123,
			ContemporaneousCardsCountPercent: 73.151750972762645,
			FormattedPeriod: 'Dec 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-11-01T00:00:00',
			ContemporaneousHoursTotalPercent: 76.77735517846693,
			ContemporaneousCardsCountPercent: 85.648148148148148,
			FormattedPeriod: 'Nov 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-10-01T00:00:00',
			ContemporaneousHoursTotalPercent: 67.34928989969212,
			ContemporaneousCardsCountPercent: 76.616915422885572,
			FormattedPeriod: 'Oct 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-09-01T00:00:00',
			ContemporaneousHoursTotalPercent: 47.77422170497526,
			ContemporaneousCardsCountPercent: 63.551401869158878,
			FormattedPeriod: 'Sep 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-08-01T00:00:00',
			ContemporaneousHoursTotalPercent: 62.77800823255368,
			ContemporaneousCardsCountPercent: 60.377358490566037,
			FormattedPeriod: 'Aug 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-07-01T00:00:00',
			ContemporaneousHoursTotalPercent: 63.57905898986725,
			ContemporaneousCardsCountPercent: 67.256637168141592,
			FormattedPeriod: 'Jul 2019',
			PeriodType: 'M',
		},
		{
			Period: '2019-06-01T00:00:00',
			ContemporaneousHoursTotalPercent: 60.40952287645733,
			ContemporaneousCardsCountPercent: 77.114427860696517,
			FormattedPeriod: 'Jun 2019',
			PeriodType: 'M',
		},
	];

	static timescoreHistoryDTO = [
		{
			Timescore: 'A+',
			NumberOfUsers: 2,
			PercentageOfTotalUsers: 16,
			NumberOfUsersInPreviousPeriod: 1,
			PercentageOfTotalUsersInPreviousPeriod: 7,
			PercentageDifference: 9,
		},
		{
			Timescore: 'A ',
			NumberOfUsers: 0,
			PercentageOfTotalUsers: 0,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 0,
		},
		{
			Timescore: 'A-',
			NumberOfUsers: 2,
			PercentageOfTotalUsers: 16,
			NumberOfUsersInPreviousPeriod: 5,
			PercentageOfTotalUsersInPreviousPeriod: 38,
			PercentageDifference: -22,
		},
		{
			Timescore: 'B+',
			NumberOfUsers: 0,
			PercentageOfTotalUsers: 0,
			NumberOfUsersInPreviousPeriod: 3,
			PercentageOfTotalUsersInPreviousPeriod: 23,
			PercentageDifference: -23,
		},
		{
			Timescore: 'B ',
			NumberOfUsers: 1,
			PercentageOfTotalUsers: 8,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 8,
		},
		{
			Timescore: 'B-',
			NumberOfUsers: 0,
			PercentageOfTotalUsers: 0,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 0,
		},
		{
			Timescore: 'C+',
			NumberOfUsers: 0,
			PercentageOfTotalUsers: 0,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 0,
		},
		{
			Timescore: 'C ',
			NumberOfUsers: 1,
			PercentageOfTotalUsers: 8,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 8,
		},
		{
			Timescore: 'C-',
			NumberOfUsers: 1,
			PercentageOfTotalUsers: 8,
			NumberOfUsersInPreviousPeriod: 3,
			PercentageOfTotalUsersInPreviousPeriod: 23,
			PercentageDifference: -15,
		},
		{
			Timescore: 'D+',
			NumberOfUsers: 0,
			PercentageOfTotalUsers: 0,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 0,
		},
		{
			Timescore: 'D ',
			NumberOfUsers: 0,
			PercentageOfTotalUsers: 0,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 0,
		},
		{
			Timescore: 'D-',
			NumberOfUsers: 3,
			PercentageOfTotalUsers: 25,
			NumberOfUsersInPreviousPeriod: 1,
			PercentageOfTotalUsersInPreviousPeriod: 7,
			PercentageDifference: 18,
		},
		{
			Timescore: 'F+',
			NumberOfUsers: 1,
			PercentageOfTotalUsers: 8,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 8,
		},
		{
			Timescore: 'F ',
			NumberOfUsers: 0,
			PercentageOfTotalUsers: 0,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 0,
		},
		{
			Timescore: 'F-',
			NumberOfUsers: 1,
			PercentageOfTotalUsers: 8,
			NumberOfUsersInPreviousPeriod: 0,
			PercentageOfTotalUsersInPreviousPeriod: 0,
			PercentageDifference: 8,
		},
	];

	static usersTimescoresDTO = [
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 15878,
			UserEmail: 'attorney1@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 1',
			UserLastName: 'AzureAD',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 15879,
			UserEmail: 'attorney2@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 2',
			UserLastName: 'AzureAD',
		},
		{
			Timescore: 'C ',
			PreviousTimescore: 'B ',
			Title: '',
			PracticeArea: '',
			UserId: 19035,
			UserEmail: 'daniel.barr@aderant.com',
			UserFirstName: 'Daniel',
			UserLastName: 'Barr',
		},
		{
			Timescore: 'D-',
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 19326,
			UserEmail: 'cristina.butler@aderant.com',
			UserFirstName: 'Cristina',
			UserLastName: 'Butler',
		},
		{
			Timescore: 'A-',
			PreviousTimescore: 'A-',
			Title: 'Other',
			PracticeArea: '',
			UserId: 19005,
			UserEmail: 'robert.domico@aderant.com',
			UserFirstName: 'Robert',
			UserLastName: 'Domico',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: null,
			PracticeArea: null,
			UserId: 15618,
			UserEmail: 'mgomez@bellefield.com',
			UserFirstName: 'Miguel',
			UserLastName: 'Gomez',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 18966,
			UserEmail: 'lisa.greer@bellefield.com',
			UserFirstName: 'Lisa',
			UserLastName: 'Greer',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: 'Associate2',
			PracticeArea: 'Appellate Practice',
			UserId: 19042,
			UserEmail: 'patrick.hertling@aderant.com',
			UserFirstName: 'Patrick',
			UserLastName: 'Hertling',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 19037,
			UserEmail: 'angela.huffman@aderant.com',
			UserFirstName: 'Angela',
			UserLastName: 'Huffman',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: 'Analyst',
			PracticeArea: 'Adoption',
			UserId: 15900,
			UserEmail: 'angela.huffman@bellefield.com',
			UserFirstName: 'Angela',
			UserLastName: 'Huffman',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 15781,
			UserEmail: 'eric.leach@bellefield.com',
			UserFirstName: 'Eric',
			UserLastName: 'Leach',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 19045,
			UserEmail: 'mario.martin@aderant.com',
			UserFirstName: 'mario',
			UserLastName: 'martin',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 19070,
			UserEmail: 'mario.martin@bellefield.com',
			UserFirstName: 'Mario',
			UserLastName: 'Martin',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: null,
			PracticeArea: null,
			UserId: 19041,
			UserEmail: 'jason.milliken@aderant.com',
			UserFirstName: 'Jason',
			UserLastName: 'Milliken',
		},
		{
			Timescore: 'B-',
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 18970,
			UserEmail: 'timekeeperonly@email.com',
			UserFirstName: 'Timekeeper',
			UserLastName: 'Only',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 15903,
			UserEmail: 'bellefieldautomationtest+lockedoutuser@gmail.com',
			UserFirstName: 'Locked',
			UserLastName: 'Out',
		},
		{
			Timescore: 'B ',
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 18958,
			UserEmail: 'tara.quinn@bellefield.com',
			UserFirstName: 'Tara',
			UserLastName: 'Quinn',
		},
		{
			Timescore: 'C-',
			PreviousTimescore: 'C-',
			Title: 'Consultant',
			PracticeArea: 'Auto Dealer Fraud',
			UserId: 15884,
			UserEmail: 'desmond.russell@bellefield.com',
			UserFirstName: 'Desmond',
			UserLastName: 'Russell',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 18993,
			UserEmail: 'rose.silfani@bellefield.com',
			UserFirstName: 'Rose',
			UserLastName: 'Silfani',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: 'Associate',
			PracticeArea: 'Adoption',
			UserId: 19030,
			UserEmail: 'ephraim.smith@aderant.com',
			UserFirstName: 'Ephraim',
			UserLastName: 'Smith',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: 'Partner',
			PracticeArea: 'Alcoholic Beverage Law',
			UserId: 18948,
			UserEmail: 'ephraim.smith@bellefield.com',
			UserFirstName: 'Ephraim',
			UserLastName: 'Smith',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14666,
			UserEmail: 'test12@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14667,
			UserEmail: 'test13@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14668,
			UserEmail: 'test14@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14669,
			UserEmail: 'test15@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14670,
			UserEmail: 'test16@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14671,
			UserEmail: 'test17@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14672,
			UserEmail: 'test18@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14673,
			UserEmail: 'test20@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14674,
			UserEmail: 'test19@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14675,
			UserEmail: 'test21@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14676,
			UserEmail: 'test22@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14677,
			UserEmail: 'test23@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14678,
			UserEmail: 'test24@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14679,
			UserEmail: 'test25@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14680,
			UserEmail: 'test260@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14681,
			UserEmail: 'test27@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14682,
			UserEmail: 'test28@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 19056,
			UserEmail: 'morepasswordtests@test.com',
			UserFirstName: 'test',
			UserLastName: 'test',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: null,
			PracticeArea: null,
			UserId: 15935,
			UserEmail: 'attorney9@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Delegation',
			UserLastName: 'Tester',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 15930,
			UserEmail: 'attorney3@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 3',
			UserLastName: 'TestUser',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 15933,
			UserEmail: 'attorney6@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 6',
			UserLastName: 'TestUser',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 15934,
			UserEmail: 'attorney7@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 7',
			UserLastName: 'TestUser',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 19351,
			UserEmail: 'kate.titchenal@aderant.com',
			UserFirstName: 'Kate',
			UserLastName: 'Titchenal',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: 'Consultant',
			PracticeArea: '',
			UserId: 18996,
			UserEmail: 'priautomation@bellefield.com',
			UserFirstName: 'PriAutomation',
			UserLastName: 'Trial',
		},
		{
			Timescore: null,
			PreviousTimescore: null,
			Title: '',
			PracticeArea: '',
			UserId: 14685,
			UserEmail: 'devfirmpolicyreport@bellefield.com',
			UserFirstName: 'Report',
			UserLastName: 'User',
		},
	];

	static userTimescoreHistoryDTO = [
		{
			Day: '2020-05-21T00:00:00',
			Timescore: 'A+',
			FormattedDay: '05/21/2020',
		},
		{
			Day: '2020-05-05T00:00:00',
			Timescore: 'A+',
			FormattedDay: '05/05/2020',
		},
		{
			Day: '2020-04-28T00:00:00',
			Timescore: 'A-',
			FormattedDay: '04/28/2020',
		},
		{
			Day: '2020-04-22T00:00:00',
			Timescore: 'F-',
			FormattedDay: '04/22/2020',
		},
		{
			Day: '2020-04-20T00:00:00',
			Timescore: 'A-',
			FormattedDay: '04/20/2020',
		},
		{
			Day: '2020-04-16T00:00:00',
			Timescore: 'C ',
			FormattedDay: '04/16/2020',
		},
		{
			Day: '2020-03-23T00:00:00',
			Timescore: 'A-',
			FormattedDay: '03/23/2020',
		},
		{
			Day: '2020-03-13T00:00:00',
			Timescore: 'A-',
			FormattedDay: '03/13/2020',
		},
	];

	static usersComplianceDTO = [
		{
			UserId: 219,
			UserEmail: 'whitney.ruffin@bellefield.com',
			UserFirstName: 'Whitney',
			UserLastName: 'Ruffin',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: '',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 1282,
			UserEmail: 'juamangomalv@gmail.com',
			UserFirstName: 'Juan',
			UserLastName: 'Gómez',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: null,
			Title: null,
			PracticeArea: null,
		},
		{
			UserId: 14523,
			UserEmail: 'automation@bellefield.com',
			UserFirstName: 'automation',
			UserLastName: 'Test a<>',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: null,
			Title: null,
			PracticeArea: null,
		},
		{
			UserId: 14645,
			UserEmail: 'notusingpenalty@bellefield.com',
			UserFirstName: 'Not Using',
			UserLastName: 'Then Status Change NC/C',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: null,
			Title: null,
			PracticeArea: null,
		},
		{
			UserId: 14646,
			UserEmail: 'rockstar@bellefield.com',
			UserFirstName: 'Rockstar',
			UserLastName: 'Then Status change NC/NU',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: null,
			Title: null,
			PracticeArea: null,
		},
		{
			UserId: 14647,
			UserEmail: 'compliant@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'Then Not Compliant',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: null,
			Title: null,
			PracticeArea: null,
		},
		{
			UserId: 14648,
			UserEmail: 'compliantvelocity@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'Velocity',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14649,
			UserEmail: 'compliantmin@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'Min',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14650,
			UserEmail: 'compliantmax@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'Mvax',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14651,
			UserEmail: 'compliantontime@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'On',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14652,
			UserEmail: 'notcompliant@bellefield.com',
			UserFirstName: 'Not',
			UserLastName: 'Compliant',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14653,
			UserEmail: 'notcomplaintvelocity@bellefield.com',
			UserFirstName: 'Not',
			UserLastName: 'Compliant',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14654,
			UserEmail: 'notcompliantmin@bellefield.com',
			UserFirstName: 'Not',
			UserLastName: 'Compliant',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14656,
			UserEmail: 'test2@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14657,
			UserEmail: 'test3@bellefield.com',
			UserFirstName: 'Jake',
			UserLastName: 'Rowe',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14658,
			UserEmail: 'test4@bellefield.com',
			UserFirstName: 'Whitney',
			UserLastName: 'Miller',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14659,
			UserEmail: 'test5@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14660,
			UserEmail: 'test6@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14661,
			UserEmail: 'test7@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14662,
			UserEmail: 'test8@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14663,
			UserEmail: 'test9@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14664,
			UserEmail: 'test10@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14665,
			UserEmail: 'test11@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14666,
			UserEmail: 'test12@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14667,
			UserEmail: 'test13@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14668,
			UserEmail: 'test14@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14669,
			UserEmail: 'test15@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14670,
			UserEmail: 'test16@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14671,
			UserEmail: 'test17@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14672,
			UserEmail: 'test18@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14673,
			UserEmail: 'test20@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14674,
			UserEmail: 'test19@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14675,
			UserEmail: 'test21@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14676,
			UserEmail: 'test22@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14677,
			UserEmail: 'test23@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14678,
			UserEmail: 'test24@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14679,
			UserEmail: 'test25@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14680,
			UserEmail: 'test260@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14681,
			UserEmail: 'test27@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14682,
			UserEmail: 'test28@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 14685,
			UserEmail: 'devfirmpolicyreport@bellefield.com',
			UserFirstName: 'Report',
			UserLastName: 'User',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 15723,
			UserEmail: 'craig.anderson@bellefield.com',
			UserFirstName: 'Craig',
			UserLastName: 'Anderson',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -240.0,
			FormattedAverageVelocity: '4.60',
			AverageVelocity: 4.6,
			TotalWorkHours: 22.4,
			TotalBillableWorkHours: 22.4,
			AverageGranularity: 4.48,
			FormattedAverageGranularity: '4.48',
			Timescore: 'D-',
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 2000,
			MinMaxHoursComplianceResult: 3000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 5,
			ProblemReason: 'Did not meet velocity goal. ',
			Location: 'Pittsburgh',
			Title: 'Of Counsel',
			PracticeArea: 'Bankruptcy',
		},
		{
			UserId: 15878,
			UserEmail: 'attorney1@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney1',
			UserLastName: 'AzureAD',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '0.50',
			AverageVelocity: 0.5,
			TotalWorkHours: 1.0,
			TotalBillableWorkHours: 1.0,
			AverageGranularity: 0.5,
			FormattedAverageGranularity: '0.50',
			Timescore: 'B ',
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 3000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 1,
			ITKCreatedByDelegateCount: 1,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 15879,
			UserEmail: 'attorney2@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney2',
			UserLastName: 'AzureAD',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: null,
			Title: null,
			PracticeArea: null,
		},
		{
			UserId: 15884,
			UserEmail: 'desmond.russell@bellefield.com',
			UserFirstName: 'Desmond',
			UserLastName: 'Russell',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -253.0,
			FormattedAverageVelocity: '6.43',
			AverageVelocity: 6.43,
			TotalWorkHours: 117.8,
			TotalBillableWorkHours: 117.8,
			AverageGranularity: 2.97,
			FormattedAverageGranularity: '2.97',
			Timescore: 'D-',
			HadTimecardCreatedAfterGracePeriod: 'Yes',
			TotalHoursCreatedAfterGracePeriod: 1.25,
			TotalBillableHoursCreatedAfterGracePeriod: 1.25,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 2000,
			MinMaxHoursComplianceResult: 3000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 40,
			ITKCreatedByDelegateCount: 4,
			ProblemReason: 'Some entries were created late. Did not meet velocity goal. ',
			Location: 'Boston',
			Title: 'Legal Assistant',
			PracticeArea: 'Bankruptcy',
		},
		{
			UserId: 15900,
			UserEmail: 'angela.huffman@bellefield.com',
			UserFirstName: 'Angela',
			UserLastName: 'Huffman',
			ComplianceStatus: 'Rockstar',
			TotalCashReward: 377.0,
			TotalCashPenalty: 0.0,
			FormattedAverageVelocity: '0.00',
			AverageVelocity: 0.0,
			TotalWorkHours: 15.0,
			TotalBillableWorkHours: 15.0,
			AverageGranularity: 2.5,
			FormattedAverageGranularity: '2.50',
			Timescore: 'A-',
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 3000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 10,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: null,
			Location: '',
			Title: 'Analyst',
			PracticeArea: 'Adoption',
		},
		{
			UserId: 15903,
			UserEmail: 'bellefieldautomationtest+lockedoutuser@gmail.com',
			UserFirstName: 'Locked',
			UserLastName: 'Out',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 15930,
			UserEmail: 'attorney3@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 3',
			UserLastName: 'TestUser',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -263.0,
			FormattedAverageVelocity: '31.00',
			AverageVelocity: 31.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.25,
			FormattedAverageGranularity: '0.25',
			Timescore: 'F+',
			HadTimecardCreatedAfterGracePeriod: 'Yes',
			TotalHoursCreatedAfterGracePeriod: 0.25,
			TotalBillableHoursCreatedAfterGracePeriod: 0.25,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 2000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 1,
			ProblemReason: 'Did not meet minimum hours. Some entries were created late. Did not meet velocity goal. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 15933,
			UserEmail: 'attorney6@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'TestAttorney 6',
			UserLastName: 'TestUser',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 15934,
			UserEmail: 'attorney7@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 7',
			UserLastName: 'TestUser',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 15935,
			UserEmail: 'attorney9@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Delegation',
			UserLastName: 'Tester',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: null,
			Title: null,
			PracticeArea: null,
		},
		{
			UserId: 18947,
			UserEmail: 'priyanka.kumar@bellefield.com',
			UserFirstName: 'Priyanka',
			UserLastName: 'Kumar',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -13.0,
			FormattedAverageVelocity: '1.48',
			AverageVelocity: 1.48,
			TotalWorkHours: 4.62,
			TotalBillableWorkHours: 4.62,
			AverageGranularity: 0.63,
			FormattedAverageGranularity: '0.63',
			Timescore: 'C ',
			HadTimecardCreatedAfterGracePeriod: 'Yes',
			TotalHoursCreatedAfterGracePeriod: 2.0,
			TotalBillableHoursCreatedAfterGracePeriod: 2.0,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 3000,
			MinMaxHoursComplianceResult: 3000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 26,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Some entries were created late. ',
			Location: 'Pittsburgh',
			Title: 'Consultant',
			PracticeArea: 'Science and Technology',
		},
		{
			UserId: 18948,
			UserEmail: 'ephraim.smith@bellefield.com',
			UserFirstName: 'Ephraim',
			UserLastName: 'Smith',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: 'Partner',
			PracticeArea: 'Alcoholic Beverage Law',
		},
		{
			UserId: 18958,
			UserEmail: 'tara.quinn@bellefield.com',
			UserFirstName: 'Tara',
			UserLastName: 'Quinn',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: '',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 18961,
			UserEmail: 'daniel.barr@bellefield.com',
			UserFirstName: 'Daniel',
			UserLastName: 'Barr',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: 'Consultant',
			PracticeArea: 'Chancery and Equity',
		},
		{
			UserId: 18966,
			UserEmail: 'lisa.greer@bellefield.com',
			UserFirstName: 'Lisa',
			UserLastName: 'Greer',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -240.0,
			FormattedAverageVelocity: '3.50',
			AverageVelocity: 3.5,
			TotalWorkHours: 2.93,
			TotalBillableWorkHours: 2.93,
			AverageGranularity: 1.47,
			FormattedAverageGranularity: '1.47',
			Timescore: 'D-',
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 2000,
			MinMaxHoursComplianceResult: 3000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 2,
			ProblemReason: 'Did not meet velocity goal. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 18970,
			UserEmail: 'timekeeperonly@email.com',
			UserFirstName: 'Timekeeper',
			UserLastName: 'Only',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Pittsburgh',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 18993,
			UserEmail: 'rose.silfani@bellefield.com',
			UserFirstName: 'Rose',
			UserLastName: 'Silfani',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Boston',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 18994,
			UserEmail: 'laurie.blackhurst@bellefield.com',
			UserFirstName: 'Laurie',
			UserLastName: 'Blackhurst',
			ComplianceStatus: 'Rockstar',
			TotalCashReward: 377.0,
			TotalCashPenalty: 0.0,
			FormattedAverageVelocity: '0.00',
			AverageVelocity: 0.0,
			TotalWorkHours: 16.93,
			TotalBillableWorkHours: 16.93,
			AverageGranularity: 1.69,
			FormattedAverageGranularity: '1.69',
			Timescore: 'A-',
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 3000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 8,
			ITKCreatedByDelegateCount: 1,
			ProblemReason: null,
			Location: 'Boston',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 18996,
			UserEmail: 'priautomation@bellefield.com',
			UserFirstName: 'PriAutomation',
			UserLastName: 'Trial',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '',
			Timescore: null,
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 0,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Boston',
			Title: 'Consultant',
			PracticeArea: '',
		},
		{
			UserId: 19004,
			UserEmail: 'marie.burgess@aderant.com',
			UserFirstName: 'Marie',
			UserLastName: 'Burgess',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '0.00',
			AverageVelocity: 0.0,
			TotalWorkHours: 2.0,
			TotalBillableWorkHours: 1.0,
			AverageGranularity: 0.4,
			FormattedAverageGranularity: '0.40',
			Timescore: 'A+',
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 5,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: null,
			Title: null,
			PracticeArea: null,
		},
		{
			UserId: 19005,
			UserEmail: 'robert.domico@aderant.com',
			UserFirstName: 'Robert',
			UserLastName: 'Domico',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -253.0,
			FormattedAverageVelocity: '15.71',
			AverageVelocity: 15.71,
			TotalWorkHours: 42.0,
			TotalBillableWorkHours: 42.0,
			AverageGranularity: 7.57,
			FormattedAverageGranularity: '7.57',
			Timescore: 'F-',
			HadTimecardCreatedAfterGracePeriod: 'Yes',
			TotalHoursCreatedAfterGracePeriod: 11.0,
			TotalBillableHoursCreatedAfterGracePeriod: 11.0,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 2000,
			MinMaxHoursComplianceResult: 3000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 7,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Some entries were created late. Did not meet velocity goal. ',
			Location: 'Boston',
			Title: '',
			PracticeArea: '',
		},
		{
			UserId: 19013,
			UserEmail: 'brendan.roy@aderant.com',
			UserFirstName: 'Brendan',
			UserLastName: 'Roy',
			ComplianceStatus: 'Not Compliant',
			TotalCashReward: 0.0,
			TotalCashPenalty: -10.0,
			FormattedAverageVelocity: '0.00',
			AverageVelocity: 0.0,
			TotalWorkHours: 0.0,
			TotalBillableWorkHours: 0.0,
			AverageGranularity: 0.0,
			FormattedAverageGranularity: '0.00',
			Timescore: 'A+',
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 4000,
			MinMaxHoursComplianceResult: 2000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 14,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: 'Did not meet minimum hours. ',
			Location: 'Portland',
			Title: 'Consultant',
			PracticeArea: 'Chancery and Equity',
		},
		{
			UserId: 19030,
			UserEmail: 'ephraim.smith@aderant.com',
			UserFirstName: 'Ephraim',
			UserLastName: 'Smith',
			ComplianceStatus: 'Compliant',
			TotalCashReward: 147.0,
			TotalCashPenalty: 0.0,
			FormattedAverageVelocity: '3.00',
			AverageVelocity: 3.0,
			TotalWorkHours: 24.0,
			TotalBillableWorkHours: 24.0,
			AverageGranularity: 2.4,
			FormattedAverageGranularity: '2.40',
			Timescore: 'C-',
			HadTimecardCreatedAfterGracePeriod: '',
			TotalHoursCreatedAfterGracePeriod: null,
			TotalBillableHoursCreatedAfterGracePeriod: null,
			AssistantComplianceResult: 0,
			VelocityComplianceResult: 3000,
			MinMaxHoursComplianceResult: 3000,
			ITKUsageComplianceResult: 0,
			ITKCreatedByOwnerCount: 8,
			ITKCreatedByDelegateCount: 0,
			ProblemReason: null,
			Location: 'Pittsburgh',
			Title: 'Analyst',
			PracticeArea: 'Adoption',
		},
	];

	static usersGoalsDTO = [
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15723,
			UserEmail: 'craig.anderson@bellefield.com',
			UserFirstName: 'Craig',
			UserLastName: 'Anderson',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15878,
			UserEmail: 'attorney1@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney1',
			UserLastName: 'AzureAD',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15879,
			UserEmail: 'attorney2@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney2',
			UserLastName: 'AzureAD',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 18961,
			UserEmail: 'daniel.barr@bellefield.com',
			UserFirstName: 'Daniel',
			UserLastName: 'Barr',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 18994,
			UserEmail: 'laurie.blackhurst@bellefield.com',
			UserFirstName: 'Laurie',
			UserLastName: 'Blackhurst',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 19004,
			UserEmail: 'marie.burgess@aderant.com',
			UserFirstName: 'Marie',
			UserLastName: 'Burgess',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14652,
			UserEmail: 'notcompliant@bellefield.com',
			UserFirstName: 'Not',
			UserLastName: 'Compliant',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14653,
			UserEmail: 'notcomplaintvelocity@bellefield.com',
			UserFirstName: 'Not',
			UserLastName: 'Compliant',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14654,
			UserEmail: 'notcompliantmin@bellefield.com',
			UserFirstName: 'Not',
			UserLastName: 'Compliant',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 19005,
			UserEmail: 'robert.domico@aderant.com',
			UserFirstName: 'Robert',
			UserLastName: 'Domico',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 1282,
			UserEmail: 'juamangomalv@gmail.com',
			UserFirstName: 'Juan',
			UserLastName: 'Gómez',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 18966,
			UserEmail: 'lisa.greer@bellefield.com',
			UserFirstName: 'Lisa',
			UserLastName: 'Greer',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15900,
			UserEmail: 'angela.huffman@bellefield.com',
			UserFirstName: 'Angela',
			UserLastName: 'Huffman',
		},
		{
			Hours: 2.02,
			Goal: 8.0,
			Difference: -5.98,
			UserId: 18947,
			UserEmail: 'priyanka.kumar@bellefield.com',
			UserFirstName: 'Priyanka',
			UserLastName: 'Kumar',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14658,
			UserEmail: 'test4@bellefield.com',
			UserFirstName: 'Whitney',
			UserLastName: 'Miller',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14649,
			UserEmail: 'compliantmin@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'Min',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14650,
			UserEmail: 'compliantmax@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'Mvax',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14651,
			UserEmail: 'compliantontime@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'On',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 18970,
			UserEmail: 'timekeeperonly@email.com',
			UserFirstName: 'Timekeeper',
			UserLastName: 'Only',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15903,
			UserEmail: 'bellefieldautomationtest+lockedoutuser@gmail.com',
			UserFirstName: 'Locked',
			UserLastName: 'Out',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 18958,
			UserEmail: 'tara.quinn@bellefield.com',
			UserFirstName: 'Tara',
			UserLastName: 'Quinn',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14657,
			UserEmail: 'test3@bellefield.com',
			UserFirstName: 'Jake',
			UserLastName: 'Rowe',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 19013,
			UserEmail: 'brendan.roy@aderant.com',
			UserFirstName: 'Brendan',
			UserLastName: 'Roy',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 219,
			UserEmail: 'whitney.ruffin@bellefield.com',
			UserFirstName: 'Whitney',
			UserLastName: 'Ruffin',
		},
		{
			Hours: 6.65,
			Goal: 8.0,
			Difference: -1.35,
			UserId: 15884,
			UserEmail: 'desmond.russell@bellefield.com',
			UserFirstName: 'Desmond',
			UserLastName: 'Russell',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 18993,
			UserEmail: 'rose.silfani@bellefield.com',
			UserFirstName: 'Rose',
			UserLastName: 'Silfani',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 18948,
			UserEmail: 'ephraim.smith@bellefield.com',
			UserFirstName: 'Ephraim',
			UserLastName: 'Smith',
		},
		{
			Hours: 1.5,
			Goal: 8.0,
			Difference: -6.5,
			UserId: 19030,
			UserEmail: 'ephraim.smith@aderant.com',
			UserFirstName: 'Ephraim',
			UserLastName: 'Smith',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14656,
			UserEmail: 'test2@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14659,
			UserEmail: 'test5@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14660,
			UserEmail: 'test6@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14661,
			UserEmail: 'test7@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14662,
			UserEmail: 'test8@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14663,
			UserEmail: 'test9@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14664,
			UserEmail: 'test10@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14665,
			UserEmail: 'test11@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14666,
			UserEmail: 'test12@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14667,
			UserEmail: 'test13@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14668,
			UserEmail: 'test14@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14669,
			UserEmail: 'test15@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14670,
			UserEmail: 'test16@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14671,
			UserEmail: 'test17@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14672,
			UserEmail: 'test18@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14673,
			UserEmail: 'test20@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14674,
			UserEmail: 'test19@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14675,
			UserEmail: 'test21@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14676,
			UserEmail: 'test22@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14677,
			UserEmail: 'test23@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14678,
			UserEmail: 'test24@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14679,
			UserEmail: 'test25@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14680,
			UserEmail: 'test260@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14681,
			UserEmail: 'test27@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14682,
			UserEmail: 'test28@bellefield.com',
			UserFirstName: 'Test',
			UserLastName: 'Test',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14523,
			UserEmail: 'automation@bellefield.com',
			UserFirstName: 'automation',
			UserLastName: 'Test a<>',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15935,
			UserEmail: 'attorney9@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Delegation',
			UserLastName: 'Tester',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15930,
			UserEmail: 'attorney3@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 3',
			UserLastName: 'TestUser',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15933,
			UserEmail: 'attorney6@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'TestAttorney 6',
			UserLastName: 'TestUser',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 15934,
			UserEmail: 'attorney7@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 7',
			UserLastName: 'TestUser',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14647,
			UserEmail: 'compliant@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'Then Not Compliant',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14645,
			UserEmail: 'notusingpenalty@bellefield.com',
			UserFirstName: 'Not Using',
			UserLastName: 'Then Status Change NC/C',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14646,
			UserEmail: 'rockstar@bellefield.com',
			UserFirstName: 'Rockstar',
			UserLastName: 'Then Status change NC/NU',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 18996,
			UserEmail: 'priautomation@bellefield.com',
			UserFirstName: 'PriAutomation',
			UserLastName: 'Trial',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14685,
			UserEmail: 'devfirmpolicyreport@bellefield.com',
			UserFirstName: 'Report',
			UserLastName: 'User',
		},
		{
			Hours: 0.0,
			Goal: 8.0,
			Difference: -8.0,
			UserId: 14648,
			UserEmail: 'compliantvelocity@bellefield.com',
			UserFirstName: 'Compliant',
			UserLastName: 'Velocity',
		},
	];

	static usersContemporaneousDTO = [
		{
			ContemporaneousHoursTotalPercent: 100.0,
			ContemporaneousCardsCountPercent: 100.0,
			UserId: 15900,
			UserEmail: 'angela.huffman@bellefield.com',
			UserFirstName: 'Angela',
			UserLastName: 'Huffman',
		},
		{
			ContemporaneousHoursTotalPercent: 0.0,
			ContemporaneousCardsCountPercent: 0.0,
			UserId: 15930,
			UserEmail: 'attorney3@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney 3',
			UserLastName: 'TestUser',
		},
		{
			ContemporaneousHoursTotalPercent: 0.0,
			ContemporaneousCardsCountPercent: 50.0,
			UserId: 15878,
			UserEmail: 'attorney1@testlawfirm1.onmicrosoft.com',
			UserFirstName: 'Attorney1',
			UserLastName: 'AzureAD',
		},
		{
			ContemporaneousHoursTotalPercent: 0.0,
			ContemporaneousCardsCountPercent: 100.0,
			UserId: 19013,
			UserEmail: 'brendan.roy@aderant.com',
			UserFirstName: 'Brendan',
			UserLastName: 'Roy',
		},
		{
			ContemporaneousHoursTotalPercent: 60.71428571428571,
			ContemporaneousCardsCountPercent: 60.0,
			UserId: 15723,
			UserEmail: 'craig.anderson@bellefield.com',
			UserFirstName: 'Craig',
			UserLastName: 'Anderson',
		},
		{
			ContemporaneousHoursTotalPercent: 37.45485090298194,
			ContemporaneousCardsCountPercent: 36.363636363636,
			UserId: 15884,
			UserEmail: 'desmond.russell@bellefield.com',
			UserFirstName: 'Desmond',
			UserLastName: 'Russell',
		},
		{
			ContemporaneousHoursTotalPercent: 50.0,
			ContemporaneousCardsCountPercent: 60.0,
			UserId: 19030,
			UserEmail: 'ephraim.smith@aderant.com',
			UserFirstName: 'Ephraim',
			UserLastName: 'Smith',
		},
		{
			ContemporaneousHoursTotalPercent: 100.0,
			ContemporaneousCardsCountPercent: 100.0,
			UserId: 18994,
			UserEmail: 'laurie.blackhurst@bellefield.com',
			UserFirstName: 'Laurie',
			UserLastName: 'Blackhurst',
		},
		{
			ContemporaneousHoursTotalPercent: 37.54266211604095,
			ContemporaneousCardsCountPercent: 50.0,
			UserId: 18966,
			UserEmail: 'lisa.greer@bellefield.com',
			UserFirstName: 'Lisa',
			UserLastName: 'Greer',
		},
		{
			ContemporaneousHoursTotalPercent: 100.0,
			ContemporaneousCardsCountPercent: 100.0,
			UserId: 19004,
			UserEmail: 'marie.burgess@aderant.com',
			UserFirstName: 'Marie',
			UserLastName: 'Burgess',
		},
		{
			ContemporaneousHoursTotalPercent: 69.78851963746223,
			ContemporaneousCardsCountPercent: 88.888888888888,
			UserId: 18947,
			UserEmail: 'priyanka.kumar@bellefield.com',
			UserFirstName: 'Priyanka',
			UserLastName: 'Kumar',
		},
		{
			ContemporaneousHoursTotalPercent: 9.43396226415094,
			ContemporaneousCardsCountPercent: 14.285714285714,
			UserId: 19005,
			UserEmail: 'robert.domico@aderant.com',
			UserFirstName: 'Robert',
			UserLastName: 'Domico',
		},
	];

	static policiesForSubscriptionDTO = [
		// {
		// 	PolicyId: 85,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2020-05-06T16:48:51.353',
		// 	CreatedByUserId: 15618,
		// 	CreatedByUserName: 'Miguel Gomez',
		// 	UpdatedByUserId: null,
		// 	UpdatedByUserName: '',
		// 	UpdatedOn: null,
		// 	LastRanDate: null,
		// 	CurrentPeriod: null,
		// 	NextPeriodToRun: null,
		// 	NextPeriodEffectiveGraceDays: null,
		// 	PolicySerialized: null,
		// 	PolicyName: '',
		// },
		// {
		// 	PolicyId: 81,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2020-02-28T13:49:58.767',
		// 	CreatedByUserId: 15928,
		// 	CreatedByUserName: 'Carlos Bello',
		// 	UpdatedByUserId: 15928,
		// 	UpdatedByUserName: 'Carlos Bello',
		// 	UpdatedOn: '2020-03-19T14:34:06.567',
		// 	LastRanDate: null,
		// 	CurrentPeriod: null,
		// 	NextPeriodToRun: null,
		// 	NextPeriodEffectiveGraceDays: null,
		// 	PolicySerialized: null,
		// 	PolicyName: 'carlos policy',
		// },
		// {
		// 	PolicyId: 66,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2020-01-27T14:51:14.77',
		// 	CreatedByUserId: 19005,
		// 	CreatedByUserName: 'Robert Domico',
		// 	UpdatedByUserId: 15928,
		// 	UpdatedByUserName: 'Carlos Bello',
		// 	UpdatedOn: '2020-05-07T17:11:53.037',
		// 	LastRanDate: '2020-05-04T11:00:23.623',
		// 	CurrentPeriod: '2020-04-01T00:00:00',
		// 	NextPeriodToRun: '2020-05-01T00:00:00',
		// 	NextPeriodEffectiveGraceDays: 4,
		// 	PolicySerialized: null,
		// 	PolicyName: 'Copy of copy of CraigTestPolicy - 1',
		// },
		// {
		// 	PolicyId: 22,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2018-10-19T13:55:44.67',
		// 	CreatedByUserId: 15723,
		// 	CreatedByUserName: 'Craig Anderson',
		// 	UpdatedByUserId: 19005,
		// 	UpdatedByUserName: 'Robert Domico',
		// 	UpdatedOn: '2020-03-25T14:39:53.777',
		// 	LastRanDate: '2020-05-05T11:00:18.44',
		// 	CurrentPeriod: '2020-04-01T00:00:00',
		// 	NextPeriodToRun: '2020-05-01T00:00:00',
		// 	NextPeriodEffectiveGraceDays: 4,
		// 	PolicySerialized: null,
		// 	PolicyName: 'Copy of CraigTestPolicy - 1',
		// },
		// {
		// 	PolicyId: 23,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2018-10-19T19:58:40.427',
		// 	CreatedByUserId: 15723,
		// 	CreatedByUserName: 'Craig Anderson',
		// 	UpdatedByUserId: 19030,
		// 	UpdatedByUserName: 'Ephraim Smith',
		// 	UpdatedOn: '2020-03-30T17:24:46.2',
		// 	LastRanDate: '2018-10-22T19:36:17.4',
		// 	CurrentPeriod: '2018-06-01T00:00:00',
		// 	NextPeriodToRun: '2018-07-01T00:00:00',
		// 	NextPeriodEffectiveGraceDays: 3,
		// 	PolicySerialized: null,
		// 	PolicyName: 'Copy of CraigTestPolicy - 2',
		// },
		// {
		// 	PolicyId: 24,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2018-10-19T20:02:54.883',
		// 	CreatedByUserId: 15723,
		// 	CreatedByUserName: 'Craig Anderson',
		// 	UpdatedByUserId: 15723,
		// 	UpdatedByUserName: 'Craig Anderson',
		// 	UpdatedOn: '2018-10-19T20:23:53.323',
		// 	LastRanDate: '2018-10-22T19:36:21.387',
		// 	CurrentPeriod: '2018-06-01T00:00:00',
		// 	NextPeriodToRun: '2018-07-01T00:00:00',
		// 	NextPeriodEffectiveGraceDays: 3,
		// 	PolicySerialized: null,
		// 	PolicyName: 'Copy of CraigTestPolicy - 3',
		// },
		// {
		// 	PolicyId: 25,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2018-10-19T20:24:15.93',
		// 	CreatedByUserId: 15723,
		// 	CreatedByUserName: 'Craig Anderson',
		// 	UpdatedByUserId: 19005,
		// 	UpdatedByUserName: 'Robert Domico',
		// 	UpdatedOn: '2020-04-02T13:43:12.757',
		// 	LastRanDate: '2018-10-22T19:36:26.307',
		// 	CurrentPeriod: '2018-06-01T00:00:00',
		// 	NextPeriodToRun: '2018-07-01T00:00:00',
		// 	NextPeriodEffectiveGraceDays: 3,
		// 	PolicySerialized: null,
		// 	PolicyName: 'Copy of CraigTestPolicy - 4',
		// },
		// {
		// 	PolicyId: 82,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2020-03-12T13:36:04.353',
		// 	CreatedByUserId: 15928,
		// 	CreatedByUserName: 'Carlos Bello',
		// 	UpdatedByUserId: 15928,
		// 	UpdatedByUserName: 'Carlos Bello',
		// 	UpdatedOn: '2020-03-19T14:34:52.433',
		// 	LastRanDate: null,
		// 	CurrentPeriod: null,
		// 	NextPeriodToRun: null,
		// 	NextPeriodEffectiveGraceDays: null,
		// 	PolicySerialized: null,
		// 	PolicyName: 'Miguel policy',
		// },
		// {
		// 	PolicyId: 84,
		// 	SubscriptionId: 756,
		// 	SubscriptionName: 'Craig OCG Testing',
		// 	CreatedOn: '2020-04-30T15:24:04.39',
		// 	CreatedByUserId: 19030,
		// 	CreatedByUserName: 'Ephraim Smith',
		// 	UpdatedByUserId: 19030,
		// 	UpdatedByUserName: 'Ephraim Smith',
		// 	UpdatedOn: '2020-04-30T17:17:49.497',
		// 	LastRanDate: '2020-05-02T11:00:16.23',
		// 	CurrentPeriod: '2020-04-01T00:00:00',
		// 	NextPeriodToRun: '2020-05-01T00:00:00',
		// 	NextPeriodEffectiveGraceDays: 0,
		// 	PolicySerialized: null,
		// 	PolicyName: 'User "One Policy\'s" only Thrive policy',
		// },
	];

	static policyPeriodsDTO = [
		'2021-08-01T00:00:00',
		'2021-07-01T00:00:00',
		'2021-06-01T00:00:00',
		'2021-05-01T00:00:00',
		'2021-04-01T00:00:00',
		'2021-03-01T00:00:00',
		'2021-02-01T00:00:00',
		'2021-01-01T00:00:00',
		'2020-12-01T00:00:00',
		'2020-11-01T00:00:00',
		'2020-10-01T00:00:00',
		'2020-09-01T00:00:00',
	];

	static policyPeriodsDTOWithCurrentDate = [
		'2021-10-01T00:00:00',
		'2021-09-01T00:00:00',
		'2021-08-01T00:00:00',
		'2021-07-01T00:00:00',
		'2021-06-01T00:00:00',
		'2021-05-01T00:00:00',
		'2021-04-01T00:00:00',
		'2021-03-01T00:00:00',
		'2021-02-01T00:00:00',
		'2021-01-01T00:00:00',
		'2020-12-01T00:00:00',
		'2020-11-01T00:00:00',
		'2020-10-01T00:00:00',
		'2020-09-01T00:00:00',
	];

	static policyDTO = {
		PolicyId: 66,
		PolicyVersion: 37,
		EngineVersion: 1,
		PolicyName: 'Copy of copy of CraigTestPolicy - 1',
		FiscalYearStartMonth: 1,
		IncludeGranularity: false,
		Timescores: [
			{
				Grade: 'A',
				Limit: 0.2,
			},
			{
				Grade: 'B',
				Limit: 1,
			},
			{
				Grade: 'C',
				Limit: 3,
			},
			{
				Grade: 'D',
				Limit: 7,
			},
		],
		Velocity: {
			Rewards: [
				{
					LevelName: 'NotCompliant',
					Level: 2000,
					Reward: {
						Cash: -240.0,
						NonCash: [],
					},
				},
				{
					LevelName: 'Compliant',
					Level: 3000,
					Reward: {
						Cash: 20.0,
						NonCash: [],
					},
				},
				{
					LevelName: 'RockStar',
					Level: 4000,
					Reward: {
						Cash: 250.0,
						NonCash: [],
					},
				},
			],
			AverageVelocity: 5.0,
			AverageVelocityRockstar: 0.0,
			Disabled: false,
			Notifications: {},
		},
		MinMaxHours: {
			Rewards: [
				{
					LevelName: 'NotCompliant',
					Level: 2000,
					Reward: {
						Cash: -10.0,
						NonCash: [],
					},
				},
				{
					LevelName: 'Compliant',
					Level: 3000,
					Reward: {
						Cash: 111.0,
						NonCash: [],
					},
				},
			],
			MinHours: 2.0,
			MaxHours: 30.0,
			MinHoursList: [5.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
			UseBillableForEvaluation: true,
			Disabled: false,
			Notifications: {},
		},
		MinMaxHoursDaily: {
			Rewards: [
				{
					LevelName: 'NotCompliant',
					Level: 2000,
					Reward: {
						Cash: -10.0,
						NonCash: [],
					},
				},
				{
					LevelName: 'Compliant',
					Level: 3000,
					Reward: {
						Cash: 111.0,
						NonCash: [],
					},
				},
			],
			MinHours: 8.0,
			MaxHours: 8.0,
			MinHoursList: null,
			UseBillableForEvaluation: true,
			Disabled: false,
			Notifications: {},
		},
		MinMaxHoursWeekly: {
			Rewards: [
				{
					LevelName: 'NotCompliant',
					Level: 2000,
					Reward: {
						Cash: -10.0,
						NonCash: [],
					},
				},
				{
					LevelName: 'Compliant',
					Level: 3000,
					Reward: {
						Cash: 111.0,
						NonCash: [],
					},
				},
			],
			MinHours: 60.0,
			MaxHours: 60.0,
			MinHoursList: null,
			UseBillableForEvaluation: true,
			Disabled: false,
			Notifications: {},
		},
		Assistant: {
			Rewards: [],
			MaxAssistantPercentage: 0.0,
			MaxAssistantPercentageRockstar: 0.0,
			Disabled: true,
			Notifications: {},
		},
		Deadlines: {
			Rewards: [
				{
					LevelName: 'NotCompliant',
					Level: 2000,
					Reward: {
						Cash: -13.0,
						NonCash: [],
					},
				},
				{
					LevelName: 'Compliant',
					Level: 3000,
					Reward: {
						Cash: 16.0,
						NonCash: [],
					},
				},
			],
			GraceDays: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
			Disabled: false,
			Notifications: {},
		},
		DeadlinesWeekly: {
			Rewards: [
				{
					LevelName: 'NotCompliant',
					Level: 2000,
					Reward: {
						Cash: -13.0,
						NonCash: [],
					},
				},
				{
					LevelName: 'Compliant',
					Level: 3000,
					Reward: {
						Cash: 16.0,
						NonCash: [],
					},
				},
			],
			GraceDays: [
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
				1,
			],
			Disabled: false,
			Notifications: {},
		},
		iTKUsage: {
			Rewards: [],
			MinimumPercentageTimecards: 0.0,
			Disabled: true,
			Notifications: {},
		},
	};

	static propertiesFakeDto = [
		{
			PropertyName: 'Applications.UserTheme.ColorTheme',
			PropertyValue: 'ADERANT',
			PropertyLevel: 50,
		},
	];
}

export class FakeDataUtils {
	static updateEntityId = (entity: Entity, newId: string) => {
		entity.id = newId;
		return entity;
	};

	static createUTCDate = (date: string) => {
		const dateParsed = new Date(date);
		const dateUTC = new Date(dateParsed.toLocaleString('en-US', {timeZone: 'UTC'}));
		return dateUTC;
	};
}

export class PolicyDashboardComparisonFake extends PolicyDashboardComparison {
	static createfrom(pdcDTO: PolicyDashboardComparisonDTO) {
		let pdc = new PolicyDashboardComparisonFake();
		pdc.policyId = pdcDTO.PolicyId;
		pdc.date = new Date(pdcDTO.Period);
		pdc.dateDisplay = pdcDTO.PeriodDisplay;
		pdc.periodGraceDays = pdcDTO.PeriodGraceDays;
		pdc.periodAverageVelocity = Maybe.fromNull(pdcDTO.PeriodAverageVelocity);
		pdc.periodAverageGranularity = Maybe.fromNull(pdcDTO.PeriodAverageGranularity);
		pdc.firmTimeScore = pdcDTO.FirmTimeScore;
		pdc.contemporaneousHoursTotalPercent = pdcDTO.ContemporaneousHoursTotalPercent;
		pdc.contemporaneousCardsCountPercent = pdcDTO.ContemporaneousCardsCountPercent;
		pdc.numberOfUsers = pdcDTO.NumberOfUsers;
		pdc.totalHoursCreatedAfterGracePeriod = pdcDTO.TotalHoursCreatedAfterGracePeriod;
		pdc.totalTimecardsCreatedAfterGracePeriod = pdcDTO.TotalTimecardsCreatedAfterGracePeriod;
		pdc.periodTotalWorkHours = pdcDTO.PeriodTotalWorkHours;
		pdc.totalBillableHoursCreatedAfterGracePeriod = pdcDTO.TotalBillableHoursCreatedAfterGracePeriod;
		pdc.totalBillableTimecardsCreatedAfterGracePeriod = pdcDTO.TotalBillableTimecardsCreatedAfterGracePeriod;
		pdc.periodTotalBillableWorkHours = pdcDTO.PeriodTotalBillableWorkHours;
		pdc.totalTimekeepersUsingiTK = pdcDTO.TotalTimekeepersUsingiTK;
		pdc.totalTimekeepersUsingDelegates = pdcDTO.TotalTimekeepersUsingDelegates;
		pdc.periodSystemAverageGranularity = Maybe.fromNull(pdcDTO.PeriodSystemAverageGranularity);
		pdc.periodSystemTimeScore = Maybe.fromNull(pdcDTO.PeriodSystemTimeScore);
		pdc.periodSystemAverageVelocity = Maybe.fromNull(pdcDTO.PeriodSystemAverageVelocity);
		pdc.periodConfiguredVelocityGoal = pdcDTO.PeriodConfiguredVelocityGoal;
		pdc.totalTimekeepersWithRewards = pdcDTO.TotalTimekeepersWithRewards;
		pdc.totalCashRewards = pdcDTO.TotalCashRewards;
		pdc.totalTimekeepersWithPenalties = pdcDTO.TotalTimekeepersWithPenalties;
		pdc.totalCashPenalties = pdcDTO.TotalCashPenalties;
		pdc.resultComparisons = pdcDTO.ResultComparisons.map(
			(rcDTO, i) => FakeDataUtils.updateEntityId(ResultComparison.createFrom(rcDTO), `${i}`) as ResultComparison
		);

		return pdc;
	}
}
