import {PeriodKind} from '../../core/ModelContracts';

export class LocaleService {
	static fromDateToLocaleFormattedPeriod = (date: Date, currentPeriod: PeriodKind): string => {
		const year = date.getFullYear().toString();
		const transcriptedMonth = date.toLocaleString('en-US', {month: 'short'}).replace(/[^ -~]/g, '');
		const monthlyFormattedDate = `${transcriptedMonth} ${year}`;
		const formattedDate = date.toLocaleDateString('en-US', {
			year: 'numeric',
			day: '2-digit',
			month: '2-digit',
		});

		return currentPeriod === 'M' ? monthlyFormattedDate : formattedDate;
	};
}
