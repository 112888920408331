import {Subject} from 'rxjs';
import {OkComponent} from '../views/shared/modal/Modals';
import {IModalService} from './IModalService';

export class ModalService implements IModalService {
	modals = [];
	modalsStream = new Subject<any[]>();

	push = (modal) => {
		this.modals.push(modal);
		this.modalsStream.next(this.modals);
	};

	close = () => {
		this.modals.pop();
		this.modalsStream.next(this.modals);
	};

	openOkDialog = (message: string, ok: () => void) => {
		const apply = () => {
			ok();
			this.close();
		};

		this.push(OkComponent(message, apply));
	};
}
