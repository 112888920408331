import {
	PeriodKind,
	CallContext,
	PolicyDashboardComparisonRequest,
	ServiceRequest,
	ServiceResponse,
	PolicyDashboardComparisonDTO,
	VelocityHistoryRequest,
	VelocityHistoryDTO,
	ComplianceHistoryRequest,
	ComplianceHistoryDTO,
} from '../ModelContracts';
import {Observable, of} from 'rxjs';
import {ComplianceHistory, VelocityHistory, PolicyDashboardComparison} from '../Models';
import {Maybe} from 'monet';
import {FakeData} from '../../data/FakeData';
import {RequestWrapper} from '../../frontend/helpers/RequestWrapper';
import {PolicyService} from '../../frontend/appServices/PolicyService';
import {PeriodsService} from '../../frontend/appServices/PeriodsService';
import {map} from 'rxjs/operators';
import {UrlBuilder, BackendRoutes, Environment} from '../../frontend/Routes';

export interface ComplianceRepository {
	getAllByPeriod: (periodKind: PeriodKind) => Observable<ComplianceHistory[]>;
}

export class ComplianceInMemoryRepository implements ComplianceRepository {
	constructor(private complianceHistories: ComplianceHistory[]) {}

	getAllByPeriod = (periodKind: PeriodKind) => of(this.complianceHistories);
}
