import {after} from 'lodash';
import moment = require('moment');

export class PolicyInitialMonthUtils {
	private static readonly MONTH_FORMAT: string = 'MMMM YYYY';

	static getOrderedMonthsFormatted = (policyInitialMonth: number) => {
		const monthIndex = policyInitialMonth - 1;
		const initialMonths = new Array(12).fill('');
		let formattedMonths = initialMonths.map((month, index) =>
			moment().month(monthIndex).add(index, 'months').format(PolicyInitialMonthUtils.MONTH_FORMAT)
		);
		return formattedMonths;
	};

	static getOrderedMonthsIndexes = (policyInitialMonth: number) => {
		const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
		let policyInitialMonthIndex = policyInitialMonth - 1;
		let initialMonth = months[policyInitialMonthIndex];
		let afterInitialMonth = months.slice(policyInitialMonth);
		let beforeInitialMonth = months.slice(0, policyInitialMonthIndex);
		let orderedMonths = [initialMonth, ...afterInitialMonth, ...beforeInitialMonth];
		return orderedMonths;
	};

	static orderByPolicyInitialMonth<T>(array: T[], previousIndex: number, currentIndex: number) {
		const steps = currentIndex - previousIndex;
		const firstPart = array.slice(0, steps);
		const lastPart = array.slice(steps);

		return [...lastPart, ...firstPart];
	}
}
