import {Subject} from 'rxjs';
import {PolicyEditorRepository} from '../repositories/PolicyEditorRepository';
import {tap, mergeMap, map} from 'rxjs/operators';
import {Policy} from '../models/Policy';

export class PolicyEditorService {
	private _policy: Policy = {} as Policy;
	private _policyStream: Subject<Policy> = new Subject();

	constructor(private repo: PolicyEditorRepository) {}

	initialRequest = (policyId: number) =>
		this.requestPolicy(policyId).pipe(
			mergeMap((policy) => this.requestIsPolicyEnabled(policy.id)),
			mergeMap(() => this.requestEngagementProperties(policyId))
		);

	private requestIsPolicyEnabled = (id: number) =>
		this.repo.isPolicyEnabledById(id).pipe(tap(this._policy.setIsEnabled));

	private requestEngagementProperties = (id: number) =>
		this.repo.getEngagementProperties(id).pipe(
			tap((ep) => this._policy.setBrazeDailyEnabled(ep.brazeDaily)),
			tap((ep) => this._policy.setBrazeWeeklyEnabled(ep.brazeWeekly)),
			tap((ep) => this._policy.setTimetelligenceWeeklyEnabled(ep.timetelligenceWeekly)),
			tap((ep) => this._policy.setTimetelligenceMonthlyEnabled(ep.timetelligenceMonthly)),
			tap(() => this.notifyPolicyUpdated(this._policy))
		);

	requestSetEngagementPropertyStatus = (policy: Policy) => this.repo.setEngagementPropertyStatus(policy);

	requestPolicy = (policyId: number) => this.repo.getPolicy(policyId).pipe(tap((policy) => (this._policy = policy)));

	editPolicy = (policy: Policy) => this.repo.editPolicy(policy).pipe(tap(this.notifyPolicyUpdated));

	editPolicyWithoutUpdatePolicy = (policy: Policy) =>
		this.repo.editPolicy(policy).pipe(tap(this.onlyNotifyPolicyUpdated));

	enablePolicy = (policy: Policy) => this.repo.enablePolicyById(policy.id);

	disablePolicy = (policy: Policy) =>
		this.repo.disablePolicyById(policy.id).pipe(
			tap(() => policy.setIsEnabled(false)),
			map(() => policy)
		);

	deletePolicy = () => this.repo.deletePolicyById(this._policy.id);

	duplicatePolicy = (policy: Policy) => {
		return this.repo.duplicatePolicy(policy).pipe(
			tap((p) => p.setName(`Copy of ${p.name}`)),
			mergeMap((p) => this.disablePolicy(p)),
			mergeMap((p) => this.editPolicy(p))
		);
	};

	addNewPolicy = (policyName: string) =>
		this.repo.addPolicy(Policy.createDefault(policyName)).pipe(tap(this.notifyPolicyUpdated));

	private notifyPolicyUpdated = (policy: Policy) => {
		this._policy = policy;
		this._policyStream.next(policy);
	};

	private onlyNotifyPolicyUpdated = (policy: Policy) => {
		console.log(this._policy);
		this._policyStream.next(policy);
	};

	get policy() {
		return this._policy;
	}

	get policyStream() {
		return this._policyStream;
	}
}
