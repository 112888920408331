import {PolicyEditorInteractorBase} from './PolicyEditorBaseInteractor';
import {INotificationService} from '../../../appServices/INotificationService';
import {PolicyEditorService} from '../../../../core/services/PolicyEditorService';
import {PolicyService} from '../../../appServices/PolicyService';
import {tap, mergeMap} from 'rxjs/operators';
import {Routes} from '../../../Routes';
import {INavigationService} from '../../../appServices/INavigationService';

export class PolicyEditorActionsInteractor extends PolicyEditorInteractorBase {
	constructor(
		navigationService: INavigationService,
		notificationService: INotificationService,
		policyEditorService: PolicyEditorService,
		policyService: PolicyService
	) {
		super(navigationService, notificationService, policyEditorService, policyService);
	}

	onChangePolicyIsEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.policyEditorService.policy.setIsEnabled(e.target.checked);
		this.updateView();
	};

	onAddNewPolicy = (name: React.RefObject<HTMLInputElement>) => (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		let policyName = name.current.value;

		this.notificationService.showLoader();
		this.policyEditorService
			.addNewPolicy(policyName)
			.pipe(
				tap((policy) => this.notificationService.log(`Policy succesfully added. New Id: ${policy.id}`)),
				tap((policy) => this.policyService.updateCurrentPolicyWithoutNotify(policy.id)),
				mergeMap(() => this.policyService.requestPoliciesForSubscription())
			)
			.subscribe();

		this.closeModal();
	};
	onDeletePolicy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		const refreshAndNavigate = () => {
			this.navigationService.navigate(Routes.dashboard);
			this.navigationService.refresh();
		};

		this.policyEditorService
			.deletePolicy()
			.pipe(tap(refreshAndNavigate))
			.subscribe(() => console.log('policy deleted'));

		this.closeModal();
	};

	onDuplicatePolicy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		this.notificationService.showLoader();
		this.policyEditorService
			.duplicatePolicy(this.policyEditorService.policy)
			.pipe(
				tap((policy) => this.notificationService.log(`Policy succesfully duplicated. New Id: ${policy.id}`)),
				tap((policy) => this.policyService.updateCurrentPolicyWithoutNotify(policy.id)),
				mergeMap(() => this.policyService.requestPoliciesForSubscription())
			)
			.subscribe(() => console.log('policy duplicated'));

		this.updateView();
		this.closeModal();
	};

	get isPolicyEnabled() {
		return this.policyEditorService.policy.isEnabled;
	}
}
